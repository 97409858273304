import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Item } from 'src/app/CALC/shared/item.model';
import { VehicleService } from '../../vehicle.service';

@Component({
  selector: 'app-item-delete',
  templateUrl: './item-delete.component.html',
  styleUrls: ['./item-delete.component.scss']
})
export class ItemDeleteComponent implements OnInit {

  @Input() public item: Item;
  constructor(public vehicleService: VehicleService,
              private modalService: NgbModal,
              public cookieService: CookieService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.vehicleService.trashItem(this.item);
    // this.vehicleService.selectedItem.next(undefined);
   
  }

}
