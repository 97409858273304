import { User } from "src/app/account/user.model";

export class ProjectDELPHY {


	static selectedProjectDELPHY: string = "selectedProjectDELPHY";
	static idProjectSelectedDELPHY: string = "idProjectSelectedDELPHY";


	public id: number;
	public name: string;
	public startDate: string;
	public latestUpdate: string;
	


	constructor($id: number, $name: string, $startDate: string, $latestUpdate: string) {
		this.id = $id;
		this.name = $name;
		this.startDate = $startDate;
		this.latestUpdate = $latestUpdate;
	}

}