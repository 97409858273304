import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Engine } from '../shared/engine.model';
import { Propeller } from '../shared/propeller.model';
import { AppendageService } from './appendages/appendage.service';
import { EngineService } from './engines/engine.service';
import { HullService } from './hulls/hull.service';
import { LcService } from './lcs/lc.service';
import { PropellerService } from './propellers/propeller.service';
import { VehicleService } from './vehicles/vehicle.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectDELPHYService {

  dashboardDis = "block"
  hullDis = "none";
  lcDis = "none";
  engDis = "none";
  propDis = "none";
  appDis = "none";
  // vehiclesDis= "none";
  othersDis= "none";
  vehDis = "none";
  selectedType = 'dashboard';
  selectedPropeller = new Subject<Propeller>();
  selectedEngine = new Subject<Engine>();

  constructor(private lcService: LcService,
    private vehicleService: VehicleService,
    public engineService: EngineService,
    public hullService: HullService,
    public propellerService: PropellerService,
    private appendageService: AppendageService,
    ) { }

  onActivate(display: string){
    console.log('display')
    console.log(display)
    // this.vehiclesDis = "none";
    this.othersDis = "none";
    this.dashboardDis = "none";
    this.hullDis = "none";
    this.lcDis = "none";
    this.engDis = "none";
    this.propDis = "none";
    this.appDis = "none";  
    this.vehDis = "none";  
    this.selectedType = display;
    if(display == 'hull'){
      this.hullDis = "block";
    }

    else if (display == 'dashboard'){
      this.dashboardDis = "block";
    }

    else if (display == 'lc'){
      this.lcDis = "block";
      this.lcService.lcsChanged.next(this.lcService.lcs);
    } 
    
    else if (display == 'eng'){
      this.engDis = "block";
    } 
    
    else if (display == 'prop'){
      this.propDis = "block";
     } 
     
    else if (display == 'app'){
      this.appDis = "block";
    } 

    else if (display == 'veh'){
      this.vehDis = "block";
    } 
   
  }
 /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }


  setUndefinedAll(){
    this.vehicleService.danielsChanged.next(undefined);
    this.vehicleService.daniels = undefined;

    this.vehicleService.selectedVehicleEvent.next(undefined);
    this.vehicleService.selectedVehicle = undefined;
    
    this.lcService.selectedLcEvent.next(undefined);
    this.lcService.selectedLc = undefined;

    this.engineService.selectedEngineEvent.next(undefined);
    this.engineService.selectedEngine = undefined;

    this.hullService.selectedHullEvent.next(undefined);
    this.hullService.selectedHull = undefined;

    this.propellerService.selectedPropellerEvent.next(undefined);
    this.propellerService.selectedPropeller = undefined;

    this.appendageService.selectedAppendageEvent.next(undefined);
    this.appendageService.selectedAppendage = undefined;

    this.appendageService.selectedAppendageSetEvent.next(undefined);
    this.appendageService.selectedAppendageSet = undefined;



    this.propellerService.ludwigsChanged.next(undefined);
    this.propellerService.ludwigs = undefined;
  }


}
