import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Lc } from '../../../shared/lc.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { LcEditComponent } from '../lc-edit/lc-edit.component';
import { LcService } from '../lc.service';

@Component({
  selector: 'app-lc-list',
  templateUrl: './lc-list.component.html',
  styleUrls: ['./lc-list.component.scss']
})
export class LcListComponent implements OnInit {

  lcs: Lc[];
  selectedLcId: number;
  constructor(
    public cookieService: CookieService,
    public lcService: LcService,
    public modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
    this.lcService.getLcs().subscribe(res => {
      this.lcService.lcs = res;
      this.lcService.lcsChanged.next(res);
    });
  }

  openAdd() {
    this.lcService.selectedEditMode = false;
    const modalRef = this.modalService.open(LcEditComponent);
  }

  onLcSelected(lc: Lc) {
    this.lcService.selectedLc = lc;
    this.lcService.selectedLcEvent.emit(lc);

    if (lc != undefined)
      this.selectedLcId = lc.id;
    else
      this.selectedLcId = undefined;
  }
}