import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Item } from '../../shared/item.model';
import { ListService } from '../projects/lists/list.service';
import { VariantService } from '../variants/variant.service';
import { VehicleDeleteComponent } from './vehicle-delete/vehicle-delete.component';
import { VehicleEditComponent } from './vehicle-edit/vehicle-edit.component';
import { Vehicle } from './vehicle.model';
import { VehicleService } from './vehicle.service';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  subscription: Subscription;
  vehicles: Vehicle[];
  // incomingFilter: VehicleFilter = new VehicleFilter("incoming", "INCOMING", "NONE");

  @Output() vehicleWasSelected = new EventEmitter<Vehicle>();

  // filters: VehicleFilter;
  selectedVehicleId: number;
  selectedVehicle: Vehicle;

  
  constructor(
    public vehicleService: VehicleService,
    private router: Router,
    private modalService: NgbModal,
    public cookieService: CookieService,
    public accountService: AccountService,
    private variantService: VariantService,
    private listService: ListService
  ) 
    {this.cookieService.set('typeMenu', 'vehicle'); }


  ngOnInit() {
    this.cookieService.set("topbarTitle","Vehicles");
    this.variantService.deleteFromCookie();
    this.listService.deleteFromCookie();

    // this.vehicleService.deleteItemInfoListCookie()

    this.cookieService.set(Item.servletName, 'BaseLineItem');
    this.cookieService.set(Item.tableTitleItem, 'Baseline items');

    this.vehicleService.selectedVehicle = undefined;
    this.vehicleService.closeItemEditForm();
    this.subscription = this.vehicleService.getVehicles().subscribe(res => {
      this.vehicles = res;
      this.vehicleService.setVehicles(this.vehicles);
      console.log(this.vehicles);
    });
    
  }

  ngAfterViewInit(){
    // this.cookieService.set('typeMenu', 'vehicle');
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  openAdd() {
    this.vehicleService.selectedVehicle=undefined;
    this.vehicleService.selectedVehEditMode = false;
    const modalRef = this.modalService.open(VehicleEditComponent);
  }

  
  onVehicleSelected(vehicle: Vehicle) {
    this.vehicleService.selectedVehicleEvent.next(vehicle);
    this.vehicleService.selectedVehicle=vehicle;
  }


  onVehicles(vehicle: Vehicle){
    // routing to vehicles
    this.router.navigate(['../CALC/variants']);
    // aggiungere al cookie il vehicleEl o un emmiter per i vehicles
    this.cookieService.set(Vehicle.selectedVehicle, JSON.stringify(vehicle));
    this.cookieService.set(Vehicle.idVehicleSelected, JSON.stringify(vehicle.idVehicle));

    // aggiungere un evento per cambiare la sidebar
    this.cookieService.set('typeMenu', 'variant');
    this.vehicleService.selectedVehicleEvent.next(vehicle);
    // this.vehicleService.selectedVehicle=vehicle;
  }

}
