import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hull-dashboard',
  templateUrl: './hull-dashboard.component.html',
  styleUrls: ['./hull-dashboard.component.scss']
})
export class HullDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
