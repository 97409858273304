import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Appendage } from '../../../shared/appendage.model';
import { AppendageSet } from '../../../shared/appendageset.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { AppendageSetDeleteComponent } from '../appendage-set-delete/appendage-set-delete.component';
import { AppendageSetEditComponent } from '../appendage-set-edit/appendage-set-edit.component';
import { AppendageService } from '../appendage.service';

@Component({
  selector: 'app-appendage-set-details',
  templateUrl: './appendage-set-details.component.html',
  styleUrls: ['./appendage-set-details.component.scss']
})
export class AppendageSetDetailsComponent implements OnInit {

  appendageSet: AppendageSet;
  project: ProjectDELPHY;

  rudder: Appendage;
  fin: Appendage;
  keel: Appendage;
  shaft: Appendage;
  bracket: Appendage;

  constructor(
    public cookieService: CookieService,
    public appendageService: AppendageService,
    private modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
 
    if(this.appendageService.selectedAppendageSet == undefined){
      this.appendageService.selectedAppendageSetEvent.subscribe(
        (selectedAppendageSet: AppendageSet) => {
          this.appendageSet = selectedAppendageSet;

          if(selectedAppendageSet != undefined){
            this.rudder = this.appendageService.getAppendage(this.appendageSet.rudder)
            this.fin = this.appendageService.getAppendage(this.appendageSet.fin)
            this.keel = this.appendageService.getAppendage(this.appendageSet.keel)
            this.shaft = this.appendageService.getAppendage(this.appendageSet.shaft)
            this.bracket = this.appendageService.getAppendage(this.appendageSet.bracket)
          }
          
        }
      );
    }
  }

  onEdit() {
    this.appendageService.selectedSetEditMode = true;
    const modalRef = this.modalService.open(AppendageSetEditComponent);
    modalRef.componentInstance.appendageSet = this.appendageService.selectedAppendageSet;
    // modalRef.componentInstance.type = this.appendageSet.category;
  }

  onClone() {
    // const modalRef = this.modalService.open(AppendageSetCloneComponent);
    // modalRef.componentInstance.appendageSet = this.appendageService.selectedAppendageSet;
    // modalRef.componentInstance.type = this.appendageSet.category;
    // modalRef.componentInstance.type = this.appendageSet.category;
  }

  onTrash() {
    const modaldelete = this.modalService.open(AppendageSetDeleteComponent);
    modaldelete.componentInstance.appendageSet = this.appendageService.selectedAppendageSet;
  }

}
