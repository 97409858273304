import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from 'src/app/account/account.service';
import { Item } from 'src/app/CALC/shared/item.model';
import { Tank } from 'src/app/CALC/shared/tank.model';

@Injectable({
  providedIn: 'root'
})
export class TankService {

  tanks: Tank[];
  tanksChanged = new Subject<Tank[]>();
  selectedEditModeEvent = new EventEmitter<boolean>();
  selectedEditMode: boolean;

  selectedTankEvent = new EventEmitter<Tank>();
  selectedTank: Tank;

  editTankReady: boolean = false;
  error = new Subject<string>();
  // tanksChanged = new Subject<Tank[]>(); 
  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }




    createTank(tank: Tank) {

      this.http
        // aggiungere <{è un optional raccomandato}>
        .post<{ tank: Tank }>(
          this.accountService.REST_API_SERVER_CALC
          + 'tankServlet?action=CREATE&loggedInEmail=' + this.accountService.currentUser.email 
          + '&idForeign=' +this.cookieService.get(Item.tableNameIdList),
          tank
        )
        .subscribe(responseData => {
          if(responseData['Result'] == 'OK'){
            let tankRes = responseData['Record'];
            this.tanks.push(tankRes);
            this.setTanks(this.tanks);
            this.toastr.success('Tank  created');
          }
          
        },
          error => {
            this.toastr.error(error.message);
            console.log(error.message);
            this.error.next(error.message);
          });
  
    }
  
    updateTank(tank: Tank) {
      this.http
        // aggiungere <{è un optional raccomandato}>
        .post<{ tank: Tank }>(
          this.accountService.REST_API_SERVER_CALC
          + 'tankServlet?action=UPDATE&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' +this.cookieService.get(Item.tableNameIdList),
          tank
        )
        .subscribe(responseData => {
          // devo trovare l'tank negli tanks e sostituirlo
          if(responseData['Result'] == 'OK'){
            
            let retrievedTank = responseData['Record'];
            console.log('retrievedTank');
            console.log(retrievedTank);
            let tankIndex = this.tanks.findIndex(tank => tank.id == retrievedTank.id);
            this.tanks[tankIndex] = retrievedTank;
            // this.tanks.unshift(tank);
            this.setTanks(this.tanks);
            // this.tanksChanged.next(this.tanks.slice());
            this.toastr.success('Tank  updated');
          }
        },
          error => {
            this.toastr.error(error.message);
            console.log(error.message);
            this.error.next(error.message);
          });
  
    }
  
    trashTank(tank: Tank) {
      this.http
        // aggiungere <{è un optional raccomandato}>
        .post<{ tank: Tank }>(
          this.accountService.REST_API_SERVER_CALC + 'tankServlet?action=DELETE&loggedInEmail='
          + this.accountService.currentUser.email + '&idForeign=' + tank.idForeign+ '&id=' + tank.id,
          tank
        )
        .subscribe(responseData => {
          if (responseData["Result"] == "OK") {
            this.onCloseForm();
            console.log(responseData["Result"]);
            const index = this.tanks.findIndex(x => x.id == tank.id);
            this.tanks.splice(index, 1);
            this.setTanks(this.tanks);
            // this.selectedEditModeEvent.next(undefined);
            // this.selectedEditMode = undefined;
            this.toastr.success('This tank has been deleted', 'Tank deleted');
          } else {
            this.error.next(responseData["Result"].message);
          }
        },
          error => {
            this.toastr.error(error.message);
            console.log(error.message);
            this.error.next(error.message);
          });
  
    }


  public getTanks(tableName: string, idList: number): Observable<Tank[]> {
    return this.http.get<Tank[]>(this.accountService.REST_API_SERVER_CALC
      + tableName + 'Servlet?action=LIST&loggedInEmail='
      + this.accountService.currentUser.email + '&idForeign='
      + idList 
    )
      .pipe(map(result => result['Records'])
      );
  }

  getTank(id: number) {
    const Tank = this.tanks.find(
      (r) => {
        return r.id== id;
      }
    );
    return Tank;
  }

  setTanks(tanks: Tank[]) {
    this.tanks = tanks;
    this.tanksChanged.next(this.tanks.slice());
    
  }

  notifyEditor() {
    console.log("nofityeditor start");

    this.selectedEditModeEvent.next(this.selectedEditMode);
    this.selectedTankEvent.next(this.selectedTank);
  }

  public selectTankForEdit(tank: Tank) {
    this.selectedTank = tank;
    if (this.editTankReady) {
      this.notifyEditor();
    } else {
      this.selectedEditModeEvent.next(this.selectedEditMode);
    }
  }
  public editorReady() {
    this.editTankReady = true;
    this.notifyEditor();
  }

  onCloseForm(){
    this.selectedTank = undefined;
    this.selectedTankEvent.next(undefined);

    this.selectedEditMode = undefined;
    this.selectedEditModeEvent.next(undefined);
  }

}
