<div class="container-fluid">
    <!-- <app-page-title title="Vehicles Grid" [breadcrumbItems]="breadCrumbItems"></app-page-title> -->



    <div class="textUnderIcon" *ngIf="cookieService.get('typeMenu') == 'vehicle' || cookieService.get('typeList') == 'versionList' || cookieService.get('typeList') == 'propulsionList'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; ">Item dashboard – Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to an item, by clicking on a row is possible to edit the detailed information.</h6>
                                            </div>
                                        </div>
                                        <div class="col-6" style="cursor: pointer">
                                            <i class="bx bx-line-chart font-size-20"></i>
                                            <h6 class="list-name-help">Graphic analysis</h6>
                                            <h6 class="font-size-11">by clicking on the arrow is possible to access to some graphs.</h6>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-copy font-size-20"></i>
                                            <h6 class="font-size-13">Clone feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6>The <b>Baseline</b> will always be part of the configuration. In this table is possible to insert items that are always present in all vehicle and variants.</h6>

                                        <h6><b>Summary information</b> of this table (Displacement and CG coordinates) are computed only on data present in this table. For the result of the specific configuration please refer to the <b>Report</b> feature.</h6>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'optList'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; ">Optional Items - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Optional Items list, by clicking on a row is possible to edit the list adding its content.</h6>
                                            </div>
                                        </div>
                                        <div class="col-6" style="cursor: pointer">
                                            <i class="bx bx-line-chart font-size-20"></i>
                                            <h6 class="list-name-help">Graphic analysis</h6>
                                            <h6 class="font-size-11">by clicking on the arrow is possible to access to some graphs.</h6>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-copy font-size-20"></i>
                                            <h6 class="font-size-13">Clone feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bx-customize font-size-20"></i>
                                            <h6 class="font-size-13">Manage the items, adding or deleting from the Options Summary</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6>“<b>Optional Items</b>” is the category that represents the list of optional items name to be used in the project. An example might be:
                                        </h6>
                                        <ul>
                                            <li>
                                                <h6>Premium Pack</h6>
                                            </li>
                                            <li>
                                                <h6>Silver Pack</h6>
                                            </li>
                                            <li>
                                                <h6>etc</h6>
                                            </li>
                                        </ul>
                                        <h6>The specific “<b>Options list</b>” is a subcategory of Optional Items and it is possible to add elements by clicking on the “<b>Manage list</b>” button.</h6>
                                        <h6>If the row is red, it means that the item needs to be updated. More specifically, the coordinates and the quantity must be entered.</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>




    <div class="row" style="align-items: center; ">

        <div class="col-sm-2" style="text-align: center; ">

            <div class="cardChart card">
                <div class="cardInfo">
                    <div class="card-body">
                        <div class="media d-flex">
                            <div class="align-self-center">
                                <i class="bx bx-line-chart font-size-22" style="color: white;"></i>
                            </div>
                            <div class="media-body text-right" style="cursor: pointer;" (click)="showChart()">
                                <i class="bx bx-caret-down  font-size-22" style="color: white;" *ngIf=" !chartShow "></i>
                                <i class="bx bx-caret-up font-size-22 " style="color: white;" *ngIf="chartShow"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-2" style="text-align: center; ">
            <div class="card">
                <div class="cardInfo card-body pt-0">

                    <div class="media d-flex">
                        <div class="align-self-center">
                            <i class="bx bx-send bxExp font-size-22"></i>
                        </div>
                        <div class="media-body text-right">
                            <h5 class="font-size-13">Title</h5>
                            <h5 class="font-size-15" style="font-weight: bold;">{{this.cookieService.get('itemTableTitle')}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div class="col-sm-2" style="text-align: center; ">
            <div class="card">
                <div class="cardInfo card-body pt-0">
                    <div class="media d-flex">
                        <div class="align-self-center">
                            <i class="bx bx-list-ul bxExp font-size-22"></i>
                        </div>
                        <div class="media-body text-right">
                            <h5 class="font-size-13">#</h5>
                            <h5 class="font-size-15" style="font-weight: bold;">{{vehicleService.listCalculate[7]}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-sm-2" style="text-align: center; ">
            <div class="card">
                <div class="cardInfo card-body pt-0">

                    <div class="media d-flex">
                        <div class="align-self-center">
                            <i class="bx bxs-ship bxExp font-size-22"></i>
                        </div>
                        <div class="media-body text-right">
                            <h5 class="font-size-13">&Delta;</h5>
                            <h5 class="font-size-15" style="font-weight: bold;">{{vehicleService.listCalculate[0] | number : '1.0-0' | noComma}} Kg</h5>
                        </div>
                    </div>

                </div>
            </div>
        </div>




        <div class="col-sm-4" style="text-align: center; ">
            <div class="card">
                <div class="cardInfo card-body pt-0">

                    <div class="media d-flex">
                        <div class="align-self-center">
                            <i class="bx bx-shape-triangle bxExp font-size-22"></i>
                        </div>
                        <div class="media-body">
                            <div class="row">
                                <div class="col-3 text-left">

                                </div>
                                <div class="col-3 text-left">
                                    <h5 class="font-size-13">X<sub>G</sub></h5>
                                    <h5 class="font-size-15" style="font-weight: bold;">{{vehicleService.listCalculate[1] | number : '1.2-2'}} m</h5>
                                </div>
                                <div class="col-3 text-center">
                                    <h5 class="font-size-13">Y<sub>G</sub></h5>
                                    <h5 class="font-size-15" style="font-weight: bold;">{{vehicleService.listCalculate[2] | number : '1.2-2'}} m</h5>
                                </div>
                                <div class="col-3 text-right">
                                    <h5 class="font-size-13">Z<sub>G</sub></h5>
                                    <h5 class="font-size-15" style="font-weight: bold;">{{vehicleService.listCalculate[3] | number : '1.2-2'}} m</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>



    </div>

    <div *ngIf="chartShow">
        <app-item-pie-graph></app-item-pie-graph>
    </div>



    <div class="row">
        <div [ngClass]="{'col-xl-9':  vehicleService.selectedEditMode != undefined,'col-xl-12':vehicleService.selectedEditMode == undefined}">


            <div class="card">
                <div class="card-body" style="padding: 0px!important;">


                    <!-- <div class="col-xl-9"> -->
                    <p-table #dt [columns]="this.vehicleService._selectedColumns" [value]="this.vehicleService.itemsLastRev" [reorderableColumns]="true" styleClass="p-datatable-gridlines" [scrollable]="true" scrollHeight="55vh" [paginator]="true" [rows]="rows" virtualScroll={true}
                        [showCurrentPageReport]="true" [(first)]="first" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single" [rowsPerPageOptions]="[10,25,50,100,500,1000,10000]" dataKey="idItem" rowExpandMode="single">


                        <ng-template pTemplate="caption" style=" margin-right: 0px; height: 45px; ">

                            <div class="row" style=" margin-right: 0px; height: 35px;">
                                <div class="col-sm-4" style="text-align: left; padding-bottom: 0px!important;">
                                    <p-multiSelect [options]="this.vehicleService.cols" [(ngModel)]="this.vehicleService._selectedColumns" optionLabel="header" selectedItemsLabel="{0} cols selected" [style]="{minWidth: '260px'}" placeholder="Choose Columns">
                                    </p-multiSelect>
                                </div>
                                <div class="col-sm-7">
                                </div>
                                <div class="col-sm-1" style="text-align: center;" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
                                    <div class="row" style="display: contents;">
                                        <i class="bx bx-customize font-size-22" style="align-items: right; color: white; cursor: pointer;" *ngIf="(cookieService.get('servletName') == 'optItem' || cookieService.get('selectedVariant') == undefined)" (click)="onSelectOptionalModal()" title="Manage list"></i>
                                    </div>
                                    <div class="row" style="color: white; font-size: 10px; display:grid; cursor: pointer" *ngIf="(cookieService.get('servletName') == 'optItem' || cookieService.get('selectedVariant') == undefined)" (click)="onSelectOptionalModal()" title="Manage list">Manage list</div>
                                </div>
                                <!-- <div class="col-sm-4" style="text-align: right; padding-top:10px;" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
                                    <i class="bx bx-customize font-size-22" style="align-items: right; color: white; cursor: pointer;" *ngIf="(cookieService.get('servletName') == 'optItem' || cookieService.get('selectedVariant') == undefined)" (click)="onSelectOptionalModal()" title="Manage list"> Manage list</i>

                                </div> -->
                            </div>

                        </ng-template>




                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col style="width: 30px!important">
                                <col *ngFor="let col of columns" [ngStyle]="{ 'width': col.width}">

                            </colgroup>
                        </ng-template>



                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th></th>
                                <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" pReorderableColumn>

                                    <div *ngIf="col.header=='Name'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Job'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="this.vehicleService.jobsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Topological'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="this.vehicleService.topologicalsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Weight'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Xg'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Yg'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Zg'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" step="0.1" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Rev'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="this.vehicleService.revsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Type'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="this.vehicleService.typesFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Product'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="this.vehicleService.productsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Design'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="this.vehicleService.designsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Est.Weight'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>


                                    <div *ngIf="col.header=='Margin'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Units'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="this.vehicleService.typesFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Quantity'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Note'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='AssemblyHours'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Price'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Last Date'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="date" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-itemRow let-columns="columns" let-index="rowIndex">
                            <tr [pSelectableRow]="itemRow" [ngClass]="{'item-to-be-check':  itemRow.xg == 0 && itemRow.yg == 0 && itemRow.zg == 0}" [pReorderableRow]="index">
                                <td>
                                    <i class="bx bx-sitemap font-size-10" *ngIf="itemRow.type=='DWG'" [pRowToggler]="itemRow" (click)="onItemsRev(itemRow.itemCode)"></i>

                                </td>
                                <td *ngFor="let col of columns" (click)="onSelectItem(itemRow)" title="{{itemRow.name}}, Rev: {{itemRow.revisionName}}">
                                    <div *ngIf="col.header=='Product'">
                                        {{itemRow[col.field] | shorten: 15}}
                                    </div>
                                    <div *ngIf="col.header!='Product'">
                                        {{itemRow[col.field] }}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="paginatorleft">
                            <div class="p-d-flex">
                                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom" title="Download csv"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" title="Download excel"></button>
                                <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onExportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" title="Download pdf"></button>
                            </div>
                        </ng-template>


                        <ng-template pTemplate="rowexpansion" let-item>
                            <tr>
                                <td class="tableExpan"></td>
                                <div>
                                    <p-table [value]="this.vehicleService.itemsRev" dataKey="idItem" [columns]="selectedColumns">
                                        <!-- <ng-template pTemplate="header" let-columns>
                                        <tr>
                                            <th *ngFor="let col of columns" pSortableColumn="{{col.field}}">
                                                {{col.header}}
                                                <p-sortIcon id="idIconSort" field="{{col.field}}"></p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template> -->
                                        <ng-template pTemplate="colgroup" let-columns>
                                            <colgroup>
                                                <col *ngFor="let col of columns" [ngStyle]="{ 'width': col.width}">

                                            </colgroup>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item let-columns="columns">
                                            <tr [pSelectableRow]="item">
                                                <!-- <td>
                                                                <p-tableCheckbox [value]="item"></p-tableCheckbox>
                                                            </td> -->
                                                <td class="tableExpan" *ngFor="let col of columns" (click)="onSelectItem(item)" title="{{item.name}}, Rev: {{item.revisionName}}">
                                                    {{item[col.field]}}
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td class="tableExpan" colspan="columns.length">There are no revision for this item, yet.</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

            </div>
        </div>



        <!-- details -->
        <div class="col-lg-3" *ngIf="vehicleService.selectedEditMode !=undefined">
            <!-- class="col-xl-3"  -->
            <!-- <router-outlet></router-outlet>
            <ng-template #infoText>
                <p>Please select an item</p>
            </ng-template> -->
            <app-item-edit></app-item-edit>
        </div>
    </div>

    <!-- end row -->
</div>







<div *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
    <div class="floating-action-button" (click)="onNewItem()" *ngIf="!(cookieService.get('servletName') == 'optItem' || cookieService.get('selectedVariant'))">
        <button type="button" class="btn btn-primary btn-circle btn-lg" id="addBtn">
        <i class="fas fa-plus" title="New"></i>
        <div class="textUnderIcon">New</div>
    </button>
    </div>
</div>