<div class="micard overflow-hidden">

    <div class="micard-header" style="cursor: pointer;" title="Show projectEl.name">
        <div>
            <h5 *ngIf="hullService.selectedHull != undefined" class="list-title">{{hull?.name}}</h5>
        </div>
    </div>


    <div class="details-body" *ngIf="hullService.selectedHull == undefined">
        <p>Please Select a Hull</p>
    </div>

    <div class="details-body" *ngIf="hullService.selectedHull != undefined">
        <div class="row">
            <div class="col-sm-12 property-box">
                <div class="row">
                    <div class="property-name">
                        Creation
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{hull?.date}}
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        B
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{hull?.b}} m
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        B<sub>OA</sub>
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{hull?.boa}} m
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 property-box">
                <div class="row">
                    <div class="property-name">
                        L<sub>OA</sub>
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{hull?.loa}} m
                    </div>
                </div>
            </div>
            <div class="col-sm-4 property-box">
                <div class="row">
                    <div class="property-name">
                        &beta;
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{hull?.beta}} °
                    </div>
                </div>
            </div>
            <div class="col-sm-4 property-box">
                <div class="row">
                    <div class="property-name">
                        &epsilon;
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{hull?.epsilon}} °
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        P<sub>X</sub>
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{hull?.Px}} m
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        P<sub>Z</sub>
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{hull?.Pz}} m
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row" *ngIf="hullService.selectedHull != undefined">
            <div class="col-sm-6 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-edit-alt font-size-22 action-icon" (click)="onEdit()" title="Edit"></i>
            </div>

            <div class="col-sm-6 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-trash-alt font-size-22 action-icon" (click)="onTrash()" title="Delete"></i>
            </div>

        </div>







    </div>
</div>