import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/account/user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-delete',
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss']
})
export class UserDeleteComponent implements OnInit {

  @Input() user: User;
  constructor(public userService: UserService) { }

  ngOnInit(): void {
  }
  onSubmit() {
    this.userService.onCloseForm();
    this.userService.deleteUser(this.user);
   
  }
}
