<div class="content">
    <div class="row">
        <div class="col-sm-12" style="text-align: center;">
            <h3 style=" padding-bottom: 2%;
                        font-family: Barlow;
                        font-weight: bold;
                        font-size: 2 rem;     
                        " *ngIf="userService.selectedEditMode == false">
                Invite someone in your company
            </h3>

            <h3 style=" padding-bottom: 2%;
                        font-family: Barlow;
                        font-weight: bold;
                        font-size: 2 rem;     
                        " *ngIf="userService.selectedEditMode == true">
                Edit Account
            </h3>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">

            <form [formGroup]="signupForm" (ngSubmit)="onSubmitRegister()">

                <fieldset class="col s12">

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label>Name*</label>
                                <input type="text" autocomplete="off" id="name" formControlName="name" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label>Surname*</label>
                                <input type="text" autocomplete="off" id="surname" formControlName="surname" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form form-group">
                                <label>Company name*</label>
                                <select multiple class="form-control" formControlName="company" id="company">

                                    <option *ngFor="let company of projectService.companies" [ngValue]="company">
                                        {{company.name}}
                                    </option>
    
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form form-group">
                                <label for="roleName">Select a Role*</label>
                                <select class="form-control" formControlName="roleName" id="roleName">
                                <option *ngFor="let role of roleList" [value]="role.name">
                                    {{role.name}}
                                </option>
                            </select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form form-group">
                                <label>Country*</label>
                                <input type="text" id="country" formControlName="country" class="form-control">
                            </div>
                        </div>
                    </div>


                    <div class="row" *ngIf="userService.selectedEditMode == false">
                        <div class="col-sm-12">
                            <div class="form form-group">
                                <label>Email*</label>
                                <input type="email" id="email" formControlName="email" class="form-control">
                            </div>
                        </div>
                    </div>



                    <div class="row" *ngIf="userService.selectedEditMode == false">
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label>Password*</label>
                                <input type="password" id="txtNewPassword" onkeyup="passwordkeyup()" formControlName="password" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label>Confirm password*</label>
                                <input type="password" id="txtConfirmPassword" onkeyup="passwordkeyup()" formControlName="confirmPassword" class="form-control">
                            </div>
                        </div>
                    </div>



                    <div class="registrationFormAlert" id="divCheckPasswordMatch"></div>


                    <!-- <label><span></span><input type="submit" value="Register">  </label>	 -->
                </fieldset>

                <button *ngIf="userService.selectedEditMode == false" class="btn login" type="submit" style="color:#040E1D" value="Register" id="register" [disabled]="!signupForm.valid">Register</button>
                <button *ngIf="userService.selectedEditMode == true" class="btn login" type="submit" style="color:#040E1D" value="Register" id="register" [disabled]="!signupForm.valid">Update</button>

            </form>
        </div>
    </div>
</div>