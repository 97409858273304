import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DashboardComponent } from '../../CALC/pages/projects/dashboard/dashboard.component';
import { ManageComponent } from '../users/manage/manage.component';
import { ProfileComponent } from '../users/profile/profile.component';
import { DibaComponent } from './dibas/diba/diba.component';
import { ReportsComponent } from './reports/reports.component';
import { OptionalsComponent } from './vehicles/optionals/optionals.component';

const routes: Routes = [

  // { path: '', component: DashboardComponent },
 

  // {
  //   path: 'projects', loadChildren: () =>
  //     import('./projects/projects-routing.module').then(m => m.ProjectsRoutingModule)
  // },

  // {
  //   path: 'vehicles', loadChildren: () =>
  //     import('./vehicles/vehicles-routing.module').then(m => m.VehiclesRoutingModule)
  // },

  // {
  //   path: 'baseline', loadChildren: () =>
  //     import('./vehicles/items/items-routing.module').then(m => m.ItemsRoutingModule)
  // },

  // { path: 'optionalItems', component: OptionalsComponent },

  // {
  //   path: 'variants', loadChildren: () =>
  //     import('./variants/variants-routing.module').then(m => m.VariantsRoutingModule)
  // },

  // { path: 'report', component: ReportsComponent },

  // { path: 'diba', component: DibaComponent },

  { path: 'dashboard', component: DashboardComponent },
  // { path: 'dashboard', redirectTo: 'https://easypro.micad.it/easyproFE/' },

  // { path: 'userProfile', component: ProfileComponent },

  // { path: 'manage', component: ManageComponent }
  
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalcRoutingModule {
 
 }
