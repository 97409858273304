import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AppendageSet } from '../../../shared/appendageset.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { AppendageService } from '../appendage.service';

@Component({
  selector: 'app-appendage-set-edit',
  templateUrl: './appendage-set-edit.component.html',
  styleUrls: ['./appendage-set-edit.component.scss']
})
export class AppendageSetEditComponent implements OnInit {

  appendageSetForm: FormGroup;
  error = "";
  @Input() public appendageSet: AppendageSet;

  constructor(public appendageService: AppendageService,
    public cookieService: CookieService,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {

      // if(this.appendageSet == undefined){
      //   this.hullService.getHulls().subscribe(res => {
      //     this.hullService.hulls = res;
      //     this.appendageSetForm.patchValue({ "hull": this.hullService.hulls[0].id });
      //   });
      //   this.engineService.getEngines().subscribe(res => {
      //     this.engineService.engines = res;
      //     this.appendageSetForm.patchValue({ "engine": this.engineService.engines[0].id });
      //   });

      //   this.lcService.getLcs().subscribe(res => {
      //     this.lcService.lcs = res;
      //     this.lcService.lcsChanged.next(res);
      //     this.appendageSetForm.patchValue({ "lc": this.lcService.lcs[0].id });
      //   });

      //   this.propellerService.getPropellers().subscribe(res => {
      //     this.propellerService.propellers = res;
      //     this.appendageSetForm.patchValue({ "propeller": this.propellerService.propellers[0].id });
      //   });
        
      // }
      
    this.initForm();
  }

  ngOnChanges() {
    console.log(this.appendageSetForm);
  }
  onSubmit() {

    if(this.appendageSetForm.value.rudder != undefined 
      || this.appendageSetForm.value.fin != undefined
      || this.appendageSetForm.value.keel != undefined
      || this.appendageSetForm.value.shaft != undefined
      || this.appendageSetForm.value.bracket != undefined
      ){
    this.modalService.dismissAll();

    if(this.appendageService.selectedSetEditMode){
      this.appendageService.updateAppendageSet(this.appendageSetForm.value);
    }else{
      this.appendageService.createAppendageSet(this.appendageSetForm.value);
    }

  } else {
    this.error = "Select at least one appendage"
  }
    
  }

  


  private initForm() {

   

    let id: number;
    let name: string;
    let date: string;

    let rudder : number;
    let n_rudder : number;

    let fin: number;
    let n_fin: number;

    let keel: number;
    let n_keel: number;

    let shaft: number;
    let n_shaft: number;

    let bracket: number;
    let n_bracket: number;

    let project = +this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY);

    if(this.appendageSet != undefined && this.appendageService.selectedSetEditMode == true){
    id = this.appendageSet.id;
    name = this.appendageSet.name;
    date = this.appendageSet.date;

    rudder = this.appendageSet.rudder;
    n_rudder = this.appendageSet.n_rudder;

    fin = this.appendageSet.fin;
    n_fin = this.appendageSet.n_fin;

    keel = this.appendageSet.keel;
    n_keel = this.appendageSet.n_keel;

    shaft = this.appendageSet.shaft;
    n_shaft = this.appendageSet.n_shaft;

    bracket = this.appendageSet.bracket;
    n_bracket = this.appendageSet.n_bracket;

    }

    this.appendageSetForm = new FormGroup({
      'id': new FormControl(id),

      'name': new FormControl(name, Validators.required),
      'date': new FormControl(date),

      'rudder': new FormControl(rudder),
      'n_rudder': new FormControl(n_rudder),

      'fin': new FormControl(fin),
      'n_fin': new FormControl(n_fin),

      'keel': new FormControl(keel),
      'n_keel': new FormControl(n_keel),

      'shaft': new FormControl(shaft),
      'n_shaft': new FormControl(n_shaft),
      
      'bracket': new FormControl(bracket),
      'n_bracket': new FormControl(n_bracket),
      
      'project': new FormControl(project)
    });
  }

}
