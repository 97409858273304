import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Variant } from 'src/app/CALC/shared/variant.model';
import { DibaService } from '../diba.service';

@Component({
  selector: 'app-diba',
  templateUrl: './diba.component.html',
  styleUrls: ['./diba.component.scss']
})
export class DibaComponent implements OnInit {

  cols: any[];
  constructor(
    public dibaService: DibaService,
    public cookieService: CookieService
  ) { }
 
  ngOnInit(): void {

    let selVariant = this.cookieService.get(Variant.selectedVariant);
    if (selVariant != undefined) {
      this.dibaService.getDibaItems(JSON.parse(selVariant) as Variant);
    }
    
  
    this.cols = [
      { field: 'name', header: 'Name', width: "260px" },
      { field: 'jobName', header: 'Job', width: "120px" },
      { field: 'topologicalName', header: 'Topological', width: "150px" },
      
      { field: 'quantity', header: 'Quantity', width: "140px" },
      { field: 'revisionName', header: 'Rev', width: "95px" },
      { field: 'type', header: 'Type', width: "100px" },
      { field: 'productName', header: 'Product', width: "140px" },
      { field: 'designName', header: 'Design', width: "140px" },
      { field: 'estimateWeight', header: 'Est.Weight', width: "150px" },
      { field: 'margin', header: 'Margin', width: "140px" },
      { field: 'unitsOfMeasureName', header: 'Units', width: "140px" },
      { field: 'note', header: 'Note', width: "140px" },
      { field: 'lastUpdateDate', header: 'Last Date', width: "140px" },
      { field: 'assemblyHours', header: 'AssemblyHours', width: "150px" },
      { field: 'price', header: 'Price', width: "100px" }
    ];
  }

}
