import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ProjectService } from '../../../projects/project.service';
import { AppendageSet } from '../../../shared/appendageset.model';
import { Engine } from '../../../shared/engine.model';
import { Hull } from '../../../shared/hull.model';
import { Lc } from '../../../shared/lc.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { Propeller } from '../../../shared/propeller.model';
import { Vehicle } from '../../../shared/vehicle.model';
import { AppendageService } from '../../appendages/appendage.service';
import { EngineService } from '../../engines/engine.service';
import { HullService } from '../../hulls/hull.service';
import { LcService } from '../../lcs/lc.service';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { PropellerService } from '../../propellers/propeller.service';
import { VehicleEditComponent } from '../vehicle-edit/vehicle-edit.component';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit {

  selectedVehicleId: number;



  constructor(public hullService: HullService,
    public lcService: LcService,
    public engineService: EngineService,
    public propellerService: PropellerService,
    public appendageService: AppendageService,
    public cookieService: CookieService,
    public vehicleService: VehicleService,
    public modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService,
    public projectService: ProjectService) { }

  ngOnInit(): void {
    this.vehicleService.getVehicles().subscribe(res => {
      this.vehicleService.vehicles = res;
      this.vehicleService.vehiclesChanged.next(this.vehicleService.vehicles);
    });

    if (this.projectService.selectedProject == undefined) {
      this.projectService.selectedProject = JSON.parse(this.cookieService.get(ProjectDELPHY.selectedProjectDELPHY));
    }
  }

  openAdd() {
    this.vehicleService.selectedEditMode = false;
    const modalRef = this.modalService.open(VehicleEditComponent);
  }

  onVehicleSelected(vehicle: Vehicle) {
    if (vehicle.id != this.selectedVehicleId) {
      this.vehicleService.selectedVehicle = vehicle;
      this.vehicleService.selectedVehicleEvent.emit(vehicle);

      if (vehicle != undefined)
        this.selectedVehicleId = vehicle.id;
      else
        this.selectedVehicleId = undefined;
    }
  }

}
