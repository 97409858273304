<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
    <perfect-scrollbar [config]="configData">

        <!--- Sidemenu -->
        <div id="sidebar-menu">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
                <li>
                    <a routerLink="" class="side-nav-link-ref" routerLinkActive="active" style="display: none;">
                    </a>
                </li>
                <li class="menu-title">Documental Approval</li>

                <li>

                    <a [routerLink]="['dashboard']" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-dashboard font-size-22"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li>
                    <a routerLink="approvals/list/yours" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-send font-size-22"></i>
                        <span>Your Requests</span>
                    </a>
                </li>
                <li>

                    <a routerLink="approvals/list/incoming" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-import font-size-22"></i>
                        <span>Incoming Requests</span>
                    </a>

                </li>

            </ul>
        </div>
        <!-- Sidebar -->

    </perfect-scrollbar>
</div>
<!-- Left Sidebar End -->