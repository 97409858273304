<div class="row full-row">
    <div class="col-on-five">
        <div>
            <app-hull-list></app-hull-list>
        </div>
        <hr>
        <div>
            <app-hull-details></app-hull-details>
        </div>
    </div>
    <div class="col-four-on-five">
        <app-hull-plots></app-hull-plots>
    </div>
</div>