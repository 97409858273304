<div class="content">
    <div class="row">
        <div class="col-sm-10">
            <h5>You are going to export the selected columns in your view</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="vehicleService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>
    <div class="row" *ngIf="this.vehicleService._selectedColumns.length > 10">

        <div class="col-sm-1">
            <i class="bx bx-help-circle font-size-22" style="padding-top: 270%;"></i>
        </div>
        <div class="col-sm-11">
            <h4>You selected more then 10 colomns.</h4>
            <h4>Best way to select the colomns for export</h4>
            <ul style="text-align: left;">
                <li>
                    <h6>On the header use the dropdown</h6>
                </li>
                <li>
                    <h6>Unselect all using the checkbox on the left of search box</h6>
                </li>
                <li>
                    <h6>Select one by one in order</h6>
                </li>
                <li>
                    <h6>For a good view select max 10 columns</h6>
                </li>
                <li>
                    <h6>The column is going to be add on the right</h6>
                </li>
                <li>
                    <h6>The responsive view is going to help you</h6>
                </li>
                <li>
                    <h6>You can see the preview</h6>
                </li>
                <li>
                    <h6>Click on the button to dowload PDF file</h6>
                </li>
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12">
            <form class="form" (ngSubmit)="onSubmit()">

                <div class="row">
                    <div class="col-lg-11">
                    </div>

                    <div class="col-lg-1" style="padding-left: 25px;">
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onSubmit()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" title="Download pdf"></button>

                        <div class="textUnderIcon">Download PDF</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>