import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';



import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { UIModule } from 'src/app/shared/ui/ui.module';

import { LayoutMicappComponent } from './layout-micapp.component';
import { SidebarMicappComponent } from './sidebar-micapp/sidebar-micapp.component';
import { TopbarMicappComponent } from './topbar-micapp/topbar-micapp.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutMicappComponent, 
    SidebarMicappComponent, 
    TopbarMicappComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    UIModule,
    PerfectScrollbarModule
  ],
  providers:[LanguageService]
})
export class LayoutsMicappModule { }
