<div class="container-fluid">
    <!-- <app-page-title title="Vehicles Grid" [breadcrumbItems]="breadCrumbItems"></app-page-title> -->


    <div class="row">
        <div [ngClass]="{'col-xl-9':  tankService.selectedEditMode != undefined,'col-xl-12':tankService.selectedEditMode == undefined}">


            <div class="card">
                <div class="card-body">


                    <p-table [columns]="cols" [value]="tankService.tanks" styleClass="p-datatable-gridlines" [scrollable]="true" scrollHeight="450px" [paginator]="true" [rows]="15" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        selectionMode="single" [rowsPerPageOptions]="[10,25,50]">

                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">

                            </colgroup>
                        </ng-template>

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-tank let-columns="columns">
                            <tr [pSelectableRow]="tank">
                                <td *ngFor="let col of columns" (click)="onSelect(tank)">
                                    {{tank[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>
            </div>
        </div>

        <div class="col-lg-3" *ngIf="tankService.selectedEditMode !=undefined">

            <app-tank-edit></app-tank-edit>
        </div>
    </div>
</div>




<div class="floating-action-button" (click)="onNew()" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
    <button type="button" class="btn btn-primary btn-circle btn-lg" id="addBtn">
        <i class="fas fa-plus" title="New"></i>
        <div class="textUnderIcon">New</div>
    </button>
</div>