<div class="full-row">
    <div class="row first-row">

        <div class="col-on-five">
            <app-category-list type="1"></app-category-list>
        </div>

        <div class="col-on-five">
            <app-category-list type="2"></app-category-list>
        </div>
        <div class="col-on-five">
            <app-category-list type="3"></app-category-list>
        </div>

        <div class="col-on-five">
            <app-category-list type="4"></app-category-list>
        </div>

        <div class="col-on-five">
            <app-category-list type="5"></app-category-list>
        </div>

    </div>

    <div class="row other-row">
        <div class="col-on-five">
            <app-category-details></app-category-details>
        </div>
        <div class="col-two-on-five ">
            <app-appendage-osborne></app-appendage-osborne>
        </div>
        <div class="col-two-on-five ">
            <app-appendage-plots></app-appendage-plots>
        </div>
    </div>
    <hr>
    <div class="row other-row">
        <div class="col-on-five">
            <app-appendage-set-list></app-appendage-set-list>
        </div>
        <div class="col-two-on-five ">
            <app-appendage-set-details></app-appendage-set-details>
        </div>
        <div class="col-two-on-five ">
            <!-- <app-appendage-plots></app-appendage-plots> -->
        </div>
    </div>
</div>