import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Hull } from '../../../shared/hull.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { HullDeleteComponent } from '../hull-delete/hull-delete.component';
import { HullEditComponent } from '../hull-edit/hull-edit.component';
import { HullService } from '../hull.service';

@Component({
  selector: 'app-hull-details',
  templateUrl: './hull-details.component.html',
  styleUrls: ['./hull-details.component.scss']
})
export class HullDetailsComponent implements OnInit {

  hull: Hull;
  project: ProjectDELPHY;
  
  constructor(
    public cookieService: CookieService,
    public hullService: HullService,
    private modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

    ngOnInit(): void {
   
      if(this.hullService.selectedHull == undefined){
        this.hullService.selectedHullEvent.subscribe(
          (selectedHull: Hull) => {
            this.hull = selectedHull;
          }
        );
      }
    }

  onEdit() {
    this.hullService.selectedEditMode = true;
    const modalRef = this.modalService.open(HullEditComponent);
    modalRef.componentInstance.hull = this.hullService.selectedHull;
  }

  onTrash() {
    const modaldelete = this.modalService.open(HullDeleteComponent);
    modaldelete.componentInstance.hull = this.hullService.selectedHull;
  }

}