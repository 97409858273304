<div class="content">
    <div class="row">
        <div class="col-sm-10">
            <h5>Choose a .csv file</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="vehicleService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12">

            <Ngx-CSVtoJSON (onConvert)="convert($event)" (onFail)="onError($event)"></Ngx-CSVtoJSON>



            <div>
                {{convertedObj}}
            </div>
        </div>
    </div>
</div>