import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { ProjectDELPHY } from '../../shared/projectDELPHY.model';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit {

  projectForm: FormGroup;
  subscription: Subscription;

  @Input() public project: ProjectDELPHY;

  constructor(public projectService: ProjectService,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {
    


    this.initForm();
  }

  ngOnChanges() {
    console.log(this.projectForm);
  }
  onSubmit() {
    this.modalService.dismissAll();

    if(this.projectService.selectedEditMode){
      this.projectService.updateProject(this.projectForm.value);
    }else{
      this.projectService.createProject(this.projectForm.value);
    }
    
  }

  


  private initForm() {

    let id: number;
    let name: string;
    let startDate = '';
    let latestUpdate = '';

    if(this.project != undefined && this.projectService.selectedEditMode == true){
    id = this.project.id;
    name = this.project.name; 
    startDate = this.project.startDate;
    latestUpdate = this.project.latestUpdate;
    }

    this.projectForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(name, Validators.required),
      'startDate': new FormControl(startDate),
      'latestUpdate': new FormControl(latestUpdate)
    });
  }

}
