<div class="home-btn d-none d-sm-block">
    <a href="/" class="text-dark"><i class="fas fa-home h2"></i></a>
</div>

<!-- end page -->


<div class="container-login">
    <div class="login-card container" id="container">

        <div class="form-container form-group sign-in-container">
            <!-- <h4><font color="red">${message}</font></h4>    -->
            <form class="needs-validation" name="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()" novalidate>
                <h3 style="
                padding-bottom: 8%;
              font-family: Barlow;
              font-weight: bold;
              font-size: 2 rem;     
                ">Password reset request</h3>

                <fieldset class="col s12">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form form-group">
                                <label>Email*</label>
                                <input type="email" id="email" formControlName="email" class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-0 row">
                        <div class="col-sm-12 text-center">
                            <button class="btn login" type="submit" style="color:#040E1D" id="login" [disabled]="!resetForm.valid">Reset</button>
                        </div>
                    </div>
                </fieldset>
            </form>
            <div class="row">
                <a routerLink="/account/login" class="font-weight-medium text-primary"><button class="btn" style="color:#040E1D" >Sign In here</button> </a>

            </div>
        </div>

        <div class="overlay">

            <div class="overlay-panel overlay-right">
                <div class="logo-panel">
                    <div class="boxes">
                        <img class="logo" src="assets/images/logoblack.png">
                    </div>
                    <div class="blue-box"></div>
                </div>
                <div class="lower-panel-right" style="right: 0">
                    <h2 style="padding-bottom: 20%"> EasyPro</h2>

                </div>
            </div>
        </div>
    </div>
</div>