<div class="result-card">

    <div class="micard-header" title="Show projectEl.name">
        <h5 class="result-title">RESULTS</h5>
    </div>

    <div class="card-body pt-0">

        <div class="table-responsive">
            <table class="table table-nowrap table-centered mb-0">
                <tbody>
                    <th>V [kts]</th>
                    <th>R<sub>T</sub> [N]</th>
                    <th>&Tau; [°]</th>
                    <th>P<sub>D</sub> [kW]</th>
                    <th>rpm</th>
                    <th>eta</th>
                    <tr *ngIf="vehicleService.daniels?.length == 0 && !waiting" (click)="onCalculateDaniels()">
                        <button type="button" class="btn btn-primary">Calulate Daniel</button>
                    </tr>
                    <tr *ngIf="waiting">


                        <td></td>
                        <td></td>
                        <td>
                            <div class="spinner-border">
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr *ngFor="let el of vehicleService.daniels">
                        <td>{{el?.V | number : '1.0-0'}}</td>
                        <td>{{el?.Rt | number : '1.0-0' | noComma}}</td>
                        <td>{{el?.tau | number : '1.2-2'}}</td>
                        <td>{{el?.Pd | number : '1.0-0' | noComma}}</td>
                        <td>{{el?.rpm | number : '1.0-0' | noComma}}</td>
                        <td>{{el?.eta | number : '1.2-2' | noComma}}</td>
                    </tr>

                </tbody>
            </table>
        </div>

        <div *ngIf="vehicleService.daniels == undefined">

        </div>

    </div>
</div>