<div class="content">


    <div class="row" style="font-weight: bold;">
        <div class="col-sm-10" *ngIf="(listService.selectedEditMode == false)">
            <h5>Add {{this.cookieService.get('titleList')}} form</h5>
        </div>
        <div class="col-sm-10" *ngIf="(listService.selectedEditMode == true)">
            <h5>Edit {{this.cookieService.get('titleList')}} form</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="listService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>



    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="listForm" (ngSubmit)="onSubmit()">

                <input type="hidden" id="id" formControlName="id" class="form-control">
                <input type="hidden" id="company" formControlName="company" class="form-control" *ngIf="cookieService.get('typeList') == 'versionList' 
                || cookieService.get('typeList') == 'propulsionList' 
                || cookieService.get('typeList') == 'optList'  
                ||cookieService.get('typeList') == 'revision' 
                || cookieService.get('typeList') == 'regNumber'
                ||cookieService.get('typeList') == 'product' 
                || cookieService.get('typeList') == 'topological' 
                || cookieService.get('typeList') == 'job' 
                        || cookieService.get('typeList') == 'design' 
                        || cookieService.get('typeList') == 'unitsOfMeasure'">

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="cookieService.get('typeList') == 'jobList' 
                        ||cookieService.get('typeList') == 'topologicalList' 
                        || cookieService.get('typeList') == 'designList' 
                        || cookieService.get('typeList') == 'unitsOfMeasureList'">
                            <label>Company name*</label>
                            <select class="form-control" formControlName="company" id="company">

                                <option *ngFor="let company of projectService.companies" [ngValue]="company">
                                    {{company.name}}
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" id="name" formControlName="name" class="form-control" #name>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Note</label>
                            <input type="text" id="note" formControlName="note" class="form-control">
                        </div>
                    </div>
                </div>



                <div class="row">
                    <div class="col-sm-11">
                    </div>
                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(listService.selectedEditMode == false)">
                        <button type="submit" class="btn btn-success" [disabled]="!listForm.valid"><i class="bx bxs-save font-size-50"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>
                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(listService.selectedEditMode == true)">
                        <button type="submit" class="btn btn-success" [disabled]="!listForm.valid"><i class="bx bxs-edit-alt font-size-50"></i></button>
                        <div class="textUnderIcon">Edit</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>