<div class="container-fluid">
    <!-- <app-page-title title="Vehicles Grid" [breadcrumbItems]="breadCrumbItems"></app-page-title> -->
    <div class="row">
        <div class="col-xl-12">

            <div class="row">

                <div class="col-lg-10">
                    <h4 class="card-title mb-4">Select optional items to add in the list</h4>
                </div>
                <div class="col-lg-2">
                    <button type="button" class="close" aria-label="Close" title="Close" (click)="vehicleService.onCloseForm()">
                                 <span aria-hidden="true"> &times;</span>
                              </button>
                </div>
            </div>

            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton type="button" label="Send items to list" (click)="onSendSelectedOptItems()" [disabled]="!selectedOptItems || !selectedOptItems.length">
                            </button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton type="button" label="Trash all items" (click)="onTrashAllOptItems()" [disabled]="!this.vehicleService.items" class="p-button-danger">
                            </button>
                </ng-template>


            </p-toolbar>
            <!-- <div class="col-xl-9"> -->
            <p-table #dt [columns]="cols" [value]="itemsOpt" [scrollable]="true" [paginator]="true" [rows]="25" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50,100,500,1000,10000]"
                dataKey="idItem" [(selection)]="selectedOptItems" [rowHover]="true">



                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col style="width: 50px">
                        <col *ngFor="let col of columns" [ngStyle]="{ 'width': col.width}">

                    </colgroup>
                </ng-template>



                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>

                        <th *ngFor="let col of columns" pSortableColumn="{{col.field}}">

                            <div *ngIf="col.header=='Name'" class="p-d-flex p-jc-between p-ai-center">
                                {{col.header}}
                                <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                <p-columnFilter type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                            </div>

                            <div *ngIf="col.header=='Job'" class="p-d-flex p-jc-between p-ai-center">
                                {{col.header}}
                                <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="jobsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                            <ng-template let-option pTemplate="item">
                                                <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>

                            <div *ngIf="col.header=='Topological'" class="p-d-flex p-jc-between p-ai-center">
                                {{col.header}}
                                <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="topologicalsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                            <ng-template let-option pTemplate="item">
                                                <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>

                            <div *ngIf="col.header=='Weight'" class="p-d-flex p-jc-between p-ai-center">
                                {{col.header}}
                                <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                            </div>

                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-itemRow let-columns="columns">
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="itemRow" (click)="onSelectAlgorithm(itemRow)"></p-tableCheckbox>
                        </td>
                        <td *ngFor="let col of columns" title="{{itemRow.name}}">
                            {{itemRow[col.field]}}
                        </td>
                    </tr>
                </ng-template>


            </p-table>

        </div>




    </div>

    <!-- end row -->
</div>