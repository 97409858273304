import { Injectable, EventEmitter } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectDELPHY } from '../shared/projectDELPHY.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  projects: ProjectDELPHY[];
  projectsChanged = new Subject<ProjectDELPHY[]>();
  error = new Subject<string>();

  selectedProjectEvent = new EventEmitter<ProjectDELPHY>();
  selectedProject: ProjectDELPHY;

  selectedEditMode: boolean;

  switch: boolean;
  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  // Get data from servlet and return the projects array
  public getProjects(): Observable<ProjectDELPHY[]> {
    return this.http.get<ProjectDELPHY[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'project/'
    )
      .pipe(
      );
  }


  setProjects(projects: ProjectDELPHY[]) {
    this.projects = projects;

   
    // console.log(this.projects)
    this.projectsChanged.next(this.projects.slice());
  }

  getProject(id: number) {
    if (this.projects == undefined)
      return undefined;
    const project = this.projects.find(
      (r) => {
        return r.id == id;
      }
    );
    return project;
  }

  createProject(project: ProjectDELPHY) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ project: ProjectDELPHY }>(this.accountService.REST_API_SERVER_DELPHY
        + 'project/',
        {
          "name": project.name,
          "startDate": project.startDate,
        }
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          project.id = responseData["id"];
          // this.projects.unshift(project);
          this.projects.push(project);
          this.projectsChanged.next(this.projects.slice());
          this.toastr.success('Project DELPHY created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }



  updateProject(project: ProjectDELPHY) {
    console.log(project);
    this.http
      // aggiungere <{è un optional raccomandato}>
      .put<{ project: ProjectDELPHY }>(
        this.accountService.REST_API_SERVER_DELPHY
        + 'project/',
        {
          "name": project.name,
          "id": project.id
        }
      )
      .subscribe(responseData => {

          let itemIndex = this.projects.findIndex(item => item.id == project.id);
          this.projects[itemIndex] = project;
          this.toastr.success('Project updated');
      

      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }


  trashProject(project: ProjectDELPHY) {


    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: project.id,
      },
    };
    
    this.http
      .delete(this.accountService.REST_API_SERVER_DELPHY
        + 'project/?id=' + project.id, options)
        .subscribe(responseData => {
          if (responseData != undefined) {
            const index = this.projects.findIndex(x => x.id === project.id);
            this.projects.splice(index, 1);
            this.projectsChanged.next(this.projects.slice());
            this.toastr.success('This project has been deleted', 'Project deleted');
          } else {
            this.error.next(responseData["Result"].message);
          }
        },
          error => {
            this.error.next(error.message);
          });



  }

  deleteFromCookie() {
    this.cookieService.delete(ProjectDELPHY.selectedProjectDELPHY);
    this.cookieService.delete(ProjectDELPHY.idProjectSelectedDELPHY);
  }

  onCloseForm() {
    this.modalService.dismissAll();

  }

  onCloseDetails() {
    this.selectedProject = undefined;

  }

  cloneProject(id: number, name: string) {

    console.log("cloneProjectDELPHY"); console.log(id); console.log(name);

    this.http.post<{ project: ProjectDELPHY }>(
      this.accountService.REST_API_SERVER_CALC + 'projectServlet?action=CLONE&loggedInEmail=' + this.accountService.currentUser.email + '&idProject=' + id,
      {id, name}
    )
      .subscribe(responseData => {
        if (responseData["Result"] == 'OK') {
          let projectEl = responseData["Record"];
          this.projects.push(projectEl);
          this.toastr.success('Element cloned');
        }

      },
        error => {
          this.error.next(error.message);
        });
  }
}
