<div class="container-fluid">
    <!-- <app-page-title title="Variants Grid" [breadcrumbItems]="breadCrumbItems"></app-page-title> -->


    <div class="textUnderIcon">

        <div class="card overflow-hidden noScale" style="height: 250px;">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 class="card-variant-title">
                            Variant dashboard – Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <div><i class="bx bx-wrench font-size-20"></i>
                                                <h6 class="list-name-help">Version list</h6>
                                                <h6 class="font-size-11">list of version names, it is possible to add items in the specific version by entering the specific list</h6>
                                            </div>
                                        </div>
                                        <div class="col-4" style="cursor: pointer">
                                            <i class="bx bx-atom font-size-20"></i>
                                            <h6 class="list-name-help">Propulsion lists</h6>
                                            <h6 class="font-size-11">list of propulsion names, it is possible to add items for the specific propulsion by entering the specific list</h6>
                                        </div>

                                        <div class="col-4" style="cursor: pointer">
                                            <i class="bx bx-brush font-size-20"></i>
                                            <h6 class="list-name-help">Optional Items</h6>
                                            <h6 class="font-size-11">list of options collection names, it is possible to add items in the specific options collection by entering the specific list</h6>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-copy font-size-20"></i>
                                            <h6 class="font-size-13">Clone feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm 12">
                                            <h5>How to define new variant?</h5>
                                            <hr>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-6">

                                            <h6><b>Variant</b> is a combination of Version, Propulsion and Optional items.
                                            </h6>

                                            <h6>The <b>baseline</b> will always be part of the configuration.</h6>

                                            <h6>It is possible to define a name and then combine one list for Version, Propulsion and Optional.</h6>

                                            <h6>Otherwise, only the baseline will be part of the configuration.</h6>


                                        </div>

                                        <div class="col-6">
                                            <ol style="text-align: left;">
                                                <li>
                                                    <h6>Define at least one list for Version, Propulsion or Optional items
                                                    </h6>
                                                </li>
                                                <li>
                                                    <h6>Click on “+” button</h6>
                                                </li>
                                                <li>
                                                    <h6>Edit variant name and notes</h6>
                                                </li>
                                                <li>
                                                    <h6>Associate the required lists
                                                    </h6>
                                                </li>
                                                <li>
                                                    <h6>Save</h6>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>






    <div class="row">
        <div class="col-xl-12">
            <div class="row">
                <div class="col-sm-4" *ngFor="let variantEl of variants">

                    <div class="card overflow-hidden">

                        <div class="card-variant-title-bar" style="cursor: pointer;">
                            <div class="row">
                                <!-- <div class="col-sm-4">
                                    <img class="rounded-circle imgCardDetails img-thumbnail rounded-circle" src="assets/images/hull.svg">
                                </div> -->

                                <div class="col-xl-12" style="text-align: center;">

                                    <h5 class="card-variant-title" (click)="onVariantSelected(variantEl)" title="Show {{variantEl.variantName}} lists">
                                        {{variantEl.variantName}}
                                    </h5>

                                </div>
                            </div>
                        </div>


                        <div class="card-body">

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="pt-6">
                                        <div class="row numberReq">
                                            <div class="col-4" style="border-right: 1px solid #dee2e6;  cursor: pointer" (click)="onListItems(variantEl, 'versionItem',variantEl.idVersionList, 1)" title="Show {{variantEl.versionListName}} list">
                                                <i class="bx bx-wrench font-size-20"></i>
                                                <h6 class="font-size-13">Version</h6>
                                                <h6 class="font-size-15" style="font-weight: bold;">{{variantEl.versionListName}}</h6>
                                            </div>
                                            <div class="col-4" style="border-right: 1px solid #dee2e6;  cursor: pointer" (click)="onListItems(variantEl, 'propulsionItem',variantEl.idPropulsionList, 2)" title="Show {{variantEl.propulsionListName}} list">
                                                <i class="bx bx-atom font-size-20"></i>
                                                <h6 class="font-size-13">Propulsion</h6>
                                                <h6 class="font-size-15" style="font-weight: bold;">{{variantEl.propulsionListName}}</h6>
                                            </div>
                                            <div class="col-4" style="cursor: pointer" (click)="onListItems(variantEl, 'optItem',variantEl.idOptList, 3)" title="Show {{variantEl.optListName}} list">
                                                <i class="bx bx-brush font-size-20"></i>
                                                <h6 class="font-size-13">Optional Items</h6>
                                                <h6 class="font-size-15" style="font-weight: bold;">{{variantEl.optListName}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="pt-6">
                                        <div class="row numberReq">
                                            <div class="col-4" style="border-right: 1px solid #dee2e6; cursor: pointer" (click)="onListItems(variantEl, 'tank',variantEl.idTankList, 4)" title="Show {{variantEl.tankListName}} list">
                                                <i class="bx bx-water font-size-20"></i>
                                                <h6 class="font-size-13">Tank</h6>
                                                <h6 class="font-size-15" style="font-weight: bold;">{{variantEl.tankListName}}</h6>
                                            </div>
                                            <div class="col-4" style="border-right: 1px solid #dee2e6; cursor: pointer" (click)="onListItems(variantEl, 'crew',variantEl.idCrewList, 5)" title="Show {{variantEl.crewListName}} list">
                                                <i class="bx bx-street-view font-size-20"></i>
                                                <h6 class="font-size-13">Crew</h6>
                                                <h6 class="font-size-15" style="font-weight: bold;">{{variantEl.crewListName}}</h6>
                                            </div>
                                            <div class="col-4" style="cursor: pointer" (click)="onListItems(variantEl, 'pax',variantEl.idPaxList, 6)" title="Show {{variantEl.paxListName}} list">
                                                <i class="bx bx-street-view font-size-20"></i>
                                                <h6 class="font-size-13">Pax</h6>
                                                <h6 class="font-size-15" style="font-weight: bold;">{{variantEl.paxListName}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="border-top">
                            <div class="row card-action" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
                                <div class="col-sm-12 card-button">
                                    <div>
                                        <i class="bx bxs-edit-alt font-size-22" (click)="onEdit(variantEl)" title="Edit"></i>
                                        <div class="textUnderIcon">Edit</div>
                                    </div>
                                    <div>
                                        <i class="bx bxs-copy font-size-22" (click)="onClone(variantEl)" title="Clone"></i>
                                        <div class="textUnderIcon">Clone</div>
                                    </div>
                                    <div>
                                        <i class="bx bxs-trash-alt font-size-22" (click)="onTrash(variantEl)" title="Delete"></i>
                                        <div class="textUnderIcon">Delete</div>
                                    </div>
                                    <div>
                                        <img class="logo" src="assets/images/ISO.png" (click)="onReport(variantEl,'ISO')" title="Report ISO">
                                        <div class="textUnderIcon">Report ISO</div>
                                    </div>
                                    <div>
                                        <img class="logo" src="assets/images/RINA.png" (click)="onReport(variantEl, 'RINA')" title="Report RINA">
                                        <div class="textUnderIcon">Report RINA</div>
                                    </div>


                                    <div>
                                        <i class="bx bx-task font-size-22" (click)="onDiba(variantEl)" title="Diba"></i>
                                        <div class="textUnderIcon">Diba</div>
                                    </div>

                                </div>
                            </div>


                            <div class="row card-action " *ngIf="(cookieService.get('userRole') == accountService.viewer)">

                                <div class="col-sm-6 card-button">
                                    <i class="bx bx-clipboard font-size-22" (click)="onReport(variantEl,'ISO')" style="cursor: pointer" title="Report ISO">
                                    </i>
                                </div>
                                <div class="col-sm-6 card-button">
                                    <i class="bx bxs-report font-size-22" (click)="onReport(variantEl, 'RINA')" style="cursor: pointer" title="Report RINA">
                                    </i>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>

            </div>

        </div>
        <!-- details -->
        <!-- <div class="col-xl-3">

            <app-variant-details *ngIf="selectedVariant!=undefined; else infoText"></app-variant-details>
            <ng-template #infoText>
                <p>Please select an Variant!</p>
            </ng-template>
        </div> -->
    </div>

    <!-- end row -->






</div>


<div class="floating-action-button" (click)="openAdd()" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
    <button type="button" class="btn btn-primary btn-circle btn-lg" id="addBtn">
        <i class="fas fa-plus" title="New"></i>
        <div class="textUnderIcon">New</div>
    </button>
</div>