<div class="content">
    <div class="row">
        <div class="col-sm-10" *ngIf="projectService.selectedEditMode == false">
            <h5>Add project form</h5>
        </div>
        <div class="col-sm-10" *ngIf="projectService.selectedEditMode == true">
            <h5>Edit {{project.name}}</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="projectService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="projectForm" (ngSubmit)="onSubmit()">




                <input type="hidden" id="id" formControlName="id" class="form-control">
                <input type="hidden" id="latestUpdate" formControlName="latestUpdate" class="form-control">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" id="name" formControlName="name" class="form-control">
                        </div>
                    </div>

                </div>



                <div class="row" *ngIf="(projectService.selectedEditMode == false)">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Start Date*</label>
                            <input type="date" formControlName="startDate" id="startDate" class="form-control">
                        </div>

                    </div>
                </div>


                <div class="row">

                    <div class="col-sm-11">
                    </div>

                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(projectService.selectedEditMode == false)">
                        <button type="submit" class="btn btn-success" [disabled]="!projectForm.valid"><i class="bx bxs-save font-size-50"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>
                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(projectService.selectedEditMode == true)">
                        <button type="submit" class="btn btn-success" [disabled]="!projectForm.valid"><i class="bx bxs-edit-alt font-size-50"></i></button>
                        <div class="textUnderIcon">Edit</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>