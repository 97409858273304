import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Vehicle } from '../../../shared/vehicle.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-daniel',
  templateUrl: './vehicle-daniel.component.html',
  styleUrls: ['./vehicle-daniel.component.scss']
})
export class VehicleDanielComponent implements OnInit {

  waiting = false;
  constructor(public cookieService: CookieService,
    public projectDELPHYservice: ProjectDELPHYService,
    public vehicleService: VehicleService) { }


  ngOnInit(): void {


    if(this.vehicleService.selectedVehicle == undefined){
      this.vehicleService.selectedVehicleEvent.subscribe(
        (selectedVehicle: Vehicle) => {
          
          this.vehicleService.getDaniels(selectedVehicle).subscribe(res => {
            this.vehicleService.daniels = res;
            console.log("res");
            console.log(res);
            this.vehicleService.danielsChanged.next(this.vehicleService.daniels);
         
          });
          
        }
      );
    }
 
  }

  onCalculateDaniels(){
    this.waiting = true;
    this.vehicleService.putDaniels().subscribe(res => {
      this.vehicleService.daniels = res;
      console.log("res");
      console.log(res);
      this.waiting = false;
      this.vehicleService.danielsChanged.next(this.vehicleService.daniels);
   
    });
  }

}
