<div class="content">
    <div class="card">
        <div class="row">
            <div class="col-sm-12">
                <form class="form" [formGroup]="tankForm">
                    <input type="hidden" id="id" formControlName="id" class="form-control">
                    <input type="hidden" id="idForeign" formControlName="idForeign" class="form-control">
                    <input type="hidden" id="isTrash" formControlName="isTrash" class="form-control">
                    <input type="hidden" id="isDelete" formControlName="isDelete" class="form-control">


                    <div class="row">
                        <div class="col-sm-10" *ngIf="tankService.selectedEditMode == true">
                            <h5>Edit Tank</h5>
                        </div>
                        <div class="col-sm-10" *ngIf="tankService.selectedEditMode == false">
                            <h5>New Tank</h5>
                        </div>
                        <div class="col-sm-2">
                            <button type="button" class="close" aria-label="Close" title="Close" (click)="tankService.onCloseForm()">
                                 <span aria-hidden="true"> &times;</span>
                              </button>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form form-group">
                                <label>Name*</label>
                                <input type="text" id="name" formControlName="name" class="form-control">
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label>Quantity*</label>
                                <input type="number" step="1" id="quantity" min="0" oninput="validity.valid||(value='');" formControlName="quantity" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label>Density*</label>
                                <input type="number" step=".01" min="0" max="1" oninput="validity.valid||(value='');" id="density" formControlName="density" class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form form-group">
                                <label>xg*</label>
                                <input type="number" id="xg" step=".001" formControlName="xg" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form form-group">
                                <label>Yg*</label>
                                <input type="number" id="yg" step=".001" formControlName="yg" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form form-group">
                                <label>Zg*</label>
                                <input type="number" step=".001" id="zg" formControlName="zg" class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form form-group">
                                <label>Note</label>
                                <input type="text" id="note" formControlName="note" class="form-control">
                            </div>
                        </div>
                    </div>



                    <div class="row" style="padding-top: 10px;" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">

                        <div class="col-sm-6" style="text-align: center;" *ngIf="(tankService.selectedEditMode == false)">
                            <button type="submit" class="btn btn-success" [disabled]="!tankForm.valid"><i class="bx bxs-save font-size-22"(click)="onSubmit()" title="Save data"></i></button>
                            <div class="textUnderIcon">Save</div>
                        </div>

                        <div class="col-sm-6" style="text-align: center;" *ngIf="(tankService.selectedEditMode == true)">
                            <button type="submit" class="btn btn-success" [disabled]="!tankForm.valid || !tankForm.dirty"><i class="bx bxs-edit-alt font-size-22"(click)="onSubmit()" title="Save edited data"></i></button>
                            <div class="textUnderIcon">Edit</div>
                        </div>

                        <div class="col-sm-6" style="padding-left: 20px; text-align: center;" *ngIf="tankService.selectedEditMode==true">
                            <button type="clear" class="btn btn-danger" (click)="onCancel()"><i class="bx bxs-trash-alt font-size-22" title="Delete {{tank.name}}"></i></button>
                            <div class="textUnderIcon">Delete</div>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>
</div>