import { Injectable, EventEmitter } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectCALC } from '../../shared/projectCALC.model';
import { List } from '../../shared/list.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../shared/company.model';
import { User } from 'src/app/account/user.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  projects: ProjectCALC[];
  projectsChanged = new Subject<ProjectCALC[]>();
  error = new Subject<string>();

  selectedProjectEvent = new EventEmitter<ProjectCALC>();
  selectedProject: ProjectCALC;

  selectedEditMode: boolean;

  companiesAll = [];
  companies = [];
  switch: boolean;
  currentUser: User;
  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  // Get data from servlet and return the projects array
  public getProjects(): Observable<ProjectCALC[]> {
    return this.http.get<ProjectCALC[]>(this.accountService.REST_API_SERVER_CALC
      + 'projectServlet?action=LIST&loggedInEmail=' + this.accountService.currentUser.email
    )
      .pipe(map(result => result['Records'])
      );
  }

  public getLists(listName: string): Observable<List[]> {
    return this.http.get<List[]>(this.accountService.REST_API_SERVER_CALC
      + listName + 'Servlet?action=LIST&loggedInEmail=' + this.accountService.currentUser.email
    )
      .pipe(map(result => result['Records'])
      );
  }

  setProjects(projects: ProjectCALC[]) {
    this.projects = projects;

    // this.projects.forEach(project => {
    //   this.companies.push(project.company);
    //   project.users.sort((a, b) => a.email.localeCompare(b.email));
    //   project.users = project.users.filter((test, index, array) =>
    //     index === array.findIndex((findTest) =>
    //       findTest.email === test.email
    //     )
    //   );

    // });

    // var mymap = new Map();
    // this.companies = this.companies.filter(el => {
    //   const val = mymap.get(el);
    //   if (val) {
    //     if (el < val) {
    //       mymap.delete(el);
    //       mymap.set(el, el);
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    //   mymap.set(el, el);
    //   return true;
    // });

    this.setCompanies();
    console.log(this.projects);
    this.projectsChanged.next(this.projects.slice());
  }





  getProject(id: number) {
    if (this.projects == undefined)
      return undefined;
    const project = this.projects.find(
      (r) => {
        return r.idProject == id;
      }
    );
    return project;
  }

  createProject(project: ProjectCALC) {

    console.log(project);

    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ project: ProjectCALC }>(
        this.accountService.REST_API_SERVER_CALC
        + 'projectServlet?action=CREATE&loggedInEmail=' + this.accountService.currentUser.email,
        [project]
      )
      .subscribe(responseData => {
        if (responseData['Result'] == 'OK') {
          project = responseData['Record'];
          // this.projects.unshift(project);
          this.projects.push(project);
          this.projectsChanged.next(this.projects.slice());
          this.toastr.success('Project CALC created');
        }
      },
        error => {
          this.toastr.error(error.message);
          // console.log(error.message);
          this.error.next(error.message);
        });

  }



  updateProject(project: ProjectCALC) {
    // console.log(project);
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ project: ProjectCALC }>(
        this.accountService.REST_API_SERVER_CALC
        + 'projectServlet?action=UPDATE&loggedInEmail=' + this.accountService.currentUser.email,
        project
      )
      .subscribe(responseData => {

        if (responseData['Result'] == 'OK') {
          let itemIndex = this.projects.findIndex(item => item.idProject == project.idProject);
          this.projects[itemIndex] = project;
          this.selectedProjectEvent.next(project);
          this.toastr.success('Project updated');
        }

      },
        error => {
          this.toastr.error(error.message);
          // console.log(error.message);
          this.error.next(error.message);
        });

  }


  trashProject(project: ProjectCALC) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ project: ProjectCALC }>(
        this.accountService.REST_API_SERVER_CALC + 'projectServlet?action=TRASH&loggedInEmail=' + this.accountService.currentUser.email,
        project
      )
      .subscribe(responseData => {
        if (responseData["Result"] == "OK") {
          const index = this.projects.findIndex(x => x.idProject === project.idProject);
          this.projects.splice(index, 1);
          this.projectsChanged.next(this.projects.slice());
          this.selectedProjectEvent.next(undefined);
          this.selectedProject = undefined;
          this.toastr.success('This project has been deleted', 'Project deleted');
        } else {
          this.error.next(responseData["Result"].message);
        }
      },
        error => {
          this.error.next(error.message);
        });

  }

  deleteFromCookie() {
    this.cookieService.delete(ProjectCALC.selectedProject);
    this.cookieService.delete(ProjectCALC.idProjectSelected);
  }
  // Get data from servlet and return the projects array


  public getCompanies(): Observable<ProjectCALC[]> {
    return this.http.get<Company[]>(this.accountService.REST_API_SERVER_CALC
      + 'companyServlet?action=LIST&loggedInEmail=' + this.accountService.currentUser.email
    )
      .pipe(map(result => result['Records'])
      );
  }



  setCompanies() {
    this.getCompanies().subscribe(res => {

      this.companiesAll = res;

      var isMICAD = false;
      console.log("this.companiesAll");
      console.log(this.companiesAll);

      let cookieValue = this.cookieService.get('loggedInUser');
      this.currentUser = JSON.parse(cookieValue);

      console.log("this.currentUser.company");
      console.log(this.currentUser.company);
      
      if (this.currentUser.roleName == 'Company Owner') {
        
            this.companies = this.companiesAll;
        
      } else {
        this.currentUser.company.forEach(comUser => {
        if (comUser.id == 1) 
            isMICAD = true;
        });

        console.log("isMICAD")
        console.log(isMICAD)

        console.log("this.currentUser.company")
        console.log(this.currentUser.company)

        
          if (isMICAD) {
            this.companies = this.companiesAll;
          } else {
            this.companies = this.currentUser.company;
          }
       
      } 
      // else if (this.currentUser.company.length == 1) {
      //   if (this.currentUser.company[0].name == "MICAD") {
      //     this.companies = this.companiesAll;
      //   } else  {
      //     this.companies = this.companiesAll.filter(com => com.name == this.currentUser.company[0].name)
      //   }
      // }
      console.log("this.companies");
      console.log(this.companies);
      
    });

  }






  onCloseForm() {
    this.modalService.dismissAll();

  }

  onCloseDetails() {
    this.selectedProject = undefined;

  }

  cloneProject(id: number, name: string) {

    // console.log("cloneProjectCALC"); console.log(id); console.log(name);

    this.http.post<{ project: ProjectCALC }>(
      this.accountService.REST_API_SERVER_CALC + 'projectServlet?action=CLONE&loggedInEmail=' + this.accountService.currentUser.email + '&idProject=' + id,
      { id, name }
    )
      .subscribe(responseData => {
        if (responseData["Result"] == 'OK') {
          let projectEl = responseData["Record"];
          console.log("projectEl dopo clone")
          console.log(projectEl)
          this.projects.push(projectEl);
          this.toastr.success('Element cloned');
        }

      },
        error => {
          this.error.next(error.message);
        });
  }
}
