<div class="content">
    <div class="row">
        <div class="col-sm-12">
            <form class="form" [formGroup]="engineForm">
                <input type="hidden" id="id" formControlName="id" class="form-control">
                <input type="hidden" id="date" formControlName="date" class="form-control">
                <input type="hidden" id="project" formControlName="project" class="form-control">

                <div class="row">
                    <div class="col-sm-10">
                        <h5 *ngIf="engineService.selectedEditMode == false">New Engine</h5>
                        <h5 *ngIf="engineService.selectedEditMode == true">Edit Engine</h5>
                    </div>
                    <div class="col-sm-2">
                        <button type="button" class="close" aria-label="Close" title="Close" (click)="engineService.onCloseForm()">
                                 <span aria-hidden="true"> &times;</span>
                              </button>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" class="form-control" formControlName="name" #s_name (change)="nameCheckUnique()" />
                        </div>
                        <div *ngIf="engineForm.controls['name'].invalid && (engineForm.controls['name'].dirty || engineForm.controls['name'].touched)" class="alert alert-danger">
                            <div *ngIf="engineForm.controls['name'].errors.required">
                                Name is required.
                            </div>
                            <div *ngIf="engineForm.controls['name'].errors.name">
                                Enter Valid Name
                            </div>
                        </div>
                        <div *ngIf="nameAlredyExist != ''" class="alert alert-danger">
                            {{nameAlredyExist}}
                        </div>
                    </div>
                </div>





                <div class="row">
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label>Installed Power*</label>
                            <input type="number" step=".01" id="Pi_kw" formControlName="Pi_kw" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label>Gear ratio*</label>
                            <input type="number" step=".01" id="Z" formControlName="Z" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label>Mechanical Efficiency*</label>
                            <input type="number" step=".01" id="eta_m" formControlName="eta_m" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row" style="padding-top: 10px;">
                    <div class="col-sm-11">
                    </div>

                    <div class="col-sm-1" *ngIf="(engineService.selectedEditMode == false)">
                        <button type="submit" class="btn btn-success" [disabled]="!engineForm.valid || nameAlredyExist != ''"><i class="bx bxs-save font-size-50"(click)="onSubmit()" title="Save data"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>

                    <div class="col-sm-1" *ngIf="(engineService.selectedEditMode == true)">
                        <button type="submit" class="btn btn-success" [disabled]="!engineForm.valid || nameAlredyExist != ''"><i class="bx bxs-edit-alt font-size-50"(click)="onSubmit()" title="Save edited data"></i></button>
                        <div class="textUnderIcon">Edit</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>