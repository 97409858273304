<div class="home-btn d-none d-sm-block">
    <a href="/" class="text-dark"><i class="fas fa-home h2"></i></a>
</div>

<!-- end page -->


<div class="container-login">
    <div class="login-card container" id="container">

        <div class="form-container form-group sign-in-container">
            <!-- <h4><font color="red">${message}</font></h4>    -->
            <form class="needs-validation" name="resetForm" [formGroup]="changeForm" (ngSubmit)="onSubmit()" novalidate>
                <h3 style="
                padding-bottom: 8%;
              font-family: Barlow;
              font-weight: bold;
              font-size: 2 rem;     
                ">Change password</h3>

                <fieldset class="col s12">
                    <div class="form-group" formGroupName="passwords">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="password" class="control-label">Password*</label>
                                    <input type="password" class="form-control" formControlName="password" title="Please enter your password">
                                    <!-- <p class="help-block" *ngIf="changeForm.get('passwords').hasError('passwordMismatch') && changeForm.get('passwords.confirmedPassword').touched">Passes don't match</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="confirmedPassword" class="control-label">Password confirm*</label>
                                    <input type="password" class="form-control" formControlName="confirmedPassword" title="Please re-enter your password">
                                    <!-- <p class="help-block" *ngIf="changeForm.get('confirmedPassword').hasError('required') && changeForm.get('confirmedPassword').touched">Password must be required</p>
                                    <p class="help-block" *ngIf="changeForm.get('confirmedPassword').hasError('passwordMismatch') && changeForm.get('confirmedPassword').touched">password does not match</p> -->
                                </div>
                            </div>
                        </div>

                        <div style='color:#ff7355' *ngIf="!changeForm.valid">
                            Password does not match or empty
                        </div>
                        <div style='color:green' *ngIf="changeForm.valid">
                            Password match
                        </div>
                    </div>
                    <div class="form-group mb-0 row">
                        <div class="col-sm-12 text-center">
                            <button class="btn login" type="submit" style="color:#040E1D" id="login" [disabled]="!changeForm.valid">Update</button>
                        </div>
                    </div>


                </fieldset>
            </form>

            <!-- <div style='color:#ff7355' *ngIf="changeForm.get(['passwords','password']).value != changeForm.get(['passwords','confirm_password']).value && changeForm.get(['passwords','confirm_password']).value != null">
                Password does not match
            </div> -->


            <div class="row">
                <a routerLink="/account/login" class="font-weight-medium text-primary"><button class="btn" style="color:#040E1D" >Sign In here</button> </a>

            </div>
        </div>

        <div class="overlay">

            <div class="overlay-panel overlay-right">
                <div class="logo-panel">
                    <div class="boxes">
                        <img class="logo" src="assets/images/logoblack.png">
                    </div>
                    <div class="blue-box"></div>
                </div>
                <div class="lower-panel-right" style="right: 0">
                    <h2 style="padding-bottom: 20%"> EasyPro</h2>

                </div>
            </div>
        </div>
    </div>
</div>