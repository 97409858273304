import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Item } from 'src/app/CALC/shared/item.model';
import { VehicleService } from '../../vehicle.service';

@Component({
  selector: 'app-item-clone',
  templateUrl: './item-clone.component.html',
  styleUrls: ['./item-clone.component.scss']
})
export class ItemCloneComponent implements OnInit {
  @Input() public item: Item;
  constructor(public vehicleService: VehicleService,
              private modalService: NgbModal,
              public cookieService: CookieService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    let tableName = this.cookieService.get(Item.servletName);
      this.vehicleService.cloneItem(this.item, tableName);
   
  }

}
