import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { VehicleService } from '../../vehicle.service';

@Component({
  selector: 'app-item-export-pdf',
  templateUrl: './item-export-pdf.component.html',
  styleUrls: ['./item-export-pdf.component.scss']
})
export class ItemExportPDFComponent implements OnInit {

  constructor(public vehicleService: VehicleService,
    private modalService: NgbModal,
    public cookieService: CookieService) { }

  ngOnInit(): void {
  }
  onSubmit() {
    this.modalService.dismissAll();
    this.vehicleService.exportPdf();
   
  }
}
