import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Item } from 'src/app/CALC/shared/item.model';
import { ProjectCALC } from '../../../../shared/projectCALC.model';
import { VehicleService } from '../../vehicle.service';
import { OptionalService } from '../optional.service';

@Component({
  selector: 'app-optional-match',
  templateUrl: './optional-match.component.html',
  styleUrls: ['./optional-match.component.scss']
})
export class OptionalMatchComponent implements OnInit {

  optItems: Item[];
  optItemsMatched: Item[];

  matchName = "";

  @ViewChild('name', { static: false }) inputEl: ElementRef;
  constructor(public vehicleService: VehicleService,
    private optionalService: OptionalService,
    public cookieService: CookieService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.optItemsMatched = [];
    this.vehicleService.getItems("BaseLineItem", +this.cookieService.get(ProjectCALC.idProjectSelected)).subscribe(
      res => {
        this.optItems = res["Records"];
        this.optItems = this.optItems.filter(t => t.isOptional === true && t.conflictOptCode == 0 && t.inseparableOptCode == 0);

      }
    );

  }
  ngAfterViewInit() {
    this.inputEl.nativeElement.focus();
  }
  onMatchInConflict() {
    if ((this.optItemsMatched.length > 1 && this.matchName.length > 0)) {
      console.log(this.matchName);
      this.optItemsMatched.forEach(itemOpt => {
        itemOpt.matchName = this.matchName;
      });
      // vanno aggiornati tutti i conflictOptCode
      console.log("this.optItemsMatchedInCOnflict");
      console.log(this.optItemsMatched);
      this.modalService.dismissAll();
      this.optionalService.matchConflictOpt(this.optItemsMatched);

      this.optItemsMatched.forEach(opt => {
        this.optionalService.itemsOptMatched.find(x => x.idItem === opt.idItem).matchName = this.matchName;
      });
      this.optionalService.updateRowGroupMetaData();
    }


  }

  onMatchInseparable() {
    if ((this.optItemsMatched.length > 1 && this.matchName.length > 0)) {
      // vanno aggionati i inseparableOptCode
      this.optItemsMatched.forEach(itemOpt => {
        itemOpt.matchName = this.matchName;
      });
      console.log("this.optItemsMatchedInseparable");
      console.log(this.optItemsMatched);
      this.modalService.dismissAll();
      this.optionalService.matchInseparableOpt(this.optItemsMatched);

      this.optItemsMatched.forEach(opt => {
        this.optionalService.itemsOptMatched.find(x => x.idItem === opt.idItem).matchName = this.matchName;
      });
      this.optionalService.updateRowGroupMetaData();
    }

  }

}
