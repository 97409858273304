import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { Item } from 'src/app/CALC/shared/item.model';
import { ProjectCALC } from 'src/app/CALC/shared/projectCALC.model';
import { OptionalSelectComponent } from '../optionals/optional-select/optional-select.component';
import { VehicleService } from '../vehicle.service';
import { ItemExportPDFComponent } from './item-export-pdf/item-export-pdf.component';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
declare function drawChart(bubbleItem: Item[]);
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  chartShow: boolean;
  project: ProjectCALC;
  first = 0;
  rows = 100;
  constructor(public vehicleService: VehicleService,
    public accountService: AccountService,
    public cookieService: CookieService,
    private modalService: NgbModal) {
 }


  ngOnInit(): void {
    this.cookieService.set("topbarTitle","Baseline data input");
    this.chartShow = false;
    this.vehicleService.listCalculate = [0, 0, 0, 0, 0, 0, 0, 0]
    this.vehicleService.onInitItems();

    this.vehicleService.itemsChanged.subscribe(() => {
      setTimeout(() => {
        // console.log(document.querySelector('#bubbleChart'));
        drawChart(this.vehicleService.itemsLastRev);
      }, 100);
    });

  }
  next() {
    this.first = this.first + this.rows;
}

  @Input() get selectedColumns(): any[] {
    return this.vehicleService._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this.vehicleService._selectedColumns = this.vehicleService.cols.filter(col => val.includes(col));

  }

  onSelectOptionalModal() {
    const modalRef = this.modalService.open(OptionalSelectComponent);
  }


  // fine filtri
  showChart() {
    this.chartShow = !this.chartShow;
    // console.log("this.chartShow");
    // console.log(this.chartShow);
    if (this.chartShow) {
      setTimeout(() => {
        // console.log(document.querySelector('#bubbleChart'));
        drawChart(this.vehicleService.itemsLastRev);
      }, 100);
    }

  }

  onSelectItem(item: Item) {
    this.vehicleService.selectedEditMode = true;
    this.vehicleService.selectedItem = item;
    this.vehicleService.selectItemForEdit(item);
  }

  onItemsRev(itemCode: number) {
    this.vehicleService.itemsRev = this.vehicleService.items.filter(t => t.itemCode === itemCode);
    this.vehicleService.itemsRev = this.vehicleService.itemsRev.filter(t => t.lastRev === false);
  }

  onNewItem() {

    this.vehicleService.selectedEditMode = false;
    let item = new Item(1, 1, 1, undefined, "", "", false, false, 0, "", 0, "", 0, "", 0, "", 0, "", false, false, false, "", 0, 0, 0, 0, "", 0, 0, 0, 0, 0, "", false, "", "", 0, false, false, 0, 0, false, false, false, 0, 0, false, "");

    this.vehicleService.selectItemForEdit(item);
  }


  // export

  onExportPdf() {
    const modalRef = this.modalService.open(ItemExportPDFComponent);
  }
 

  // const doc = new jsPDF('p', 'mm', [297, 210]);
  //const options=new autoTable();
  // (doc as any)autoTable(doc,{exportColumns:this.exportColumns,});
  //doc.autoTable(this.exportColumns, this.itemsLastRev);
  // doc.save('itemsLastRev.pdf');
  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.vehicleService.itemsLastRev);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "itemsLastRev");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
}
