<div class="content">


    <div class="row" style="font-weight: bold;">
        <div class="col-sm-10">
            <h5>Clone {{list.name}} {{this.cookieService.get('titleList')}}</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="listService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>

    <hr>

    <div class="row">
        <div class="col-sm-1">
            <i class="bx bx-help-circle font-size-22" style="padding-top: 70%;"></i>
        </div>
        <div class="col-sm-11">
            <h4>You will clone these list-associated things:</h4>
            <ul style="text-align: left;">
                <li>
                    <h6>Items</h6>
                </li>

            </ul>
        </div>
    </div>
    <hr>



    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="listForm" (ngSubmit)="onSubmit()">

                <input type="hidden" id="id" formControlName="id" class="form-control">
                <input type="hidden" id="company" formControlName="company" class="form-control">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" id="name" formControlName="name" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Note</label>
                            <input type="text" id="note" formControlName="note" class="form-control">
                        </div>
                    </div>
                </div>



                <div class="row">
                    <div class="col-sm-11">
                    </div>
                    <div class="col-sm-1" style="padding-left: 15px;">
                        <button type="submit" class="btn btn-success" [disabled]="!listForm.valid"><i class="bx bxs-copy font-size-50"></i></button>
                        <div class="textUnderIcon">Clone</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>