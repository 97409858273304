<div class="micard overflow-hidden">

    <div class="micard-header" style="cursor: pointer;" title="Show projectEl.name">
        <div>
            <h5 *ngIf="propellerService.selectedPropeller != undefined" class="list-title">{{propeller?.name}}</h5>
        </div>
    </div>


    <div class="details-body" *ngIf="propellerService.selectedPropeller == undefined">
        <p>Please Select a Propeller</p>
    </div>

    <div class="details-body" *ngIf="propellerService.selectedPropeller != undefined">
        <div class="row">
            <div class="col-sm-12 property-box">
                <div class="row">
                    <div class="property-name">
                        Creation
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{propeller?.date}}
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        D
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{propeller?.D}} m
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Blades
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{propeller?.N}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Area Ratio
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{propeller?.EAR}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Pitch Ratio
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{propeller?.PD_ratio}}
                    </div>
                </div>
            </div>
        </div>

        <hr>
        <div class="row" *ngIf="propellerService.selectedPropeller != undefined">
            <div class="col-sm-6 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-edit-alt font-size-22 action-icon" (click)="onEdit()" title="Edit"></i>
            </div>

            <div class="col-sm-6 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-trash-alt font-size-22 action-icon" (click)="onTrash()" title="Delete"></i>
            </div>

        </div>

    </div>
</div>