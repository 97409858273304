import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Variant } from 'src/app/CALC/shared/variant.model';
import { VariantService } from '../variant.service';

@Component({
  selector: 'app-variant-clone',
  templateUrl: './variant-clone.component.html',
  styleUrls: ['./variant-clone.component.scss']
})
export class VariantCloneComponent implements OnInit {

  
  @Input() public variant: Variant;
  variantForm: FormGroup;
  constructor(public variantService: VariantService,
    public cookieService: CookieService,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.initForm();
    
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.variantService.cloneVariant(this.variantForm.value.id, this.variantForm.value.name);
  }


  private initForm() {
    let id : number;
    let variantName = 'Copy of '+this.variant.variantName;
    if (this.variant != undefined) {
      id = this.variant.idVariant;
    }
    this.variantForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(variantName, Validators.required)
    });
  }

}
