<div class="container-fluid">


    <div class="row">

        <div class="col-12">
            <div class="card">
                <div class="card-body" style="padding: 0px;">

                    <p-table [value]="dibaService.dibaItems" [columns]="cols" (onSort)="dibaService.onSort()" [reorderableColumns]="true" sortMode="single" dataKey="tableName" [expandedRowKeys]="dibaService.expandedGroups" [paginator]="true" [rows]="100" [showCurrentPageReport]="true"
                        [scrollable]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single" [rowsPerPageOptions]="[10,25,50,100,500,1000]" styleClass="p-datatable-gridlines">


                        <ng-template pTemplate="caption">
                            <div class="row" style=" margin-right: 0px; height: 35px;">
                                <div class="col-sm-4" style="text-align: left;">
                                    <p-multiSelect [options]="cols" [(ngModel)]="cols" optionLabel="header" selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
                                </div>
                            </div>

                        </ng-template>
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [ngStyle]="{ 'width': col.width}">

                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>

                                <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" pReorderableColumn>

                                    <div *ngIf="col.header=='Name'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Job'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="dibaService.jobsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Topological'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="dibaService.topologicalsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>
                                    <div *ngIf="col.header=='Rev'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Type'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]=" 'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Product'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="dibaService.productsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Design'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="dibaService.designsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Est.Weight'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>


                                    <div *ngIf="col.header=='Margin'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Units'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Quantity'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Conflict Code'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Inseparable Code'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Note'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>
                                    <div *ngIf="col.header=='AssemblyHours'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Price'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>
                                    <div *ngIf="col.header=='Last Date'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="date" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>
                                </th>
                            </tr>
                        </ng-template>


                        <ng-template pTemplate="body" let-itemRow let-columns="columns" let-rowIndex="rowIndex" let-index="rowIndex" let-expanded="expanded">
                            <tr *ngIf="dibaService.rowGroupMetadata[itemRow.tableName].index === rowIndex" [pReorderableRow]="index">
                                <td colspan=colsSize>
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <button type="button" style="width: 2.357rem!important;" pButton pRipple [pRowToggler]="itemRow" class="p-button-text p-button-rounded p-button-plain " [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                        </div>
                                        <div class="col-sm-10" style="text-align: center; padding-top: 10px;">
                                            <span class="p-text-bold " title="Show cluster optional items">{{itemRow.tableName}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>




                        <ng-template pTemplate="rowexpansion" let-rowIndex="rowIndex" let-itemRow let-columns="columns">
                            <tr>
                                <td style="cursor: pointer;" class="tableExpan" *ngFor="let col of columns" title="{{itemRow.name}}, Rev: {{itemRow.revisionName}} ">
                                    {{itemRow[col.field]}}
                                </td>
                            </tr>
                        </ng-template>



                    </p-table>
                </div>
            </div>




        </div>
    </div>

    <!-- end row -->
</div>