<div class="result-card">

    <div class="micard-header" title="Show projectEl.name">
        <h5 class="result-title">LUDWIG RESULTS</h5>
    </div>

    <div class="card-body pt-0">

        <div class="table-responsive">
            <table class="table table-nowrap table-centered mb-0">
                <tbody>
                    <th>J</th>
                    <th>K<sub>T</sub></th>
                    <th>K<sub>Q</sub></th>
                    <th>&eta;</th>
                    <tr *ngFor="let el of propellerService.ludwigs">
                        <td>{{el?.J | number : '1.2-2'}}</td>
                        <td>{{el?.Kt | number : '1.3-3'}}</td>
                        <td>{{el?.Kq | number : '1.3-3'}}</td>
                        <td>{{el?.eta | number : '1.3-3'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>