import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/account/user.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  currentUser: User;
   constructor(public cookieService: CookieService) { }
 
   ngOnInit() {

    this.cookieService.set("topbarTitle","My Profile");
    this.cookieService.set('typeMenu','user');
    this.currentUser = JSON.parse(this.cookieService.get("loggedInUser")) ;
   }
 
  }
