<div class="row full-row">
    <div class="col-on-five">
        <div>
            <app-engine-list></app-engine-list>
        </div>
        <hr>
        <div>
            <app-engine-details></app-engine-details>
        </div>


    </div>
    <div class="col-four-on-five">
        <app-engine-plots></app-engine-plots>
    </div>
</div>