<!-- <div class="list-card overflow-hidden">
    <div class="list-header">
        <div>
            <h5 class="list-title">{{title}}</h5>
        </div>
    </div>

    <div class="list-body" [ngStyle]="{'display': projectDELPHYservice.appDis}">
        <ul class="list-group">
            <li class="list-item" *ngFor="let el of appendageService.appendages | filterAppendagesPipe:type" (click)="onAppendageSelected(el)" style="cursor: pointer;" [ngClass]="{'active': appendageService.selectedCategoryId == el.id}">
                {{el.name}}
            </li>
        </ul>
    </div>

    <div class="border-top">
        <div class="row card-action">
            <div class="col-sm-12 card-button action-icon" style="text-align: center;">
                <i class="bx bx-plus font-size-22" style="cursor: pointer;" title="New" (click)="openAdd()"></i>
            </div>

            <div class="col-sm-6 card-button action-icon" style="text-align: center;">
                <i style="cursor: pointer;" class="bx bx-dots-horizontal-rounded font-size-22" title="Details" (click)="projectDELPHYservice.onActivate('app')">
                </i>
            </div>

        </div>
    </div>
</div> -->

<div class="card card-mini text-white card-mini-has-bg">

    <div class="card-mini-img-overlay d-flex flex-column">

        <div class="card-mini-body" title="Show {{title}}">
            <h4 class="card-mini-title mt-0 ">{{title}}</h4>
            <div class="list-body" [ngStyle]="{'display': projectDELPHYservice.appDis}">
                <ul class="list-group">
                    <li class="list-item" *ngFor="let el of appendageService.appendages | filterAppendagesPipe:type" (click)="onAppendageSelected(el)" style="cursor: pointer;" [ngClass]="{'active': appendageService.selectedCategoryId == el.id}">
                        {{el.name}}
                    </li>
                </ul>
            </div>
        </div>

        <div class="card-mini-footer">
            <div class="row">
                <div class="col-sm-12 card-button action-icon" style="text-align: center;">
                    <i class="bx bx-plus font-size-22" style="cursor: pointer;" title="New" (click)="openAdd()"></i>
                </div>
            </div>
        </div>

    </div>
</div>