export class Crew {
    public id: number;
	public idForeign: number;
	public name: string;
	public number: number;

	public unitaryWeight: number;

	public weight: number;
	public xg: number;
	public yg: number;
	public zg: number;
	public lastUpdateDate: string;

	public note: string;
	public isTrash: boolean;
	public isDelete: boolean;

	constructor($id: number, $idForeign: number, $name: string, $number: number, $unitaryWeight: number, $weight: number, $xg: number, $yg: number, $zg: number, $lastUpdateDate: string, $note: string, $isTrash: boolean, $isDelete: boolean) {
		this.id = $id;
		this.idForeign = $idForeign;
		this.name = $name;
		this.number = $number;
		this.unitaryWeight = $unitaryWeight;
		this.weight = $weight;
		this.xg = $xg;
		this.yg = $yg;
		this.zg = $zg;
		this.lastUpdateDate = $lastUpdateDate;
		this.note = $note;
		this.isTrash = $isTrash;
		this.isDelete = $isDelete;
	}
	
	
}