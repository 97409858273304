import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Appendage } from '../../../shared/appendage.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { AppendageService } from '../appendage.service';
import { CategoryEditComponent } from '../category-edit/category-edit.component';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  @Input() type: number;
  title: String;
  // selectedCategoryId: number;
  constructor(
    public cookieService: CookieService,
    public appendageService: AppendageService,
    public modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {

    this.setTitle();
    this.appendageService.getAppendages().subscribe(res => {
      this.appendageService.appendages = res;
      this.appendageService.setAppendages(res);
    });



    this.appendageService.appendagesChanged.subscribe(res => {
      this.appendageService.appendages = res;
    });
  }

  openAdd() {
    this.appendageService.selectedEditMode = false;
    const modalRef = this.modalService.open(CategoryEditComponent);
    modalRef.componentInstance.type = this.type;

  }

  onAppendageSelected(appendage: Appendage) {
    if (appendage.id != this.appendageService.selectedCategoryId) {
      this.appendageService.selectedAppendage = appendage;
      this.appendageService.selectedAppendageEvent.emit(appendage);
      if (appendage != undefined) {
        this.appendageService.selectedCategoryId = undefined;
        this.appendageService.selectedCategoryId = appendage.id;
      } else {
        this.appendageService.selectedCategoryId = undefined;
      }
    }

  }






  setTitle() {
    if (this.type == 1) {
      this.title = "Rudder";
    }
    if (this.type == 2) {
      this.title = "Fin";
    }
    if (this.type == 3) {
      this.title = "Keel";
    }
    if (this.type == 4) {
      this.title = "Shaft";
    }
    if (this.type == 5) {
      this.title = "Bracket";
    }

  }

}
