<div class="row">

    <div class="col-lg-5">
        <div class="row">

            <span class="p-float-label">
                <input type="text" pInputText [(ngModel)]="matchName" disabled/> 
                <label for="float-input">Insert a name for this mark</label>
            </span>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="row">
            <div class="col-sm-6" *ngIf="optItemsMatched[0]?.conflictOptCode!=0">
                <p-button (click)="onMatchInConflictEdit()" label="Edit conflict" [disabled]="!(optItemsMatched.length > 1)"></p-button>
            </div>
            <div class="col-sm-6" *ngIf="optItemsMatched[0]?.inseparableOptCode!=0">
                <p-button (click)="onMatchInseparableEdit()" label="Edit cluster" [disabled]="!(optItemsMatched.length > 1)"></p-button>
            </div>

        </div>
    </div>
    <div class="col-lg-1">
        <button type="button" class="close" aria-label="Close" title="Close" (click)="vehicleService.onCloseForm()">
             <span aria-hidden="true"> &times;</span>
          </button>
    </div>
</div>

<hr>
<p-pickList [source]="optItems" [target]="optItemsMatched" [responsive]="true" filterBy="name" sourceHeader="All optional" targetHeader="Optional to mark" dragdrop="true">


    <ng-template let-optItem pTemplate="item">
        <div>
            <div style="font-size:10px">{{optItem.name}} - {{optItem.weight}}</div>
        </div>
    </ng-template>

</p-pickList>