import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Appendage } from '../../shared/appendage.model';
import { AppendageSet } from '../../shared/appendageset.model';
import { Osborne } from '../../shared/osborne.model';
import { ProjectDELPHY } from '../../shared/projectDELPHY.model';

@Injectable({
  providedIn: 'root'
})
export class AppendageService {

  selectedEditMode: boolean;
  error = new Subject<string>();

  appendages: Appendage[];
  appendagesChanged = new Subject<Appendage[]>();

  selectedAppendage: Appendage;
  selectedAppendageEvent = new EventEmitter<Appendage>();

  selectedCategoryId: number;

  osbornes: Osborne[];
  osbornesChanged = new Subject<Osborne[]>();

  
  graphOsborne: any[] = [];
  selectedSetEditMode: boolean;

  appendagesSet: AppendageSet[];
  appendagesSetChanged = new Subject<AppendageSet[]>();

  selectedAppendageSet: AppendageSet;
  selectedAppendageSetEvent = new EventEmitter<AppendageSet>();

  selectedSetId: number;
  
  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  createAppendage(appendage: Appendage) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ appendage: Appendage }>(
        this.accountService.REST_API_SERVER_DELPHY + 'appendage/',
        appendage
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          appendage.id = responseData["id"];
          appendage.date = responseData["date"];
          // this.appendages.unshift(appendage);
          this.appendages.push(appendage);
          this.setAppendages(this.appendages);
          this.toastr.success('Appendage created');
        }
      },
        error => {
          this.toastr.error(error.message);
          this.error.next(error.message);
        });

  }

  updateAppendage(appendage: Appendage) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .put<{ appendage: Appendage }>(
        this.accountService.REST_API_SERVER_DELPHY + 'appendage/',
        appendage
      )
      .subscribe(responseData => {
        let itemIndex = this.appendages.findIndex(item => item.id == this.selectedAppendage.id);
        appendage.date = responseData["date"];
        this.appendages[itemIndex] = appendage;
        this.setAppendages(this.appendages);
        this.selectedAppendage = appendage;
        this.selectedAppendageEvent.next(appendage);
        this.toastr.success('Appendage  updated');
      },
        error => {
          this.toastr.error(error.message);
            console.log(error.message);
            // this.error.next(error.message);
        });

  }

  
  cloneAppendage(appendage: Appendage) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ appendage: Appendage }>(
        this.accountService.REST_API_SERVER_DELPHY + 'appendage/',
        appendage
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          appendage.id = responseData["id"];
          appendage.date = responseData["date"];
          // this.appendages.unshift(appendage);
          this.appendages.push(appendage);
          this.setAppendages(this.appendages);
          this.toastr.success('Appendage created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }
  trashAppendage() {
   
    this.http
      .delete(this.accountService.REST_API_SERVER_DELPHY 
        + 'appendage?id=' + this.selectedAppendage.id)
        .subscribe(responseData => {
          if (responseData != undefined) {
            const index = this.appendages.findIndex(x => x.id === this.selectedAppendage.id);
            this.appendages.splice(index, 1);
            this.toastr.success('This appendage has been deleted', 'Appendage deleted');
            this.setAppendages(this.appendages);
            this.selectedAppendageEvent.next(undefined);
            this.selectedAppendage = undefined;
          } else {
            this.error.next(responseData["Result"].message);
          }
        },
          error => {
            this.error.next(error.message);
          });

  }

  public getAppendages(): Observable<Appendage[]> {
    return this.http.get<Appendage[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'appendage/?project_id=' + this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY)
    )
      .pipe(
      );
  }

  getAppendage(id: number) {
    if (this.appendages == undefined)
      return undefined;
    const el = this.appendages.find(
      (r) => {
        return r.id == id;
      }
    );
    return el;
  }

  setAppendages(apps:Appendage[]){
    this.appendages = apps;
    this.appendagesChanged.next(this.appendages.slice());
  }

  onCloseForm() {
    this.modalService.dismissAll();
  }


  public getOsbornes(appendage: Appendage): Observable<Osborne[]> {
    return this.http.get<Osborne[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'osborne/?appendage_id=' + appendage.id
    )
      .pipe(
      );
  }

  onInitGraph() {
    this.setGraphJob();
  }




  // restituisce label e data
  setGraphJob() {
    let dataOsborne = [], dataKq = [], dataEta = [], label = [];

    this.osbornes.forEach(element => {
      label.push(element.V.toFixed(2))
      dataOsborne.push(element.Rt.toFixed(3))
    });

    this.graphOsborne = [label, dataOsborne];

  }



  public getAppendagesSet(): Observable<AppendageSet[]> {
    return this.http.get<AppendageSet[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'appendageSet/?project_id=' + this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY)
    )
      .pipe(
      );
  }

  getAppendageSet(id: number) {
    if (this.appendagesSet == undefined)
      return undefined;
    const el = this.appendagesSet.find(
      (r) => {
        return r.id == id;
      }
    );
    return el;
  }
  
  setAppendagesSet(apps:AppendageSet[]){
    this.appendagesSet = apps;
    this.appendagesSetChanged.next(this.appendagesSet.slice());
  }

  createAppendageSet(appendageSet: AppendageSet) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ appendage: Appendage }>(
        this.accountService.REST_API_SERVER_DELPHY + 'appendageSet/',
        appendageSet
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          appendageSet.id = responseData["id"];
          appendageSet.date = responseData["date"];
          this.appendagesSet.push(appendageSet);
          this.selectedAppendageSetEvent.next(appendageSet);
          this.setAppendagesSet(this.appendagesSet);
          this.toastr.success('Appendage Set created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  updateAppendageSet(appendageSet: AppendageSet) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .put<{ appendageSet: AppendageSet }>(
        this.accountService.REST_API_SERVER_DELPHY + 'appendageSet/',
        appendageSet
      )
      .subscribe(responseData => {
        let itemIndex = this.appendagesSet.findIndex(item => item.id == this.selectedAppendageSet.id);
        appendageSet.date = responseData["date"];
        this.appendagesSet[itemIndex] = appendageSet;
        this.setAppendagesSet(this.appendagesSet);
        this.selectedAppendageSet = appendageSet;
        this.selectedAppendageSetEvent.next(appendageSet);
        this.toastr.success('Appendage Set updated');
      },
        error => {
          this.toastr.error(error.message);
            console.log(error.message);
            // this.error.next(error.message);
        });

  }

  
  cloneAppendageSet(appendageSet: AppendageSet) {
    console.log(" dopo form appendageSet");
    console.log(appendageSet);
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ appendage: Appendage }>(
        this.accountService.REST_API_SERVER_DELPHY + 'appendageSet/',
        appendageSet
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          appendageSet.id = responseData["id"];
          appendageSet.date = responseData["date"];
          this.appendagesSet.push(appendageSet);
          this.setAppendagesSet(this.appendagesSet);
          this.toastr.success('Appendage Set created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }
  trashAppendageSet() {
   
    this.http
      .delete(this.accountService.REST_API_SERVER_DELPHY 
        + 'appendageSet?id=' + this.selectedAppendageSet.id)
        .subscribe(responseData => {
          if (responseData != undefined) {
            const index = this.appendagesSet.findIndex(x => x.id === this.selectedAppendageSet.id);
            this.appendagesSet.splice(index, 1);
            this.toastr.success('This appendage set has been deleted', 'Appendage Set deleted');
            this.setAppendagesSet(this.appendagesSet);
            this.selectedAppendageSet = undefined;
            this.selectedAppendageSetEvent.next(undefined);
            
          } else {
            this.error.next(responseData["Result"].message);
          }
        },
          error => {
            this.error.next(error.message);
          });

  }





}