import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { User } from './user.model';
import { CookieService } from 'ngx-cookie-service';
declare function isHttps();
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  // public REST_API_SERVER = "http://localhost:9395/easyPRO/";
  // public REST_API_SERVER_CALC = "http://localhost:9395/CALC/";

  // public REST_API_SERVER = "http://192.168.178.10:8080/easyPRO/";
  // public REST_API_SERVER_CALC = "http://192.168.178.10:8080/CALC/";

  public REST_API_SERVER  = localStorage.getItem("REST_API_SERVER");
  public REST_API_SERVER_CALC= localStorage.getItem("REST_API_SERVER_CALC");


  // public REST_API_SERVER = "http://18.202.62.109/easyPRO/";
  // public REST_API_SERVER_CALC = "http://18.202.62.109/CALC/";

  public REST_API_SERVER_DELPHY = "http://52.30.72.88:8000/";

  companyOwner: string;
  projectOwner: string;
  collaborator: string;
  viewer: string;

  error = new Subject<string>();
  currentUser: User;

  constructor(
    private http: HttpClient,
    public cookieService: CookieService,
  ) {

    


    try {
      let cookieValue = this.cookieService.get('loggedInUser');
      this.currentUser = JSON.parse(cookieValue);
    } catch {

    }

    this.companyOwner = User.companyOwner;
    this.projectOwner = User.projectOwner;
    this.collaborator = User.collaborator;
    this.viewer = User.viewer;

  }



  logIn(email: string, password: string) {
    
    console.log('LogIn: ' + email + ' ' + password);
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post(
        this.REST_API_SERVER + 'loginAngular',
        [email, password]
      )
      .subscribe(responseData => {
        alert("Login");
      },
        error => {
          this.error.next(error.message);
        });

  }

  public setCurrentUser(user: User) {
    this.currentUser = user;
    this.cookieService.set('loggedInUser', JSON.stringify(user));

  }
}
