import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ListEditComponent } from './lists/list-edit/list-edit.component';
import { ListsComponent } from './lists/lists.component';


const routes: Routes = [
 
  { path: '', component: DashboardComponent },
  { path: 'lists/:listType', component: ListsComponent,children: [ 
    { path: 'new', component: ListEditComponent},
    { path: ':idList', component: ListsComponent},
    { path: ':idList/edit', component: ListEditComponent}
  ]  },
  { path: 'list/:listType', component: ListsComponent,children: [ 
      { path: 'new', component: ListEditComponent},
      { path: ':idList/edit', component: ListEditComponent}
    ] 
  },
  { path: 'list/:listType/:idList', component: ListsComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }


