import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { Tank } from 'src/app/CALC/shared/tank.model';
import { TankDeleteComponent } from '../tank-delete/tank-delete.component';
import { TankService } from '../tank.service';

@Component({
  selector: 'app-tank-edit',
  templateUrl: './tank-edit.component.html',
  styleUrls: ['./tank-edit.component.scss']
})
export class TankEditComponent implements OnInit {

  tank: Tank;
  tankForm: FormGroup;

  constructor(public tankService: TankService,
    public accountService: AccountService,
    public cookieService: CookieService,
    private modalService: NgbModal) { }

  ngOnInit(): void {

    this.tankService.selectedTankEvent.subscribe(
      (selectedTank: Tank) => {
        console.log("selectedTank") ;
          this.tank = selectedTank;
          console.log(selectedTank);
          this.initForm();
          
      }
    );
  
    console.log("construntor edit end");
    this.tankService.editorReady();
  }

  onSubmit() {
    // this.dialogRef.close();
    if(this.tankForm.valid && this.tankForm.dirty){

      if (this.tankService.selectedEditMode ) {

        this.tankService.updateTank(this.tankForm.value);
      } else {
        this.tankService.createTank(this.tankForm.value)
      }
    }
    
  }

  onCancel() {
    const modaldelete = this.modalService.open(TankDeleteComponent);
    modaldelete.componentInstance.tank = this.tank;
  }

  private initForm() {
    let id: number;
    let idForeign: number;
    
    let name = '';

    
    let density: number;
    let quantity: number;

    let weight: number;
    let xg: number;
    let yg: number;
    let zg: number;
    let note = '';

    
    let isTrash = false;
    let isDelete = false;

    if (this.tankService.selectedEditMode && this.tank != undefined) {
      console.log("this.tank")
      console.log(this.tank)
      id = this.tank.id;
      idForeign = this.tank.idForeign;
      
      name = this.tank.name;
      density = this.tank.density;
      quantity = this.tank.quantity;

      weight = this.tank.weight;
      xg = this.tank.xg;
      yg = this.tank.yg;
      zg = this.tank.zg;
      note = this.tank.note;

      isTrash = this.tank.isTrash;
      isDelete = this.tank.isDelete;
    }

    this.tankForm = new FormGroup({
      'id': new FormControl(id),
      'idForeign': new FormControl(idForeign),
      'name': new FormControl(name, Validators.required),      
      'density': new FormControl(density, [Validators.required, Validators.min(0), Validators.max(1)]),
     'quantity': new FormControl(quantity, [Validators.required, Validators.min(0)]),
      'xg': new FormControl(xg, Validators.required),
      'yg': new FormControl(yg, Validators.required),
      'zg': new FormControl(zg, Validators.required),
      'note': new FormControl(note),
      'isTrash': new FormControl(isTrash),
      'isDelete': new FormControl(isDelete)

    });
  }
}
