import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-importgrasshopper',
  templateUrl: './importgrasshopper.component.html',
  styleUrls: ['./importgrasshopper.component.scss']
})
export class ImportgrasshopperComponent implements OnInit {

  convertedObj:any = "";

  constructor(private modalService: NgbModal,
    public vehicleService: VehicleService) { }

  ngOnInit(): void {
  }
  
  convert(objArray) {
    this.convertedObj = JSON.stringify(objArray['result'], null, 2);
    this.vehicleService.importJSON(this.convertedObj);
    this.vehicleService.onCloseForm();
  }
  onError(err) {
    this.convertedObj = err
    console.log(err);
  }

}
