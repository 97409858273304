<div class="card">



    <!-- Card content -->
    <div class="card-body">

        <!-- Title -->

        <!-- Data -->
        <div class="row">
            <div class="col-sm-2">

            </div>
            <div class="col-sm-8">
                <h4 class="card-title font-weight-bold  card-details"><a>{{project?.projectName}}</a></h4>
            </div>
            <div class="col-sm-2" style="text-align: center;">
                <button type="button" class="close" aria-label="Close" title="Close" (click)="projectService.onCloseDetails()">
                    <span aria-hidden="true"> &times;</span>
                </button>
            </div>
        </div>





        <!-- Card image -->
        <div class="view overlay">
            <!-- <img class="card-img-top" src="assets/images/hull.svg" alt="Card image cap"> -->
            <img class="card-img-top" src={{project?.img}}>
        </div>

        <div class="border-top" *ngIf="(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">
            <div class="row card-action">
                <div class="col-sm-4 card-button">
                    <i class="bx bxs-edit-alt font-size-22" (click)="onEdit()" style="cursor: pointer;" title="Edit"></i>
                    <div class="textUnderIcon">Edit</div>
                </div>

                <div class="col-sm-4 card-button">
                    <i style="cursor: pointer;" class="bx bxs-copy font-size-22" (click)="onCloneProject()" title="Clone">
                    </i>
                    <div class="textUnderIcon">Clone</div>
                </div>

                <div class="col-sm-4 card-button">
                    <i style="cursor: pointer;" class="bx bxs-trash-alt font-size-22" (click)="onTrash()" title="Delete"></i>
                    <div class="textUnderIcon">Delete</div>
                </div>

            </div>
        </div>


        <hr>
        <div class="row" style="margin-top:5px">
            <div class="col-sm-6 card-details">
                <strong>Length</strong>
            </div>
            <div class="col-sm-6 card-details">
                <strong>Beam</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6  card-details">
                {{project?.length | number:'1.0-2'}}m
            </div>
            <div class="col-sm-6 card-details">
                {{project?.beam | number:'1.0-2'}}m
            </div>
        </div>
        <hr>
        <div class="row" style="margin-top:5px">
            <div class="col-sm-6 card-details">
                <strong>Start Date</strong>
            </div>
            <div class="col-sm-6 card-details">
                <strong>End Date</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6  card-details">
                {{project?.startDate}}
            </div>
            <div class="col-sm-6 card-details">
                {{project?.endDate}}
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-6 card-details">
                <strong>Job </strong>
            </div>
            <div class="col-sm-6 card-details">
                <strong>Topological</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 card-details">
                {{project?.jobListName}}
            </div>
            <div class="col-sm-6 card-details">
                {{project?.topologicalListName}}
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 card-details">
                <strong>Design</strong>
            </div>
            <div class="col-sm-6 card-details">
                <strong>Units</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 card-details">
                {{project?.designListName}}
            </div>
            <div class="col-sm-6 card-details">
                {{project?.unitsOfMeasureListName}}
            </div>
        </div>

        <div *ngIf="project.note">
            <hr>
            <div class="row" style="margin-top:5px">
                <div class="col-sm-12 card-details">
                    <strong>Note</strong>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 card-details">
                    {{project?.note}}
                </div>
            </div>
        </div>

    </div>


</div>