import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from 'src/app/account/account.service';
import { User } from 'src/app/account/user.model';
import { ProjectService } from '../pages/projects/project.service';
import { Company } from '../shared/company.model';
import { List } from '../shared/list.model';
import { ProjectCALC } from '../shared/projectCALC.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  users: User[];
  usersChanged = new Subject<User[]>();
  error = new Subject<string>();
  rowGroupMetadata: any;
  expandedGroups: any;
  selectedEditMode: boolean;
  constructor(private projectService: ProjectService,
    private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }



  // Get data from servlet and return the projects array
  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.accountService.REST_API_SERVER_CALC
      + 'userServlet?action=LIST&loggedInEmail=' + this.accountService.currentUser.email
    )
      .pipe(map(result => result['Records'])
      );
  }

  setUsers(users: User[]) {
    this.users = users;

    this.sortByEnailUsers(this.users);
    // console.log(this.users)
    this.usersChanged.next(this.users.slice());
  }

  getUser(id: number) {
    if (this.users == undefined)
      return undefined;
    const user = this.users.find(
      (r) => {
        return r.idUser == id;
      }
    );
    return user;
  }


  public getRoleList(): Observable<List[]> {
    return this.http.get<List[]>(this.accountService.REST_API_SERVER_CALC
      + 'userServlet?action=ROLELIST&loggedInEmail=' + this.accountService.currentUser.email
    )
      .pipe(map(result => result['Records'])
      );
  }



  sortByEnailUsers(users: User[]) {
    users.sort((a, b) => {
      return a.email > b.email
        ? 1
        : a.email < b.email
          ? -1
          : 0;
    });
  }




  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.projectService.projects) {
      // this.projectService.projects.sort((a, b) => {
      //   if (a.projectName == this.defaultMatchName && b.projectName != this.defaultMatchName)
      //     return 1;
      //   if (b.projectName == this.defaultMatchName && a.projectName != this.defaultMatchName)
      //     return -1;
      //   if (b.projectName == this.defaultMatchName && a.projectName == this.defaultMatchName) {
      //     return a.name > b.name
      //       ? 1
      //       : a.name < b.name
      //         ? -1
      //         : 0;
      //   }

      //   return a.projectName > b.projectName
      //     ? 1
      //     : a.projectName < b.projectName
      //       ? -1
      //       : a.name > b.name
      //         ? 1
      //         : a.name < b.name
      //           ? -1
      //           : 0;;
      // });
      for (let i = 0; i < this.projectService.projects.length; i++) {
        let rowData = this.projectService.projects[i];
        let projectName = rowData.projectName;
        // if (projectName != 'Default') {
        if (i == 0) {
          this.rowGroupMetadata[projectName] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.projectService.projects[i - 1];
          let previousRowGroup = previousRowData.projectName;
          if (projectName === previousRowGroup)
            this.rowGroupMetadata[projectName].size++;
          else
            this.rowGroupMetadata[projectName] = { index: i, size: 1 };
        }

      }

      this.expandedGroups = {};

    }
  }

  onCloseForm() {
    this.modalService.dismissAll();

  }

  sortByNameUsersArray(arrayUsers: User[]) {
    arrayUsers.sort((a, b) => {
      return a.name > b.name
        ? 1
        : a.name < b.name
          ? -1
          : 0;
    });
  }



  updateSelectedUsers(users: User[], project: ProjectCALC) {
    var arrayIdUsers = []
    users.forEach(element => {

      arrayIdUsers.push(element.idUser);
    });
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ users: User }>(
        this.accountService.REST_API_SERVER_CALC
        + 'userServlet?action=UPDATEUSERSPOJECT&loggedInEmail='
        + this.accountService.currentUser.email
        + '&idProject=' + project.idProject,
        arrayIdUsers
      )
      .subscribe(responseData => {
        if (responseData['Result'] == "OK") {
          this.projectService.getProjects().subscribe(res => {

            this.projectService.setProjects(res);
            this.updateRowGroupMetaData();
          });
          this.toastr.success('User list updated');
        }
      },
        error => {
          this.error.next(error.message);
        });

  }

  deleteUser(user: User) {
    this.http
      .get(
        this.accountService.REST_API_SERVER_CALC
        + 'userServlet?action=DELETE&loggedInEmail='
        + this.accountService.currentUser.email
        + '&userEmail=' + user.email

      )
      .subscribe(responseData => {
        if (responseData['Result'] == "OK") {

          const index = this.users.findIndex(x => x.idUser === user.idUser);
          this.users.splice(index, 1);
          this.setUsers(this.users);

          this.toastr.success('Trash user successed');

        } else {
          this.toastr.error('Error');
        }

      },
        error => {
          this.error.next(error.message);
        });

  }

  createUser(name: string, surname: string, company: Company[], country: string, email: string, roleName: string, password: string, confirmPassword: string) {
    return this.http.post<any>(this.accountService.REST_API_SERVER + 'signinAngular', { name, surname, company, country, email, roleName, password, confirmPassword })
      .subscribe(responseData => {
        if (responseData['Result'] == 'OK') {
          var user = responseData['User'];
          // this.projects.unshift(project);
          this.users.push(user);
          this.toastr.success('User created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }


  updateUser(name: string, surname: string, company: Company[], country: string, email: string, roleName: string) {
    return this.http.post<any>(this.accountService.REST_API_SERVER_CALC + 'userServlet?action=UPDATE&loggedInEmail='
      + this.accountService.currentUser.email, { name, surname, company, country, email, roleName }).subscribe(responseData => {

        if (responseData['Result'] == 'OK') {
          let itemIndex = this.users.findIndex(user => user.email == email);

          this.users[itemIndex].name = name;
          this.users[itemIndex].surname = surname;
          this.users[itemIndex].company = company;
          this.users[itemIndex].country = country;
          this.users[itemIndex].roleName = roleName;
          this.toastr.success('Project updated');
        }

      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });




  }


}
