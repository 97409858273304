import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';



import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { UIModule } from 'src/app/shared/ui/ui.module';
import { SidebarCalcComponent } from './sidebar-calc/sidebar-calc.component';
import { TopbarCalcComponent } from './topbar-calc/topbar-calc.component';
import { LayoutCalcComponent } from './layout-calc.component';


@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutCalcComponent, 
    SidebarCalcComponent, 
    TopbarCalcComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    UIModule,
    PerfectScrollbarModule
  ],
  providers:[LanguageService]
})
export class LayoutsCalcModule { }
