<div class="content">
    <div class="row">
        <div class="col-sm-10" *ngIf="vehicleService.selectedEditMode == false">
            <h5>Add vehicle form</h5>
        </div>
        <div class="col-sm-10" *ngIf="vehicleService.selectedEditMode == true">
            <h5>Edit {{vehicle.name}}</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="vehicleService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="vehicleForm" (ngSubmit)="onSubmit()">

                <input type="hidden" id="id" formControlName="id" class="form-control">
                <input type="hidden" id="date" formControlName="date" class="form-control">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" id="name" formControlName="name" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="hull">Select a Hull*</label>
                            <select class="form-control" formControlName="hull" id="hull">
                                <option *ngFor="let el of hullService.hulls" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="lc">Select a Lc*</label>
                            <select class="form-control" formControlName="lc" id="lc">
                                <option *ngFor="let el of lcService.lcs" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-2">
                        <div class="form form-group">
                            <div class="form form-group">
                                <label>N_Engine*</label>
                                <input type="number" step="1" min="1" max="4" id="n_engine" formControlName="n_engine" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1" style="padding-top: 35px; padding-left: 22px;">
                        <h5>X</h5>
                    </div>

                    <div class="col-sm-3">
                        <div class="form form-group">
                            <label for="engine">Select a Engine*</label>
                            <select class="form-control" formControlName="engine" id="engine">
                                <option *ngFor="let el of engineService.engines" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="propeller">Select a Propeller*</label>
                            <select class="form-control" formControlName="propeller" id="propeller">
                                <option [value]="null">None</option>
                                <option *ngFor="let el of propellerService.propellers" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label for="appendageSet">Select a Appendage Set*</label>
                            <select class="form-control" formControlName="appendageSet" id="appendageSet">
                                <option [value]="null">None</option>
                                <option *ngFor="let el of appendageService.appendagesSet" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-sm-11">
                    </div>

                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(vehicleService.selectedEditMode == false)">
                        <button type="submit" class="btn btn-success" [disabled]="!vehicleForm.valid"><i class="bx bxs-save font-size-50"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>
                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(vehicleService.selectedEditMode == true)">
                        <button type="submit" class="btn btn-success" [disabled]="!vehicleForm.valid"><i class="bx bxs-edit-alt font-size-50"></i></button>
                        <div class="textUnderIcon">Edit</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>