import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem } from 'primeng/api';
import { Item } from 'src/app/CALC/shared/item.model';
import { VehicleService } from '../../vehicles/vehicle.service';
import { Variant } from '../../../shared/variant.model';
import { VariantService } from '../variant.service';


@Component({
  selector: 'app-variant-details',
  templateUrl: './variant-details.component.html',
  styleUrls: ['./variant-details.component.scss']
})
export class VariantDetailsComponent implements OnInit {

  variant: Variant;
  menuItems: MenuItem[];

  activeItem: MenuItem;

  items: Item[];
  index: number;


  constructor(
    public cookieService: CookieService,
    private variantService: VariantService,
    public vehicleService: VehicleService) {

  }



  ngOnInit() {
    this.cookieService.set('typeMenu', 'variant');
    this.vehicleService.closeItemEditForm();
    this.index = 0;
    var indexVD = this.cookieService.get("indexActive");
    
    console.log("index: " + this.index);
    this.variantService.selectedVariantEvent.subscribe(
      (selectedvariant: Variant) => {
        if (selectedvariant != undefined) {
          this.variant = selectedvariant;
          this.menuItems = [
            // { label: 'Baseline', icon: 'bx bxs-data' },
            // { label: this.variant.versionListName, icon: 'bx bx-wrench' },
            // { label: this.variant.propulsionListName, icon: 'bx bx-atom' },
            // { label: this.variant.optListName, icon: 'bx bx-brush' },
            // { label: this.variant.tankListName, icon: 'bx bx-water' },
            // { label: this.variant.crewListName, icon: 'bx bx-street-view' },
            // { label: this.variant.paxListName, icon: 'bx bx-street-view' }

            { label: 'Baseline' },
            { label: this.variant.versionListName },
            { label: this.variant.propulsionListName },
            { label: this.variant.optListName },
            { label: this.variant.tankListName },
            { label: this.variant.crewListName },
            { label: this.variant.paxListName }



          ];
          this.activeItem = this.menuItems[1];
          if(indexVD){
            this.index = +indexVD;
            this.activeItem = this.menuItems[indexVD];
          }
          
        }
      }
    );

    let selVariant = this.cookieService.get("selectedVariant");
    if (selVariant != undefined) {
      this.variantService.selectedVariantEvent.next(JSON.parse(selVariant) as Variant);
      this.variant = JSON.parse(selVariant) as Variant;
    }

   
  }

  onShowData(event) {
 
    this.vehicleService.deleteItemInfoListCookie();

    this.vehicleService.closeItemEditForm();
   

    if(this.menuItems.findIndex(x => x.label === event.srcElement.outerText) > -1){
      this.index = this.menuItems.findIndex(x => x.label === event.srcElement.outerText);
      this.cookieService.set("indexActive", this.index+"");
      console.log("this.index onShowData");
      console.log(this.index);

    }
    
   

    if (this.index == 0) {
      this.cookieService.set(Item.servletName, 'BaseLineItem');
      this.cookieService.set(Item.tableNameIdList, "");
      this.cookieService.set(Item.tableTitleItem, "Baseline");
    }

    if (this.index == 1) {
      this.cookieService.set(Item.servletName, 'versionItem');
      this.cookieService.set(Item.tableNameIdList, this.variant.idVersionList + "");
      this.cookieService.set(Item.tableTitleItem, this.variant.versionListName + "");
    }

    if (this.index == 2) {
      this.cookieService.set(Item.servletName, 'propulsionItem');
      this.cookieService.set(Item.tableNameIdList, this.variant.idPropulsionList + "");
      this.cookieService.set(Item.tableTitleItem, this.variant.propulsionListName + "");
    }

    if (this.index == 3) {
      this.cookieService.set(Item.servletName, 'optItem');
      this.cookieService.set(Item.tableNameIdList, this.variant.idOptList + "");
      this.cookieService.set(Item.tableTitleItem, this.variant.optListName + "");
    }

    if (this.index == 4) {
      this.cookieService.set(Item.servletName, 'tank');
      this.cookieService.set(Item.tableNameIdList, this.variant.idTankList + "");
      this.cookieService.set(Item.tableTitleItem, this.variant.tankListName + "");
    }

    if (this.index == 5) {
      this.cookieService.set(Item.servletName, 'crew');
      this.cookieService.set(Item.tableNameIdList, this.variant.idCrewList + "");
      this.cookieService.set(Item.tableTitleItem, this.variant.crewListName + "");
    }

    if (this.index == 6) {
      this.cookieService.set(Item.servletName, 'pax');
      this.cookieService.set(Item.tableNameIdList, this.variant.idPaxList + "");
      this.cookieService.set(Item.tableTitleItem, this.variant.paxListName + "");
    }


  }

}
