import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthHttpService } from '../../../core/services/authhttp.service';

import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AccountService } from '../../account.service';
import { ApprovalService } from 'src/app/MICAPP/pages/approvals/approval.service';
import { CookieService } from 'ngx-cookie-service';

import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';

declare function isHttps();
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login component
 */
export class LoginComponent implements OnInit, AfterViewInit {

  loginForm: FormGroup;
  submitted = false;
  error = '';
  // returnUrl: string;

  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private authHttpService: AuthHttpService,
    private accountService: AccountService,
    public cookieService: CookieService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {

    // console.log(window.location.protocol);
    if (window.location.protocol == 'https:') {
      this.accountService.REST_API_SERVER = "https://easypro.micad.it/easyPRO/";
      this.accountService.REST_API_SERVER_CALC = "https://easypro.micad.it/CALC/";
    } else {

      // this.accountService.REST_API_SERVER = "http://localhost:9395/easyPRO/";
      // this.accountService.REST_API_SERVER_CALC = "http://localhost:9395/CALC/";

      this.accountService.REST_API_SERVER = "http://easypro.micad.it/easyPRO/";
      this.accountService.REST_API_SERVER_CALC = "http://easypro.micad.it/CALC/";
    }

    localStorage.setItem("REST_API_SERVER", this.accountService.REST_API_SERVER);
    localStorage.setItem("REST_API_SERVER_CALC", this.accountService.REST_API_SERVER_CALC);

    this.cookieService.delete('loggedInUser');
    this.cookieService.delete('typeMenu');
    this.cookieService.delete("userRole");

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });




    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngAfterViewInit() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */



  onSubmitLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.authHttpService.login(this.f.email.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data['Result'] == 'OK') {
              console.log(data['User']);
              this.accountService.setCurrentUser(data['User']);
              this.router.navigate(['/dashboardLinks']);

            } else if (data['Result'] == 'ERROR') {
              this.error = data['Message'];
              this.toastr.error(this.error);
            }

          },
          error => {
            // console.log(error + " erroe")
            this.error = error ? error : '';
            this.router.navigate(['/login']);
          });
    }

  }

  onForgetPassword() {
    this.router.navigate(['/resetPassword']);
  }
}
