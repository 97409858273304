import { Component, OnInit, ViewChild } from '@angular/core';
import OrgChart from "@balkangraph/orgchart.js";
import { VariantService } from '../../../variants/variant.service';
import { Vehicle } from '../../../vehicles/vehicle.model';
import { VehicleService } from '../../../vehicles/vehicle.service';
import { ProjectService } from '../../project.service';
import { ProjectCALC } from '../../../../shared/projectCALC.model';
import { ChartObject } from '../chartObject.model';

@Component({
  selector: 'app-treegraph',
  templateUrl: './treegraph.component.html',
  styleUrls: ['./treegraph.component.scss']
})
export class TreegraphComponent implements OnInit {

  data: ChartObject[];
  dataMapping: number[];
  constructor(private projectService: ProjectService,
    private vehicleService: VehicleService,
    private variantService: VariantService) {

    this.data = [];
    this.dataMapping = [];
  }

  onProjectSelected(project: ProjectCALC) {
    this.vehicleService.selectedVehicleEvent.next(undefined);
    this.vehicleService.selectedVehicle = undefined;
    this.projectService.selectedProjectEvent.next(project);
    this.projectService.selectedProject = project;
    
    //console.log(project);
  }


  onVehicleSelected(vehicle: Vehicle) {
    this.projectService.selectedProjectEvent.next(undefined);
    this.projectService.selectedProject = undefined;

    this.vehicleService.selectedVehicleEvent.next(vehicle);
    this.vehicleService.selectedVehicle = vehicle;
  }


  ngOnInit() {


    // OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.ana);
    // OrgChart.templates.myTemplate.size = [300, 300];
    // OrgChart.templates.myTemplate.node = 
    //             '<circle cx="100" cy="100" r="100" fill="#4D4D4D" stroke-width="1" stroke="#1C1C1C"></circle>';
    var chart = new OrgChart(document.getElementById('tree'), {
      orientation: OrgChart.orientation.left_top,
      nodeMouseClick: OrgChart.action.none,

      scaleInitial: 0.5,
      nodeBinding: {
        field_0: "name",
        field_1: "title",
        img_0: "img"
      },
      tags: {
        "Project": {
          template: "diva"
        },
        "Vehicle": {
          template: "polina"
        },
        "Variant": {
          template: "ana"
        }
      },
      menu: {
        pdf: { text: "Export PDF" },
        png: { text: "Export PNG" },
        svg: { text: "Export SVG" },
        csv: { text: "Export CSV" }
      },
    }
    );
    let projSel = this.onProjectSelected.bind(this);
    let vehSel = this.onVehicleSelected.bind(this);
    chart.on('click', function (sender, args) {
      let nodeId = args.node.id;
      let val = sender.config.nodes.find(n => n.id == nodeId);
      // if (val.ProjectItem != undefined)
      projSel(val.ProjectItem);
      // else
      //   projSel(undefined);

      // if (val.VehicleItem != undefined) {
      vehSel(val.VehicleItem);
      // } else {
      //   vehSel(undefined);
      // }
    });
    this.projectService.getProjects().subscribe(projects => {
      this.vehicleService.getAllVehicles().subscribe(vehicles => {
        this.variantService.getAllVariants().subscribe(variants => {
          //
          projects.forEach(project => {
            let projId = this.data.length + 1;
            this.dataMapping[projId] = project.idProject;
            this.data.push(ChartObject.createFromProjectCALC(project, projId, "assets/images/hull.svg"))
            vehicles.filter(veh => veh.idProject == project.idProject).forEach(vehicle => {
              let vehicleId = this.data.length + 1;
              this.dataMapping[vehicleId] = vehicle.idVehicle;
              this.data.push(ChartObject.createFromVehicle(vehicle, vehicleId, projId, "assets/images/hull.svg"))
              variants.filter(variant => variant.idVehicle == vehicle.idVehicle).forEach(variant => {
                let variantId = this.data.length + 1;
                this.dataMapping[variantId] = variant.idVariant;
                this.data.push(ChartObject.createFromVariant(variant, variantId, vehicleId, "assets/images/hull.svg"))
              });
            });
          });
          chart.load(this.data);
        });
      });
    });
    /*
        chart.load([
          { id: 1, tags: ["Project"], name: "Demo_Yacht", title: "Length:30m Beam:5m", img: "assets/images/hull.svg" },
          { id: 2, tags: ["Project"], name: "Demo Mets Project", title: "Length:20.30m, Beam:6.07m", img: "https://bluegame.it/sites/default/files/2019-10/29_Mooring_0688.jpg" },
    
          { id: 3, tags: ["Vehicle"], pid: 1, name: "#1 - Demo", title: "D: 35842.43Kg", img: "assets/images/hull.svg" },
          { id: 4, tags: ["Vehicle"], pid: 1, name: "#2 - Demo", title: "D: 35842.43Kg", img: "assets/images/hull.svg" },
    
          { id: 5, tags: ["Vehicle"], pid: 2, name: "#1 - Mets", title: "D: 37957.60Kg", img: "https://bluegame.it/sites/default/files/2019-10/29_Mooring_0688.jpg" },
          { id: 6, tags: ["Vehicle"], pid: 2, name: "#2 - Mets", title: "D: 37957.60Kg" },
    
          { id: 7, tags: ["Variant"], pid: 3, name: "Europa SuperLeggero", title: "D: 40199.9Kg" },
          { id: 8, tags: ["Variant"], pid: 3, name: "US Super", title: "D: 42165.3Kg" },
          { id: 9, tags: ["Variant"], pid: 3, name: "Europa Base", title: "D: 35842.4Kg" },
    
          { id: 10, tags: ["Variant"], pid: 5, name: "Mets MAN I6", title: "D: 40830.7Kg" },
          { id: 11, tags: ["Variant"], pid: 5, name: "Mets MAN 1000", title: "D: 41969.4Kg" },
          { id: 12, tags: ["Variant"], pid: 5, name: "Mets MAN 1200", title: "D: 42172.7Kg" },
    
          { id: 13, tags: ["Variant"], pid: 6, name: "Mets MAN I6", title: "D: 40830.7Kg", img: "https://bluegame.it/sites/default/files/2019-10/29_Mooring_0688.jpg" },
          { id: 14, tags: ["Variant"], pid: 6, name: "Mets MAN 1000", title: "D: 41969.4Kg", img: "https://bluegame.it/sites/default/files/2019-10/29_Mooring_0688.jpg" },
          { id: 15, tags: ["Variant"], pid: 6, name: "Mets MAN 1200", title: "D: 42172.7Kg", img: "https://bluegame.it/sites/default/files/2019-10/29_Mooring_0688.jpg" },
    
        ]);*/
  }




}
