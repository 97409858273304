
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Propeller } from '../../../shared/propeller.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { PropellerEditComponent } from '../propeller-edit/propeller-edit.component';
import { PropellerService } from '../propeller.service';

@Component({
  selector: 'app-propeller-list',
  templateUrl: './propeller-list.component.html',
  styleUrls: ['./propeller-list.component.scss']
})
export class PropellerListComponent implements OnInit {

  selectedPropellerId: number;
  constructor(
    public cookieService: CookieService,
    public propellerService: PropellerService,
    public modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
    this.propellerService.getPropellers().subscribe(res => {
      this.propellerService.propellers = res;
    });
  }

  openAdd() {
    this.propellerService.selectedEditMode = false;
    const modalRef = this.modalService.open(PropellerEditComponent);
  }

  onPropellerSelected(propeller: Propeller) {

    if(propeller.id != this.selectedPropellerId){
      this.propellerService.selectedPropeller = propeller;
      this.propellerService.selectedPropellerEvent.emit(propeller);

      if (propeller != undefined)
        this.selectedPropellerId = propeller.id;
      else
        this.selectedPropellerId = undefined;
    }


  }
}
