import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Item } from 'src/app/CALC/shared/item.model';
import { ProjectCALC } from 'src/app/CALC/shared/projectCALC.model';
import { VehicleService } from '../../vehicle.service';
import { OptionalService } from '../optional.service';

@Component({
  selector: 'app-optional-match-edit',
  templateUrl: './optional-match-edit.component.html',
  styleUrls: ['./optional-match-edit.component.scss']
})
export class OptionalMatchEditComponent implements OnInit {
  optItems:Item[];
  optItemsAll:Item[];
  optItemsMatched:Item[];

  @Input() public matchName: string;

  constructor(public vehicleService: VehicleService,
    private optionalService:OptionalService,
    public cookieService: CookieService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
   
    this.optItemsMatched = [];
    this.vehicleService.getItems("BaseLineItem", +this.cookieService.get(ProjectCALC.idProjectSelected)).subscribe(
      res => {
        this.optItemsAll = res["Records"];
        this.optItems = res["Records"];
        this.optItems = this.optItems.filter(t => t.isOptional === true && t.conflictOptCode==0 && t.inseparableOptCode == 0);

        console.log("this.matchName");
        console.log(this.matchName);

        this.optItemsAll.forEach(t => {
          if(t.matchName == this.matchName)
          this.optItemsMatched = [...this.optItemsMatched, t];
        });
      }
    );


  }

  onMatchInConflictEdit(){
    if((this.optItemsMatched.length > 1 && this.matchName.length > 0)){
      console.log(this.matchName);
      this.optItemsMatched.forEach(itemOpt => {
        itemOpt.matchName = this.matchName;
      });
      // vanno aggiornati tutti i conflictOptCode
      console.log("this.optItemsMatchedInCOnflict");
      console.log(this.optItemsMatched);
      this.modalService.dismissAll();
      this.optionalService.matchConflictOptEdit(this.optItemsMatched, this.matchName);

      this.optItemsMatched.forEach(opt => {
        this.optionalService.itemsOptMatched.find(x => x.idItem === opt.idItem).matchName=this.matchName;
      });
      


      this.optionalService.updateRowGroupMetaData();
    }
    
    
  }

  onMatchInseparableEdit(){
    if((this.optItemsMatched.length > 1 && this.matchName.length > 0)){
      // vanno aggionati i inseparableOptCode
      this.optItemsMatched.forEach(itemOpt => {
        itemOpt.matchName = this.matchName;
      });
      console.log("this.optItemsMatchedInseparable");
      console.log(this.optItemsMatched);
      this.modalService.dismissAll();
      this.optionalService.matchInseparableOptEdit(this.optItemsMatched, this.matchName);

      this.optItemsMatched.forEach(opt => {
        this.optionalService.itemsOptMatched.find(x => x.idItem === opt.idItem).matchName=this.matchName;
      });
      this.optItemsMatched = [];
      




      this.optionalService.updateRowGroupMetaData();
    }

  }

}
