<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
    <perfect-scrollbar [config]="configData">

        <!--- Sidemenu -->
        <div id="sidebar-menu">
            <!-- Left Menu Start -->



            <!-- Project -->
            <ul class="metismenu list-unstyled" id="side-menu" #sideMenu *ngIf="cookieService.get('typeMenu')=='project'">
                <li>
                    <a routerLink="" class="side-nav-link-ref" routerLinkActive="active" style="display: none;">
                    </a>
                </li>
                <li>
                    <a routerLink="../dashboardLinks" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-arrow-back font-size-22"></i>
                        <span>Modules</span>
                    </a>
                </li>
                <li class="menu-title">Performance Prediction Tool</li>
                <!-- Layouts menu -->

                <!-- end Layout menu -->

                <li>
                    <a [routerLink]="" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-dashboard font-size-22"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <!-- <li>
                    <a routerLink="projects" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-ship font-size-22"></i>
                        <span>Projects</span>
                    </a>
                </li> -->

                <hr>
                <li class="menu-title">OLD INTERFACE</li>
                <li style="vertical-align: bottom;">
                    <a href="http://easypro.micad.it:4000/login" class="side-nav-link-ref">
                        <i class="bx bxs-book font-size-22"></i>
                        <span>DANIELS</span>
                    </a>
                </li>

            </ul>



            <!-- Vehicle -->
            <ul class="metismenu list-unstyled" id="side-menu" #sideMenu *ngIf="cookieService.get('typeMenu')=='projectDELPHY'">
                <li>
                    <a routerLink="" class="side-nav-link-ref" routerLinkActive="active" style="display: none;">
                    </a>
                </li>
                <li>
                    <a [routerLink]="['dashboard']" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-arrow-back font-size-22"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li class="menu-title"></li>
                <!-- Layouts menu -->

                <!-- end Layout menu -->

                <li>
                    <a class="side-nav-link-ref" [ngClass]="{'active': projectDELPHYService.selectedType == 'dashboard'}" routerLinkActive="active" (click)="projectDELPHYService.onActivate('dashboard')">
                        <i class="bx bxs-dashboard font-size-22"></i>
                        <!-- <span>{{selectedProject.projectName}}</span> -->
                        <span>{{projectService.selectedProject?.name}}</span>
                    </a>
                </li>

                <li>
                    <a class="side-nav-link-ref" [ngClass]="{'active': projectDELPHYService.selectedType == 'veh'}" routerLinkActive="active" (click)="projectDELPHYService.onActivate('veh')">
                        <i class="bx bxs-data font-size-22"></i>
                        <span>Vehicles</span>
                    </a>
                </li>

                <li>
                    <a class="side-nav-link-ref" [ngClass]="{'active': projectDELPHYService.selectedType == 'hull'}" routerLinkActive="active" (click)="projectDELPHYService.onActivate('hull')">
                        <i class="bx bxs-data font-size-22"></i>
                        <span>Hulls</span>
                    </a>
                </li>

                <li>
                    <a class="side-nav-link-ref" [ngClass]="{'active': projectDELPHYService.selectedType == 'lc'}" routerLinkActive="active" (click)="projectDELPHYService.onActivate('lc')">
                        <i class="bx bxs-book font-size-22"></i>
                        <span>Loads</span>
                    </a>
                </li>
                <li>
                    <a class="side-nav-link-ref" [ngClass]="{'active': projectDELPHYService.selectedType == 'eng'}" routerLinkActive="active" (click)="projectDELPHYService.onActivate('eng')">
                        <i class="bx bxs-ship font-size-22"></i>
                        <span>Engines</span>
                    </a>
                </li>

                <li>
                    <a class="side-nav-link-ref" [ngClass]="{'active': projectDELPHYService.selectedType == 'prop'}" routerLinkActive="active" (click)="projectDELPHYService.onActivate('prop')">
                        <i class="bx bxs-book font-size-22"></i>
                        <span>Propellers</span>
                    </a>
                </li>
                <li>
                    <a class="side-nav-link-ref" [ngClass]="{'active': projectDELPHYService.selectedType == 'app'}" routerLinkActive="active" (click)="projectDELPHYService.onActivate('app')">
                        <i class="bx bxs-ship font-size-22"></i>
                        <span>Appendages Set</span>
                    </a>
                </li>

            </ul>


        </div>
        <!-- Sidebar -->
    </perfect-scrollbar>
</div>
<!-- Left Sidebar End -->