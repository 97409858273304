<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
    <perfect-scrollbar [config]="configData">

        <!--- Sidemenu -->
        <div id="sidebar-menu">
            <!-- Left Menu Start -->



            <!-- Project -->
            <ul class="metismenu list-unstyled" id="side-menu" #sideMenu *ngIf="cookieService.get('typeMenu')=='project'">
                <li>
                    <a routerLink="" class="side-nav-link-ref" routerLinkActive="active" style="display: none;">
                    </a>
                </li>
                <li>
                    <a routerLink="../dashboardLinks">
                        <i class="bx bx-arrow-back font-size-22"></i>
                        <span>Modules</span>
                    </a>
                </li>
                <li class="menu-title">Computer Aided Load Case</li>
                <!-- Layouts menu -->

                <!-- end Layout menu -->

                <li>
                    <a routerLink="dashboard" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-dashboard font-size-22"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <!-- <li>
                    <a routerLink="projects" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-ship font-size-22"></i>
                        <span>Projects</span>
                    </a>
                </li> -->

                <li class="menu-title">Lists</li>

                <li (click)="onList('jobList')">
                    <a routerLink="projects/lists/jobList" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-wrench font-size-22"></i>
                        <span>Job</span>
                    </a>
                </li>

                <li (click)="onList('topologicalList')">
                    <a routerLink="projects/lists/topologicalList" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-atom font-size-22"></i>
                        <span>Topological</span>
                    </a>
                </li>

                <li (click)="onList('designList')">
                    <a routerLink="projects/lists/designList" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-brush font-size-22"></i>
                        <span>Design</span>
                    </a>
                </li>

                <li (click)="onList('unitsOfMeasureList')">
                    <a routerLink="projects/lists/unitsOfMeasureList" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-ruler font-size-22"></i>
                        <span>Units of measure</span>
                    </a>
                </li>

                <li class="menu-title">Features</li>

                <li>
                    <a routerLink="catalog" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-book font-size-22"></i>
                        <span>Catalog</span>
                    </a>
                </li>

                <li>
                    <a routerLink="analysis" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-ship font-size-22"></i>
                        <span>Analysis</span>
                    </a>
                </li>

            </ul>



            <!-- Vehicle -->
            <ul class="metismenu list-unstyled" id="side-menu" #sideMenu *ngIf="cookieService.get('typeMenu')=='vehicle'">
                <li>
                    <a routerLink="" class="side-nav-link-ref" routerLinkActive="active" style="display: none;">
                    </a>
                </li>

                <li>
                    <a routerLink="dashboard">
                        <i class="bx bx-arrow-back font-size-22"></i>
                        <span>Dashboard</span>
                    </a>
                </li>

                <li class="menu-title">Computer Aided Load Case</li>

                <li>
                    <a [routerLink]="['vehicles']" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-dashboard font-size-22"></i>
                        <span>{{selectedProject.projectName}}</span>
                    </a>
                </li>

                <li>
                    <a routerLink="baseline" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-data font-size-22"></i>
                        <span>Baseline</span>
                    </a>
                </li>

                <li>
                    <a routerLink="optionalItems" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-data font-size-22"></i>
                        <span>Options Summary</span>
                    </a>
                </li>
                <li class="menu-title">Lists</li>

                <li (click)="onList('regNumber')">
                    <a routerLink="projects/list/regNumber" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-list-ul font-size-22"></i>
                        <span>Vehicles</span>
                    </a>
                </li>

                <li (click)="onList('revision')">
                    <a routerLink="projects/list/revision" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-sitemap font-size-22"></i>
                        <span>Revisions</span>
                    </a>
                </li>
                <li class="menu-title">Features</li>
                <li>
                    <a routerLink="catalog" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-book font-size-22"></i>
                        <span>Catalog</span>
                    </a>
                </li>
                <li>
                    <a routerLink="analysis" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-ship font-size-22"></i>
                        <span>Analysis</span>
                    </a>
                </li>

                <li>
                    <a (click)="importJSON()">
                        <i class="bx bx-import font-size-22"></i>
                        <span>Import JSON</span>
                    </a>
                </li>

            </ul>






            <!-- Variant -->
            <ul class="metismenu list-unstyled" id="side-menu" #sideMenu *ngIf="cookieService.get('typeMenu')=='variant'">
                <li>
                    <a routerLink="" class="side-nav-link-ref" routerLinkActive="active" style="display: none;">
                    </a>
                </li>

                <li>
                    <a routerLink="vehicles" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-arrow-back font-size-22"></i>
                        <span>{{selectedProject.projectName}}</span>
                    </a>
                </li>

                <li class="menu-title">Computer Aided Load Case</li>

                <li>
                    <a [routerLink]="['variants']" [routerLinkActiveOptions]="{exact: true}" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-dashboard font-size-22"></i>
                        <span>{{selectedVehicle.regNumberName}}</span>
                    </a>
                </li>

                <li class="menu-title">Lists</li>

                <li (click)="onList('versionList')">
                    <a routerLink="lists/versionList" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-wrench font-size-22"></i>
                        <span>Versions</span>
                    </a>
                </li>

                <li (click)="onList('propulsionList')">
                    <a routerLink="lists/propulsionList" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-atom font-size-22"></i>
                        <span>Propulsions</span>
                    </a>
                </li>

                <li (click)="onList('optList')">
                    <a routerLink="lists/optList" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-brush font-size-22"></i>
                        <span>Optional Items</span>
                    </a>
                </li>

                <li class="menu-title">Features</li>
                <li>
                    <a routerLink="catalog" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-book font-size-22"></i>
                        <span>Catalog</span>
                    </a>
                </li>
                <li>
                    <a routerLink="analysis" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-ship font-size-22"></i>
                        <span>Analysis</span>
                    </a>
                </li>

            </ul>

            <!-- user -->
            <!-- Vehicle -->
            <ul class="metismenu list-unstyled" id="side-menu" #sideMenu *ngIf="cookieService.get('typeMenu')=='user'">
                <li>
                    <a routerLink="" class="side-nav-link-ref" routerLinkActive="active" style="display: none;">
                    </a>
                </li>

                <li>
                    <a routerLink="dashboard">
                        <i class="bx bx-arrow-back font-size-22"></i>
                        <span>Dashboard</span>
                    </a>
                </li>



            </ul>


        </div>
        <!-- Sidebar -->



    </perfect-scrollbar>
</div>
<!-- Left Sidebar End -->