<div class="content">
    <div class="row">
        <div class="col-sm-10" *ngIf="variantService.selectedEditMode == false">
            <h5>Add new Variant</h5>
        </div>
        <div class="col-sm-10" *ngIf="variantService.selectedEditMode == true">
            <h5>Edit {{variant.variantName}}</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="variantService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>



    <div *ngIf="variantService.selectedEditMode == false">
        <div class="row">
            <div class="col-sm-12">

                <h6>Please, check if you have already created one list for Version, Propulsion and Optional, it is necessary to select at least one list for each category.</h6>

                <h6>Each list associated with the variant can be edited by clicking on its name.</h6>

                <h6>Lists relating to <b>Tanks</b> (meaning the contents inside the boxes), <b>Crew</b> and <b>Pax</b> (passengers) are created automatically and are referred to as Variants.</h6>

            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-1">
                <img class="logo" src="assets/images/ISO.png" style="padding-top: 120%;">
                <img class="logo" src="assets/images/RINA.png" style="padding-top: 80%;">
            </div>
            <div class="col-sm-11">
                <h4>Report ISO/FDIS 12217-1</h4>

                <h6>Small craft — Stability and buoyancy assessment and categorization </h6>

                <h6>Part 1: Non-sailing boats of hull length greater than or equal to 6 m</h6>

                <h4>Report RINA Pleasure Yachts</h4>

            </div>
        </div>
        <hr>



    </div>



    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="variantForm" (ngSubmit)="onSubmit()">




                <input type="hidden" id="versionListName" formControlName="versionListName" class="form-control">
                <input type="hidden" id="propulsionListName" formControlName="propulsionListName" class="form-control">
                <input type="hidden" id="optListName" formControlName="optListName" class="form-control">

                <input type="hidden" id="idTankList" formControlName="idTankList" class="form-control">
                <input type="hidden" id="tankListName" formControlName="tankListName" class="form-control">

                <input type="hidden" id="idCrewList" formControlName="idCrewList" class="form-control">
                <input type="hidden" id="crewListName" formControlName="crewListName" class="form-control">

                <input type="hidden" id="idPaxList" formControlName="idPaxList" class="form-control">
                <input type="hidden" id="paxListName" formControlName="paxListName" class="form-control">

                <input type="hidden" id="idDistributedList" formControlName="idDistributedList" class="form-control">
                <input type="hidden" id="distributedListName" formControlName="distributedListName" class="form-control">

                <input type="hidden" id="isTrash" formControlName="isTrash" class="form-control">
                <input type="hidden" id="isDelete" formControlName="isDelete" class="form-control">



                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>Nome*</label>
                            <input type="text" id="variantName" formControlName="variantName" class="form-control">
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>Note</label>
                            <input type="text" id="note" formControlName="note" class="form-control">
                        </div>
                    </div>
                </div>



                <div class="row">
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label for="idVersionList">Select a Version List*</label>
                            <select class="form-control" formControlName="idVersionList" id="idVersionList">

                                <option *ngFor="let versionList of versionLists" [value]="versionList.id">
                                    {{versionList.name}}
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label for="idPropulsionList">Select a Propulsion List*</label>
                            <select class="form-control" formControlName="idPropulsionList" id="idPropulsionList">

                                <option *ngFor="let propulsionList of propulsionLists" [value]="propulsionList.id">
                                    {{propulsionList.name}}
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label for="idOptList">Select a Opt Item List*</label>
                            <select class="form-control" formControlName="idOptList" id="idOptList">

                                <option *ngFor="let optList of optLists" [value]="optList.id">
                                    {{optList.name}}
                                </option>

                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-11">
                    </div>

                    <div class="col-lg-1" style="padding-left: 15px;" *ngIf="variantService.selectedEditMode == false">
                        <button type="submit" class="btn btn-success" [disabled]="!variantForm.valid"><i class="bx bxs-save font-size-50"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>
                    <div class="col-lg-1" style="padding-left: 15px;" *ngIf="variantService.selectedEditMode == true">
                        <button type="submit" class="btn btn-success" [disabled]="!variantForm.valid"><i class="bx bxs-edit-alt font-size-50"></i></button>
                        <div class="textUnderIcon">Edit</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>