import { Component, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { Vehicle } from '../../../shared/vehicle.model';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-plots',
  templateUrl: './vehicle-plots.component.html',
  styleUrls: ['./vehicle-plots.component.scss']
})
export class VehiclePlotsComponent implements OnInit {


  @ViewChild('RtCanvas') RtCanvas;
  @ViewChild('tauCanvas') tauCanvas;
  @ViewChild('RpmCanvas') RpmCanvas;

  elChart: any;
  constructor(public vehicleService: VehicleService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

    this.vehicleService.danielsChanged.subscribe(() => {
      this.vehicleService.onInitGraphDaniel();
      this.chartMethod("Rt", this.RtCanvas, this.vehicleService.graphRt[0], this.vehicleService.graphRt[1]);
      this.chartMethod("tau", this.tauCanvas, this.vehicleService.graphTau[0], this.vehicleService.graphTau[1]);
      this.chartMethod("Rpm", this.RpmCanvas, this.vehicleService.graphRpm[0], this.vehicleService.graphRpm[1]);
    });
    
  }

  chartMethod(title: string, elCanvas: any, labelsData: string[], dataData: number[]) {

    
    this.elChart = new Chart(elCanvas?.nativeElement, {
      type: 'line',
      options: {
        title: {
          display: false,
          text: title
        },
        legend: {
          position: 'top',
          labels: {
            fontSize: 14
          }
  
        },
        rotation: -0.1
  
  
      },
      data: {
        labels: labelsData,
        datasets: [{
          label: title,
          data: dataData,
          backgroundColor: '#009EE3',
          fill: false
        }]
      }
    });
  }

  getRandomColorHover() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',0.3)';
  }

}
