<div style="height:33.34%; padding-bottom:20px">
    <div class="plot-card overflow-hidden">
        <canvas #ktCanvas></canvas>
    </div>
</div>
<div style="height:33.34%; padding-bottom:20px">
    <div class="plot-card overflow-hidden">
        <canvas #kqCanvas></canvas>
    </div>
</div>
<div style="height:33.34%">
    <div class="plot-card overflow-hidden">
        <canvas #etaCanvas></canvas>
    </div>
</div>