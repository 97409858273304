<div class="content">


    <div class="row">
        <div class="col-sm-10" *ngIf="vehicleService.selectedVehEditMode == false">
            <h5>Add new Vehicle</h5>
        </div>
        <div class="col-sm-10" *ngIf="vehicleService.selectedVehEditMode == true">
            <h5>Edit {{vehicle.regNumberName}}</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="vehicleService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>

    <div *ngIf="vehicleService.selectedVehEditMode == false">
        <div class="row">
            <div class="col-sm-1">
                <i class="bx bx-help-circle font-size-22" style="padding-top: 150%;"></i>
            </div>
            <div class="col-sm-11">
                <h6><b>Vehicle</b> is a combination of <b>Baseline</b> and <b>Variant</b>. </h6>

                <h6>The vehicle name is set in the associated list and generally identifies the name of the specific registration number</h6>

                <ol style="text-align: left;">
                    <li>
                        <h6>Insert the date and some notes to describe the object created.</h6>
                    </li>
                    <li>
                        <h6>Click on save button</h6>
                    </li>
                    <li>
                        <h6>A new Vehicle appear</h6>
                    </li>
                    <li>
                        <h6>Click on the Vehicle name in order to create the Variants associated</h6>
                    </li>

                </ol>
            </div>
        </div>
        <hr>





        <div class="row">
            <div class="col-sm-12" style="text-align: center;">
                <h4>The name will be assigned according to </h4>
                <h4>the order imposed in the list of Vehicles</h4>
            </div>
        </div>
        <hr>

    </div>


    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="vehicleForm" (ngSubmit)="onSubmit()">

                <input type="hidden" id="idVehicle" formControlName="idVehicle" class="form-control">
                <input type="hidden" id="idRegNumber" formControlName="idRegNumber" class="form-control">
                <input type="hidden" id="regNumberName" formControlName="regNumberName" class="form-control">
                <input type="hidden" id="idProject" formControlName="idProject" class="form-control">
                <input type="hidden" id="isTrash" formControlName="isTrash" class="form-control">
                <input type="hidden" id="isDelete" formControlName="isDelete" class="form-control">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Note</label>
                            <input type="text" id="note" formControlName="note" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>Start date*</label>
                            <input type="date" formControlName="startDate" id="startDate" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>End date*</label>
                            <input type="date" formControlName="endDate" id="endDate" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-11">
                    </div>
                    <div class="col-lg-1" style="padding-left: 15px;" *ngIf="vehicleService.selectedVehEditMode == false">
                        <button type="submit" class="btn btn-primary" [disabled]="!vehicleForm.valid"><i class="bx bxs-save font-size-50"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>
                    <div class="col-lg-1" style="padding-left: 15px;" *ngIf="vehicleService.selectedVehEditMode == true">
                        <button type="submit" class="btn btn-primary" [disabled]="!vehicleForm.valid"><i class="bx bxs-edit-alt font-size-50"></i></button>
                        <div class="textUnderIcon">Edit</div>
                    </div>
                </div>

            </form>

        </div>
    </div>

</div>