import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Appendage } from '../../../shared/appendage.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { AppendageService } from '../appendage.service';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent implements OnInit {

  appendageForm: FormGroup;

  @Input() appendage: Appendage;
  @Input() type: number;

  nameAlredyExist = "";
  title:string; 
  constructor(public appendageService: AppendageService,
    public accountService: AccountService,
    public cookieService: CookieService) {

  }

  ngOnInit(): void {
    this.initForm();
  }


  
  nameCheckUnique() {

    console.log("Dentro check");
    let flag = false;
    

    this.appendageService.appendages.forEach(appendage => {
      if(appendage.name == this.appendageForm.controls['name'].value)
          flag = true
    });

    if (flag) {
      this.nameAlredyExist = "Name Alredy Exist";
    }
    else{
      this.nameAlredyExist = "";
    }

  }
  onSubmit() {
    // this.dialogRef.close();
    if (this.appendageForm.valid && this.nameAlredyExist == "") {

      if (this.appendageService.selectedEditMode) {

        this.appendageService.updateAppendage(this.appendageForm.value);
      } else {
        this.appendageService.createAppendage(this.appendageForm.value)
      }
      this.appendageService.onCloseForm()
    }

  }


  private initForm() {
    this.setTitle(this.type);
    let id: number;
    let name: string;
    let date: string;
    let category = +this.type;
    let A: number;
    let L: number;
    let t: number;
    let c: number;
    let D: number;
    let epsilon: number;
    let Px = 0;
    let Py = 0;
    let Pz = 0;
    let project = +this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY);
   
    console.log(" edit mode imit");
    console.log(this.appendageService.selectedEditMode);
    
    if (this.appendageService.selectedEditMode && this.appendage != undefined) {
      id = this.appendage.id;
      name = this.appendage.name;
      date = this.appendage.date;
      A = this.appendage.A;
      L = this.appendage.L;
      t = this.appendage.t;
      c = this.appendage.c;
      D = this.appendage.D;
      epsilon = this.appendage.epsilon;
      Px = this.appendage.Px;
      Py = this.appendage.Py;
      Pz = this.appendage.Pz;

      

    }

    if(this.type == 1){
      this.appendageForm = new FormGroup({
        'id': new FormControl(id),
        'name': new FormControl(name, Validators.required),
        'date': new FormControl(date),
        'category': new FormControl(category),
        'A': new FormControl(A, Validators.required),
        't': new FormControl(t, Validators.required),
        'c': new FormControl(c, Validators.required),
        'epsilon': new FormControl(epsilon),
        'Px': new FormControl(Px),
        'Py': new FormControl(Py),
        'Pz': new FormControl(Pz),
        'project': new FormControl(project)
        
      });
    }
    if(this.type == 2){
      this.appendageForm = new FormGroup({
        'id': new FormControl(id),
        'name': new FormControl(name, Validators.required),
        'date': new FormControl(date),
        'category': new FormControl(category),
        'A': new FormControl(A, Validators.required),
        't': new FormControl(t, Validators.required),
        'c': new FormControl(c, Validators.required),
        'epsilon': new FormControl(epsilon),
        'Px': new FormControl(Px),
        'Py': new FormControl(Py),
        'Pz': new FormControl(Pz),
        'project': new FormControl(project)
  
      });
    }
    if(this.type == 3){
      this.appendageForm = new FormGroup({
        'id': new FormControl(id),
        'name': new FormControl(name, Validators.required),
        'date': new FormControl(date),
        'category': new FormControl(category),
        'A': new FormControl(A, Validators.required),
        'L': new FormControl(L, Validators.required),
        'epsilon': new FormControl(epsilon),
        'Px': new FormControl(Px),
        'Py': new FormControl(Py),
        'Pz': new FormControl(Pz),
        'project': new FormControl(project)
  
      });
    }
    if(this.type == 4){
      this.appendageForm = new FormGroup({
        'id': new FormControl(id),
        'name': new FormControl(name, Validators.required),
        'date': new FormControl(date),
        'category': new FormControl(category),
        'A': new FormControl(A, Validators.required),
        'L': new FormControl(L, Validators.required),
        'D': new FormControl(D, Validators.required),
        'epsilon': new FormControl(epsilon, Validators.required),
        'Px': new FormControl(Px),
        'Py': new FormControl(Py),
        'Pz': new FormControl(Pz),
        'project': new FormControl(project)
  
      });
    }
    if(this.type == 5){
      this.appendageForm = new FormGroup({
        'id': new FormControl(id),
        'name': new FormControl(name, Validators.required),
        'date': new FormControl(date),
        'category': new FormControl(category),
        'A': new FormControl(A, Validators.required),
        't': new FormControl(t, Validators.required),
        'c': new FormControl(c, Validators.required),
        'epsilon': new FormControl(epsilon),
        'Px': new FormControl(Px),
        'Py': new FormControl(Py),
        'Pz': new FormControl(Pz),
        'project': new FormControl(project)
  
      });
    }


   



  }

  setTitle(type:number){
    if(type == 1){
      this.title = "Rudder";
    }
    if(type == 2){
      this.title = "Fin";
    }
    if(type == 3){
      this.title = "Keel";
    }
    if(type == 4){
      this.title = "Shaft";
    }
    if(type == 5){
      this.title = "Bracket";
    }

  }
}
