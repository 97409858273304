<div class="container">
    <div class="row">

        <div class="col-md-3 verticalLine" *ngFor="let moduleLinkEl of moduleLinks">
            <app-module-link style="padding-top: 20px;" [moduleLink]="moduleLinkEl">

            </app-module-link>

        </div>
    </div>

</div>