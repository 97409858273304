import { User } from "src/app/account/user.model";
import { Company } from "./company.model";

export class ProjectCALC {


	static selectedProject: string = "selectedProject";
	static idProjectSelected: string = "idProjectSelected";


	public idProject: number;
	public projectName: string;
	public length: number;
	public beam: number;
	public startDate: string;
	public endDate: string;
	public note: string;
	public company: Company;
	public idJobList: number;
	public jobListName: string;
	public idTopologicalList: number;
	public topologicalListName: string;
	public idDesignList: number;
	public designListName: string;
	public idUnitsOfMeasureList: number;
	public unitsOfMeasureListName: string;
	public isTrash: boolean;
	public isDelete: boolean;	
	public users: User[];	
	public creationDate: string;
	public projectOwner: string;
	public imgProjectOwner: string;
	public img: string;
	
	
	constructor($idProject: number, $projectName: string, $length: number, $beam: number, $startDate: string, 
		$endDate: string, $note: string, $idJobList: number, $jobListName: string, $idTopologicalList: number, 
		$topologicalListName: string, $idDesignList: number, $designListName: string, $idUnitsOfMeasureList: number, 
		$unitsOfMeasureListName: string, $isTrash: boolean, $isDelete: boolean, $users: User[], $company: Company,
		 $creationDate: string, $projectOwner: string, $imgProjectOwner: string, $img: string) {
		this.idProject = $idProject;
		this.projectName = $projectName;
		this.length = $length;
		this.beam = $beam;
		this.startDate = $startDate;
		this.endDate = $endDate;
		this.note = $note;
		this.company = $company;
		this.idJobList = $idJobList;
		this.jobListName = $jobListName;
		this.idTopologicalList = $idTopologicalList;
		this.topologicalListName = $topologicalListName;
		this.idDesignList = $idDesignList;
		this.designListName = $designListName;
		this.idUnitsOfMeasureList = $idUnitsOfMeasureList;
		this.unitsOfMeasureListName = $unitsOfMeasureListName;
		this.isTrash = $isTrash;
		this.isDelete = $isDelete;
		this.users = $users;
		this.creationDate = $creationDate;
		this.projectOwner = $projectOwner;
		this.imgProjectOwner = $imgProjectOwner;
		this.img = $img;
	}

}