import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from 'src/app/account/account.service';
import { Item } from '../../shared/item.model';
import { ProjectCALC } from '../../shared/projectCALC.model';
import { Variant } from '../../shared/variant.model';
import { Vehicle } from '../vehicles/vehicle.model';
import { VehicleService } from '../vehicles/vehicle.service';
import { Report } from '../../shared/report.model';
import { formatDate } from '@angular/common';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

declare function drawChart(bubbleItem: Item[]);

declare function getImageUri();
@Injectable({
  providedIn: 'root'
})
export class ReportService {

  variant: Variant;
  typeReport: string;
  vehicle: Vehicle;
  reports: Report[];
  reportsPdf: Report[];
  project: ProjectCALC;
  bubbleItem: Item[];

  optItems: Item[];
  optNumber: number;
  optsNameReport = [];
  optsNamePdf = [];
  reportColumn: any[];

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    public vehicleService: VehicleService) {
  }



  onExportPdf() {

    this.project = JSON.parse(localStorage.getItem(ProjectCALC.selectedProject));
    this.vehicle = JSON.parse(this.cookieService.get(Vehicle.selectedVehicle));

    const doc = new jsPDF.default();

    var reportX = 17;

    doc.setFontSize(18);
    doc.setTextColor(50);
    doc.text(reportX, 20, this.project.projectName + " - " + this.vehicle.regNumberName);
    doc.addImage('assets/images/logo-dark.png', 'PNG', 155, 15, 40, 13);


    // load case
    doc.setFontSize(20);
    doc.setTextColor(60)
    doc.text(88, 37, 'Load case');



    var proDetBoxY = 50;

     // immagine del gragico a bolle
     doc.addImage(getImageUri(), 'PNG', reportX-5, proDetBoxY + 38, 190, 115);




    doc.setFontSize(16);
    doc.setTextColor(255)
    doc.addImage('assets/images/header-background.jpg', 'JEPG', reportX - 2, proDetBoxY - 7, 180, 9);
    doc.text(reportX, proDetBoxY, 'Project details:');

    var proDetBoxNumX = reportX + 70;
    doc.setTextColor(70)
    doc.setFontSize(11);
    doc.text(reportX, proDetBoxY + 10, 'Length:');
    doc.text(proDetBoxNumX, proDetBoxY + 10, '' + this.project.length + ' m');


    doc.text(reportX, proDetBoxY + 15, 'Beam:');
    doc.text(proDetBoxNumX, proDetBoxY + 15, '' + this.project.beam + ' m');

    doc.text(reportX, proDetBoxY + 25, 'Variant name:');
    doc.text(proDetBoxNumX, proDetBoxY + 25, '' + this.variant.variantName);

    doc.text(reportX, proDetBoxY + 35, 'Version list:');
    doc.text(proDetBoxNumX, proDetBoxY + 35, '' + this.variant.versionListName);

    doc.text(reportX, proDetBoxY + 40, 'Propulsion list:');
    doc.text(proDetBoxNumX, proDetBoxY + 40, '' + this.variant.propulsionListName);

    doc.text(reportX, proDetBoxY + 45, 'Optioanl Items list:');
    doc.text(proDetBoxNumX, proDetBoxY + 45, '' + this.variant.optListName);




    //list optional items
    if (this.optsNamePdf.length == 0){
      if (this.optsNameReport.length > 2) {
        const n = Math.floor(this.optsNameReport.length / 3);

        for (let i = 0; i < n; i++) {
          const y = i + n ;
          const z = i + 2 * n;
          this.optsNamePdf.push([this.optsNameReport[i], this.optsNameReport[y], this.optsNameReport[z]]);
        }

      } else if (this.optsNameReport.length == 1) {
        this.optsNamePdf.push([this.optsNameReport[0], "", ""]);
      } else if (this.optsNameReport.length == 2) {
        this.optsNamePdf.push([this.optsNameReport[0], this.optsNameReport[1], ""]);
      }
    }
    doc.autoTable({
      head: [['Optional item', '', '']],
      body: this.optsNamePdf,
      startY: 210
    })


    // pagina Condition
    doc.addPage();
    this.reportsPdf = this.reports.filter(r => r.conditionName != "");
    var reportX = 17;
    var reportY = 25;
    var reportWx = 110;
    var reportXX = reportWx + 30;
    var reportYX = reportXX + 20;
    var reportZX = reportYX + 20;

    doc.addImage('assets/images/header-background.jpg', 'JEPG', reportX - 2, reportY - 6, 180, 8);
    doc.setTextColor(255);
    doc.setFontSize(11);
    doc.text(reportX, reportY, 'Condition Name');
    doc.text(reportWx, reportY, 'Weight [kg]');
    doc.text(reportXX, reportY, 'Xg [m]');
    doc.text(reportYX, reportY, 'Yg [m]');
    doc.text(reportZX, reportY, 'Zg [m]');
    doc.setFontSize(10);


    for (let i = 0; i < this.reportsPdf.length; i++) {
      reportY += 7;


      if (this.reportsPdf[i].conditionName.includes("Condition") || this.reportsPdf[i].conditionName.includes("Maximum")
        || this.reportsPdf[i].conditionName == ("Optional") || this.reportsPdf[i].conditionName == ("Crew")
        || this.reportsPdf[i].conditionName == ("Tanks")) {
        doc.setTextColor(10)
        doc.setFontSize(10);
      } else {
        this.reportsPdf[i].conditionName = '     ' + this.reportsPdf[i].conditionName
      }

      doc.setFillColor(60);
      if (i % 2 == 0) {
        doc.addImage('assets/images/row-background.jpg', 'JEPG', reportX - 2, reportY - 5, 180, 7);
      }

      // rimuovo lo 0 dal crew e tanks
      if (this.reportsPdf[i].conditionName == ("Crew") || this.reportsPdf[i].conditionName == ("Tanks")) {
        doc.text(reportX, reportY, '' + this.reportsPdf[i].conditionName);
        doc.text(reportWx, reportY, '');
        doc.text(reportXX, reportY, '');
        doc.text(reportYX, reportY, '');
        doc.text(reportZX, reportY, '');
      } else {
        doc.text(reportX, reportY, '' + this.reportsPdf[i].conditionName);
        doc.text(reportWx, reportY, '' + this.reportsPdf[i].weight);
        doc.text(reportXX, reportY, '' + this.reportsPdf[i].xg);
        doc.text(reportYX, reportY, '' + this.reportsPdf[i].yg);
        doc.text(reportZX, reportY, '' + this.reportsPdf[i].zg);
      }

      doc.setTextColor(100);
      doc.setFontSize(9);
      // doc.setFont('Arial', 'normal');
    }

    doc.save(this.project.projectName + ' - ' + this.vehicle.regNumberName+ ' - ' + this.variant.variantName + '_'+ formatDate(new Date(), 'yyyy/MM/dd', 'en') +'.pdf');
  }






  getReport(variant: Variant, reportType: string): Observable<any> {
    this.variant = variant;
    this.vehicleService.getItems('optItem', variant.idOptList).subscribe(res => {
      this.optItems = res['Records'];
      this.optItems.sort((a, b) => a.name.localeCompare(b.name));
      this.optNumber = this.optItems.length;
      var optDefault = this.optItems.filter(r => r.matchName == "Default");
      var optNoDefault = this.optItems.filter(r => r.matchName != "Default");

      // optNoDefault.filter((e, i) => optNoDefault.findIndex(a => a["matchName"] === e["matchName"]) === i);

      console.log("optItems")
      console.log(this.optItems)

 
      console.log("optDefault")
      console.log(optDefault)

      console.log("optNoDefault")
      console.log(optNoDefault)


      let mymap = new Map();

      optNoDefault = optNoDefault.filter(el => {
        const val = mymap.get(el.matchName);
        if (val) {
          if (el.matchName < val) {
            mymap.delete(el.matchName);
            mymap.set(el.matchName, el.idItem);
            return true;
          } else {
            return false;
          }
        }
        mymap.set(el.matchName, el.idItem);
        return true;
      });

      console.log("optDefaultDopo")
      console.log(optDefault)


      console.log("optNoDefaultDopo")
      console.log(optNoDefault)

     
      optNoDefault.forEach(opt => {
        this.optsNameReport.push(opt.matchName);
      });

      optDefault.forEach(opt => {
        this.optsNameReport.push(opt.name);
      });


      this.optsNameReport.sort((a, b) => a.localeCompare(b));
    });
    


    return this.http.get<any>(this.accountService.REST_API_SERVER_CALC
      + 'reportServlet?action=FULLREPORTLASTREV' + reportType + '&loggedInEmail='
      + this.accountService.currentUser.email + '&idVariant=' + variant.idVariant
    )
      .pipe(map(
        (result: any) => {

          this.reports = (result["Records"]);
          this.bubbleItem = (result["BubbleItem"]);
          drawChart(this.bubbleItem);

          this.project = (result["Project"]);
          this.vehicle = (result["Vehicle"]);
          this.typeReport = (reportType);

        }
      )
      );
  }

  deleteFromCookie() {
    this.cookieService.delete('reportType');
  }

}

