import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { Item } from 'src/app/CALC/shared/item.model';
import { Tank } from 'src/app/CALC/shared/tank.model';
import { TankService } from './tank.service';

@Component({
  selector: 'app-tanks',
  templateUrl: './tanks.component.html',
  styleUrls: ['./tanks.component.scss']
})
export class TanksComponent implements OnInit {

  cols: any[];
  selectedTank: Tank;
  requestType = "tank";
  idList: number;
  
  constructor(
    public tankService: TankService,
    public accountService: AccountService,
    public cookieService: CookieService) { }

  ngOnInit(): void {

    this.cookieService.set("topbarTitle","Tank item data input");
    this.selectedTank = undefined;

    this.idList = +this.cookieService.get(Item.tableNameIdList);
    this.requestType = this.cookieService.get(Item.servletName);
    this.tankService.getTanks(this.requestType, this.idList).subscribe(res => {
      this.tankService.setTanks(res);
    });
      


    this.cols = [
      { field: 'name', header: 'Name', width: "260px" },
      // { field: 'shape', header: 'Shape', width: "120px" },
      // { field: 'angle', header: 'Angle', width: "150px" },
      // { field: 'dimension1', header: 'Dimension1', width: "90px" },
      // { field: 'dimension2', header: 'Dimension2', width: "90px" },
      // { field: 'dimension3', header: 'Dimension3', width: "90px" },
      // { field: 'dimension4', header: 'Dimension4', width: "90px" },
      // { field: 'dimension5', header: 'Dimension5', width: "90px" },
      { field: 'quantity', header: 'Quantity', width: "100px" },
      { field: 'density', header: 'Density', width: "100px" },
      { field: 'weight', header: 'Weight', width: "100px" },
      { field: 'xg', header: 'Xg', width: "90px" },
      { field: 'yg', header: 'Yg', width: "90px" },
      { field: 'zg', header: 'Zg', width: "90px" },
      { field: 'lastUpdateDate', header: 'LastUpdateDate', width: "140px" },
      { field: 'note', header: 'Note', width: "140px" }
    ];

  }

  onNew() {

    this.tankService.selectedEditMode = false;
    let tank = new Tank(0,0,'','',0,0,0,0,0,0,0,0,0,0,0,0,'','',false,false);
  
    this.tankService.selectTankForEdit(tank);
  }

  onSelect(tank: Tank) {
    this.tankService.selectedEditMode = true;
    this.tankService.selectedTank = tank;
    this.tankService.selectTankForEdit(tank);
  }
} 
