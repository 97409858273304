import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { ProjectCALC } from 'src/app/CALC/shared/projectCALC.model';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-clone',
  templateUrl: './project-clone.component.html',
  styleUrls: ['./project-clone.component.scss']
})
export class ProjectCloneComponent implements OnInit {

  @Input() public project: ProjectCALC;
  projectForm: FormGroup;
  constructor(public projectService: ProjectService,
    public cookieService: CookieService,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.initForm();
    
  }

  ngOnChanges() {
    console.log(this.projectForm);
  }
  onSubmit() {
    this.modalService.dismissAll();
    this.projectService.cloneProject(this.projectForm.value.id, this.projectForm.value.name);
  }

  onCancel() {
    this.modalService.dismissAll();
  }

 

  private initForm() {
    let id : number;
    let projectName = 'Copy of '+this.project.projectName;
    if (this.project != undefined) {
      id = this.project.idProject;
    }
    this.projectForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(projectName, Validators.required)
    });
  }

}
