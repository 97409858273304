import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { Propeller } from '../../../shared/propeller.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { PropellerDeleteComponent } from '../propeller-delete/propeller-delete.component';
import { PropellerEditComponent } from '../propeller-edit/propeller-edit.component';
import { PropellerService } from '../propeller.service';

@Component({
  selector: 'app-propeller-details',
  templateUrl: './propeller-details.component.html',
  styleUrls: ['./propeller-details.component.scss']
})
export class PropellerDetailsComponent implements OnInit {

  propeller: Propeller;
  project: ProjectDELPHY;

  constructor(
    public cookieService: CookieService,
    public propellerService: PropellerService,
    private modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
 
    if(this.propellerService.selectedPropeller == undefined){
      this.propellerService.selectedPropellerEvent.subscribe(
        (selectedPropeller: Propeller) => {
          this.propeller = selectedPropeller;
        }
      );
    }
  }

  onEdit() {
    this.propellerService.selectedEditMode = true;
    const modalRef = this.modalService.open(PropellerEditComponent);
    modalRef.componentInstance.propeller = this.propellerService.selectedPropeller;
  }

  onTrash() {
    const modaldelete = this.modalService.open(PropellerDeleteComponent);
    modaldelete.componentInstance.propeller = this.propellerService.selectedPropeller;
  }

}