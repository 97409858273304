<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card overflow-hidden firstRow">
                <div class="bg-soft-primary">
                    <div class="row">
                        <div class="col-sm-3">
                            <img class="rounded-circle imgCardDetails img-thumbnail rounded-circle" src="{{currentUser.avatarImg}}">
                        </div>
                        <div class="col-7">
                            <div class="p-3">
                                <h5 style="color: white;">{{currentUser.name}} {{currentUser.surname}}</h5>
                                <p style="color: white;">{{currentUser.email}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">


                    <div class="row" style="vertical-align: middle; text-align: center; padding-top: 12%;">
                        <!-- <div class="col-sm-4" style="border-right: 1px solid #747779; height: 60px;">
                            <i class="bx bx-send font-size-20"></i>
                            <h5 class="font-size-13">cose</h5>
                            <h5 class="font-size-15" style="font-weight: bold;">cose</h5>
                        </div>
                        <div class="col-4" style="border-right: 1px solid #747779; height: 60px;">
                            <i class="bx bx-import font-size-20"></i>
                            <h5 class="font-size-13">cose</h5>
                            <h5 class="font-size-15" style="font-weight: bold;">cose</h5>
                        </div>
                        <div class="col-4">
                            <i class="bx bx-time font-size-20"></i>
                            <h5 class="font-size-13">cose</h5>
                            <h5 class="font-size-15" style="font-weight: bold;">cose</h5>
                        </div> -->
                    </div>


                </div>

            </div>
        </div>
        <!-- here graphs\ -->
        <div class="col-xl-8">
            <div class="card overflow-hidden firstRow">
                <div class="row">
                    <div class="col-sm-12">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>