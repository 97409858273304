<div class="row ">
    <div class="col-sm-4">
        <div class="card">
            <canvas #jobCanvas></canvas>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="card">
            <canvas #topoCanvas></canvas>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="card">
            <div id="bubbleChart"></div>
        </div>
    </div>
</div>