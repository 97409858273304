import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Item } from '../../shared/item.model';
import { Variant } from '../../shared/variant.model';
import { VariantService } from '../variants/variant.service';
import { VehicleService } from '../vehicles/vehicle.service';
import { ReportService } from './report.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {


  constructor(public reportService: ReportService,
    private variantService: VariantService,
    private router: Router,
    private route: ActivatedRoute,
    public cookieService: CookieService,
    public vehicleService: VehicleService) {

   
   }

  ngOnInit(): void {


    let reportType = this.cookieService.get("reportType");
    let selVariant = this.cookieService.get(Variant.selectedVariant);
    if (selVariant != undefined) {
      this.reportService.getReport(JSON.parse(selVariant) as Variant, reportType).subscribe(
        (data: any) => { }
      );
    }
  }

  onVariantSelectedReport(variant: Variant) {
    this.variantService.selectedVariant = variant;
    this.variantService.selectedVariantEvent.next(variant);

    console.log("variant");
    console.log(variant);

    this.cookieService.set("indexActive", 1+"");
    this.cookieService.set(Variant.selectedVariant, JSON.stringify(variant));
    this.cookieService.set(Variant.idVariantSelected, JSON.stringify(variant.idVariant));

    this.cookieService.set(Item.servletName, 'versionItem');
    this.cookieService.set(Item.tableNameIdList, variant.idVersionList + "");

    // this.cookieService.set('typeMenu', 'variant');
    this.router.navigate(['../variants/details'], { relativeTo: this.route });

    this.vehicleService.titleSelected = '';
    this.cookieService.delete(Item.tableTitleItem);

    
  }


  onListSelectedReport(requestType: string, idList: number, listName: string) {

   
    var page = "variants"


    if(requestType == "versionList"){
      
      this.vehicleService.titleSelected = listName;
      this.cookieService.set(Item.tableTitleItem, listName);

      this.cookieService.set(Item.tableNameIdList, ""+idList);
      this.cookieService.set(Item.servletName, 'versionItem');
      

      this.router.navigate(['../CALC/'+page+'/list/versionItem'])
    }

    


    if(requestType == "propulsionList"){
      page = "variants"
      this.vehicleService.titleSelected = listName;
      this.cookieService.set(Item.tableTitleItem, listName);

      this.cookieService.set(Item.tableNameIdList, ""+idList);
      this.cookieService.set(Item.servletName, 'propulsionItem');

      this.router.navigate(['../CALC/'+page+'/list/propulsionItem'])
    }
    
    if(requestType == "optList"){
      page = "variants"
      this.vehicleService.titleSelected = listName;
      this.cookieService.set(Item.tableTitleItem, listName);

      this.cookieService.set(Item.tableNameIdList, ""+idList);
      this.cookieService.set(Item.servletName, 'optItem');

      this.router.navigate(['../CALC/'+page+'/list/optItem'])
    }
    
  }
}
