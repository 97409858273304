import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/operators';
import { UserProfileService } from '../../../core/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { AuthHttpService } from 'src/app/core/services/authhttp.service';
import { AccountService } from '../../account.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit {

  signupForm: FormGroup;
  
  error = '';


  constructor(
    private router: Router,
    private authHttpService: AuthHttpService,
    private accountService: AccountService,
    public cookieService: CookieService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.signupForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'surname': new FormControl('', Validators.required),
      'company': new FormControl('', Validators.required),
      'country': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'password': new FormControl('', Validators.required),
      'confirmPassword': new FormControl('', Validators.required)
    });
  }

  ngAfterViewInit() {
  }

  onSubmitRegister() {
       console.log(this.signupForm.invalid)
    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    } else {
        this.authHttpService.signin(this.signupForm.value.name, this.signupForm.value.surname, 
          this.signupForm.value.company, this.signupForm.value.country, this.signupForm.value.email, 
          this.signupForm.value.password, this.signupForm.value.confirmPassword)
          .pipe(first())
          .subscribe(
            data => {
              if (data['Result'] == 'OK') {
                console.log(data['User']);
                this.accountService.setCurrentUser(data['User']);
                this.router.navigate(['/dashboardLinks']);

              } else if (data['Result'] == 'ERROR') {
                this.error = data['Message']
                this.toastr.error(this.error);
              }

            },
            error => {
              console.log(error + " erroe")
              this.error = error ? error : '';
              this.router.navigate(['/login']);
            });
    }
  }
}
