<div class="container-fluid">

    <div class="textUnderIcon">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; ">Vehicle dashboard – Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Baseline</h6>
                                                <h6 class="font-size-11">list of items that are permanently present in all configurations</h6>
                                            </div>
                                        </div>
                                        <div class="col-6" style="cursor: pointer">
                                            <i class="bx bxs-data font-size-20"></i>
                                            <h6 class="list-name-help">Options Summary</h6>
                                            <h6 class="font-size-11">list of all potential optional items on board</h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6" style="cursor: pointer">
                                            <i class="bx bx-list-ul font-size-20"></i>
                                            <h6 class="list-name-help">Vehicles</h6>
                                            <h6 class="font-size-11">list of vehicles names, commonly used to distinguish registration numbers</h6>
                                        </div>
                                        <div class="col-6" style="cursor: pointer;">
                                            <i class="bx bx-sitemap font-size-20"></i>
                                            <h6 class="list-name-help">Revisions</h6>
                                            <h6 class="font-size-11">list of revision tags to be assigned to items</h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-copy font-size-20"></i>
                                            <h6 class="font-size-13">Clone feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6><b>Vehicle</b> is a combination of <b>Baseline</b> and <b>Variant</b>. </h6>
                                        <h6><b>Variant</b> is a combination of <b>Version</b>, <b>Propulsion</b> and <b>Optional items</b>.</h6>
                                        <h6>The "<b>Baseline</b>“ and “<b>Options Summary</b>” lists are common to all vehicles and are added to all configurations.</h6>
                                        <h6>The “<b>Vehicles</b>” and “<b>Revisions</b>” lists are common to all vehicles, if you change objects in these lists, item properties change.</h6>
                                    </div>

                                    <ol style="text-align: left;">
                                        <li>
                                            <h6>Customise the “Vehicles” and “Revisions” lists</h6>
                                        </li>
                                        <li>
                                            <h6>Click on “+” button</h6>
                                        </li>
                                        <li>
                                            <h6>Edit vehicle details</h6>
                                        </li>
                                        <li>
                                            <h6>Click on vehicle name</h6>
                                        </li>
                                        <li>
                                            <h6>Go to Baseline and Options Summary to insert items</h6>
                                        </li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>











    <div class="row">
        <div [ngClass]="{'col-xl-9':  vehicleService.selectedVehicle!= undefined,'col-xl-12':vehicleService.selectedVehicle == undefined}">
            <div class="row">
                <div [ngClass]="{'col-xl-4':  vehicleService.selectedVehicle!= undefined,'col-xl-3':vehicleService.selectedVehicle == undefined}" *ngFor="let vehicleEl of vehicles">

                    <div class="card card-mini text-white card-mini-has-bg">

                        <div class="card-mini-img-overlay d-flex flex-column">

                            <div class="card-mini-body" style="cursor: pointer;" (click)="onVehicles(vehicleEl)" title="Show {{vehicleEl.regNumberName}}'s variants">
                                <h4 class="card-mini-title mt-0 "><a class="text-white" herf="#">{{vehicleEl?.regNumberName}}</a></h4>
                                <small><i class="far fa-clock"></i> {{vehicleEl?.startDate}}</small>
                            </div>

                            <div class="card-mini-footer">
                                <div class="row">
                                    <div class="col-sm-3">
                                    </div>
                                    <div class="col-sm-6" style="text-align: center; padding-top: 15px;">
                                        <i style="cursor: pointer;" class="bx bx-dots-horizontal-rounded font-size-22" title="Details" (click)="onVehicleSelected(vehicleEl)"></i>
                                        <div class="textUnderIcon">Details</div>
                                    </div>
                                    <div class="col-sm-3"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-3">
            <app-vehicle-details *ngIf="vehicleService.selectedVehicle!=undefined"></app-vehicle-details>
        </div>

    </div>

















    <!-- 

    <div class="row">
        <div [ngClass]="{'col-xl-9':  vehicleService.selectedVehicle!= undefined,'col-xl-12':vehicleService.selectedVehicle == undefined}">
            <div class="row">
                <div [ngClass]="{'col-xl-4':  vehicleService.selectedVehicle!= undefined,'col-xl-3':vehicleService.selectedVehicle == undefined}" *ngFor="let vehicleEl of vehicles">


                    <div class="card overflow-hidden">

                        <div class="bg-soft-primary" style="cursor: pointer;" (click)="onVehicles(vehicleEl)" title="Show {{vehicleEl.regNumberName}}'s variants">
                            <div class="row">
                                <div class="col-sm-4">
                                    <img class="rounded-circle imgCardDetails img-thumbnail rounded-circle" src="assets/images/hull.svg">
                                </div>

                                <div class="col-sm-8">
                                    <div>
                                        <h5 style="color: white; padding-top: 35px; font-weight: bold;">{{vehicleEl.regNumberName}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body>
                            <div class="row" style="margin-top:5px">
                                <div class="col-sm-12" style="font-weight: bold; text-align: center;">
                                    Note
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12" style="text-align: center;">
                                    {{vehicleEl.note | shorten: 80}}
                                </div>
                            </div>

                        </div>

                        <div class="border-top" *ngIf="(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">
                            <div class="row card-action">
                                <div class="col-sm-4 card-button">
                                    <i class="bx bxs-edit-alt font-size-22" title="Edit" (click)="onEdit(vehicleEl)" style="cursor: pointer;"></i>
                                    <div class="textUnderIcon">Edit</div>
                                </div>
                                <div class="col-sm-4 card-button">
                                    <i class="bx bx-dots-horizontal-rounded font-size-22" title="Details" style="cursor: pointer;" (click)="onVehicleSelected(vehicleEl)">
                                    </i>
                                    <div class="textUnderIcon">Details</div>
                                </div>
                                <div class="col-sm-4 card-button">
                                    <i class="bx bxs-trash-alt font-size-22" title="Delete" (click)="onTrash(vehicleEl)" style="cursor: pointer;"></i>
                                    <div class="textUnderIcon">Delete</div>
                                </div>
                            </div>
                        </div>

                        <div class="border-top" *ngIf="!(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">
                            <div class="row card-action">
                                <div class="col-sm-12 card-button">
                                    <i class="bx bx-dots-horizontal-rounded font-size-22" style="cursor: pointer;" (click)="onVehicleSelected(vehicleEl)" title="Details">
                                    </i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </div>
        <div class="col-xl-3">

            <app-vehicle-details *ngIf="vehicleService.selectedVehicle!=undefined"></app-vehicle-details>

        </div>

    </div> -->

</div>

<div class="floating-action-button" (click)="openAdd()" *ngIf="(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">
    <button type="button" class="btn btn-primary btn-circle btn-lg" id="addBtn">
        <i class="fas fa-plus" title="New"></i>
        <div class="textUnderIcon">New</div>
    </button>
</div>