import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { DashboardLinksComponent } from './account/dashboard-links/dashboard-links.component';
import { LayoutMicappComponent } from './MICAPP/layouts/layout-micapp.component';
import { LayoutPrjComponent } from './PROJECTMANAGMENT/layouts/layout-prj.component';
import { LayoutCalcComponent } from './CALC/layouts/layout-calc.component';
import { PasswordresetComponent } from './account/auth/passwordreset/passwordreset.component';
import { PasswordchangeComponent } from './account/auth/passwordchange/passwordchange.component';
import { LayoutDelphyComponent } from './DELPHY/layouts/layout-delphy.component';

const routes: Routes = [
  // { path: '', redirectTo: 'account/login', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: 'resetPassword', component: PasswordresetComponent },
  { path: 'changePassword/:tokenPassReset', component: PasswordchangeComponent },
  {
    path: 'MICAPP', component: LayoutMicappComponent, loadChildren: () =>
      import('./MICAPP/pages/micapp.module').then(m => m.MicappModule)
  }
  ,
  {
    path: 'EASYPRO', component: LayoutPrjComponent, loadChildren: () =>
      import('./PROJECTMANAGMENT/pages/projectmanagment.module').then(m => m.ProjectManagmentModule)
  }
  ,
  // {
  //   path: 'CALC', component: LayoutCalcComponent, loadChildren: () =>
  //     import('./CALC/pages/calc.module').then(m => m.CalcModule)
  // }
  // ,
  // {
  //   path: 'C.A.L.C./dashboard', redirectTo: 'https://easypro.micad.it/easyproFE/', pathMatch: 'full'
  // }

  {
    path: 'CALC', component: LayoutCalcComponent, loadChildren: () =>
      import('./CALC/pages/calc.module').then(m => m.CalcModule)
  }
  ,
  {
    path: 'DELPHY', component: LayoutDelphyComponent, loadChildren: () =>
      import('./DELPHY/pages/delphy.module').then(m => m.DelphyModule)
  }
  ,
  {
    path: 'account', loadChildren: () =>
      import('./account/account.module').then(m => m.AccountModule)
  },
  { path: 'dashboardLinks', component: DashboardLinksComponent }
  // tslint:disable-next-line: max-line-length
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', enableTracing: false, useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
// canActivate: [AuthGuard]
