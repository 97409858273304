import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AppendageSet } from '../../../shared/appendageset.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { AppendageSetEditComponent } from '../appendage-set-edit/appendage-set-edit.component';
import { AppendageService } from '../appendage.service';

@Component({
  selector: 'app-appendage-set-list',
  templateUrl: './appendage-set-list.component.html',
  styleUrls: ['./appendage-set-list.component.scss']
})
export class AppendageSetListComponent implements OnInit {

  constructor(
    public cookieService: CookieService,
    public appendageService: AppendageService,
    public modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
    
    this.appendageService.getAppendagesSet().subscribe(res => {
      this.appendageService.appendagesSet = res;
      this.appendageService.setAppendagesSet(res);
    });



    this.appendageService.appendagesSetChanged.subscribe(res => {
      this.appendageService.appendagesSet = res;
    });
  }

  openAdd() {
    console.log("openAdd")
    this.appendageService.selectedSetEditMode = false;
    const modalRef = this.modalService.open(AppendageSetEditComponent);
  
  }

  onAppendageSetSelected(appendageSet: AppendageSet) {
    console.log("appendageSet");
    console.log(appendageSet);
    this.appendageService.selectedAppendageSet = appendageSet;
    this.appendageService.selectedAppendageSetEvent.emit(appendageSet);
    
    if (appendageSet != undefined){
      this.appendageService.selectedSetId = undefined;
      this.appendageService.selectedSetId = appendageSet.id;
    }else{
      this.appendageService.selectedSetId = undefined;
  }
      
  }

}
