<div class="container-fluid">


    <div class="textUnderIcon" *ngIf="cookieService.get('typeMenu') == 'variant'">

        <div class="card overflow-hidden noScale" style="height: 450px;">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 class="card-variant-title">
                            Variant dashboard – Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <div><i class="bx bx-wrench font-size-20"></i>
                                                <h6 class="list-name-help">Version</h6>
                                                <h6 class="font-size-11">List of items belonging to the specific version</h6>
                                            </div>
                                        </div>
                                        <div class="col-4" style="cursor: pointer">
                                            <i class="bx bx-atom font-size-20"></i>
                                            <h6 class="list-name-help">Propulsion</h6>
                                            <h6 class="font-size-11">List of items belonging to the specific propulsion</h6>
                                        </div>

                                        <div class="col-4" style="cursor: pointer">
                                            <i class="bx bx-brush font-size-20"></i>
                                            <h6 class="list-name-help">Optional</h6>
                                            <h6 class="font-size-11">List of items belonging to the specific optional item list, it is possible to customise combinations of options by clicking on the "Manage items" button</h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <div><i class="bx bx-water font-size-20"></i>
                                                <h6 class="list-name-help">Tank</h6>
                                                <h6 class="font-size-11">List of items belonging to the specific tank list</h6>
                                            </div>
                                        </div>
                                        <div class="col-4" style="cursor: pointer">
                                            <i class="bx bx-street-view font-size-20"></i>
                                            <h6 class="list-name-help">Crew</h6>
                                            <h6 class="font-size-11">List of items belonging to the specific crew list</h6>
                                        </div>

                                        <div class="col-4" style="cursor: pointer">
                                            <i class="bx bx-street-view font-size-20"></i>
                                            <h6 class="list-name-help">Pax</h6>
                                            <h6 class="font-size-11">List of items belonging to the specific pax list</h6>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-copy font-size-20"></i>
                                            <h6 class="font-size-13">Clone feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bx-customize font-size-20"></i>
                                            <h6 class="font-size-13">Options manage </h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm 12">
                                            <h5>Summary</h5>
                                            <hr>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div style="text-align: left;">
                                                <h6><b>Variant</b> is a combination of Version, Propulsion and Optional items.
                                                </h6>
                                                <h6>The <b>baseline</b> will always be part of the configuration.</h6>

                                                <h6>It is always possible to edit the items for all tables. Only for <b>Tank</b>, <b>Crew</b> and <b>Pax</b>, is possible to add a new items in this view.</h6>

                                                <h6>In order to add items to <b>Baseline</b>, <b>Version</b> and <b>Propulsion</b> is necessary to go to specific list.</h6>

                                                <h6><b>Optional items</b> allows to insert options picking items from the <b>Options Summary</b> (“Manage cluster” button), putting them in the specific list.</h6>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>




    <div class="card card-filter">
        <div style="text-align: center;">
            <h5 style="font-weight: bold;">{{variant.variantName}}</h5>
        </div>
        <div>
            <p-tabMenu class="filter-bar" [model]="menuItems" [activeItem]="activeItem" (click)="onShowData($event)"></p-tabMenu>
        </div>
    </div>
</div>

<br>
<app-items *ngIf="index == 0">0</app-items>
<app-items *ngIf="index == 1">1</app-items>
<app-items *ngIf="index == 2">2</app-items>
<app-items *ngIf="index == 3">3</app-items>

<app-tanks *ngIf="index == 4">4</app-tanks>

<app-crews *ngIf="index == 5">5</app-crews>
<app-crews *ngIf="index == 6">6</app-crews>