<div class="container-fluid">
    <div class="textUnderIcon">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; "> Manage Users - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Left Panel,</h6>
                                                <h6 class="font-size-11">shows all users assigned to the company and their respective roles.</h6>
                                            </div>
                                        </div>
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Right Panel</h6>
                                                <h6 class="font-size-11">shows all projects and the users associated with each one.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">

                                        <h6>Within EasyPro, users can be classified according to the role assigned to them, the available roles are as follows:
                                        </h6>
                                        <h6><b>Company Owner</b></h6>
                                        <h6> He can access all the projects of the Company, can invite new users (through the dedicated form) and can assign users to different projects.</h6>
                                        <h6><b>Project Owner</b></h6>
                                        <h6>Has access to all projects to which he is assigned, can invite new users, and can assign users to different projects.</h6>

                                        <h6><b>Collaborator</b></h6>

                                        <h6>Can access the projects to which it is assigned even when editing.</h6>

                                        <h6><b>Viewer</b></h6>
                                        <h6>Can access the projects to which it is assigned for viewing only.</h6>
                                        <h6>In order to associate users to projects, you need to access the dedicated form by clicking on "Manage Users".</h6>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>







    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">

                        <div class="col-sm-10" style="text-align: left;">
                            <h4 class="card-title mb-4">Users</h4>
                        </div>
                        <div class="col-sm-2" style="text-align: center;">
                            <i class="bx bxs-plus-square font-size-22" style="cursor: pointer;" (click)="onNewUser()" title="New user CALC"></i>
                            <div>Add user</div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-nowrap table-centered mb-0">
                            <tbody>
                                <!-- <th scope="col">Img</th> -->
                                <th scope="col">Name</th>
                                <th scope="col">Surname</th>
                                <th scope="col">Email</th>
                                <th scope="col">Company</th>
                                <th scope="col">Role</th>
                                <th scope="col"></th>

                                <tr *ngFor="let userEl of this.userService.users">
                                    <!-- <td style="min-width: 40px;">
                                        <img src="{{userEl.avatarImg}}" title="{{userEl.email}}" class="rounded-circle avatar-xs m-1" alt="">
                                    </td> -->
                                    <td>
                                        {{userEl.name}}
                                    </td>


                                    <td>
                                        {{userEl.surname}}
                                    </td>

                                    <td>
                                        {{userEl.email}}
                                    </td>
                                    <td>
                                        <div *ngFor="let company of userEl.company">
                                            {{company.name}}
                                        </div>
                                    </td>
                                    <td>
                                        {{userEl.roleName}}
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="col-sm-6" style="text-align: -webkit-center;">
                                                <i class="bx bxs-edit-alt font-size-22" style="cursor: pointer;" (click)="onEditUser(userEl)" title="Edit"></i>
                                            </div>
                                            <div class="col-sm-6" style="text-align: -webkit-center;">
                                                <i class="bx bxs-trash-alt font-size-22" style="cursor: pointer;" (click)="onDeleteUser(userEl)" title="Delete"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>



        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Projects</h4>
                    <p-table [value]="this.projectService.projects" sortField="project.projectName" sortMode="single" (onSort)="onSort()" dataKey="project.projectName">

                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th>Img</th> -->
                                <th>Name</th>
                                <th>Surname</th>
                                <th style="width: 150px;">Email</th>
                                <th>Company</th>
                                <th>Role</th>
                            </tr>
                        </ng-template>


                        <ng-template pTemplate="body" let-project let-rowIndex="rowIndex" let-expanded="expanded">
                            <tr *ngIf="userService.rowGroupMetadata[project.projectName].index === rowIndex">
                                <td colspan="5" style="background-color: rgb(184, 184, 184);">

                                    <div class="row">
                                        <div class="col-sm-2">
                                            <button type="button" style="width: 2.357rem!important;" pButton pRipple [pRowToggler]="project" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                        </div>
                                        <div class="col-sm-8" style="text-align: center; padding-top: 10px;">
                                            <span class="p-text-bold p-ml-2">{{project.projectName}} - {{project.company.name}}</span>
                                        </div>
                                        <div class="col-sm-2" style="vertical-align: center; text-align: center; padding-top: 10px;">
                                            <i class="bx bx-select-multiple font-size-22" style="cursor: pointer;" (click)="onManageUserProject(project)" title="Manage user for {{project.projectName}}"></i>
                                            <div>Manage users</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-project>
                            <tr *ngFor="let user of project.users">
                                <!-- <td>
                                    <img src="{{user.avatarImg}}" title="{{user.email}}" class="rounded-circle avatar-xs m-1" alt="">
                                </td> -->

                                <td>
                                    {{user.name}}
                                </td>
                                <td>
                                    {{user.surname}}
                                </td>
                                <td>
                                    {{user.email}}
                                </td>
                                <td>
                                    <div *ngFor="let company of user.company ">
                                        {{company.name}}
                                    </div>
                                </td>
                                <td>
                                    {{user.roleName}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>