import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Propeller } from '../../../shared/propeller.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { PropellerService } from '../propeller.service';

@Component({
  selector: 'app-propeller-ludwig',
  templateUrl: './propeller-ludwig.component.html',
  styleUrls: ['./propeller-ludwig.component.scss']
})
export class PropellerLudwigComponent implements OnInit {

  constructor(public cookieService: CookieService,
    public projectDELPHYservice: ProjectDELPHYService,
    public propellerService: PropellerService) { }


  ngOnInit(): void {


    if(this.propellerService.selectedPropeller == undefined){
      this.propellerService.selectedPropellerEvent.subscribe(
        (selectedPropeller: Propeller) => {
          
          this.propellerService.getLudwigs(selectedPropeller).subscribe(res => {
            this.propellerService.ludwigs = res;
            console.log("res");
            console.log(res);
            this.propellerService.ludwigsChanged.next(this.propellerService.ludwigs);
         
          });
          
        }
      );
    }
 
  }


  
}
