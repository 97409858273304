import { Injectable, EventEmitter } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { Variant } from '../../shared/variant.model';
import { List } from '../../shared/list.model';
import { Vehicle } from '../vehicles/vehicle.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TankService } from '../vehicles/tanks/tank.service';
import { CrewService } from '../vehicles/crews/crew.service';

@Injectable({
  providedIn: 'root'
})
export class VariantService {
  

  variants: Variant[];
  variantsChanged = new Subject<Variant[]>();
  error = new Subject<string>();
  selectedVariant: Variant;
  selectedVariantEvent = new EventEmitter<Variant>();
  
  selectedIndex: number;
  selectedIndexEvent = new EventEmitter<number>();

  selectedEditMode: boolean;
  

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private tankService: TankService,
    private crewService: CrewService) { 
      
  }

  // aggiungere parametro queryString idVehicle da cookie
  // Get data from servlet and return the variants array
  public getVariants(): Observable<Variant[]> {
    return this.getVariantsForVehicleId(this.cookieService.get(Vehicle.idVehicleSelected));
  }

  public getVariantsForVehicleId(idVehicle: string) {

    return this.http.get<Variant[]>(this.accountService.REST_API_SERVER_CALC
      + 'variantServlet?action=LIST&loggedInEmail='
      + this.accountService.currentUser.email + '&idVehicle='
      + idVehicle
    )
      .pipe(map(result => result['Records'])
      );
  }

  getAllVariants() {
    return this.http.get<Variant[]>(this.accountService.REST_API_SERVER_CALC
      + 'variantServlet?action=ALLLIST&loggedInEmail='
      + this.accountService.currentUser.email 
    )
      .pipe(map(result => result['Records'])
      );
  }

  setVariants(variants: Variant[]) {
    this.variants = variants;
    this.variantsChanged.next(this.variants.slice());
  }

  getVariant(id: number) {
    const variant = this.variants.find(
      (r) => {
        return r.idVariant == id;
      }
    );
    return variant;
  }

  createVariant(variant: Variant) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ variant: Variant }>(
        this.accountService.REST_API_SERVER_CALC
        + 'variantServlet?action=CREATE&loggedInEmail='
        + this.accountService.currentUser.email + '&idVehicle='
        + this.cookieService.get(Vehicle.idVehicleSelected),
        variant
      )
      .subscribe(responseData => {
        if(responseData['Result'] == 'OK'){
          variant = responseData['Record'];
          this.variants.push(variant);
          this.variantsChanged.next(this.variants.slice());
          this.toastr.success('Variant created');
        }
      },
        error => {
          this.toastr.error(error.message);
          this.error.next(error.message);
        });

  }


  updateVariant(variant: Variant) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ variant: Variant }>(
        this.accountService.REST_API_SERVER_CALC
        + 'variantServlet?action=UPDATE&loggedInEmail='
        + this.accountService.currentUser.email + '&idVehicle='
        + this.cookieService.get(Vehicle.idVehicleSelected)+'&idVariant='+variant.idVariant,
        variant
      )
      .subscribe(responseData => {
        if(responseData['Result'] == 'OK'){
          
          let variantUpdated = responseData['Record'];
          let itemIndex = this.variants.findIndex(item => item.idVariant == variantUpdated.idVariant);
          this.variants[itemIndex] = variantUpdated;
          this.toastr.success('Variant updated');
        }
        
      },
        error => {
          this.toastr.error(error.message);
          this.error.next(error.message);
        });

  }


  cloneVariant(id: number, name: string) {
    this.http
      .post<{ variant: Variant }>(
        this.accountService.REST_API_SERVER_CALC
        + 'variantServlet?action=CLONE&loggedInEmail='
        + this.accountService.currentUser.email +'&idVariant='+id,{id, name}
      )
      .subscribe(responseData => {
        if (responseData["Result"] == 'OK') {
          let variantEl = responseData["Record"];
          console.log("variantEl dopo clone")
          console.log(variantEl)
          this.variants.push(variantEl);
          this.toastr.success('Element cloned');
        }
        
      },
        error => {
          this.toastr.error(error.message);
          this.error.next(error.message);
        });

  }

  trashVariant(variant: Variant) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ variant: Variant }>(
        this.accountService.REST_API_SERVER_CALC + 'variantServlet?action=TRASH&loggedInEmail=' + this.accountService.currentUser.email,
        variant
      )
      .subscribe(responseData => {
        if (responseData["Result"] == "OK") {
          const index = this.variants.findIndex(x => x.idVariant === variant.idVariant);
          this.variants.splice(index, 1);
          this.variantsChanged.next(this.variants.slice());
          this.toastr.success('This variant has been deleted', 'Variant deleted');
        } else {
          this.error.next(responseData["Result"].message);
        }
      },
        error => {
          this.error.next(error.message);
        });

  }


  public getVersionListsOptions(): Observable<List[]> {
    return this.http.get<List[]>(this.accountService.REST_API_SERVER_CALC
      + 'variantServlet?action=LIST&loggedInEmail=' + this.accountService.currentUser.email + '&idVehicle='
      + this.cookieService.get(Vehicle.idVehicleSelected)
    )
      .pipe(map(result => result['versionListOptions'])
      );
  }

  public getPropulsionListsOptions(): Observable<List[]> {
    return this.http.get<List[]>(this.accountService.REST_API_SERVER_CALC
      + 'variantServlet?action=LIST&loggedInEmail=' + this.accountService.currentUser.email + '&idVehicle='
      + this.cookieService.get(Vehicle.idVehicleSelected)
    )
      .pipe(map(result => result['propulsionListOptions'])
      );
  }
  
  public getOptListsOptions(): Observable<List[]> {
    return this.http.get<List[]>(this.accountService.REST_API_SERVER_CALC
      + 'variantServlet?action=LIST&loggedInEmail=' + this.accountService.currentUser.email + '&idVehicle='
      + this.cookieService.get(Vehicle.idVehicleSelected)
    )
      .pipe(map(result => result['optListOptions'])
      );
  }

  deleteFromCookie(){
    this.cookieService.delete("indexActive");
    this.cookieService.delete(Variant.selectedVariant);
    this.cookieService.delete(Variant.idVariantSelected);

  }

  onCloseForm() {
    this.modalService.dismissAll();

  }

  unselectItems(){
    this.tankService.selectedEditMode = undefined;
    this.crewService.selectedEditMode = undefined;
  }

}
