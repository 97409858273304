import { Component, EventEmitter, OnDestroy, OnInit, Output, ɵisListLikeIterable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ProjectService } from '../project.service';
import { ProjectCALC } from '../../../shared/projectCALC.model';
import { CookieService } from 'ngx-cookie-service';
import { ProjectDeleteComponent } from '../project-delete/project-delete.component';
import { VehicleService } from '../../vehicles/vehicle.service';
import { Item } from 'src/app/CALC/shared/item.model';
import { ListService } from '../lists/list.service';
import { VariantService } from '../../variants/variant.service';
import { ReportService } from '../../reports/report.service';
import { ProjectEditComponent } from '../project-edit/project-edit.component';
import { ProjectCloneComponent } from '../project-clone/project-clone.component';
import { AccountService } from 'src/app/account/account.service';
import { List } from 'src/app/CALC/shared/list.model';
import { Vehicle } from '../../vehicles/vehicle.model';
import { ListEditComponent } from '../lists/list-edit/list-edit.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  subscription: Subscription;
  projects: ProjectCALC[];
  // incomingFilter: ProjectFilter = new ProjectFilter("incoming", "INCOMING", "NONE");

  @Output() projectWasSelected = new EventEmitter<ProjectCALC>();

  // filters: ProjectFilter;
  selectedProjectId: string;
  img = "https://s3-eu-west-1.amazonaws.com/tw-eu-files/478668/userAvatar/tf_BB9BC0AE-0228-3073-34E8975AD5073E1D.AM.jpg";

  constructor(
    public vehicleService: VehicleService,
    public projectService: ProjectService,
    private router: Router,
    private modalService: NgbModal,
    public cookieService: CookieService,
    public accountService: AccountService,
    private listService: ListService,
    private variantService: VariantService,
    private reportService: ReportService
  ) {
    this.cookieService.set('typeMenu', 'project');

  }


  ngOnInit() {
    window.location.href = 'https://easypro.micad.it/easyproFE/';
    this.cookieService.set("topbarTitle","Projects");
    this.projectService.deleteFromCookie();
    this.vehicleService.deleteItemInfoListCookie();
    this.vehicleService.deleteFromCookie();
    this.listService.deleteFromCookie();
    this.variantService.deleteFromCookie();
    this.reportService.deleteFromCookie();

    this.projectService.selectedProject = undefined;
    this.vehicleService.selectedVehicle = undefined;
    this.projectService.switch = true;
    this.projectService.getProjects().subscribe(res => {

      this.projects = res;
      this.projectService.setProjects(this.projects);
    });

}

ngAfterViewInit() {


}


openAdd() {
  this.projectService.selectedEditMode = false;
  const modalRef = this.modalService.open(ProjectEditComponent);
}


onProjectSelected(project: ProjectCALC) {

  this.projectService.selectedProjectEvent.next(project);
  this.projectService.selectedProject = project;

}



onVehicles(project: ProjectCALC) {
  // routing to vehicles
  // console.log("JSON.stringify(project")
  // console.log(JSON.stringify(project))
  // aggiungere al cookie il projectEl o un emmiter per i vehicles

  localStorage.setItem(ProjectCALC.selectedProject, JSON.stringify(project));
  this.cookieService.set(ProjectCALC.selectedProject, JSON.stringify(project));
  this.cookieService.set(ProjectCALC.idProjectSelected, JSON.stringify(project.idProject));

  this.cookieService.set(Item.servletName, 'BaseLineItem');
  this.cookieService.set(Item.tableTitleItem, 'Baseline items');

  // aggiungere un evento per cambiare la sidebar
  this.cookieService.set('typeMenu', 'vehicle');
  this.projectService.selectedProjectEvent.next(project);
  // this.projectService.selectedProject = project;
  this.router.navigate(['../CALC/vehicles']);
}


onSwitch() {
  this.projectService.selectedProject = undefined;
  this.projectService.switch = !this.projectService.switch;

}


onList(requestType: string) {

  var titleList;

  if (requestType == 'jobList') {
    titleList = 'Job List'
  }

  if (requestType == 'topologicalList') {
    titleList = 'Topological List'
  }

  if (requestType == 'designList') {
    titleList = 'Design List'
  }

  if (requestType == 'unitsOfMeasureList') {
    titleList = 'Units Of Measure List'
  }

  // quest info devono essere conservate in coockie per rifare la richiesta in caso di aggionamento pagina
  console.log("onList");

  this.cookieService.set(List.typeList, requestType);
  this.cookieService.set(List.titleList, titleList);

  this.listService.selectedEditMode = false;
  const modalRef = this.modalService.open(ListEditComponent);
}



}
