import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { ProjectCALC } from '../../../shared/projectCALC.model';
import { Vehicle } from '../vehicle.model';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: ['./vehicle-edit.component.scss']
})
export class VehicleEditComponent implements OnInit {

  vehicleForm: FormGroup;
  subscription: Subscription;
  
  @Input() public vehicle: Vehicle;

  constructor(public vehicleService: VehicleService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    public cookieService: CookieService) {

  }

  ngOnInit(): void {

    this.initForm();
  }

  ngOnChanges() {
    console.log(this.vehicleForm);
  }
  onSubmit() {
    this.modalService.dismissAll();
    if(this.vehicleService.selectedVehEditMode){
      this.vehicleService.updateVehicle(this.vehicleForm.value);
    }else{
      this.vehicleService.createVehicle(this.vehicleForm.value);
    }
    
  }

  onCancel() {
    this.modalService.dismissAll();

  }


  private initForm() {

    let vehicleidProject = +this.cookieService.get(ProjectCALC.idProjectSelected);
    let vehicleidVehicle: number;
    let vehiclestartDate = '';
    let vehicleendDate = '';

    let vehiclenote = '';
    let vehicleidReg: number;
    let vehicleStatus = '';
    let vehicleRegNUmberName = '';
   
    let vehicleisTrash = false;
    let vehicleisDelete = false;

    if(this.vehicleService.selectedVehEditMode && this.vehicle != undefined){
    vehicleidProject = this.vehicle.idProject
    vehicleidVehicle = this.vehicle.idVehicle;
    vehiclestartDate = this.vehicle.startDate;
    vehicleendDate = this.vehicle.endDate;
    vehicleidReg  = this.vehicle.idRegNumber;
    vehiclenote = this.vehicle.note;
    vehicleStatus = this.vehicle.vehicleStatus;
    vehicleRegNUmberName = this.vehicle.regNumberName;
  
    vehicleisTrash = this.vehicle.isTrash;
    vehicleisDelete = this.vehicle.isDelete;
    }

    this.vehicleForm = new FormGroup({
      'idProject': new FormControl(vehicleidProject),
      'idVehicle': new FormControl(vehicleidVehicle),
      'idRegNumber': new FormControl(vehicleidReg),
      'regNumberName': new FormControl(vehicleRegNUmberName),
      'startDate': new FormControl(vehiclestartDate, Validators.required),
      'endDate': new FormControl(vehicleendDate, Validators.required),
      'note': new FormControl(vehiclenote),
      'vehicleStatus': new FormControl(vehicleStatus),

      'isTrash': new FormControl(vehicleisTrash),
      'isDelete': new FormControl(vehicleisDelete)
    });
  }

}
