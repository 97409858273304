<div class="content">
    <div class="row">
        <div class="col-sm-12">
            <form class="form" [formGroup]="hullForm">
                <input type="hidden" id="id" formControlName="id" class="form-control">
                <input type="hidden" id="date" formControlName="date" class="form-control">
                <input type="hidden" id="project" formControlName="project" class="form-control">

                <div class="row">
                    <div class="col-sm-10">
                        <h5 *ngIf="hullService.selectedEditMode == false">New Hull</h5>
                        <h5 *ngIf="hullService.selectedEditMode == true">Edit Hull</h5>
                    </div>
                    <div class="col-sm-2">
                        <button type="button" class="close" aria-label="Close" title="Close" (click)="hullService.onCloseForm()">
                                 <span aria-hidden="true"> &times;</span>
                              </button>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" class="form-control" formControlName="name" #s_name (change)="nameCheckUnique()" />
                        </div>
                        <div *ngIf="hullForm.controls['name'].invalid && (hullForm.controls['name'].dirty || hullForm.controls['name'].touched)" class="alert alert-danger">
                            <div *ngIf="hullForm.controls['name'].errors.required">
                                Name is required.
                            </div>
                            <div *ngIf="hullForm.controls['name'].errors.name">
                                Enter Valid Name
                            </div>
                        </div>
                        <div *ngIf="nameAlredyExist != ''" class="alert alert-danger">
                            {{nameAlredyExist}}
                        </div>
                    </div>
                </div>





                <div class="row">
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label>b*</label>
                            <input type="number" step=".01" id="b" formControlName="b" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label>boa*</label>
                            <input type="number" step="1" min="0" max="100" oninput="validity.valid||(value='');" id="boa" formControlName="boa" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label>loa*</label>
                            <input type="number" step=".01" id="loa" formControlName="loa" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>beta*</label>
                            <input type="number" step=".01" id="beta" formControlName="beta" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>epsilon*</label>
                            <input type="number" id="epsilon" step=".001" formControlName="epsilon" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>Px*</label>
                            <input type="number" id="Px" step=".001" formControlName="Px" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>Pz*</label>
                            <input type="number" step=".001" id="Pz" formControlName="Pz" class="form-control">
                        </div>
                    </div>
                </div>



                <div class="row" style="padding-top: 10px;">
                    <div class="col-sm-11">
                    </div>

                    <div class="col-sm-1" *ngIf="(hullService.selectedEditMode == false)">
                        <button type="submit" class="btn btn-success" [disabled]="!hullForm.valid || nameAlredyExist != ''"><i class="bx bxs-save font-size-50"(click)="onSubmit()" title="Save data"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>

                    <div class="col-sm-1" *ngIf="(hullService.selectedEditMode == true)">
                        <button type="submit" class="btn btn-success" [disabled]="!hullForm.valid || nameAlredyExist != ''"><i class="bx bxs-edit-alt font-size-50"(click)="onSubmit()" title="Save edited data"></i></button>
                        <div class="textUnderIcon">Edit</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>