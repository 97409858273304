import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { Item } from 'src/app/CALC/shared/item.model';
import { Vehicle } from '../../vehicles/vehicle.model';
import { VehicleService } from '../../vehicles/vehicle.service';
import { ProjectCALC } from '../../../shared/projectCALC.model';
import { ListDeleteComponent } from './list-delete/list-delete.component';
import { ListEditComponent } from './list-edit/list-edit.component';
import { List } from '../../../shared/list.model';
import { ListService } from './list.service';
import { ListCloneComponent } from './list-clone/list-clone.component';
import {Location} from '@angular/common';
import { VariantService } from '../../variants/variant.service';
import { AccountService } from 'src/app/account/account.service';
@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit {

  @ViewChild('addModal') addModal: ElementRef;
 
  subscription: Subscription;
  requestType: string;
  titleList: string;
  idList: number;
  selectedList: List;
  items: Item[];

  setupMessage = 'not set up yet';
  someParameterValue = null;

  private routeSub: Subscription;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public accountService: AccountService,
    public listService: ListService,
    public cookieService: CookieService,
    private vehicleService: VehicleService,
    private location: Location,
    private variantService: VariantService) {

    // route.params.subscribe(params => {
    //   this.refreshDataForParams(params);
    // })

  }
  
  ngOnInit(): void {
     
    this.variantService.deleteFromCookie();
    this.vehicleService.deleteItemInfoListCookie();
    this.vehicleService.closeItemEditForm();
    this.routeSub = this.route.paramMap.subscribe(params => {
      this.refreshDataForParams(params);
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  refreshDataForParams(params: Params) {
    if (params == undefined || !this.isFunction(params.get)) {

    } else {
      this.requestType = params.get("listType");
      this.idList = params.get("idList");

      // console.log("requestType " + this.requestType);
      // console.log("this.idList " + this.idList);

      // console.log("Refresh start  " + this.titleList);
      
      if(this.requestType=="regNumber"){
        this.idList = +this.cookieService.get(ProjectCALC.idProjectSelected);
        this.cookieService.set("topbarTitle","Vehicle data input");
      }
      if(this.requestType=="revision"){
        this.idList = +this.cookieService.get(ProjectCALC.idProjectSelected);
        this.cookieService.set("topbarTitle","Revision data input");
      }
      if(this.requestType=="versionList"){
        this.idList = +this.cookieService.get(Vehicle.idVehicleSelected);
        this.cookieService.set("topbarTitle","Version list data input");
      }
      if(this.requestType=="propulsionList"){
        this.idList = +this.cookieService.get(Vehicle.idVehicleSelected);
        this.cookieService.set("topbarTitle","Propulsion list data input");
      }
      if(this.requestType=="optList"){
        this.idList = +this.cookieService.get(Vehicle.idVehicleSelected);
        
        this.cookieService.set("topbarTitle","Optional item list data input");
      }
      this.listService.getLists(this.requestType, this.idList).subscribe(res => {
        
        if(this.requestType == 'jobList' ){
          this.listService.setLists(res);
          this.cookieService.set("topbarTitle","Job data input");
        }
        else if(this.requestType == 'designList'){
          this.listService.setLists(res);
          this.cookieService.set("topbarTitle","Design data input");
        }
        else if(this.requestType == 'topologicalList'){
          this.listService.setLists(res);
          this.cookieService.set("topbarTitle","Topological data input");
        }
        else if(this.requestType == 'unitsOfMeasureList'){
          this.listService.setLists(res);
          this.cookieService.set("topbarTitle","Units data input");
        } else {
          this.listService.lists = res;
        }
        
      });

// impostazione default dei tittoli tabella
     
        this.titleList = this.listService.requestTypeToTitleList(this.requestType);
      
    }

    if(this.titleList == undefined)
    this.titleList = this.cookieService.get(List.titleList);
    // console.log("Refresh end  " + this.titleList);
  }
  

  onBack(){
    this.location.back();
  }
  onForward(){
    this.location.forward();
  }

  
  openAdd() {
    this.listService.selectedEditMode = false;
    // this.router.navigate(['new'], { relativeTo: this.route });
    const modalRef = this.modalService.open(ListEditComponent);
  }


  onCloneList(list: List){
    this.listService.selectedEditMode = true;
    const modalRef = this.modalService.open(ListCloneComponent);
    modalRef.componentInstance.list = list;
  }
  onEditList(list: List){
    this.listService.selectedEditMode = true;
    const modalRef = this.modalService.open(ListEditComponent);
    modalRef.componentInstance.list = list;
  }

  onDeleteList(list: List){
    const modalRef = this.modalService.open(ListDeleteComponent);
    modalRef.componentInstance.list = list;
  }

  onListSelected(list: List) {

    this.cookieService.set(List.titleList, list.name);
    this.cookieService.set(List.idList, "+idlist");

    
    var requestType;
    var requestTypeChild;
    
    var page = "projects";
    this.route.paramMap.subscribe(params => {
      requestType = params.get("listType");
      // requestType = requestType.slice(0, -4);
    });
    

    if(requestType=='job'){
     
      requestTypeChild = 'product'
      
      this.router.navigate(['../CALC/projects/list/'+requestTypeChild+'/'+list.id+''])
    
    }
    
    if(requestType=='jobList' || requestType=='topologicalList' || requestType=='designList' || requestType=='unitsOfMeasureList' ){
   

      if(requestType=='jobList') {
        requestTypeChild = 'job'
      }
      
      if(requestType=='topologicalList') {
        requestTypeChild = 'topological'
      }

      if(requestType=='designList') {
        requestTypeChild = 'design'
      }

      if(requestType=='unitsOfMeasureList') {
        requestTypeChild = 'unitsOfMeasure'
      }

      this.router.navigate(['../CALC/projects/list/'+requestTypeChild+'/'+list.id+''])
         
    }

    this.titleList = list.name; 
    
    

    if(requestType == "regNumber" ){
      page = "vehicles"
      this.titleList = "Vehicles";
    }
    if( requestType == "revision"){
      page = "vehicles"
      this.titleList = "Revisions";
    }


  



    if(requestType == "versionList"){
      page = "variants"
      this.titleList = "Version list";
      this.vehicleService.titleSelected = list.name;
      this.cookieService.set(Item.tableTitleItem, list.name);

      this.cookieService.set(Item.tableNameIdList, list.id+'');
      this.cookieService.set(Item.servletName, 'versionItem');
     
      this.router.navigate(['../CALC/'+page+'/list/versionItem'])
    }


    if(requestType == "propulsionList"){
      page = "variants"
      this.titleList = "Propulsion list";
      this.vehicleService.titleSelected = list.name;

      this.cookieService.set(Item.tableTitleItem, list.name);

      this.cookieService.set(Item.tableNameIdList, list.id+'');
      this.cookieService.set(Item.servletName, 'propulsionItem');

      this.router.navigate(['../CALC/'+page+'/list/propulsionItem'])
    }
    
    if(requestType == "optList"){
      page = "variants"
      this.titleList = "Optional items list";
      this.vehicleService.titleSelected = list.name;
      this.cookieService.set(Item.tableTitleItem, list.name);

      this.cookieService.set(Item.tableNameIdList, list.id+'');
      this.cookieService.set(Item.servletName, 'optItem');

      this.router.navigate(['../CALC/'+page+'/list/optItem'])
    }
    
  }

  
  
}
