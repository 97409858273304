import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from '../../../../environments/environment';
import { AuthHttpService } from 'src/app/core/services/authhttp.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})

/**
 * Reset-password component
 */
export class PasswordresetComponent implements OnInit, AfterViewInit {

  resetForm: FormGroup;
  
  error = '';
  success = '';
 

  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder, 
    private route: ActivatedRoute, 
    private router: Router, 
    private authenticationService: AuthenticationService,
    private authHttpService: AuthHttpService,
    private toastr: ToastrService) { }

  ngOnInit() {

    this.resetForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngAfterViewInit() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
   

    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    } else {
      this.authHttpService.resetPasswordRequest( this.resetForm.value.email)
        .pipe(first())
        .subscribe(
          data => {
            if (data['Result'] == 'OK') {
              this.toastr.success("Email sent success to "+ this.resetForm.value.email);
              this.router.navigate(['/login']);

            } else if (data['Result'] == 'ERROR') {
              this.error = data['Message']
              this.toastr.error(this.error);
            }

          },
          error => {
            console.log(error + " erroe")
            this.error = error ? error : '';
            this.router.navigate(['/login']);
          });
    }
  }
}
