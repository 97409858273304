import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { Vehicle } from '../../../shared/vehicle.model';
import { AppendageService } from '../../appendages/appendage.service';
import { EngineService } from '../../engines/engine.service';
import { HullService } from '../../hulls/hull.service';
import { LcService } from '../../lcs/lc.service';
import { PropellerService } from '../../propellers/propeller.service';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: ['./vehicle-edit.component.scss']
})
export class VehicleEditComponent implements OnInit {

  vehicleForm: FormGroup;

  @Input() public vehicle: Vehicle;

  constructor(public vehicleService: VehicleService,
    public hullService: HullService,
    public lcService: LcService,
    public engineService: EngineService,
    public propellerService: PropellerService,
    public appendageService: AppendageService,
    public cookieService: CookieService,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {

      if(this.vehicle == undefined){
        this.hullService.getHulls().subscribe(res => {
          this.hullService.hulls = res;
          this.vehicleForm.patchValue({ "hull": this.hullService.hulls[0].id });
        });
        this.engineService.getEngines().subscribe(res => {
          this.engineService.engines = res;
          this.vehicleForm.patchValue({ "engine": this.engineService.engines[0].id });
        });

        this.lcService.getLcs().subscribe(res => {
          this.lcService.lcs = res;
          this.lcService.lcsChanged.next(res);
          this.vehicleForm.patchValue({ "lc": this.lcService.lcs[0].id });
        });

        this.propellerService.getPropellers().subscribe(res => {
          this.propellerService.propellers = res;
          // this.vehicleForm.patchValue({ "propeller": this.propellerService.propellers[0].id });
        });

        this.appendageService.getAppendagesSet().subscribe(res => {
          this.appendageService.appendagesSet = res;
          // this.vehicleForm.patchValue({ "appendageSet": this.appendageService.appendagesSet[0].id });
        });
        
      }
      
    this.initForm();
  }

  ngOnChanges() {
    console.log(this.vehicleForm);
  }
  onSubmit() {
    this.modalService.dismissAll();

    if(this.vehicleService.selectedEditMode){
      this.vehicleService.updateVehicle(this.vehicleForm.value);
    }else{
      this.vehicleService.createVehicle(this.vehicleForm.value);
    }
    
  }

  


  private initForm() {

    let id: number;
    let name: string;
    let date = '';
    let revision: number;

    let hull: number;
    let lc: number;
    let engine: number;
    let n_engine = 1;
    let propeller: number;
    let appendageSet: number;
    let project = +this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY);

    if(this.vehicle != undefined && this.vehicleService.selectedEditMode == true){
    id = this.vehicle.id;
    name = this.vehicle.name;
    date = this.vehicle.date;
    revision = this.vehicle.revision;

    hull = this.vehicle.hull;
    lc = this.vehicle.lc;
    propeller = this.vehicle.propeller;
    appendageSet = this.vehicle.appendageSet;
    engine = this.vehicle.engine;
    n_engine = this.vehicle.n_engine;

    }

    this.vehicleForm = new FormGroup({
      'id': new FormControl(id),

      'name': new FormControl(name, Validators.required),
      'date': new FormControl(date),
      'hull': new FormControl(hull, Validators.required),
      'lc': new FormControl(lc, Validators.required),
      'engine': new FormControl(engine, Validators.required),
      'n_engine': new FormControl(n_engine, Validators.required),
      'propeller': new FormControl(propeller),
      'appendageSet': new FormControl(appendageSet),
      'project': new FormControl(project)
    });
  }

}
