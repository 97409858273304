import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { Crew } from 'src/app/CALC/shared/crew.model';
import { CrewDeleteComponent } from '../crew-delete/crew-delete.component';
import { CrewService } from '../crew.service';

@Component({
  selector: 'app-crew-edit',
  templateUrl: './crew-edit.component.html',
  styleUrls: ['./crew-edit.component.scss']
})
export class CrewEditComponent implements OnInit {

  crew: Crew;
  crewForm: FormGroup;

  constructor(public crewService: CrewService,
    public accountService: AccountService,
    public cookieService: CookieService,
    private modalService: NgbModal) { }

  ngOnInit(): void {

    this.crewService.selectedCrewEvent.subscribe(
      (selectedCrew: Crew) => {
        console.log("selectedCrew") ;
          this.crew = selectedCrew;
          console.log(selectedCrew);
          this.initForm();
          
      }
    );
  
    console.log("construntor edit end");
    this.crewService.editorReady();
  }

  onSubmit() {
    // this.dialogRef.close();
    if(this.crewForm.valid && this.crewForm.dirty){

      if (this.crewService.selectedEditMode ) {

        this.crewService.updateCrew(this.crewForm.value);
      } else {
        this.crewService.createCrew(this.crewForm.value)
      }
    }
    
  }

  onDelete() {
    const modaldelete = this.modalService.open(CrewDeleteComponent);
    modaldelete.componentInstance.crew = this.crew;
  }

  private initForm() {
    let idCrew: number;
    let idForeign: number;
    
    let name = '';

    
    let number: number;
    let unitaryWeight: number;

    let weight: number;
    let xg: number;
    let yg: number;
    let zg: number;
    let note = '';

    
    let isTrash = false;
    let isDelete = false;

    if (this.crewService.selectedEditMode && this.crew != undefined) {
      idCrew = this.crew.id;
      idForeign = this.crew.idForeign;
      
      name = this.crew.name;
      number = this.crew.number;
      unitaryWeight = this.crew.unitaryWeight;

      weight = this.crew.weight;
      xg = this.crew.xg;
      yg = this.crew.yg;
      zg = this.crew.zg;
      note = this.crew.note;

      isTrash = this.crew.isTrash;
      isDelete = this.crew.isDelete;
    }

    this.crewForm = new FormGroup({
      'id': new FormControl(idCrew),
      'idForeign': new FormControl(idForeign),
      'name': new FormControl(name, Validators.required),   

      'number': new FormControl(number, [Validators.required, Validators.min(0)]),
      'unitaryWeight': new FormControl(unitaryWeight, [Validators.required, Validators.min(0)]),
      'xg': new FormControl(xg, Validators.required),
      'yg': new FormControl(yg, Validators.required),
      'zg': new FormControl(zg, Validators.required),
      'note': new FormControl(note),
      'isTrash': new FormControl(isTrash),
      'isDelete': new FormControl(isDelete)

    });
  }
}
