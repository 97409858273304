import { Company } from "./company.model";

export class List {

	static typeList: string = "typeList";
	static idList: string = "idList";
	static titleList: string = "titleList";

	static jobList: string = "jobList";
	static designList: string = "designList";
	static topologicalList: string = "topologicalList";
	static unitsOfMeasureList: string = "unitsOfMeasureList";
	
	

	static regNumber: string = "regNumber";
	static revision: string = "revision";

	static job: string = "job";
	static product: string = "product";

	static design: string = "design";
	static topological: string = "topological";
	static unitsOfMeasure: string = "unitsOfMeasure";

	static versionList: string = "versionList";
	static propulsionList: string = "propulsionList";
	static optList: string = "optList";


	public id: number;
	public name: string;
	public idForeign: number;
	public note: string;
	public isTrash: boolean;
	public isDelete: boolean;
	public company: Company;



	constructor($id: number, $name: string, $idForeign: number, $company: Company, $note: string, $isTrash: boolean, $isDelete: boolean) {
		this.id = $id;
		this.name = $name;
		this.idForeign = $idForeign;
		this.company = $company;
		this.note = $note;
		this.isTrash = $isTrash;
		this.isDelete = $isDelete;
	}

}