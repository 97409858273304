import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';

import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { UIModule } from 'src/app/shared/ui/ui.module';

import { LayoutPrjComponent } from './layout-prj.component';
import { SidebarPrjComponent } from './sidebar-prj/sidebar-prj.component';
import { TopbarPrjComponent } from './topbar-prj/topbar-prj.component';



@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutPrjComponent, 
    SidebarPrjComponent, 
    TopbarPrjComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    UIModule,
    PerfectScrollbarModule
  ],
  providers:[LanguageService]
})
export class LayoutsProjectManagmentModule { }
