import { Variant } from '../../../shared/variant.model';
import { Vehicle } from '../../vehicles/vehicle.model';
import { ProjectCALC } from '../../../shared/projectCALC.model';

export class ChartObject {

    public id: number;
    public tags: string[];
    public pid: number;
    public name: string;
    public title: string;
    public img: string;
    public DBId: number;
    public ProjectItem: ProjectCALC;
    public VariantItem: Variant;
    public VehicleItem: Vehicle;
    constructor($id: number, $tags: string[], $pid: number, $name: string, $title: string, $img: string, $dbId: number) {
        this.id = $id;
        this.pid = $pid;
        this.tags = $tags;
        this.name = $name;
        this.title = $title;
        this.img = $img;
        this.DBId = $dbId;
    }
    static createFromProjectCALC(project: ProjectCALC, id: number, img: string): ChartObject {
        let co = new ChartObject(id, ["Project"], 0, project.projectName, "Length:" + project.length + " Beam:" + project.beam, img, project.idProject);
        co.ProjectItem = project;
        return co;
    }
    static createFromVehicle(vehicle: Vehicle, id: number, pid: number, img: string): ChartObject {
        let co = new ChartObject(id, ["Vehicle"], pid, vehicle.regNumberName, "D:", img, vehicle.idVehicle);
        co.VehicleItem = vehicle;
        return co;
    }
    static createFromVariant(variant: Variant, id: number, pid: number, img: string): ChartObject {
        let co = new ChartObject(id, ["Variant"], pid, variant.variantName, "D:", img, variant.idVariant);
        co.VariantItem = variant;
        return co;
    }

}