
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Hull } from '../../../shared/hull.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { HullEditComponent } from '../hull-edit/hull-edit.component';
import { HullService } from '../hull.service';

@Component({
  selector: 'app-hull-list',
  templateUrl: './hull-list.component.html',
  styleUrls: ['./hull-list.component.scss']
})
export class HullListComponent implements OnInit {
  
  selectedHullId: number;
  constructor(
    public cookieService: CookieService,
    public hullService: HullService,
    public modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
    this.hullService.getHulls().subscribe(res => {
      this.hullService.hulls = res;
    });
  }

  openAdd() {
    this.hullService.selectedEditMode = false;
    const modalRef = this.modalService.open(HullEditComponent);
  }

  onHullSelected(hull: Hull) {
    this.hullService.selectedHull = hull;
    this.hullService.selectedHullEvent.emit(hull);
   
    if (hull != undefined)
      this.selectedHullId = hull.id;
    else
      this.selectedHullId = undefined;
  }

}
