import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Lc } from '../../../shared/lc.model';
import { LcService } from '../lc.service';

@Component({
  selector: 'app-lc-delete',
  templateUrl: './lc-delete.component.html',
  styleUrls: ['./lc-delete.component.scss']
})
export class LcDeleteComponent implements OnInit {

  @Input() public lc: Lc;
  constructor(public lcService: LcService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.lcService.trashLc();
   
  }

}
