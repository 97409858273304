import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Table } from 'primeng/table';
import { Item } from 'src/app/CALC/shared/item.model';
import { VehicleService } from '../vehicle.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OptionalMatchComponent } from './optional-match/optional-match.component';
import { OptionalService } from './optional.service';
import { AccountService } from 'src/app/account/account.service';
import { OptionalMatchEditComponent } from './optional-match-edit/optional-match-edit.component';
import { OptionalMatchFreeComponent } from './optional-match-free/optional-match-free.component';

@Component({
  selector: 'app-optionals',
  templateUrl: './optionals.component.html',
  styleUrls: ['./optionals.component.scss']
})
export class OptionalsComponent implements OnInit {

  items: Item[];
  itemsData: Item[];
  cols: any[];

  _selectedColumns: any[];
  scrollableCols: any[];
  colsSize: number;
  titleList: string;
  //chiedere quali devono essere le colonne da export
  exportColumns: any[];
  exportColumnsNormal: any[];
  @ViewChild('dt') table: Table;

  jobsFilter: any[] = [];
  productsFilter: any[] = [];
  designsFilter: any[] = [];
  unitsOfMeasuresFilter: any[] = [];
  topologicalsFilter: any[] = [];

  revsFilter: any[] = [{ label: "APG", value: "APG" },
  { label: "001", value: "001" }];
  typesFilter: any[] = [{ label: "CAT", value: "CAT" },
  { label: "DWG", value: "DWG" }];
  requestType = "BaseLineItem";
  idList = 0;


  constructor(public vehicleService: VehicleService,
    public optionalService: OptionalService,
    public accountService: AccountService,
    public cookieService: CookieService,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
    
    this.vehicleService.onInitItems();
    
    this.vehicleService.closeItemEditForm();

    this.vehicleService.itemsChanged.subscribe(res => {
      console.log("res");
      console.log(res);
      this.items = res;
      this.optionalService.itemsOpt = this.items.filter(t => t.isOptional === true);
      this.optionalService.itemsOptMatched = this.optionalService.itemsOpt.filter(t => t.matchName.length > 1);

      this.optionalService.itemsOpt = this.optionalService.itemsOpt.filter(t => t.matchName === "");

      this.optionalService.itemsOpt.forEach(item => {

        this.jobsFilter.push({ label: item.jobName, value: item.jobName });
        this.productsFilter.push({ label: item.productName, value: item.productName });
        this.topologicalsFilter.push({ label: item.topologicalName, value: item.topologicalName });
        this.unitsOfMeasuresFilter.push({ label: item.unitsOfMeasureName, value: item.unitsOfMeasureName });
        this.designsFilter.push({ label: item.designName, value: item.designName });

      });

      this.optionalService.updateRowGroupMetaData();

      this.jobsFilter = this.jobsFilter.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.label === test.label
        )
      ).sort();

      this.productsFilter = this.productsFilter.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.label === test.label
        )
      ).sort();

      this.topologicalsFilter = this.topologicalsFilter.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.label === test.label
        )
      ).sort();

      this.unitsOfMeasuresFilter = this.unitsOfMeasuresFilter.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.label === test.label
        )
      ).sort();

      this.designsFilter = this.designsFilter.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.label === test.label
        )
      ).sort();
    });




    this.scrollableCols = [

      { field: 'name', header: 'Name', width: "260px" },
      { field: 'jobName', header: 'Job', width: "120px" },
      { field: 'topologicalName', header: 'Topological', width: "150px" },
      // { field: 'productName', header: 'Product', width: "140px" },
      // { field: 'designName', header: 'Design', width: "140px" },
      { field: 'weight', header: 'Weight', width: "120px" },
      // { field: 'note', header: 'Note', width: "140px" },
      // { field: 'matchName', header: 'Match', width: "140px" },
      // { field: 'conflictOptCode', header: 'Conflict Code', width: "140px" },
      // { field: 'inseparableOptCode', header: 'Inseparable Code', width: "140px" }

    ];

    this.exportColumnsNormal = [

      { field: 'name', header: 'Name', width: "260px" },

      { field: 'weight', header: 'Weight', width: "120px" },

      { field: 'xg', header: 'Xg', width: "90px" },
      { field: 'yg', header: 'Yg', width: "90px" },
      { field: 'zg', header: 'Zg', width: "90px" },

      { field: 'note', header: 'Note', width: "140px" }
    ];

    this.exportColumns = this.exportColumnsNormal.map(col => ({ title: col.header, dataKey: col.field }));

    // this._selectedColumns = [
    //   { field: 'revisionName', header: 'Rev', width: "60px"  },
    //   { field: 'jobName', header: 'Job', width: "140px"  },
    //   { field: 'topologicalName', header: 'Topological', width: "140px"  },
    //   { field: 'unitsOfMeasureName', header: 'Units', width: "60px"  },
    //   { field: 'quantity', header: 'Quantity', width: "100px"  },
    //   { field: 'weight', header: 'Weight', width: "100px"  },
    //   { field: 'xg', header: 'Xg', width: "50px"  },
    //   { field: 'yg', header: 'Yg', width: "50px"  },
    //   { field: 'zg', header: 'Zg', width: "50px"  },
    //   { field: 'note', header: 'Note', width: "140px"  }
    // ];

    this.cols = this.scrollableCols;
    this._selectedColumns = this.cols;
    this.colsSize = this._selectedColumns.length;
    
    this.cookieService.set("topbarTitle","Options Summary data input");
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    this.colsSize = this._selectedColumns.length;
  }

  onSort() {
    this.optionalService.updateRowGroupMetaData();
  }

 


  onFreeMatch(matchName: string){
    // fare una richiestra per con l'azione di liberare il match, tutti gli item di questo progetto che hanno lo stesso match name
    const modal = this.modalService.open(OptionalMatchFreeComponent);
    modal.componentInstance.matchName = matchName;

  }



  onOpenMatchOptModal() {
    const modalRef = this.modalService.open(OptionalMatchComponent);
  }

  onOpenMatchOptModalEdit(matchName: string) {
    console.log('matchname onOpenMatchOptModalEdit')
    console.log(matchName)
    const modal = this.modalService.open(OptionalMatchEditComponent);
    modal.componentInstance.matchName = matchName;
  }

  onSelectItem(item: Item) {
    this.vehicleService.selectedEditMode = true;
    this.vehicleService.selectedItem = item;
    this.vehicleService.selectItemForEdit(item);
  }



  onNewItem() {

    this.vehicleService.selectedEditMode = false;
    let item = new Item(1, 1, 1, undefined, "", "", false, false, 0, "", 0, "", 0, "", 0, "", 0, "", false, false, false, "", 0, 0, 0, 0, "", 0, 0, 0, 0, 0, "", false, "", "", 0, false, false, 0, 0, true, false, false, 0, 0, false, "");
    this.vehicleService.selectItemForEdit(item);
  }

  // export
  exportPdf() {
    // import("jspdf").then(jsPDF => {
    //   import("jspdf-autotable").then(x => {

    //     const doc = new jsPDF.default(0, 0);
    //     doc.autoTable(this.exportColumns, this.itemsLastRev);
    //     doc.save('itemsLastRev.pdf');
    //   })
    // })
  }

  // const doc = new jsPDF('p', 'mm', [297, 210]);
  //const options=new autoTable();
  // (doc as any)autoTable(doc,{exportColumns:this.exportColumns,});
  //doc.autoTable(this.exportColumns, this.itemsLastRev);
  // doc.save('itemsLastRev.pdf');
  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.optionalService.itemsOpt);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "itemsLastRev");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

}
