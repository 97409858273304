import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { Router, NavigationEnd } from '@angular/router';


import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/core/services/event.service';
// import { ApprovalService } from '../../pages/approvals/approval.service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ProjectCALC } from '../../shared/projectCALC.model';
import { Vehicle } from '../../pages/vehicles/vehicle.model';
import { ProjectService } from '../../pages/projects/project.service';
import { VehicleService } from '../../pages/vehicles/vehicle.service';
import { Item } from '../../shared/item.model';
import { List } from '../../shared/list.model';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/account/account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportgrasshopperComponent } from '../../pages/vehicles/importgrasshopper/importgrasshopper.component';
// import { Approval } from '../../pages/approvals/approval.model';

@Component({
  selector: 'app-sidebar-calc',
  templateUrl: './sidebar-calc.component.html',
  styleUrls: ['./sidebar-calc.component.scss']
})

/**
 * Sidebar component
 */
export class SidebarCalcComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() isCondensed = false;
  menu: any;
  typeMenu: string;
  menuItems = [];
  configData;
  subscription: Subscription;
  // approvals: Approval[];
  @ViewChild('sideMenu') sideMenu: ElementRef;

  items: Item[];
  selectedProject: ProjectCALC;
  selectedVehicle: Vehicle;
  constructor(private eventService: EventService,
    private router: Router,
    public translate: TranslateService,
    public cookieService: CookieService,
    private projectService: ProjectService,
    private vehicleService: VehicleService,
    private http: HttpClient,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal
    // private approvalService: ApprovalService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        // this.typeMenu = this.cookieService.get('typeMenu');

        // ricaricare i dati come se avessi navigato gli eventi   
        // rifare tutti gli onclick che tmi hanno fatto caricare i dati giusti es: setItems
        //lavora schiavooo lscio il commmento per quello che verra dopo di me
      }
    });

    this.projectService.selectedProjectEvent.subscribe(
      (selectedProject: ProjectCALC) => {
        if (selectedProject != undefined)
          this.selectedProject = selectedProject;
      }
    );

    this.vehicleService.selectedVehicleEvent.subscribe(
      (selectedVehicle: Vehicle) => {
        if (selectedVehicle != undefined)
          this.selectedVehicle = selectedVehicle;
      }
    );


    if (this.selectedProject == undefined && this.cookieService.get(ProjectCALC.selectedProject) != '') {
      this.selectedProject = JSON.parse(this.cookieService.get(ProjectCALC.selectedProject));
    }

    if (this.selectedVehicle == undefined && this.cookieService.get(Vehicle.selectedVehicle) != '') {
      this.selectedVehicle = JSON.parse(this.cookieService.get(Vehicle.selectedVehicle));
    }
    if (this.selectedProject == undefined) {
      this.selectedProject = JSON.parse(localStorage.getItem(ProjectCALC.selectedProject));
    }


  }

  ngOnInit() {

    // this.typeMenu = this.cookieService.get('typeMenu');
    document.body.setAttribute('data-sidebar', 'dark');

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };
  }


  importJSON() {

    const modalRef = this.modalService.open(ImportgrasshopperComponent);

  }


  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLayout', layout);
  }

  ngAfterViewInit() {

    // this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }



  onList(requestType: string) {

    var titleList;

    if (requestType == 'jobList') {
      titleList = 'Job List'
    }

    if (requestType == 'topologicalList') {
      titleList = 'Topological List'
    }

    if (requestType == 'designList') {
      titleList = 'Design List'
    }

    if (requestType == 'unitsOfMeasureList') {
      titleList = 'Units Of Measure List'
    }

    if (requestType == "regNumber") {
      titleList = "Vehicles";
    }
    if (requestType == "revision") {
      titleList = "Revisions";
    }

    if (requestType == "versionList") {
      titleList = "Version list";
      // this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
      this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
    }
    if (requestType == "propulsionList") {
      titleList = "Propulsion list";
      // this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
      this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
    }
    if (requestType == "optList") {
      titleList = "Optional items list";
      // this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
      this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
    }

    // quest info devono essere conservate in coockie per rifare la richiesta in caso di aggionamento pagina
    console.log("onList");

    this.cookieService.set(List.typeList, requestType);
    this.cookieService.set(List.titleList, titleList);


  }



  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Light sidebar
   */
  lightSidebar() {
    document.body.setAttribute('data-sidebar', 'light');
    document.body.setAttribute('data-topbar', 'dark');
    document.body.removeAttribute('data-sidebar-size');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.classList.remove('vertical-collpsed');
  }

  /**
   * Compact sidebar
   */
  compactSidebar() {
    document.body.setAttribute('data-sidebar-size', 'small');
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.classList.remove('sidebar-enable');
    document.body.classList.remove('vertical-collpsed');
  }

  /**
   * Icon sidebar
   */
  iconSidebar() {
    document.body.classList.add('sidebar-enable');
    document.body.classList.add('vertical-collpsed');
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.removeAttribute('data-topbar');
  }

  /**
   * Boxed layout
   */
  boxedLayout() {
    document.body.setAttribute('data-keep-enlarged', 'true');
    document.body.setAttribute('data-layout-size', 'boxed');
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.classList.add('vertical-collpsed');
    document.body.classList.remove('sidebar-enable');
    document.body.removeAttribute('data-topbar');
  }

  /**
   * Colored sidebar
   */
  coloredSidebar() {
    document.body.setAttribute('data-sidebar', 'colored');
    document.body.removeAttribute('data-sidebar-size');
    document.body.removeAttribute('data-layout-size');
    document.body.classList.remove('vertical-collpsed');
    document.body.removeAttribute('data-topbar');
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) { childAnchor.classList.add('mm-active'); }
            if (childDropdown) { childDropdown.classList.add('mm-active'); }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') { childanchor.classList.add('mm-active'); }
              }
            }
          }
        }
      }
    }
  }
}
