<div class="row full-row">
    <div class="col-on-five">
        <div>
            <app-lc-list></app-lc-list>
        </div>
        <hr>
        <div>
            <app-lc-details></app-lc-details>
        </div>
    </div>
    <div class="col-four-on-five">
        <app-lc-plots></app-lc-plots>
    </div>
</div>