import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from '../project.service';
import { ProjectCALC } from '../../../shared/projectCALC.model';

@Component({
  selector: 'app-project-delete',
  templateUrl: './project-delete.component.html',
  styleUrls: ['./project-delete.component.scss']
})
export class ProjectDeleteComponent implements OnInit {

  @Input() public project: ProjectCALC;
  constructor(public projectService: ProjectService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.projectService.trashProject(this.project);
   
   
  }
  
}
