import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/account/user.model';
import { ProjectService } from '../../pages/projects/project.service';
import { Company } from '../../shared/company.model';
import { List } from '../../shared/list.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  signupForm: FormGroup;

  error = '';

  roleList: List[];

  @Input() user: User;

  constructor(
    public cookieService: CookieService,
    public userService: UserService,
    public projectService: ProjectService) { }

  ngOnInit() {
    this.projectService.setCompanies();
    this.userService.getRoleList().subscribe(res => {
      this.roleList = res;
      
    });

    let company: Company;
    if (this.user == undefined) {
      this.signupForm = new FormGroup({
        'name': new FormControl('', Validators.required),
        'surname': new FormControl('', Validators.required),
        'company': new FormControl(company, Validators.required),
        'country': new FormControl('', Validators.required),
        'roleName': new FormControl('', Validators.required),
        'email': new FormControl('', [Validators.required, Validators.email]),
        'password': new FormControl('', Validators.required),
        'confirmPassword': new FormControl('', Validators.required)
      });
      this.signupForm.patchValue({ "company": this.projectService.companies[0] });
      this.signupForm.patchValue({ "roleName": this.roleList[0].name });
    } else {
      this.signupForm = new FormGroup({
        'name': new FormControl(this.user.name, Validators.required),
        'surname': new FormControl(this.user.surname, Validators.required),
        'company': new FormControl(this.user.company, Validators.required),
        'country': new FormControl(this.user.country, Validators.required),
        'roleName': new FormControl(this.user.roleName, Validators.required)
        
      });
      this.signupForm.patchValue({ "roleName": this.user.roleName });
      this.signupForm.patchValue({ "company": this.user.company });
     
    }
    
  }

  ngAfterViewInit() {
  }

  onSubmitRegister() {
    console.log(this.signupForm.invalid)
    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    } else {

      this.userService.onCloseForm();
      if (this.userService.selectedEditMode) {

        this.userService.updateUser(this.signupForm.value.name, this.signupForm.value.surname,
          this.signupForm.value.company, this.signupForm.value.country,this.user.email,this.signupForm.value.roleName);

      } else {

        this.userService.createUser(this.signupForm.value.name, this.signupForm.value.surname,
          this.signupForm.value.company, this.signupForm.value.country, this.signupForm.value.email, this.signupForm.value.roleName,
          this.signupForm.value.password, this.signupForm.value.confirmPassword);
      }
      
    }
  }
}
