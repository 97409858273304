<div class="container-fluid">

    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'regNumber'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; ">Vehicles - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Vehicle, by clicking on a row is possible to edit the detailed information.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6>In this section you can customise the list of names to be used to distinguish vehicles.</h6>
                                        <h6>You can add notes to describe the meaning of the name.</h6>
                                        <h6>Changing this list has an impact on the Vehicle names within the project.</h6>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>




    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'revision'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; ">Revisions - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Revision, by clicking on a row is possible to edit the detailed information.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6>In this section you can customise the list of names to be used to distinguish item revisions.</h6>
                                        <h6>You can add notes to describe the meaning of the name.</h6>
                                        <h6>Changing this list has an impact on the Item properties within the project.</h6>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>





    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'job' || cookieService.get('typeList') == 'jobList' || cookieService.get('typeList') == 'product'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; "> Job - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Job list, by clicking on a row is possible to edit the list adding its content.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">

                                        <h6>“<b>Job</b>” is the category that represents the destination of the object according to the product or process to which it relates. </h6>
                                        <h6>In this section you can customise the list of Jobs to be used, by clicking on a row is possible to edit the list adding its content.</h6>
                                        <h6>You can add notes to describe the meaning of the name.</h6>
                                        <h6>Changing this list has an impact on the Item properties within the project.</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>






    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'topological' || cookieService.get('typeList') == 'topologicalList'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; "> Topological- Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Topological list, by clicking on a row is possible to edit the list adding its content.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">

                                        <h6>“<b>Topological</b>” is the category that represents the spatial location of the object within the project.</h6>
                                        <h6>In this section you can customise the list of “locations” to be used, by clicking on a row is possible to edit the list adding its content.</h6>
                                        <h6>You can refer to specific compartments or places.</h6>
                                        <h6>You can add notes to describe the meaning of the name.</h6>
                                        <h6>Changing this list has an impact on the Item properties within the project.</h6>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>





    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'design' || cookieService.get('typeList') == 'designList'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; "> Design - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Design list, by clicking on a row is possible to edit the list adding its content.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6>“<b>Design</b>” is the category to be used for objects that have a specification derived from a drawing. The category can be customised on the basis of the properties contained within the drawing.</h6>
                                        <h6>By clicking on a row is possible to edit the list adding its content.</h6>
                                        <h6>You can add notes to describe the meaning of the name.</h6>
                                        <h6>Changing this list has an impact on the Item properties within the project.</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>







    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'unitsOfMeasure' || cookieService.get('typeList') == 'unitsOfMeasureList'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; "> Units - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Units list, by clicking on a row is possible to edit the list adding its content.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6>“<b>Units of Measure</b>” is the category that represents the list of units to be used in the project. An example might be:
                                        </h6>
                                        <ul>
                                            <li>
                                                <h6>quantity</h6>
                                            </li>
                                            <li>
                                                <h6>square metres</h6>
                                            </li>
                                            <li>
                                                <h6>linear metres</h6>
                                            </li>
                                            <li>
                                                <h6>etc</h6>
                                            </li>
                                        </ul>
                                        <h6>By clicking on a row is possible to edit the list adding its content.</h6>
                                        <h6>You can add notes to describe the meaning of the name.</h6>
                                        <h6>Changing this list has an impact on the Item properties within the project.</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>







    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'versionList'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; "> Versions - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Versions list, by clicking on a row is possible to edit the list adding its content.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6>“<b>Versions</b>” is the category that represents the list of version name to be used in the project. An example might be:
                                        </h6>
                                        <ul>
                                            <li>
                                                <h6>2 cabins version</h6>
                                            </li>
                                            <li>
                                                <h6>3 cabins version </h6>
                                            </li>
                                            <li>
                                                <h6>etc</h6>
                                            </li>
                                        </ul>
                                        <h6>By clicking on a row is possible to edit the list adding its content. You can add notes to describe the meaning of the name.</h6>
                                        <h6>Changing this list has an impact on the Item properties within the project.</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>







    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'propulsionList'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; "> Propulsions - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Propulsions list, by clicking on a row is possible to edit the list adding its content.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6>“<b>Propulsions</b>” is the category that represents the list of propulsion name to be used in the project. An example might be:
                                        </h6>
                                        <ul>
                                            <li>
                                                <h6>Volvo D6</h6>
                                            </li>
                                            <li>
                                                <h6>MAN i6-730</h6>
                                            </li>
                                            <li>
                                                <h6>etc</h6>
                                            </li>
                                        </ul>
                                        <h6>By clicking on a row is possible to edit the list adding its content. You can add notes to describe the meaning of the name.</h6>
                                        <h6>Changing this list has an impact on the Item properties within the project</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>







    <div class="textUnderIcon" *ngIf="cookieService.get('typeList') == 'optList'">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; "> Optional Items - Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to the name of specific Optional Items list, by clicking on a row is possible to edit the list adding its content.</h6>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-trash-alt font-size-20"></i>
                                            <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>
                                    <div style="text-align: left;">
                                        <h6>“<b>Optional Items</b>” is the category that represents the list of optional items name to be used in the project. An example might be:
                                        </h6>
                                        <ul>
                                            <li>
                                                <h6>Premium Pack</h6>
                                            </li>
                                            <li>
                                                <h6>Silver Pack</h6>
                                            </li>
                                            <li>
                                                <h6>etc</h6>
                                            </li>
                                        </ul>
                                        <h6>By clicking on a row is possible to edit the list adding its content. You can add notes to describe the meaning of the name.</h6>
                                        <h6>Changing this list has an impact on the Item properties within the project.</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>



























    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row list-title">
                        <div class="col-sm-1">
                            <div class="row">
                                <div class="col-sm-6" style="text-align: center;">
                                    <i class="bx bx-left-arrow-alt font-size-22" style="cursor: pointer;" (click)="onBack()" title='Go back'></i>
                                </div>
                                <div class="col-sm-6" style="text-align: left;">
                                    <i class="bx bx-right-arrow-alt font-size-22" style="cursor: pointer;" (click)="onForward()" title='Go forward'></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-11" style="text-align: left;">
                            <h4 class="card-title mb-4">{{titleList}}</h4>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-nowrap table-centered mb-0">
                            <tbody>
                                <th scope="col" style="min-width: 20vw;">Name</th>
                                <th scope="col" *ngIf="cookieService.get('typeList') == 'jobList' 
                            ||cookieService.get('typeList') == 'topologicalList' 
                            || cookieService.get('typeList') == 'designList' 
                            || cookieService.get('typeList') == 'unitsOfMeasureList'">Company</th>
                                <th scope="col">Note</th>
                                <th scope="col"></th>

                                <tr *ngFor="let listEl of this.listService.lists">
                                    <td class="list-name" (click)="onListSelected(listEl)" title="Show {{listEl.name}} content" *ngIf="cookieService.get('typeList') != 'product' 
                                && cookieService.get('typeList') != 'topological' 
                                && cookieService.get('typeList') != 'design' 
                                && cookieService.get('typeList') != 'unitsOfMeasure' && cookieService.get('typeList') != 'revision'&& cookieService.get('typeList') != 'regNumber'  ">
                                        {{listEl?.name}} </td>
                                    <td class="list-name" *ngIf="cookieService.get('typeList') == 'product' 
                                || cookieService.get('typeList') == 'topological' 
                                || cookieService.get('typeList') == 'design' 
                                || cookieService.get('typeList') == 'unitsOfMeasure' 
                                ||cookieService.get('typeList') == 'revision' 
                                || cookieService.get('typeList') == 'regNumber'  ">
                                        {{listEl?.name}}</td>
                                    <td style="min-width: 240px;" *ngIf="cookieService.get('typeList') == 'jobList' 
                                    ||cookieService.get('typeList') == 'topologicalList' 
                                    || cookieService.get('typeList') == 'designList' 
                                    || cookieService.get('typeList') == 'unitsOfMeasureList'">
                                        {{listEl.company?.name}}
                                    </td>
                                    <td style="min-width: 240px;">
                                        {{listEl?.note}}
                                    </td>






                                    <td>
                                        <div class="row" *ngIf="(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">


                                            <div class="col-sm-4" style="text-align: -webkit-center;">
                                                <a (click)="onEditList(listEl)" style="cursor: pointer;">
                                                    <i class="bx bxs-edit-alt font-size-22" title="Edit"></i>
                                                    <div class="textUnderIcon">Edit</div>
                                                </a>
                                            </div>

                                            <div class="col-sm-4" style="text-align: -webkit-center;" *ngIf="cookieService.get('typeList') == 'jobList' 
                                                                                                        || cookieService.get('typeList') == 'designList' 
                                                                                                        || cookieService.get('typeList') == 'topologicalList' 
                                                                                                        || cookieService.get('typeList') == 'unitsOfMeasureList'
                                                                                                        || cookieService.get('typeList') == 'versionList' 
                                                                                                        || cookieService.get('typeList') == 'propulsionList' 
                                                                                                        || cookieService.get('typeList') == 'optList'">
                                                <a (click)="onCloneList(listEl)" style="cursor: pointer;">
                                                    <i class="bx bxs-copy font-size-22" title="Clone"></i>
                                                    <div class="textUnderIcon">Clone</div>
                                                </a>
                                            </div>

                                            <div class="col-sm-4" style="text-align: -webkit-center;">
                                                <a (click)="onDeleteList(listEl)" style="cursor: pointer;">
                                                    <i class="bx bxs-trash-alt font-size-22" title="Delete"></i>
                                                    <div class="textUnderIcon">Delete</div>
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="floating-action-button" *ngIf="(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">
    <button type="button" class="btn btn-primary btn-circle btn-lg" id="addBtn" (click)="openAdd()">
        <i class="fas fa-plus" title="New"></i>
        <div class="textUnderIcon">New</div>
    </button>
</div>