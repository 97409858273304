import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { Item } from '../../shared/item.model';
import { Tank } from '../../shared/tank.model';
import { ListService } from '../projects/lists/list.service';
import { ReportService } from '../reports/report.service';
import { VehicleService } from '../vehicles/vehicle.service';
import { VariantDeleteComponent } from './variant-delete/variant-delete.component';
import { Variant } from '../../shared/variant.model';
import { VariantService } from './variant.service';
import { VariantEditComponent } from './variant-edit/variant-edit.component';
import { ProjectCALC } from '../../shared/projectCALC.model';
import { AccountService } from 'src/app/account/account.service';
import { List } from '../../shared/list.model';
import { Vehicle } from '../vehicles/vehicle.model';
import { ListEditComponent } from '../projects/lists/list-edit/list-edit.component';
import { VariantCloneComponent } from './variant-clone/variant-clone.component';
import { OptionalService } from '../vehicles/optionals/optional.service';

@Component({
  selector: 'app-variants',
  templateUrl: './variants.component.html',
  styleUrls: ['./variants.component.scss']
})
export class VariantsComponent implements OnInit {

  subscription: Subscription;
  variants: Variant[];
  // incomingFilter: VariantFilter = new VariantFilter("incoming", "INCOMING", "NONE");

  @Output() variantWasSelected = new EventEmitter<Variant>();

  // filters: VariantFilter;
  selectedVariantId: number;
  selectedVariant: Variant;

  items: Item[];

  tanks: Tank[];

  constructor(
    private variantService: VariantService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public accountService: AccountService,
    public cookieService: CookieService,
    public vehicleService: VehicleService,
    private repotService: ReportService,
    private listService: ListService
  ) { this.cookieService.set("topbarTitle","Variants"); }


  ngOnInit() {
    
    this.listService.deleteFromCookie();
    
    this.vehicleService.titleSelected = '';

    this.variantService.deleteFromCookie()

    this.vehicleService.deleteItemInfoListCookie();

    this.variantService.unselectItems();

    this.vehicleService.closeItemEditForm();

    this.repotService.optsNameReport = [];
    this.repotService.optsNamePdf = [];
    // this.cookieService.set('typeMenu', 'variant');
    this.subscription = this.variantService.getVariants().subscribe(res => {
      this.variants = res;
      this.variantService.setVariants(this.variants);
      console.log(this.variants);
    });

  }

  ngAfterViewInit() {
    // this.cookieService.set('typeMenu', 'variant');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openAdd() {
    this.variantService.selectedEditMode = false;
    const modalRef = this.modalService.open(VariantEditComponent);
  }

  onEdit(variant: Variant) {
    this.variantService.selectedEditMode = true;
    const modalRef = this.modalService.open(VariantEditComponent);
    modalRef.componentInstance.variant = variant;
  }


  onClone(variant: Variant) {
    const modalRef = this.modalService.open(VariantCloneComponent);
    modalRef.componentInstance.variant = variant;
  }


  onVariantSelected(variant: Variant) {
    

    // console.log("variant");
    // console.log(variant);

   
    this.cookieService.set("indexActive", 0+"");
    this.cookieService.set(Variant.selectedVariant, JSON.stringify(variant));
    this.cookieService.set(Variant.idVariantSelected, ''+variant.idVariant);

    this.cookieService.set(Item.servletName, 'BaseLineItem');
    this.cookieService.set(Item.tableNameIdList, this.cookieService.get(ProjectCALC.idProjectSelected));

    // this.cookieService.set('typeMenu', 'variant');
    

    this.vehicleService.titleSelected = '';
    this.cookieService.set(Item.tableTitleItem, 'Baseline');

    this.selectedVariant = variant;
    this.variantService.selectedVariant = variant;
    this.variantService.selectedVariantEvent.next(variant);
    this.router.navigate(['details'], { relativeTo: this.route });
  }







  onTrash(variantDelete: Variant) {
    const modaldelete = this.modalService.open(VariantDeleteComponent);
    modaldelete.componentInstance.variant = variantDelete;
  }
  
  onReport(variant: Variant, reportType: string) {

    this.cookieService.set(Variant.selectedVariant, JSON.stringify(variant));
    this.cookieService.set('reportType', reportType);
    this.router.navigate(['../../report'], { relativeTo: this.route });

  }

  onDiba(variant: Variant) {

    this.cookieService.set(Variant.selectedVariant, JSON.stringify(variant));
    this.router.navigate(['../../diba'], { relativeTo: this.route });

  }


  onListItems(variant: Variant, listType: string, idList: number, index: number) {

    this.cookieService.set("indexActive", index+"");
    // this.router.navigate(['../CALC/variants/list/'+listType+'/' + idList])
    this.vehicleService.titleSelected = undefined;

    this.cookieService.set(Variant.selectedVariant, JSON.stringify(variant));
    this.cookieService.set(Variant.idVariantSelected, JSON.stringify(variant.idVariant));
   
    this.cookieService.set(Item.tableTitleItem, '');
    this.cookieService.set(Item.servletName, listType);
    this.cookieService.set(Item.tableNameIdList, idList + "");

    // this.cookieService.set('typeMenu', 'variant');

    this.variantService.selectedIndex = index;
    this.variantService.selectedIndexEvent.next(index);

    this.selectedVariant = variant;
    this.variantService.selectedVariant = variant;
    this.variantService.selectedVariantEvent.next(variant);

    this.router.navigate(['details'], { relativeTo: this.route });

  }


  onList(requestType: string) {

    var titleList;

   

    if(requestType  == "versionList"){
      titleList = "Version list";
      // this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
      this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
    }
    if(requestType  == "propulsionList"){
      titleList = "Propulsion list";
      // this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
      this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
    }
    if(requestType  == "optList"){
      titleList = "Optional items list";
      // this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
      this.cookieService.set(List.idList, this.cookieService.get(Vehicle.idVehicleSelected));
    }

    // quest info devono essere conservate in coockie per rifare la richiesta in caso di aggionamento pagina
    console.log("onList");

    this.cookieService.set(List.typeList, requestType);
    this.cookieService.set(List.titleList, titleList);

    this.listService.selectedEditMode = false;
    const modalRef = this.modalService.open(ListEditComponent);

  }

}
