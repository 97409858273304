import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { VehicleService } from '../../vehicle.service';
import * as Chart from 'chart.js';
@Component({
  selector: 'app-item-pie-graph',
  templateUrl: './item-pie-graph.component.html',
  styleUrls: ['./item-pie-graph.component.scss']
})
export class ItemPieGraphComponent implements OnInit {


  @ViewChild('jobCanvas') jobCanvas;
  @ViewChild('topoCanvas') topoCanvas;

  constructor(public vehicleService: VehicleService) { }

  ngOnInit(): void {
    this.vehicleService.itemsChanged.subscribe(() => {
      this.vehicleService.onInitGraph();
    this.chartMethod("Jobs", this.vehicleService.chartJob, this.jobCanvas, this.vehicleService.graphJob[0], this.vehicleService.graphJob[1]);
        this.chartMethod("Topologicals", this.vehicleService.chartTopo, this.topoCanvas, this.vehicleService.graphTopo[0], this.vehicleService.graphTopo[1]);
    });
  }

  ngAfterViewInit() {
    this.vehicleService.onInitGraph();
    this.chartMethod("Jobs", this.vehicleService.chartJob, this.jobCanvas, this.vehicleService.graphJob[0], this.vehicleService.graphJob[1]);
        this.chartMethod("Topologicals", this.vehicleService.chartTopo, this.topoCanvas, this.vehicleService.graphTopo[0], this.vehicleService.graphTopo[1]);
  }

  chartMethod(title: string, elChart: any, elCanvas: any, labelsData: string[], dataData: number[]) {

    let backgroundColorData = [];
    let backgroundColorHoverData = [];
  
    labelsData.forEach(name => {
      // if (mapCol.get(name)) {
      //   backgroundColorData.push(mapCol.get(name));
      //   backgroundColorHoverData.push(mapColHover.get(name));
      // } else {
       
        var colorHover = this.getRandomColorHover();
        console.log(colorHover);
        
        backgroundColorHoverData.push(colorHover);
        backgroundColorData.push(colorHover.replace(',0.3',''));
      // }
    });
  
    elChart = new Chart(elCanvas.nativeElement, {
      type: 'doughnut',
      options: {
        title: {
          display: true,
          text: title
        },
        legend: {
          position: 'left',
          labels: {
            fontSize: 10
          }
  
        },
        rotation: -0.1
  
  
      },
      data: {
        labels: labelsData,
        datasets: [{
          label: '# of Votes',
          data: dataData,
          backgroundColor: backgroundColorData,
          hoverBackgroundColor: backgroundColorHoverData
        }]
      }
    });
  }

  getRandomColorHover() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',0.3)';
  }

 
}
