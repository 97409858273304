import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Company } from 'src/app/CALC/shared/company.model';
import { List } from 'src/app/CALC/shared/list.model';
import { ProjectService } from '../../project.service';
import { ListService } from '../list.service';

@Component({
  selector: 'app-list-edit',
  templateUrl: './list-edit.component.html',
  styleUrls: ['./list-edit.component.scss']
})
export class ListEditComponent implements OnInit {

  @Input() public list: List;
  listForm: FormGroup;
  @ViewChild('name', { static: false }) inputEl: ElementRef;
  constructor(public listService: ListService,
    public cookieService: CookieService,
    public projectService: ProjectService,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.projectService.setCompanies();
    this.initForm();

  }
  ngAfterViewInit() {
    this.inputEl.nativeElement.focus();
  }
  ngOnChanges() {
    console.log(this.listForm);
  }
  onSubmit() {
    this.modalService.dismissAll();
    // this.listService.createList(this.listForm.value)

    if (this.listService.selectedEditMode) {
      this.listService.updateList(this.listForm.value);
    } else {
      this.listService.createList(this.listForm.value)
    }

  }

  onCancel() {
    this.modalService.dismissAll();
  }


  private initForm() {
    let id: number;
    let listName = '';
    let company: Company;
    let listNote = '';
    let listisTrash = false;
    let listisDelete = false;

    if (this.listService.selectedEditMode && this.list != undefined) {
      id = this.list.id;
      company = this.list.company;
      listName = this.list.name;
      listNote = this.list.note;
    }
    this.listForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(listName, Validators.required),
      'company': new FormControl(company),
      'note': new FormControl(listNote),
      'isTrash': new FormControl(listisTrash),
      'isDelete': new FormControl(listisDelete)
    });

   
    
    setTimeout(() => {
      if (!this.listService.selectedEditMode) {
        this.listForm.patchValue({ "company": this.projectService.companies[0] });
      } else {
        this.listForm.patchValue({ "company": this.projectService.companies.find(comp => comp.id== company.id) });
      }
  
    }, 200);
  }


}
