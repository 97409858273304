import { Component, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { AppendageService } from '../appendage.service';
import $ from "jquery";

@Component({
  selector: 'app-appendage-plots',
  templateUrl: './appendage-plots.component.html',
  styleUrls: ['./appendage-plots.component.scss']
})
export class AppendagePlotsComponent implements OnInit {

  @ViewChild('osborneCanvas') osborneCanvas;
  elChart: any;

  width:any; height:any; gradient:any;
  constructor(public appendageService: AppendageService) { }

  ngOnInit(): void {
    this.appendageService.osbornesChanged.subscribe(() => {
      this.appendageService.onInitGraph();
      this.chartMethod("Osborne", this.osborneCanvas, this.appendageService.graphOsborne[0], this.appendageService.graphOsborne[1]);
    });
  }

  chartMethod(title: string, elCanvas: any, labelsData: string[], dataData: number[]) {

    this.elChart = new Chart(elCanvas.nativeElement, {
      type: 'line',
      options: {
        title: {
          display: false,
          text: title
        },
        legend: {
          position: 'top',
          labels: {
            fontSize: 14
          }
  
        },
        rotation: -0.1
  
  
      },
      data: {
        labels: labelsData,
        datasets: [{
          label: title,
          data: dataData,
          backgroundColor: '#009EE3',
          fill: false
        }]
      }
    });
    
  }



getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (this.gradient === null || this.width !== chartWidth || this.height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    this.width = chartWidth;
    this.height = chartHeight;
    this.gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    this.gradient.addColorStop(0, 'rgb(54, 162, 235)');
    this.gradient.addColorStop(0.5, 'rgb(255, 205, 86)');
    this.gradient.addColorStop(1, 'rgb(255, 99, 132)');
  }

  return this.gradient;
}

}
