import { Component, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { VehicleService } from '../../vehicles/vehicle.service';
@Component({
  selector: 'app-dashboard-plots-Vmax',
  templateUrl: './dashboard-plots-Vmax.component.html',
  styleUrls: ['./dashboard-plots-Vmax.component.scss']
})
export class DashboardPlotsVMaxComponent implements OnInit {


  @ViewChild('V_MAXCanvas') V_MAXCanvas;

  constructor(public vehicleService: VehicleService) { }

  elChart: any;

  ngOnInit(): void {

    this.vehicleService.vehiclesChanged.subscribe(() => {
      this.vehicleService.onInitGraphVmax();
      this.chartMethod(this.V_MAXCanvas, this.vehicleService.graphVmax[0], this.vehicleService.graphVmax[1]);
    });
  }

  chartMethod( elCanvas: any, labelsData: string[], dataData: number[]) {

    const labels = labelsData;
    const data = {
      labels: labels,
      
      datasets: [{
        label: 'V_max',
        data: dataData,
        backgroundColor: 
          'rgba(220, 170, 0, 0.2)'
        ,
        borderColor: 
          'rgba(220, 170, 0, 0.5)'
        ,
        borderWidth: 1
      }]
    };

    // if (this.elChart != undefined) {

    //   this.elChart.data.labels.splice(0); // remove the label first
    //   // this.elChart.data.datasets.data.splice(0);

    //   this.elChart.data.labels = labelsData;
    //   this.elChart.data.datasets.data = dataData;
    //   this.elChart.update();

    // } else {
      this.elChart = new Chart(elCanvas.nativeElement, {
        type: 'horizontalBar',
        data: data,
        options: {
          // indexAxis: 'y',
          // Elements options apply to all of the options unless overridden in a dataset
          // In this case, we are setting the border of each horizontal bar to be 2px wide
          // elements: {
          //   bar: {
          //     borderWidth: 2,
          //   }
          // },
          scales: {

            xAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMin: 0,
                suggestedMax: 45,
              }
              ,
              scaleLabel: {
                display: true,
                labelString: 'V [kts]'
              }
            }]
          },
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
            },
            title: {
              display: true,
              text: 'V_max'
            }
          }
        },

      });
    // }
  }


  getRandomColorHover() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',0.3)';
  }


}
