export class Variant{
    static selectedVariant: string = "selectedVariant";
	static idVariantSelected: string = "idVariantSelected";

	public idVariant: number;
	public variantName: string;
	public note: string;
	
	public idVersionList: number;
	public versionListName: string;
	public idPropulsionList: number;
	public propulsionListName: string;
	public idOptList: number;
	public optListName: string;
	
	public idTankList: number;
	public tankListName: string;
	public idCrewList: number;
	public crewListName: string;
	public idPaxList: number;
	public paxListName: string;
	public idDistributedList: number;
	public distributedListName: string;
	public idVehicle: number;
	public isTrash: boolean;
	public isDelete: boolean;

	constructor($idVariant: number, $variantName: string, $note: string, $idVersionList: number, $versionListName: string, $idPropulsionList: number, $propulsionListName: string, $idOptList: number, $optListName: string, $idTankList: number, $tankListName: string, $idCrewList: number, $crewListName: string, $idPaxList: number, $paxListName: string, $idDistributedList: number, $distributedListName: string,$idVehicle: number, $isTrash: boolean, $isDelete: boolean) {
		this.idVariant = $idVariant;
		this.variantName = $variantName;
		this.note = $note;
		this.idVersionList = $idVersionList;
		this.versionListName = $versionListName;
		this.idPropulsionList = $idPropulsionList;
		this.propulsionListName = $propulsionListName;
		this.idOptList = $idOptList;
		this.optListName = $optListName;
		this.idTankList = $idTankList;
		this.tankListName = $tankListName;
		this.idCrewList = $idCrewList;
		this.crewListName = $crewListName;
		this.idPaxList = $idPaxList;
		this.paxListName = $paxListName;
		this.idDistributedList = $idDistributedList;
		this.distributedListName = $distributedListName;
		this.idVehicle = $idVehicle;
		this.isTrash = $isTrash;
		this.isDelete = $isDelete;
	}

}