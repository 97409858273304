<div class="content">
    <div class="row">
        <div class="col-sm-10" *ngIf="appendageService.selectedSetEditMode == false">
            <h5>Add set form</h5>
        </div>
        <div class="col-sm-10" *ngIf="appendageService.selectedSetEditMode == true">
            <h5>Edit {{appendageSet.name}}</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="appendageService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="appendageSetForm" (ngSubmit)="onSubmit()">




                <input type="hidden" id="id" formControlName="id" class="form-control">
                <input type="hidden" id="project" formControlName="project" class="form-control">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" id="name" formControlName="name" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="rudder">Select a rudder*</label>
                            <select class="form-control" formControlName="rudder" id="rudder">
                                <option *ngFor="let el of appendageService.appendages | filterAppendagesPipe:1" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="appendageSetForm.controls['rudder'].value">
                            <label>N rudder</label>
                            <input type="number" id="n_rudder" step="1" min="1" formControlName="n_rudder" class="form-control">
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="fin">Select a fin*</label>
                            <select class="form-control" formControlName="fin" id="fin">
                                <option *ngFor="let el of appendageService.appendages | filterAppendagesPipe:2" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="appendageSetForm.controls['fin'].value">
                            <label>N fin</label>
                            <input type="number" id="n_fin" step="1" min="1" formControlName="n_fin" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="keel">Select a keel*</label>
                            <select class="form-control" formControlName="keel" id="keel">
                                <option *ngFor="let el of appendageService.appendages | filterAppendagesPipe:3" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="appendageSetForm.controls['keel'].value">
                            <label>N keel</label>
                            <input type="number" id="n_keel" step="1" min="1" formControlName="n_keel" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="shaft">Select a shaft*</label>
                            <select class="form-control" formControlName="shaft" id="shaft">
                                <option *ngFor="let el of appendageService.appendages | filterAppendagesPipe:4" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="appendageSetForm.controls['shaft'].value">
                            <label>N shaft</label>
                            <input type="number" id="n_shaft" step="1" min="1" formControlName="n_shaft" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="bracket">Select a bracket*</label>
                            <select class="form-control" formControlName="bracket" id="bracket">
                                <option *ngFor="let el of appendageService.appendages | filterAppendagesPipe:5" [value]="el.id">
                                    {{el.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="appendageSetForm.controls['bracket'].value">
                            <label>N bracket</label>
                            <input type="number" id="n_bracket" step="1" min="1" formControlName="n_bracket" class="form-control">
                        </div>
                    </div>
                </div>

                <div *ngIf="error != ''" class="alert alert-danger">
                    {{error}}
                </div>

                <div class="row">

                    <div class="col-sm-11">
                    </div>

                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(appendageService.selectedSetEditMode == false)">
                        <button type="submit" class="btn btn-success" [disabled]="!appendageSetForm.valid"><i class="bx bxs-save font-size-50"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>
                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(appendageService.selectedSetEditMode == true)">
                        <button type="submit" class="btn btn-success" [disabled]="!appendageSetForm.valid"><i class="bx bxs-edit-alt font-size-50"></i></button>
                        <div class="textUnderIcon">Edit</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>