import { Component, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { PropellerService } from '../propeller.service';

@Component({
  selector: 'app-propeller-plots',
  templateUrl: './propeller-plots.component.html',
  styleUrls: ['./propeller-plots.component.scss']
})
export class PropellerPlotsComponent implements OnInit {

  @ViewChild('ktCanvas') ktCanvas;
  @ViewChild('kqCanvas') kqCanvas;
  @ViewChild('etaCanvas') etaCanvas;

  constructor(public propellerService: PropellerService) { }

  ngOnInit(): void {
    this.propellerService.ludwigsChanged.subscribe(() => {
      this.propellerService.onInitGraph();
      this.chartMethod("kt", this.propellerService.chartKt, this.ktCanvas, this.propellerService.graphKt[0], this.propellerService.graphKt[1]);
      this.chartMethod("kq", this.propellerService.chartKq, this.kqCanvas, this.propellerService.graphKq[0], this.propellerService.graphKq[1]);
      this.chartMethod("eta", this.propellerService.chartEta, this.etaCanvas, this.propellerService.graphEta[0], this.propellerService.graphEta[1]);
    });
  }

  // ngAfterViewInit() {
  //   this.propellerService.onInitGraph();
  //   this.chartMethod("kt", this.propellerService.chartKt, this.ktCanvas, this.propellerService.graphKt[0], this.propellerService.graphKt[1]);
  //   this.chartMethod("kq", this.propellerService.chartKq, this.kqCanvas, this.propellerService.graphKq[0], this.propellerService.graphKq[1]);
  //   this.chartMethod("eta", this.propellerService.chartEta, this.etaCanvas, this.propellerService.graphEta[0], this.propellerService.graphEta[1]);
  // }

  chartMethod(title: string, elChart: any, elCanvas: any, labelsData: string[], dataData: number[]) {

    let backgroundColorData = [];
    let backgroundColorHoverData = [];
  
    labelsData.forEach(name => {
      // if (mapCol.get(name)) {
      //   backgroundColorData.push(mapCol.get(name));
      //   backgroundColorHoverData.push(mapColHover.get(name));
      // } else {
       
        var colorHover = this.getRandomColorHover();
        console.log(colorHover);
        
      // }
    });
  
    elChart = new Chart(elCanvas.nativeElement, {
      type: 'line',
      options: {
        title: {
          display: false,
          text: title
        },
        legend: {
          position: 'top',
          labels: {
            fontSize: 14
          }
  
        },
        rotation: -0.1
  
  
      },
      data: {
        labels: labelsData,
        datasets: [{
          label: title,
          data: dataData,
          backgroundColor: '#009EE3',
          fill: false
        }]
      }
    });
  }

  getRandomColorHover() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',0.3)';
  }

 
}
