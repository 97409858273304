import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Appendage } from '../../../shared/appendage.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { AppendageService } from '../appendage.service';
import { CategoryCloneComponent } from '../category-clone/category-clone.component';
import { CategoryDeleteComponent } from '../category-delete/category-delete.component';
import { CategoryEditComponent } from '../category-edit/category-edit.component';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})
export class CategoryDetailsComponent implements OnInit {
  appendage: Appendage;
  project: ProjectDELPHY;

  constructor(
    public cookieService: CookieService,
    public appendageService: AppendageService,
    private modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
 
    if(this.appendageService.selectedAppendage == undefined){
      this.appendageService.selectedAppendageEvent.subscribe(
        (selectedAppendage: Appendage) => {
          this.appendage = selectedAppendage;
        }
      );
    }
  }

  onEdit() {
    this.appendageService.selectedEditMode = true;
    const modalRef = this.modalService.open(CategoryEditComponent);
    modalRef.componentInstance.appendage = this.appendageService.selectedAppendage;
    modalRef.componentInstance.type = this.appendage.category;
  }

  onClone() {
    const modalRef = this.modalService.open(CategoryCloneComponent);
    modalRef.componentInstance.appendage = this.appendageService.selectedAppendage;
    modalRef.componentInstance.type = this.appendage.category;
    modalRef.componentInstance.type = this.appendage.category;
  }

  onTrash() {
    const modaldelete = this.modalService.open(CategoryDeleteComponent);
    modaldelete.componentInstance.appendage = this.appendageService.selectedAppendage;
  }

}
