import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { Crew } from 'src/app/CALC/shared/crew.model';
import { CookieService } from 'ngx-cookie-service';
import { CrewService } from './crew.service';
import { Item } from 'src/app/CALC/shared/item.model';
import { AccountService } from 'src/app/account/account.service';
import { VariantService } from '../../variants/variant.service';

@Component({
  selector: 'app-crews',
  templateUrl: './crews.component.html',
  styleUrls: ['./crews.component.scss']
})
export class CrewsComponent implements OnInit {

  cols: any[];
  crews: Crew[];
  selectedCrew: Crew;
  requestType = "crew";
  idList: number;
  constructor(
    public crewService: CrewService,
    public accountService: AccountService,
    public cookieService: CookieService,
    private variantService: VariantService) { }

  ngOnInit(): void {

    this.variantService.unselectItems();

    this.idList = +this.cookieService.get(Item.tableNameIdList);
    this.requestType = this.cookieService.get(Item.servletName);
    if(this.requestType == "crew"){
      this.cookieService.set("topbarTitle","Crew data input");
    } else if (this.requestType == "pax"){
      this.cookieService.set("topbarTitle","Pax data input");
    }
    this.crewService.getCrews(this.requestType, this.idList).subscribe(res => {
      this.crews = res;
      this.crewService.setCrews(this.crews);
    });
      
    
    this.cols = [
      { field: 'name', header: 'Name', width: "260px" },
      { field: 'number', header: 'Number', width: "90px" },
      { field: 'unitaryWeight', header: 'UnitaryWeight', width: "120px" },
      { field: 'weight', header: 'Weight', width: "90px" },
      { field: 'xg', header: 'Xg', width: "70px" },
      { field: 'yg', header: 'Yg', width: "70px" },
      { field: 'zg', header: 'Zg', width: "70px" },
      { field: 'lastUpdateDate', header: 'LastUpdateDate', width: "140px" },
      { field: 'note', header: 'Note', width: "140px" }
  ];
  }

  onNew() {

    this.crewService.selectedEditMode = false;
    let crew = new Crew(0,0,'',0,0,0,0,0,0,'','',false,false);
  
    this.crewService.selectCrewForEdit(crew);
  }

  onSelect(crew: Crew) {
    this.crewService.selectedEditMode = true;
    this.crewService.selectedCrew = crew;
    this.crewService.selectCrewForEdit(crew);
  }

}
