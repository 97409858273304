import { Injectable } from '@angular/core';
import { Project } from './project.model';

import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { Task } from '../tasks/task.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  projects: Project[];
  projectsChanged = new Subject<Project[]>();


  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService) 
  { }





    // Get data from servlet and return the projects array
    public getProjects(): Observable<Project[]> {
      return this.http.get<Project[]>(this.accountService.REST_API_SERVER 
        + 'projectServletTW?action=LISTANGULAR&currentEmail=' 
        // + this.currentUser.email
        )
        .pipe(map(result => result['projects'])
        );
    }

    setProjects(projects: Project[]) {
      this.projects = projects;
      // console.log(this.projects)
      this.projectsChanged.next(this.projects.slice());
      console.log("this.projectsChanged");
      console.log(this.projectsChanged);
    }
    
    getProject(id: string) {
      const project = this.projects.find(
        (r) => {
          return r.id == id;
        }
      );
      return project;
    }






}
