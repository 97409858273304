<div class="micard overflow-hidden">

    <div class="micard-header" style="cursor: pointer;" title="Show projectEl.name">
        <div>
            <h5 *ngIf="appendageService.selectedAppendageSet != undefined" class="list-title">{{appendageSet?.name}}</h5>
        </div>
    </div>


    <div class="details-body" *ngIf="appendageService.selectedAppendageSet == undefined">
        <p>Please Select a AppendageSet</p>
    </div>

    <div class="details-body" *ngIf="appendageService.selectedAppendageSet != undefined">
        <div class="row">
            <div class="col-sm-12 property-box">
                <div class="row">
                    <div class="property-name">
                        Creation
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendageSet?.date}}
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Rudder
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{rudder?.name}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        N_Rudder
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendageSet?.n_rudder}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Fin
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{fin?.name}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        N_Fin
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendageSet?.n_fin}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Keel
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{keel?.name}}
                    </div>
                </div>
            </div>

            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        N_Keel
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendageSet?.n_keel}}
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Shaft
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{shaft?.name}}
                    </div>
                </div>
            </div>

            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        N_Shaft
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendageSet?.n_shaft}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Bracket
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{bracket?.name}}
                    </div>
                </div>
            </div>

            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        N_Bracket
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendageSet?.n_bracket}}
                    </div>
                </div>
            </div>
        </div>

        <hr>
        <div class="row" *ngIf="appendageService.selectedAppendageSet != undefined">
            <div class="col-sm-4 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-edit-alt font-size-22 action-icon" (click)="onEdit()" title="Edit"></i>
            </div>

            <div class="col-sm-4 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-copy font-size-22 action-icon" (click)="onClone()" title="Clone"></i>
            </div>

            <div class="col-sm-4 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-trash-alt font-size-22 action-icon" (click)="onTrash()" title="Delete"></i>
            </div>

        </div>

    </div>
</div>