<div class="micard overflow-hidden">

    <div class="micard-header" style="cursor: pointer;" title="Show projectEl.name">
        <div>
            <h5 *ngIf="appendageService.selectedAppendage != undefined" class="list-title">{{appendage?.name}}</h5>
        </div>
    </div>


    <div class="details-body" *ngIf="appendageService.selectedAppendage == undefined">
        <p>Please Select a Appendage</p>
    </div>

    <div class="details-body" *ngIf="appendageService.selectedAppendage != undefined">
        <div class="row">
            <div class="col-sm-12 property-box">
                <div class="row">
                    <div class="property-name">
                        Creation
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.date}}
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        A
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.A}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box" *ngIf="appendage?.category == 4">
                <div class="row">
                    <div class="property-name">
                        D
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.D}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box" *ngIf="appendage?.category == 1 || appendage?.category == 2 || appendage?.category == 5">
                <div class="row">
                    <div class="property-name">
                        t
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.t}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box" *ngIf="appendage?.category == 1 || appendage?.category == 2 || appendage?.category == 5">
                <div class="row">
                    <div class="property-name">
                        c
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.c}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box" *ngIf="appendage?.category == 3 || appendage?.category == 4">
                <div class="row">
                    <div class="property-name">
                        L
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.L}}
                    </div>
                </div>
            </div>

            <div class="col-sm-6 property-box" *ngIf="appendage?.category == 4">
                <div class="row">
                    <div class="property-name">
                        epsilon
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.epsilon}}
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-4 property-box">
                <div class="row">
                    <div class="property-name">
                        Px
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.Px}}
                    </div>
                </div>
            </div>

            <div class="col-sm-4 property-box">
                <div class="row">
                    <div class="property-name">
                        Py
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.Py}}
                    </div>
                </div>
            </div>

            <div class="col-sm-4 property-box">
                <div class="row">
                    <div class="property-name">
                        Pz
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendage?.Pz}}
                    </div>
                </div>
            </div>
        </div>


        <hr>
        <div class="row" *ngIf="appendageService.selectedAppendage != undefined">
            <div class="col-sm-4 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-edit-alt font-size-22 action-icon" (click)="onEdit()" title="Edit"></i>
            </div>

            <div class="col-sm-4 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-copy font-size-22 action-icon" (click)="onClone()" title="Clone"></i>
            </div>

            <div class="col-sm-4 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-trash-alt font-size-22 action-icon" (click)="onTrash()" title="Delete"></i>
            </div>

        </div>

    </div>
</div>