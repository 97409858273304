import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Ludwig } from '../../shared/ludwig.model';
import { ProjectDELPHY } from '../../shared/projectDELPHY.model';
import { Propeller } from '../../shared/propeller.model';

@Injectable({
  providedIn: 'root'
})
export class PropellerService {

  selectedEditMode: boolean;
  error = new Subject<string>();

  propellers: Propeller[];
  propellersChanged = new Subject<Propeller[]>();

  selectedPropeller: Propeller;
  selectedPropellerEvent = new EventEmitter<Propeller>();

  ludwigs: Ludwig[];
  ludwigsChanged = new Subject<Ludwig[]>();

  chartKt: any;
  chartKq: any;
  chartEta: any;
  //[0] label,     [1] data
  graphKt: any[] = [];
  graphKq: any[] = [];
  graphEta: any[] = [];

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  createPropeller(propeller: Propeller) {
    console.log(" dopo form propeller");
    console.log(propeller);
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ propeller: Propeller }>(
        this.accountService.REST_API_SERVER_DELPHY + 'propellers/',
        propeller
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          propeller.id = responseData["id"];
          propeller.date = responseData["date"];
          // this.propellers.unshift(propeller);
          this.propellers.push(propeller);
          this.toastr.success('Propeller created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  updatePropeller(propeller: Propeller) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .put<{ propeller: Propeller }>(
        this.accountService.REST_API_SERVER_DELPHY + 'propellers/',
        propeller
      )
      .subscribe(responseData => {
        let itemIndex = this.propellers.findIndex(item => item.id == this.selectedPropeller.id);
        propeller.date = responseData["date"];
        this.propellers[itemIndex] = propeller;
        this.selectedPropeller = propeller;
        this.selectedPropellerEvent.next(propeller);
        this.toastr.success('Propeller  updated');
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          // this.error.next(error.message);
        });

  }

  trashPropeller() {

    this.http
      .delete(this.accountService.REST_API_SERVER_DELPHY
        + 'propellers?id=' + this.selectedPropeller.id)
      .subscribe(responseData => {
        if (responseData != undefined) {
          const index = this.propellers.findIndex(x => x.id === this.selectedPropeller.id);
          this.propellers.splice(index, 1);
          this.toastr.success('This propeller has been deleted', 'Project deleted');
          this.selectedPropellerEvent.next(undefined);
          this.selectedPropeller = undefined;
        } else {
          this.error.next(responseData["Result"].message);
        }
      },
        error => {
          this.error.next(error.message);
        });

  }

  public getPropellers(): Observable<Propeller[]> {
    return this.http.get<Propeller[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'propellers/?project_id=' + this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY)
    )
      .pipe(
      );
  }

  onCloseForm() {
    this.modalService.dismissAll();
  }




  public getLudwigs(propeller: Propeller): Observable<Ludwig[]> {
    return this.http.get<Ludwig[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'ludwig/?propeller_id=' + propeller.id
    )
      .pipe(
      );
  }

  
  getPropeller(id: number) {
    if (this.propellers == undefined)
      return undefined;
    const el = this.propellers.find(
      (r) => {
        return r.id == id;
      }
    );
    return el;
  }












  onInitGraph() {

    this.setGraphJob();
    console.log("initi" );


  }




  // restituisce label e data
  setGraphJob() {
    let dataKt = [], dataKq = [], dataEta = [], label = [];

    


    this.ludwigs.forEach(element => {
      label.push(element.J.toFixed(2))
      dataKt.push(element.Kt.toFixed(3))
      dataKq.push(element.Kq.toFixed(3))
      if(element.eta != 0)
      dataEta.push(element.eta.toFixed(3))
    });

    this.graphKt = [label, dataKt];

    this.graphKq = [label, dataKq];

    this.graphEta = [label, dataEta];
  }




  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }


}
