export class Tank {
    public id: number;
	public idForeign: number;
	public name: string;
	public shape: string;
	public angle: number;

	public dimension1: number;
	public dimension2: number;
	public dimension3: number;
	public dimension4: number;
	public dimension5: number;

	public quantity: number;
	public density: number;
	public weight: number;
	public xg: number;
	public yg: number;

	public zg: number;
	public lastUpdateDate: string;
	public note: string;
	public isTrash: boolean;
	public isDelete: boolean;

	

	constructor($id: number, $idForeign: number, $name: string, $shape: string, $angle: number, $dimension1: number, $dimension2: number, $dimension3: number, $dimension4: number, $dimension5: number, $quantity: number, $density: number, $weight: number, $xg: number, $yg: number, $zg: number, $lastUpdateDate: string, $note: string, $isTrash: boolean, $isDelete: boolean) {
		this.id = $id;
		this.idForeign = $idForeign;
		this.name = $name;
		this.shape = $shape;
		this.angle = $angle;
		this.dimension1 = $dimension1;
		this.dimension2 = $dimension2;
		this.dimension3 = $dimension3;
		this.dimension4 = $dimension4;
		this.dimension5 = $dimension5;
		this.quantity = $quantity;
		this.density = $density;
		this.weight = $weight;
		this.xg = $xg;
		this.yg = $yg;
		this.zg = $zg;
		this.lastUpdateDate = $lastUpdateDate;
		this.note = $note;
		this.isTrash = $isTrash;
		this.isDelete = $isDelete;
	}
	
}