import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { ModuleLink } from '../modulelink.model';

@Component({
  selector: 'app-module-link',
  templateUrl: './module-link.component.html',
  styleUrls: ['./module-link.component.scss']
})
export class ModuleLinkComponent implements OnInit {
  @Input() moduleLink: ModuleLink;
  constructor(public cookieService: CookieService) { 
    
  }

  ngOnInit(): void {
    
  }
  
}
