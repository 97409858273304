<div class="micard overflow-hidden">

    <div class="micard-header" style="cursor: pointer;" title="Show projectEl.name">
        <div>
            <h5 *ngIf="lcService.selectedLc != undefined" class="list-title">{{lc?.name}}</h5>
        </div>
    </div>


    <div class="details-body" *ngIf="lcService.selectedLc == undefined">
        <p>Please Select a Load Condition</p>
    </div>

    <div class="details-body" *ngIf="lcService.selectedLc != undefined">
        <div class="row">
            <div class="col-sm-12 property-box">
                <div class="row">
                    <div class="property-name">
                        Creation
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{lc?.date}}
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 property-box">
                <div class="row">
                    <div class="property-name">
                        &Delta;
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{lc?.displacement}} kg
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        L<sub>CG</sub>
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{lc?.lcg}} m
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        V<sub>CG</sub>
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{lc?.vcg}} m
                    </div>
                </div>
            </div>

        </div>

        <hr>
        <div class="row" *ngIf="lcService.selectedLc != undefined">
            <div class="col-sm-6 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-edit-alt font-size-22 action-icon" (click)="onEdit()" title="Edit"></i>
            </div>

            <div class="col-sm-6 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-trash-alt font-size-22 action-icon" (click)="onTrash()" title="Delete"></i>
            </div>

        </div>

    </div>
</div>