import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/account/user.model';
import { Company } from 'src/app/CALC/shared/company.model';
import { ProjectCALC } from 'src/app/CALC/shared/projectCALC.model';
import { List } from '../../../shared/list.model';
import { ProjectService } from '../project.service';
import { saveAs } from 'file-saver'
import { Observable } from 'rxjs';
@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit, AfterViewInit {

  projectForm: FormGroup;

  jobLists: List[];
  designLists: List[];
  topologicalLists: List[];
  unitsOfMeasureLists: List[];

  item: string;
  @Input() public project: ProjectCALC;

  currentUser: User;

  fileToUpload: File = null;
  @ViewChild('name', { static: false }) inputEl: ElementRef;
  constructor(public projectService: ProjectService,
    public cookieService: CookieService,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.projectService.getLists('jobList').subscribe(res => {
      this.jobLists = this.setLists(res);
      if (this.project == undefined)
        this.projectForm.patchValue({ "idJobList": this.jobLists[0].id });
    });
    this.projectService.getLists('designList').subscribe(res => {
      this.designLists = this.setLists(res);
      if (this.project == undefined)
        this.projectForm.patchValue({ "idDesignList": this.designLists[0].id });
    });
    this.projectService.getLists('topologicalList').subscribe(res => {
      this.topologicalLists = this.setLists(res);
      if (this.project == undefined)
        this.projectForm.patchValue({ "idTopologicalList": this.topologicalLists[0].id });
    });
    this.projectService.getLists('unitsOfMeasureList').subscribe(res => {
      this.unitsOfMeasureLists = this.setLists(res);
      if (this.project == undefined)
        this.projectForm.patchValue({ "idUnitsOfMeasureList": this.unitsOfMeasureLists[0].id });
    });

      this.initForm();
 
  }

  ngOnChanges() {
    console.log(this.projectForm);
  }

  ngAfterViewInit(){
    this.inputEl.nativeElement.focus();
  }


  setLists(lists: List[]): List[] {

    let listRet = [];
    listRet = lists;

    return listRet;
  }


  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
}



  onSubmit() {
    this.modalService.dismissAll();

    if (this.projectService.selectedEditMode) {
      this.projectService.updateProject(this.projectForm.value);
    } else {
      this.projectService.createProject(this.projectForm.value);
    }

  }


 

  private initForm() {

    let cookieValue = this.cookieService.get('loggedInUser');
      this.currentUser = JSON.parse(cookieValue);


    let projectidProject: number;
    let projecprojectName: string;
    let projectlength: number;
    let projectbeam: number;
    let projectstartDate = '';
    let projectendDate = '';

    let projectnote = '';
    let projectcompany: Company;
    let projectidJobList: number;
    let projectjobListName = '';
    let projectidTopologicalList: number;
    let projecttopologicalListName = '';

    let projectidDesignList: number;
    let projectdesignListName = '';
    let projectidUnitsOfMeasureList: number;
    let projectunitsOfMeasureListName = '';
    let projectisTrash = false;
    let projectisDelete = false;
    let creationDate = '';
    let projectOwner  = this.currentUser.email;
    let imgProjectOwner  = this.currentUser.avatarImg;
    let img  = '';

    if (this.project != undefined && this.projectService.selectedEditMode == true) {
      projectidProject = this.project.idProject;
      projecprojectName = this.project.projectName;
      projectlength = Math.round(this.project.length * 100) / 100;
      projectbeam = Math.round(this.project.beam * 100) / 100;
      projectstartDate = this.project.startDate;
      projectendDate = this.project.endDate;

      projectnote = this.project.note;
      projectcompany = this.project.company;
     
      projectidJobList = this.project.idJobList;
      projectjobListName = this.project.jobListName;
      projectidTopologicalList = this.project.idTopologicalList;
      projecttopologicalListName = this.project.topologicalListName;

      projectidDesignList = this.project.idDesignList;
      projectdesignListName = this.project.designListName;
      projectidUnitsOfMeasureList = this.project.idUnitsOfMeasureList;
      projectunitsOfMeasureListName = this.project.unitsOfMeasureListName;
      projectisTrash = this.project.isTrash;
      projectisDelete = this.project.isDelete;
      creationDate = this.project.creationDate;
      projectOwner = this.project.projectOwner;
      imgProjectOwner = this.project.imgProjectOwner;
      img = this.project.img;
     
    }

    this.projectForm = new FormGroup({
      'idProject': new FormControl(projectidProject),
      'idJobList': new FormControl(projectidJobList),
      'idTopologicalList': new FormControl(projectidTopologicalList),
      'idDesignList': new FormControl(projectidDesignList),
      'idUnitsOfMeasureList': new FormControl(projectidUnitsOfMeasureList),


      'projectName': new FormControl(projecprojectName, Validators.required),
      'length': new FormControl(projectlength, Validators.required),
      'beam': new FormControl(projectbeam, Validators.required),
      'startDate': new FormControl(projectstartDate, Validators.required),
      'endDate': new FormControl(projectendDate, Validators.required),

      'note': new FormControl(projectnote),
      'company': new FormControl(projectcompany, Validators.required),
      'jobListName': new FormControl(projectjobListName),
      'topologicalListName': new FormControl(projecttopologicalListName),
      'designListName': new FormControl(projectdesignListName),
      'unitsOfMeasureListName': new FormControl(projectunitsOfMeasureListName),

      'isTrash': new FormControl(projectisTrash,),
      'isDelete': new FormControl(projectisDelete),

      'creationDate': new FormControl(creationDate),
      'projectOwner': new FormControl(projectOwner),
      'imgProjectOwner': new FormControl(imgProjectOwner),
      'img': new FormControl(img)

    });
    
   
    setTimeout(() => {
      if(!this.projectService.selectedEditMode){
      
        this.projectForm.patchValue({ "company": this.projectService.companies[0] });
      } else {
        this.projectForm.patchValue({ "company": this.projectService.companies.find(comp => comp.id== projectcompany.id) });
      }
    }, 200);
  }
  
}
