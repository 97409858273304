import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { Engine } from '../../../shared/engine.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { EngineService } from '../engine.service';

@Component({
  selector: 'app-engine-edit',
  templateUrl: './engine-edit.component.html',
  styleUrls: ['./engine-edit.component.scss']
})
export class EngineEditComponent implements OnInit {

  engineForm: FormGroup;

  @Input() engine: Engine;

  nameAlredyExist = "";

  constructor(public engineService: EngineService,
    public accountService: AccountService,
    public cookieService: CookieService) {

  }

  ngOnInit(): void {
    this.initForm();
  }


  
  nameCheckUnique() {

    console.log("Dentro check");
    let flag = false;
    

    this.engineService.engines.forEach(engine => {
      if(engine.name == this.engineForm.controls['name'].value)
          flag = true
    });

    if (flag) {
      this.nameAlredyExist = "Name Alredy Exist";
    }
    else{
      this.nameAlredyExist = "";
    }

  }
  onSubmit() {
    // this.dialogRef.close();
    if (this.engineForm.valid && this.nameAlredyExist == "") {

      if (this.engineService.selectedEditMode) {

        this.engineService.updateEngine(this.engineForm.value);
      } else {
        this.engineService.createEngine(this.engineForm.value)
      }
      this.engineService.onCloseForm()
    }

  }


  private initForm() {
    let id: number;
    let name: string;
    let date: string;
    let Z = 1.0;
    let eta_m = 0.95;
    let Pi_kw: number;
    let project = +this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY);
   
    console.log(" edit mode imit");
    console.log(this.engineService.selectedEditMode);
    
    if (this.engineService.selectedEditMode && this.engine != undefined) {
      id = this.engine.id;
      name = this.engine.name;
      date = this.engine.date;
      Pi_kw = this.engine.Pi_kw;
      Z = this.engine.Z;
      eta_m = this.engine.eta_m;
      project = this.engine.project;

      

    }

    this.engineForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(name, Validators.required),
      'date': new FormControl(date),
      'Pi_kw': new FormControl(Pi_kw, Validators.required),
      'Z': new FormControl(Z, Validators.required),
      'eta_m': new FormControl(eta_m, Validators.required),
      'project': new FormControl(project,)

    });
  }

}
