import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProjectDELPHYService } from '../project-delphy.service';
import { PropellerService } from '../propellers/propeller.service';
import { VehicleService } from '../vehicles/vehicle.service';

@Component({
  selector: 'app-dashboardproject',
  templateUrl: './dashboardproject.component.html',
  styleUrls: ['./dashboardproject.component.scss']
})
export class DashboardprojectComponent implements OnInit, AfterViewInit {

  Hulls = "none";
  Loads = "none";
  Engines = "none";
  Propellers = "none";
  Appendages = "none";
  noOne = "block"
  constructor(public projectDELPHYservice: ProjectDELPHYService,
    public vehicleService: VehicleService,
    public propellerService:PropellerService) { }

  ngOnInit(): void {
    this.projectDELPHYservice.setUndefinedAll();
    
  }

  ngAfterViewInit(){
   
  }
    onShowDetails(display: string){

      this.Hulls = "none";
      this.Loads = "none";
      this.Engines = "none";
      this.Propellers = "none";
      this.Appendages = "none";
      this.noOne = "none";

      if(display == 'Hulls'){
        this.Hulls = "block";
      }
  
      else if (display == 'Loads'){
        this.Loads = "block";
      }
  
      else if (display == 'Engines'){
        this.Engines = "block";
      } 
      
      else if (display == 'Propellers'){
        this.Propellers = "block";
      } 
      
      else if (display == 'Appendages'){
        this.Appendages = "block";
       } 
    
    }
}
