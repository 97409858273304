import { Component, OnInit } from '@angular/core';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-dashboard',
  templateUrl: './vehicle-dashboard.component.html',
  styleUrls: ['./vehicle-dashboard.component.scss']
})
export class VehicleDashboardComponent implements OnInit {

  constructor(public projectDELPHYservice: ProjectDELPHYService,
    public vehicleService: VehicleService) { }

  ngOnInit(): void {
  }

}
