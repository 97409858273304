import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { List } from '../../../shared/list.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Item } from 'src/app/CALC/shared/item.model';
import { Vehicle } from '../../vehicles/vehicle.model';
import { User } from 'src/app/account/user.model';
import { ProjectService } from '../project.service';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  lists: List[];
  selectedlist = new Subject<List>();
  selectedEditMode: boolean;
  currentUser: User;
  error = new Subject<string>();

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    public projectService: ProjectService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  // Get data from servlet and return the projects array
  public getLists(listName: string, id: number): Observable<List[]> {

    if (listName == 'jobList' || listName == 'designList' || listName == 'topologicalList' || listName == 'unitsOfMeasureList') {
      this.projectService.setCompanies();
    }


    this.cookieService.set(List.typeList, listName);
    this.cookieService.set(List.idList, "" + id);

    return this.http.get<List[]>(this.accountService.REST_API_SERVER_CALC
      + listName + 'Servlet?action=LIST&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' + id
    )
      .pipe(map(result => result['Records'])
      );
  }

  setLists(lists: List[]) {
    this.lists = [];
    this.lists = lists;


    console.log("this.lists");
    console.log(this.lists);
  }

  getListItem(id: number) {
    const list = this.lists.find(
      (r) => {
        return r.id == id;
      }
    );
    return list;
  }

  createList(list: List) {
    console.log("list");
    console.log(list);
    var type = this.cookieService.get(List.typeList);
    var id = this.cookieService.get(List.idList);

    // console.log("createList");  console.log(type);console.log(id);

    this.http.post<{ list: List }>(
      this.accountService.REST_API_SERVER_CALC + type + 'Servlet?action=CREATE&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' + id,
      list
    )
      .subscribe(responseData => {
        list = responseData["Record"];
        this.lists.push(list);
        this.toastr.success('Element created');
      },
        error => {
          this.error.next(error.message);
        });
  }

  updateList(list: List) {

    var type = this.cookieService.get(List.typeList);
    var idFor = this.cookieService.get(List.idList);
    // console.log("updateList");  console.log(list); console.log(type)

    this.http.post<{ list: List }>(
      this.accountService.REST_API_SERVER_CALC + type + 'Servlet?action=UPDATE&loggedInEmail=' + this.accountService.currentUser.email + '&idList=' + list.id + '&idForeign=' + idFor,
      list
    )
      .subscribe(responseData => {
        if (responseData["Result"] == 'OK') {
          let itemIndex = this.lists.findIndex(item => item.id == list.id);
          this.lists[itemIndex] = list;
          this.toastr.success('Element updated');
        }

      },
        error => {
          this.error.next(error.message);
        });
  }

  cloneList(list: List) {

    var type = this.cookieService.get(List.typeList);
    // console.log("cloneList");  console.log(list); console.log(type)

    this.http.post<{ list: List }>(
      this.accountService.REST_API_SERVER_CALC + type + 'Servlet?action=CLONE&loggedInEmail=' + this.accountService.currentUser.email
      + '&idList=' + list.id
      + '&idForeign=' + this.cookieService.get(Vehicle.idVehicleSelected),
      list
    )
      .subscribe(responseData => {
        if (responseData["Result"] == 'OK') {
          let listEl = responseData["Record"];
          this.lists.push(listEl);
          this.toastr.success('Element cloned');
        }

      },
        error => {
          this.error.next(error.message);
        });
  }

  deleteList(list: List) {

    var type;

    type = this.cookieService.get(List.typeList);


    // console.log("deleteList");
    // console.log(list);
    // console.log(type);


    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ list: List }>(
        this.accountService.REST_API_SERVER_CALC + type + 'Servlet?action=DELETE&loggedInEmail=' + this.accountService.currentUser.email + '&idList=' + list.id,
        list
      )
      .subscribe(responseData => {

        if (responseData["Result"] == "OK") {
          this.lists.splice(this.lists.indexOf(list), 1);
          this.toastr.success('Element delete');
        } else {
          this.toastr.error(responseData["Message"]);
        }

      },
        error => {
          this.error.next(error.message);
        });

  }




  deleteFromCookie() {
    this.cookieService.delete(List.typeList);
    this.cookieService.delete(List.idList);
    this.cookieService.delete(List.titleList);
  }

  requestTypeToTitleList(requestType: string): string {
    var titleList;

    if (requestType == 'jobList') {
      titleList = 'Job List'
    }

    if (requestType == 'topologicalList') {
      titleList = 'Topological List'
    }

    if (requestType == 'designList') {
      titleList = 'Design List'
    }

    if (requestType == 'unitsOfMeasureList') {
      titleList = 'Units Of Measure List'
    }

    if (requestType == "regNumber") {
      titleList = "Vehicles";

      this.cookieService.set(Item.servletName, 'BaseLineItem');
      this.cookieService.set(Item.tableTitleItem, 'Baseline items');
    }

    if (requestType == "revision") {
      titleList = "Revisions";

      this.cookieService.set(Item.servletName, 'BaseLineItem');
      this.cookieService.set(Item.tableTitleItem, 'Baseline items');
    }

    if (requestType == "versionList") {
      titleList = "Version list";
    }

    if (requestType == "propulsionList") {
      titleList = "Propulsion list";
    }

    if (requestType == "optList") {
      titleList = "Optional items list";
    }

    return titleList;
  }

  onCloseForm() {
    this.modalService.dismissAll();

  }
}
