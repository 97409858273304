<div class="list-card overflow-hidden">
    <div class="list-header">
        <div>
            <h5 class="list-title">Vehicles</h5>
        </div>
    </div>

    <div class="list-body" [ngStyle]="{'display': projectDELPHYservice.dashboardDis}">



        <div class="table">
            <table class="table table-nowrap table-lefted mb-0">
                <tbody>
                    <th style="text-align: center;">Name</th>
                    <th style="text-align: center;">Hull</th>
                    <th style="text-align: center;">Loads</th>
                    <th style="text-align: center;">Engine</th>
                    <th style="text-align: center;">Propeller</th>
                    <th style="text-align: center;">Appendage Set</th>
                    <th style="text-align: center;">V<sub>MAX</sub></th>

                    <tr *ngFor="let el of this.vehicleService.vehicles">
                        <td>
                            <div class="col-sm-12" style="text-align: center;">
                                {{el?.name}}
                            </div>
                        </td>
                        <td>
                            <div class="col-sm-12" style="text-align: center;">
                                {{hullService.getHull(el.hull)?.name}}
                            </div>
                        </td>

                        <td>
                            <div class="col-sm-12" style="text-align: center;">
                                {{lcService.getLc(el.lc)?.name}}
                            </div>
                        </td>
                        <td>
                            <div class="col-sm-12" style="text-align: center;">
                                {{el?.n_engine}} x {{engineService.getEngine(el.engine)?.name}}
                            </div>
                        </td>
                        <td>
                            <div class="col-sm-12" style="text-align: center;">
                                {{propellerService.getPropeller(el.propeller)?.name}}
                            </div>
                        </td>
                        <td>
                            <div class="col-sm-12" style="text-align: center;">
                                {{appendageService.getAppendageSet(el.appendageSet)?.name}}
                            </div>
                        </td>
                        <td>
                            <div class="col-sm-12" style="text-align: center;">
                                {{el?.V_max | number:'1.0-1'}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>

    </div>


    <div class="card-body" [ngStyle]="{'display': projectDELPHYservice.vehDis}">
        <ul class="list-group">
            <li class="list-item" *ngFor="let el of vehicleService.vehicles" (click)="onVehicleSelected(el)" style="cursor: pointer;" [ngClass]="{'active': selectedVehicleId == el.id}">
                {{el.name}}
            </li>
        </ul>
    </div>
    <div class="border-top">
        <div class="row card-action">
            <div class="col-sm-12 card-button action-icon">
                <i class="bx bx-plus font-size-22" style="cursor: pointer;" title="New" (click)="openAdd()"></i>
            </div>
            <!-- 
            <div class="col-sm-6 card-button action-icon" *ngIf="projectDELPHYservice.vehDis == 'none'">
                <i style="cursor: pointer;" class="bx bx-dots-horizontal-rounded font-size-22" title="Details" (click)="projectDELPHYservice.onActivate('veh')">
                </i>
            </div> -->

        </div>
    </div>
</div>