<div class="micard overflow-hidden">

    <div class="micard-header" style="cursor: pointer;" title="Show projectEl.name">
        <div>
            <h5 *ngIf="vehicleService.selectedVehicle != undefined" class="list-title">{{vehicleService.selectedVehicle?.name}}</h5>
        </div>
    </div>


    <div class="details-body" *ngIf="vehicleService.selectedVehicle == undefined">
        <p>Please Select a Vehicle</p>
    </div>

    <div class="details-body" *ngIf="vehicleService.selectedVehicle != undefined">
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Creation
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{vehicleService.selectedVehicle?.date}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        V<sub>MAX</sub>
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{vehicleService.selectedVehicle?.V_max | number:'1.0-1'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Hull
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{hullService.getHull(vehicle?.hull)?.name}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Lc
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{lcService.getLc(vehicle?.lc)?.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Engine
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{vehicle?.n_engine}} x {{engineService.getEngine(vehicle?.engine)?.name}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6 property-box">
                <div class="row">
                    <div class="property-name">
                        Propeller
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{propellerService.getPropeller(vehicle?.propeller)?.name}}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 property-box">
                <div class="row">
                    <div class="property-name">
                        Appendage set
                    </div>
                </div>
                <div class="row">
                    <div class="property-value">
                        {{appendageService.getAppendageSet(vehicle?.appendageSet)?.name}}
                    </div>
                </div>
            </div>
        </div>

        <hr>
        <div class="row" *ngIf="vehicleService.selectedVehicle != undefined">
            <div class="col-sm-6 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-edit-alt font-size-22 action-icon" (click)="onEdit()" title="Edit"></i>
            </div>

            <div class="col-sm-6 card-button action-icon" style="text-align: center;">
                <i class="bx bxs-trash-alt font-size-22 action-icon" (click)="onTrash()" title="Delete"></i>
            </div>

        </div>

    </div>
</div>