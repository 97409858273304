import { BrowserModule } from '@angular/platform-browser';

import { CommonModule, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';


import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AccountModule } from './account/account.module';
import { ModuleLinkComponent } from './account/dashboard-links/module-link/module-link.component';
import { DashboardLinksComponent } from './account/dashboard-links/dashboard-links.component';
import { RouterModule } from '@angular/router';

import { LayoutsMicappModule } from './MICAPP/layouts/layoutsmicapp.module';
import { LayoutsProjectManagmentModule } from './PROJECTMANAGMENT/layouts/layoutsprojectmanagment.module';
import { LayoutsCalcModule } from './CALC/layouts/layoutscalc.module';
import { LayoutsDelphyModule } from './DELPHY/layouts/layoutsdelphy.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ChartsModule } from 'ng2-charts';
import { ProfileComponent } from './CALC/users/profile/profile.component';
import { ManageComponent } from './CALC/users/manage/manage.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToolbarModule } from 'primeng/toolbar';
import { ManageProjectuserComponent } from './CALC/users/manage-projectuser/manage-projectuser.component';
import { UserEditComponent } from './CALC/users/user-edit/user-edit.component';
import { UserDeleteComponent } from './CALC/users/user-delete/user-delete.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { CalcModule } from './CALC/pages/calc.module';
import { DelphyModule } from './DELPHY/pages/delphy.module';



export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,    
    ModuleLinkComponent,
    DashboardLinksComponent,
    ProfileComponent,
    ManageComponent,
    ManageProjectuserComponent,
    UserEditComponent,
    UserDeleteComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    AccountModule,
    ScrollToModule.forRoot(),
    RouterModule,
    FormsModule,
    ToastrModule.forRoot(), // ToastrModule added
    ChartsModule,
    LayoutsMicappModule,
    LayoutsDelphyModule,
    LayoutsProjectManagmentModule,
    LayoutsCalcModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    ButtonModule,
    TabMenuModule,
    ToolbarModule,
    PickListModule,
    InputTextModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    CalcModule,
    DelphyModule,
    NgbModule


  ],
  bootstrap: [AppComponent],
  providers: [
    DecimalPipe
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AppModule { }
