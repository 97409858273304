<div class="list-card overflow-hidden">
    <div class="list-header" title="Show projectEl.name">
        <div>
            <h5 class="list-title">Loads</h5>
        </div>
    </div>
    <div class="list-body-dash" [ngStyle]="{'display': projectDELPHYservice.dashboardDis}">
        <div class="row" *ngFor="let el of lcService.lcs">
            <div class="col-sm-12" style="text-align: left;">
                {{el.name}}
            </div>
        </div>
    </div>
    <div class="list-body" [ngStyle]="{'display': projectDELPHYservice.lcDis}">
        <ul class="list-group">
            <li class="list-item" *ngFor="let el of lcService.lcs" (click)="onLcSelected(el)" style="cursor: pointer;" [ngClass]="{'active': selectedLcId == el.id}">
                {{el.name}}
            </li>
        </ul>
    </div>
    <div class="border-top">
        <div class="row card-action">
            <div class="col-sm-12 card-button action-icon" style="text-align: center;">
                <i class="bx bx-plus font-size-22" style="cursor: pointer;" title="New" (click)="openAdd()"></i>
            </div>

            <!-- <div class="col-sm-6 card-button action-icon" style="text-align: center;" *ngIf="projectDELPHYservice.lcDis == 'none'">
                <i style="cursor: pointer;" class="bx bx-dots-horizontal-rounded font-size-22" title="Details" (click)="projectDELPHYservice.onActivate('lc')">
                </i>
            </div> -->

        </div>
    </div>
</div>