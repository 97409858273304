export class Item {
	
    static servletName: string = "servletName";
	static tableNameIdList: string = "itemTableIdList";
	static tableTitleItem: string = "itemTableTitle"
	
    public idItem: number;
	public idForeign: number;
	public itemCode: number;
	public idBLI: number;
	public itemStatus: string;
	public type: string;

	public standardEquipments: boolean;
	public nonEdibleStores: boolean;
	public idJob: number;
	public jobName: string;
	public idProduct: number;
	public productName: string;
	public idDesign: number;
	public designName: string;
	
	public idTopological: number;
	public topologicalName: string;
	public idRevision: number;
	public revisionName: string;
	public firstRev: boolean;
	public lastRev: boolean;
	public enableForCALC: boolean;
	public name: string;

	public estimateWeight: number;
	public margin: number;
	public verifiedWeight: number;
	public idUnitsOfMeasure: number;
	public unitsOfMeasureName: string;
	public quantity: number;

	public weight: number;
	public xg: number;
	public yg: number;
	public zg: number;
	public note: string;
	
	public hasOtherRevisions: boolean;
	public lastUpdateDate: string;
	public tableName: string;
	public idAssembly: number;
	public isTrash: boolean;
	public isDelete: boolean;
	
	public conflictOptCode: number;
	public inseparableOptCode: number;
	public isOptional: boolean;
	
	public hasOtherConflictOptCode: boolean;
	public hasOtherInseparableOptCode: boolean;
	public assemblyHours: number;
	public price: number;
    public checkValue: boolean;
	public matchName: string;
    

	constructor($idItem: number, $idForeign: number, $itemCode: number, $idBLI: number, $itemStatus: string, $type: string, 
		$standardEquipments: boolean, $nonEdibleStores: boolean, $idJob: number, $jobName: string, 
		$idProduct: number, $productName: string, $idDesign: number, $designName: string, $idTopological: number, 
		$topologicalName: string, $idRevision: number, $revisionName: string, $firstRev: boolean, $lastRev: boolean, 
		$enableForCALC: boolean, $name: string, $estimateWeight: number, $margin: number, $verifiedWeight: number, 
		$idUnitsOfMeasure: number, $unitsOfMeasureName: string, $quantity: number, $weight: number, $xg: number, 
		$yg: number, $zg: number, $note: string, $hasOtherRevisions: boolean, $lastUpdateDate: string, 
		$tableName: string, $idAssembly: number, $isTrash: boolean, $isDelete: boolean, $conflictOptCode: number, 
		$inseparableOptCode: number, $isOptional: boolean, $hasOtherConflictOptCode: boolean, $hasOtherInseparableOptCode: boolean, 
		$assemblyHours: number, $price: number, $checkValue: boolean, $matchName: string) {
		this.idItem = $idItem;
		this.idForeign = $idForeign;
		this.itemCode = $itemCode;
		this.idBLI = $idBLI;
		this.itemStatus = $itemStatus;
		this.type = $type;
		this.standardEquipments = $standardEquipments;
		this.nonEdibleStores = $nonEdibleStores;
		this.idJob = $idJob;
		this.jobName = $jobName;
		this.idProduct = $idProduct;
		this.productName = $productName;
		this.idDesign = $idDesign;
		this.designName = $designName;
		this.idTopological = $idTopological;
		this.topologicalName = $topologicalName;
		this.idRevision = $idRevision;
		this.revisionName = $revisionName;
		this.firstRev = $firstRev;
		this.lastRev = $lastRev;
		this.enableForCALC = $enableForCALC;
		this.name = $name;
		this.estimateWeight = $estimateWeight;
		this.margin = $margin;
		this.verifiedWeight = $verifiedWeight;
		this.idUnitsOfMeasure = $idUnitsOfMeasure;
		this.unitsOfMeasureName = $unitsOfMeasureName;
		this.quantity = $quantity;
		this.weight = $weight;
		this.xg = $xg;
		this.yg = $yg;
		this.zg = $zg;
		this.note = $note;
		this.hasOtherRevisions = $hasOtherRevisions;
		this.lastUpdateDate = $lastUpdateDate;
		this.tableName = $tableName;
		this.idAssembly = $idAssembly;
		this.isTrash = $isTrash;
		this.isDelete = $isDelete;
		this.conflictOptCode = $conflictOptCode;
		this.inseparableOptCode = $inseparableOptCode;
		this.isOptional = $isOptional;
		this.hasOtherConflictOptCode = $hasOtherConflictOptCode;
		this.hasOtherInseparableOptCode = $hasOtherInseparableOptCode;
		this.assemblyHours = $assemblyHours;
		this.price = $price;
		this.checkValue = $checkValue;
		this.matchName = $matchName;
	}

}