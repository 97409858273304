import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/account/user.model';
import { ProjectService } from '../../pages/projects/project.service';
import { ProjectCALC } from '../../shared/projectCALC.model';
import { ManageProjectuserComponent } from '../manage-projectuser/manage-projectuser.component';
import { UserDeleteComponent } from '../user-delete/user-delete.component';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { UserService } from '../user.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {
  users: User[];
  projects: ProjectCALC[];
  rowGroupMetadata: any;
  currentUser: User;
  constructor(public projectService: ProjectService,
    public userService: UserService,
    private modalService: NgbModal,
    public cookieService: CookieService) { }

  ngOnInit(): void {
    this.cookieService.set('typeMenu','user');
    this.cookieService.set("topbarTitle","Manage users");
    this.users = [];

    this.projectService.getProjects().subscribe(res => {
      
      this.projects = res;

      // var projAus = [];

      // if(this.currentUser.company.find(comp => comp.name == "MICAD")){
      //   this.projectService.setProjects(this.projects);
      // } else {
      //   // In teoria non contiene MICAD
      //   this.currentUser.company.forEach(com => {
      //     this.projects.forEach(proj => {
      //        if(proj.company.id ==com.id)
      //         projAus.push(proj)
           
      //     });
      //   });
      //   this.projectService.setProjects(projAus);
      // }

      this.projectService.setProjects(this.projects);
      console.log(this.projects);
      this.userService.updateRowGroupMetaData();
    });

    this.userService.getUsers().subscribe(res => {
      
      this.users = res;
      // let cookieValue = this.cookieService.get('loggedInUser');
      // this.currentUser = JSON.parse(cookieValue);
      // var userAus = []
      // // cerco di capire se contiene micad per aggiungerli tutti
      // if(this.currentUser.company.find(comp => comp.name == "MICAD")){
      //   this.userService.setUsers(this.users);
      // } else {
      //   // In teoria non contiene MICAD
      //   this.currentUser.company.forEach(com => {
      //     this.users.forEach(user => {
      //      for (let i = 0; i < user.company.length; i++) {
      //        if(user.company[i].id ==com.id)
      //        userAus.push(user)
      //      }
      //     });
      //   });
      //   this.userService.setUsers(userAus);
      // }
      this.userService.setUsers(this.users);

    });
    
    
  }

  onSort() {
    this.userService.updateRowGroupMetaData();
  }
  onManageUserProject(project: ProjectCALC){
    const modalRef = this.modalService.open(ManageProjectuserComponent);
    modalRef.componentInstance.project = project;
  }

  onNewUser(){
    this.userService.selectedEditMode = false;
    const modalRef = this.modalService.open(UserEditComponent);
  }

  onEditUser(user: User){
    this.userService.selectedEditMode = true;
    const modalRef = this.modalService.open(UserEditComponent);
    modalRef.componentInstance.user = user;
  }

  onDeleteUser(user: User){
    const modalRef = this.modalService.open(UserDeleteComponent);
    modalRef.componentInstance.user = user;
  }


}
