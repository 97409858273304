import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Engine } from '../../../shared/engine.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { EngineEditComponent } from '../engine-edit/engine-edit.component';
import { EngineService } from '../engine.service';

@Component({
  selector: 'app-engine-list',
  templateUrl: './engine-list.component.html',
  styleUrls: ['./engine-list.component.scss']
})
export class EngineListComponent implements OnInit {

  selectedEngineId: number;
  constructor(
    public cookieService: CookieService,
    public engineService: EngineService,
    public modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
    this.engineService.getEngines().subscribe(res => {
      this.engineService.engines = res;
    });
  }

  openAdd() {
    this.engineService.selectedEditMode = false;
    const modalRef = this.modalService.open(EngineEditComponent);
  }

  onEngineSelected(engine: Engine) {
    this.engineService.selectedEngine = engine;
    this.engineService.selectedEngineEvent.emit(engine);
 
    if (engine != undefined)
      this.selectedEngineId = engine.id;
    else
      this.selectedEngineId = undefined;
  }

}
