import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Item } from '../../shared/item.model';
import { ProjectCALC } from '../../shared/projectCALC.model';
import { Variant } from '../../shared/variant.model';
import { VehicleService } from '../vehicles/vehicle.service';

@Injectable({
  providedIn: 'root'
})
export class DibaService {
  allItems: Item[];
  dibaItems: Item[];
  baseItems: Item[];
  verItems: Item[];
  propItems: Item[];
  optItems: Item[];


  jobsFilter: any[] = [];
  productsFilter: any[] = [];
  designsFilter: any[] = [];
  unitsOfMeasuresFilter: any[] = [];
  topologicalsFilter: any[] = [];

  rowGroupMetadata: any;
  expandedGroups: any;

  constructor(private vehicleService: VehicleService,
    public cookieService: CookieService) { }


  getDibaItems(variant: Variant) {
    this.allItems = [];
    this.dibaItems = [];
    this.vehicleService.getItems('BaseLineItem', +this.cookieService.get(ProjectCALC.idProjectSelected)).subscribe(res => {
      this.baseItems = res['Records'];
      this.helperOrderPush(this.baseItems);
      this.allItems = this.allItems.concat(this.baseItems);
    });
    this.vehicleService.getItems('versionItem', variant.idVersionList).subscribe(res => {
      this.verItems = res['Records'];
      this.helperOrderPush(this.verItems);
      this.allItems = this.allItems.concat(this.verItems);
    });
    this.vehicleService.getItems('propulsionItem', variant.idPropulsionList).subscribe(res => {
      this.propItems = res['Records'];
      this.helperOrderPush(this.propItems);
      this.allItems = this.allItems.concat(this.propItems);
    });
    this.vehicleService.getItems('optItem', variant.idOptList).subscribe(res => {
      this.optItems = res['Records'];
      this.helperOrderPush(this.optItems);
      this.allItems = this.allItems.concat(this.optItems);
    });
    setTimeout(() => {


      this.allItems.forEach(item => {

        this.jobsFilter.push({ label: item.jobName, value: item.jobName });
        this.productsFilter.push({ label: item.productName, value: item.productName });
        this.topologicalsFilter.push({ label: item.topologicalName, value: item.topologicalName });
        this.unitsOfMeasuresFilter.push({ label: item.unitsOfMeasureName, value: item.unitsOfMeasureName });
        this.designsFilter.push({ label: item.designName, value: item.designName });

      });






      console.log("this.dibaItems");
      console.log(this.dibaItems);

      this.updateRowGroupMetaData();
    }, 1500);







  }


  helperOrderPush(items: Item[]) {
    items.sort((a, b) => {
      return a.name > b.name
        ? 1
        : a.name < b.name
          ? -1
          : 0;
    });
    // console.log("items");
    // console.log(items);
    // filtro per nome, poi elimino gli item 
    items.forEach((item) => {
      console.log("item");
        console.log(item);
      var filteredItems = items.filter(itemFilter => itemFilter.name == item.name);
      if (filteredItems.length > 1) {
        // console.log(filteredItems);
        var itemVar = { ...filteredItems[0] };
        itemVar.quantity = 0;
        filteredItems.forEach(element => {
          itemVar.quantity = itemVar.quantity + element.quantity;
        });
        this.dibaItems.push(itemVar);
        filteredItems.forEach(element => {
          const index: number = items.indexOf(element);
          if (index !== -1) {
            items.splice(index, 1);
          }
        });

        
      } else {
        
        this.dibaItems.push(item);
      }

    });
  }






  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    for (let i = 0; i < this.dibaItems.length; i++) {
      let rowData = this.dibaItems[i];
      let tableName = rowData.tableName;
      // if (tableName != 'Default') {
      if (i == 0) {
        this.rowGroupMetadata[tableName] = { index: 0, size: 1 };
      }
      else {
        let previousRowData = this.dibaItems[i - 1];
        let previousRowGroup = previousRowData.tableName;
        if (tableName === previousRowGroup)
          this.rowGroupMetadata[tableName].size++;
        else
          this.rowGroupMetadata[tableName] = { index: i, size: 1 };
      }
      // }
    }

    this.expandedGroups = {};

  }

}
