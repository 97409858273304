<div class="container-fluid">
    <!-- <app-page-title title="Vehicles Grid" [breadcrumbItems]="breadCrumbItems"></app-page-title> -->
    <div class="row">
        <div class="col-xl-12">

            <div class="row">

                <div class="col-lg-10">
                    <h4 class="card-title mb-4">Select user to add in {{project.projectName}}</h4>
                </div>
                <div class="col-lg-2">
                    <button type="button" class="close" aria-label="Close" title="Close" (click)="userService.onCloseForm()">
                                 <span aria-hidden="true"> &times;</span>
                    </button>
                </div>
            </div>

            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton type="button" label="Update users list" (click)="onSendSelectedUsers()" [disabled]="!selectedUsers || !selectedUsers.length">
                            </button>
                </ng-template>

            </p-toolbar>
            <!-- <div class="col-xl-9"> -->
            <p-table #dt [value]="userService.users" [scrollable]="true" [paginator]="true" [rows]="25" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50,100,500,1000,10000]"
                dataKey="email" [(selection)]="selectedUsers" [rowHover]="true">



                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col style="width: 50px">
                        <col *ngFor="let col of columns" [ngStyle]="{ 'width': col.width}">

                    </colgroup>
                </ng-template>



                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                        </th>

                        <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Name
                                <p-sortIcon field="name"></p-sortIcon>
                                <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Surname
                                <p-sortIcon field="surname"></p-sortIcon>
                                <p-columnFilter type="text" field="surname" display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Email
                                <p-sortIcon field="email"></p-sortIcon>
                                <p-columnFilter type="text" field="email" display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Company
                                <p-sortIcon field="company"></p-sortIcon>
                                <p-columnFilter type="text" field="company" display="menu"></p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Role
                                <p-sortIcon field="role"></p-sortIcon>
                                <p-columnFilter type="text" field="role" display="menu"></p-columnFilter>
                            </div>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-user let-columns="columns">
                    <tr *ngIf="user.roleName != 'Company Owner'">
                        <td>
                            <p-tableCheckbox [value]="user" (click)="onSelectAlgorithm(user)"></p-tableCheckbox>
                        </td>
                        <td title="{{user.name}}">
                            {{user.name}}
                        </td>
                        <td title="{{user.surname}}">
                            {{user.surname}}
                        </td>
                        <td title="{{user.email}}">
                            {{user.email}}
                        </td>
                        <td>
                            <div *ngFor="let comp of user.company" style="padding-right: 2px;">
                                {{comp.name}}
                            </div>
                        </td>
                        <td title="{{user.roleName}}">
                            {{user.roleName}}
                        </td>
                    </tr>
                </ng-template>


            </p-table>

        </div>




    </div>

    <!-- end row -->
</div>