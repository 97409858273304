import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppendageSet } from '../../../shared/appendageset.model';
import { AppendageService } from '../appendage.service';

@Component({
  selector: 'app-appendage-set-delete',
  templateUrl: './appendage-set-delete.component.html',
  styleUrls: ['./appendage-set-delete.component.scss']
})
export class AppendageSetDeleteComponent implements OnInit {

  @Input() public appendageSet: AppendageSet;
  constructor(public appendageService: AppendageService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.appendageService.trashAppendageSet();
   
  }

}
