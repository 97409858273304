import { Component, OnInit } from '@angular/core';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { PropellerService } from '../propeller.service';

@Component({
  selector: 'app-propeller-dashboard',
  templateUrl: './propeller-dashboard.component.html',
  styleUrls: ['./propeller-dashboard.component.scss']
})
export class PropellerDashboardComponent implements OnInit {

  constructor(public projectDELPHYservice: ProjectDELPHYService,
    public propellerService: PropellerService) { }

  ngOnInit(): void {
  }

}
