<div class="card booking-card">
    <!-- Card content -->
    <div class="card-body">
        <div class="row">
            <div class="col-sm-2">
            </div>
            <div class="col-sm-8">
                <h4 class="card-title font-weight-bold  card-details"><a>{{vehicle.regNumberName}}</a></h4>
            </div>
            <div class="col-sm-2">
                <button type="button" class="close" aria-label="Close" title="Close" (click)="vehicleService.onCloseDetails()">
                <span aria-hidden="true"> &times;</span>
            </button>
            </div>
        </div>


        <!-- Card image -->
        <div class="view overlay">
            <img class="card-img-top" src="assets/images/hull.svg" alt="Card image cap">
            <a href="#!">
                <div class="mask rgba-white-slight"></div>
            </a>
        </div>

        <div class="border-top" *ngIf="(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">
            <div class="row card-action">
                <div class="col-sm-6 card-button">
                    <i class="bx bxs-edit-alt font-size-22" title="Edit" (click)="onEdit()" style="cursor: pointer;"></i>
                    <div class="textUnderIcon">Edit</div>
                </div>
                <div class="col-sm-6 card-button">
                    <i class="bx bxs-trash-alt font-size-22" title="Delete" (click)="onTrash()" style="cursor: pointer;"></i>
                    <div class="textUnderIcon">Delete</div>
                </div>
            </div>
        </div>

        <hr>
        <div class="row" style="margin-top:5px">
            <div class="col-sm-6 card-details">
                <strong>Start Date</strong>
            </div>
            <div class="col-sm-6 card-details">
                <strong>End Date</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6  card-details">
                {{vehicle.startDate}}
            </div>
            <div class="col-sm-6 card-details">
                {{vehicle.endDate}}
            </div>
        </div>




        <hr class="my-4">
        <div class="row" style="margin-top:5px">
            <div class="col-sm-12 card-details">
                <strong>Note</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 card-details">
                {{vehicle.note}}
            </div>
        </div>

    </div>

</div>