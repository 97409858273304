import { Component, Input, OnInit } from '@angular/core';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { Lc } from '../../../shared/lc.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { LcService } from '../lc.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { LcDeleteComponent } from '../lc-delete/lc-delete.component';
import { LcEditComponent } from '../lc-edit/lc-edit.component';

@Component({
  selector: 'app-lc-details',
  templateUrl: './lc-details.component.html',
  styleUrls: ['./lc-details.component.scss']
})
export class LcDetailsComponent implements OnInit {

  lc: Lc;
  project: ProjectDELPHY;

  constructor(
    public cookieService: CookieService,
    public lcService: LcService,
    private modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

    ngOnInit(): void {
   
      if(this.lcService.selectedLc == undefined){
        this.lcService.selectedLcEvent.subscribe(
          (selectedLc: Lc) => {
            this.lc = selectedLc;
          }
        );
      }
    }

  onEdit() {
    this.lcService.selectedEditMode = true;
    const modalRef = this.modalService.open(LcEditComponent);
    modalRef.componentInstance.lc = this.lcService.selectedLc;
  }

  onTrash() {
    const modaldelete = this.modalService.open(LcDeleteComponent);
    modaldelete.componentInstance.lc = this.lcService.selectedLc;
  }

}