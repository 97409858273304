<div class="container-fluid">
    <div class="textUnderIcon">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; font-weight: bold;">Projects dashboard – Online Help</h5>
                    </div>
                </div>
            </div>


            <div class="card-body-help pt-0">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="numberReq">


                                <div class="row" style="padding: 0px 10px 0px 10px;">

                                    <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h5>Features on sidebar: Lists</h5>
                                                <hr>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6" style="cursor: pointer">
                                                <div><i class="bx bx-wrench font-size-20"></i>
                                                    <h6 class="list-name-help">Job</h6>
                                                    <h6 class="font-size-11">Allows items to be classified by manufacturing category</h6>
                                                </div>
                                            </div>
                                            <div class="col-6" style="cursor: pointer">
                                                <i class="bx bx-atom font-size-20"></i>
                                                <h6 class="list-name-help">Topological</h6>
                                                <h6 class="font-size-11">Allows items to be classified according to the zone where they are placed</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6" style="cursor: pointer">
                                                <i class="bx bx-brush font-size-20"></i>
                                                <h6 class="list-name-help">Design</h6>
                                                <h6 class="font-size-11">Allows items to be classified according to drawing category</h6>
                                            </div>
                                            <div class="col-6" style="cursor: pointer;">
                                                <i class="bx bx-ruler font-size-20"></i>
                                                <h6 class="list-name-help">Units of measure</h6>
                                                <h6 class="font-size-11">Allows to set different units of measurement for projects</h6>
                                            </div>
                                        </div>
                                    </div>




                                    <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h5>Buttons Features</h5>
                                                <hr>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4" style="cursor: pointer">
                                                <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                                    <i class="fas fa-plus"></i>
                                                </button>
                                                <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                            </div>

                                            <div class="col-4">
                                                <i class="bx bxs-save font-size-20"></i>
                                                <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                            </div>
                                            <div class="col-4">
                                                <i class="bx bxs-edit-alt font-size-20"></i>
                                                <h6 class="font-size-13">Edit information on specific card</h6>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <i class="bx bxs-copy font-size-20"></i>
                                                <h6 class="font-size-13">Clone feature, for entire projects, lists or items</h6>
                                            </div>
                                            <div class="col-4">
                                                <i class="bx bxs-trash-alt font-size-20"></i>
                                                <h6 class="font-size-13">Delete feature, for entire projects, lists or items</h6>
                                            </div>
                                        </div>
                                    </div>




                                    <div class="col-6">
                                        <h5>How to add new project</h5>
                                        <hr>
                                        <ol style="text-align: left;">

                                            <li>
                                                <h6>Customise the lists to be used for each project</h6>
                                            </li>

                                            <li>
                                                <h6>Add or edit a project</h6>
                                            </li>

                                            <li>
                                                <h6>Insert the project information and assign to a Company</h6>
                                            </li>

                                            <li>
                                                <h6>Click on the project and enter in “Vehicle page”</h6>
                                            </li>

                                            <li>
                                                <h6>Different combinations can be created to assemble a vehicle. A default vehicle (#1) will be created automatically</h6>
                                            </li>

                                            <li>
                                                <h6>Edit the project details</h6>
                                            </li>

                                            <li>
                                                <h6>The Vehicle name follows the Reg Numbers present in specific list, customize the list to change the vehicle name.</h6>
                                            </li>
                                        </ol>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row">
        <div [ngClass]="{'col-xl-9': projectService.selectedProject != undefined ,'col-xl-12':projectService.selectedProject == undefined }">
            <div *ngFor="let company of projectService.companies">
                <h4 class="company-name"> {{company.name}}</h4>
                <div class="row">
                    <div [ngClass]="{'col-xl-4':  projectService.selectedProject != undefined ,'col-xl-3':projectService.selectedProject == undefined }" *ngFor="let projectEl of projects | filterProjectCALCCompanyPipe:company.name">

                        <div class="card text-white card-has-bg click-col" style="background-image:url({{projectEl?.img}})">
                            <!-- <div class="card text-white card-has-bg click-col"> -->
                            <img class="card-img d-none" src="https://source.unsplash.com/600x900/?tech,street" alt="Goverment Lorem Ipsum Sit Amet Consectetur dipisi?">
                            <div class="card-img-overlay d-flex flex-column">
                                <div class="card-body" (click)="onVehicles(projectEl)" style="cursor: pointer;">
                                    <!-- <small class="card-meta mb-2">{{projectEl?.projectName}}</small> -->
                                    <h4 class="card-title mt-0"><a class="text-white" herf="#">{{projectEl?.projectName}}</a></h4>
                                    <small><i class="far fa-clock"></i> {{projectEl?.startDate}}</small>
                                </div>
                                <div class="card-footer">

                                    <div class="row">
                                        <div class="col-sm-3">
                                            <img class="mr-3 rounded-circle" src={{projectEl?.imgProjectOwner}} style="max-width:50px">

                                        </div>
                                        <div class="col-sm-6" style="text-align: center; padding-top: 15px;">
                                            <i style="cursor: pointer;" class="bx bx-dots-horizontal-rounded font-size-22" (click)="onProjectSelected(projectEl)" title="Details"></i>
                                            <div class="textUnderIcon">Details</div>

                                        </div>
                                        <div class="col-sm-3"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- <div [ngClass]="{ 'col-xl-9': (projectService.selectedProject !=undefined || vehicleService.selectedVehicle !=undefined), 'col-xl-12':(projectService.selectedProject==undefined || vehicleService.selectedVehicle==undefined)}" *ngIf="!projectService.switch"
            style="height: 100%;">
            <app-treegraph>
            </app-treegraph>
        </div> -->
        <div class="col-xl-3" *ngIf="projectService.selectedProject!=undefined">
            <app-project-details></app-project-details>
            <!-- <app-variant-details *ngIf="projectService.selectedProject!=undefined; else infoText"></app-variant-details> -->
        </div>

    </div>













    <!-- non cancellare , switch per il tree graph
        <div class="row" style="padding-bottom:10px;">

        <div class="col-xl-11">
            <i class="bx bxs-grid font-size-22" style="vertical-align: middle;"></i>
            <label class="switch" title="Switch between card view and tree view">
                <input type="checkbox"  (click)="onSwitch()" >
                <span class="slider  round"></span>
            </label>
            <i class="bx bx-sitemap font-size-22" style="vertical-align: middle;"></i>
        </div>
        <div class="col-xl-1">

        </div>
    </div> -->


    <!-- <div class="row">
        <div [ngClass]="{'col-xl-9': projectService.selectedProject != undefined ,'col-xl-12':projectService.selectedProject == undefined }" *ngIf="projectService.switch">
            <div class="row">
                <div [ngClass]="{'col-xl-4':  projectService.selectedProject != undefined ,'col-xl-3':projectService.selectedProject == undefined }" *ngFor="let projectEl of projects">
                    <div class="card overflow-hidden">

                        <div class="bg-soft-primary" style="cursor: pointer;" (click)="onVehicles(projectEl)" title="Show {{projectEl.projectName}}'s vehicles">
                            <div class="row">
                                <div class="col-sm-4">
                                    <img class="rounded-circle imgCardDetails img-thumbnail rounded-circle" src="assets/images/hull.svg">
                                </div>

                                <div class="col-sm-8">
                                    <div>
                                        <h5 style="color: white; padding-top: 35px; font-weight: bold;">{{projectEl.projectName}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row" style="margin-top:5px">
                                <div class="col-sm-12" style="font-weight: bold; text-align: center;">
                                    Note
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12" style="text-align: center;">
                                    {{projectEl.note | shorten: 120}}
                                </div>
                            </div>
                        </div>

                        <div class="border-top" *ngIf="(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">
                            <div class="row card-action">
                                <div class="col-sm-3 card-button">
                                    <i class="bx bxs-edit-alt font-size-22" (click)="onEdit(projectEl)" style="cursor: pointer;" title="Edit"></i>
                                    <div class="textUnderIcon">Edit</div>
                                </div>

                                <div class="col-sm-3 card-button">
                                    <i style="cursor: pointer;" class="bx bx-dots-horizontal-rounded font-size-22" (click)="onProjectSelected(projectEl)" title="Details">
                                    </i>
                                    <div class="textUnderIcon">Details</div>
                                </div>

                                <div class="col-sm-3 card-button">
                                    <i style="cursor: pointer;" class="bx bxs-copy font-size-22" (click)="onCloneProject(projectEl)" title="Clone">
                                    </i>
                                    <div class="textUnderIcon">Clone</div>
                                </div>

                                <div class="col-sm-3 card-button">
                                    <i style="cursor: pointer;" class="bx bxs-trash-alt font-size-22" (click)="onTrash(projectEl)" title="Delete"></i>
                                    <div class="textUnderIcon">Delete</div>
                                </div>

                            </div>
                        </div>


                        <div class="border-top" *ngIf="!(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">
                            <div class="row card-action">

                                <div class="col-sm-12 card-button">
                                    <i style="cursor: pointer;" class="bx bx-dots-horizontal-rounded font-size-22" (click)="onProjectSelected(projectEl)" title="Details">
                                    </i>
                                </div>

                            </div>
                        </div>







                    </div>


                </div>
            </div>



        </div>

        <div [ngClass]="{ 'col-xl-9': (projectService.selectedProject !=undefined || vehicleService.selectedVehicle !=undefined), 'col-xl-12':(projectService.selectedProject==undefined || vehicleService.selectedVehicle==undefined)}" *ngIf="!projectService.switch"
            style="height: 100%;">
            <app-treegraph>
            </app-treegraph>
        </div>
        <div class="col-xl-3">

            <app-project-details *ngIf="projectService.selectedProject!=undefined"></app-project-details>
            <app-vehicle-details *ngIf="vehicleService.selectedVehicle!=undefined"></app-vehicle-details>
        </div>
    </div> -->


</div>


<div class="floating-action-button" (click)="openAdd()" *ngIf="(cookieService.get('userRole') == accountService.companyOwner || cookieService.get('userRole') == accountService.projectOwner)">
    <button type="button" class="btn btn-primary btn-circle btn-lg" id="addBtn">
        <i class="fas fa-plus" title="New"></i>
        <div class="textUnderIcon">New</div>
    </button>
</div>