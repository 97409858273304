import { Component, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { LcService } from '../lc.service';

@Component({
  selector: 'app-lc-plots',
  templateUrl: './lc-plots.component.html',
  styleUrls: ['./lc-plots.component.scss']
})
export class LcPlotsComponent implements OnInit {

  @ViewChild('lcsCanvas') lcsCanvas;

  elChart: Chart;

  constructor(public lcService: LcService) { }
 
  ngOnInit(): void {
    this.lcService.lcsChanged.subscribe(() => {
      this.lcService.onInitGraph();
      this.chartMethod(this.lcsCanvas, this.lcService.graph[0], this.lcService.graph[1]);
    });
  }


  chartMethod(elCanvas: any, labelsData: string[], dataData: number[]) {
    // console.log("labelsData");
    // console.log(labelsData);

   let dataSets = [];
   for (let i = 0; i < dataData.length; i++) {
     dataSets.push({
      label: labelsData[i],
      data: [dataData[i]],
      backgroundColor: this.getRandomColorHover(),
      fill: false
     });
   }


   if(this.elChart != undefined){

    this.elChart.data.labels.splice(0); // remove the label first
    this.elChart.data.datasets.splice(0);

    this.elChart.data.labels = labelsData;
    this.elChart.data.datasets = dataSets;
    this.elChart.update();

   } else {
    this.elChart = new Chart(elCanvas.nativeElement, {
      type: 'bubble',
      options: {
        title: {
          display: false,
        },
        legend: {
          position: 'top',
          labels: {
            fontSize: 14
          }
        },
        rotation: -0.1
      },
      data: {
        labels: labelsData,
        datasets: dataSets
      }
    });
   }



    
  }

  getRandomColorHover() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',0.3)';
  }

}
