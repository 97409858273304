import { Pipe, PipeTransform } from '@angular/core';
import { ProjectCALC } from './CALC/shared/projectCALC.model';

@Pipe({
  name: 'filterProjectCALCCompanyPipe',
  pure: false
})
export class FilterProjectsCALCPipe implements PipeTransform {
  projectsFiltered: ProjectCALC[] = [];
  transform(projects: ProjectCALC[], company: string): ProjectCALC[] {



    if (projects == undefined)
      return null;

    if (company != undefined && projects != undefined ) {

      this.projectsFiltered = projects.filter(
        project =>
        project.company.name == company
      );

    }

    return this.projectsFiltered;
  }

}
