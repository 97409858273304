import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Appendage } from '../../../shared/appendage.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { AppendageService } from '../appendage.service';

@Component({
  selector: 'app-appendage-osborne',
  templateUrl: './appendage-osborne.component.html',
  styleUrls: ['./appendage-osborne.component.scss']
})
export class AppendageOsborneComponent implements OnInit {

  constructor(public cookieService: CookieService,
    public projectDELPHYservice: ProjectDELPHYService,
    public appendageService: AppendageService) { }


  ngOnInit(): void {


    if(this.appendageService.selectedAppendage == undefined){
      this.appendageService.selectedAppendageEvent.subscribe(
        (selectedAppendage: Appendage) => {
          
          this.appendageService.getOsbornes(selectedAppendage).subscribe(res => {
            this.appendageService.osbornes = res;
            console.log("res");
            console.log(res);
            this.appendageService.osbornesChanged.next(this.appendageService.osbornes);
         
          });
          
        }
      );
    }
 
  }

}
