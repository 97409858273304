import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hull-plots',
  templateUrl: './hull-plots.component.html',
  styleUrls: ['./hull-plots.component.scss']
})
export class HullPlotsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
