<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
    <perfect-scrollbar [config]="configData">
  
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
  
          <li class="menu-title">Project managment suite</li>
          <!-- Layouts menu -->
  
          <!-- end Layout menu -->
  
          <li>
  
            
  
            <a [routerLink]="['dashboard']" class="side-nav-link-ref" routerLinkActive="active">
              <i class="bx bxs-dashboard font-size-22"></i>
              <span>Dashboard</span>
            </a>
  
            <a routerLink="approvals" class="side-nav-link-ref" routerLinkActive="active">
              <i class="bx bx-send font-size-22"></i>
              <span>Projects</span>
            </a>
  
  
            <a routerLink="tasks" class="side-nav-link-ref" routerLinkActive="active">
              <i class="bx bx-import font-size-22"></i>
              <span>Tasks</span>
            </a>
  
  
          </li>
  
        </ul>
      </div>
      <!-- Sidebar -->
  
  
  
    </perfect-scrollbar>
  </div>
  <!-- Left Sidebar End -->