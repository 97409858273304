import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Engine } from '../../shared/engine.model';
import { ProjectDELPHY } from '../../shared/projectDELPHY.model';

@Injectable({
  providedIn: 'root'
})
export class EngineService {

  selectedEditMode: boolean;
  error = new Subject<string>();
  engines: Engine[];
  selectedEngine: Engine;
  selectedEngineEvent = new EventEmitter<Engine>();

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  createEngine(engine: Engine) {
    console.log(" dopo form engine");
    console.log(engine);
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ engine: Engine }>(
        this.accountService.REST_API_SERVER_DELPHY + 'engine/',
        engine
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          engine.id = responseData["id"];
          engine.date = responseData["date"];
          // this.engines.unshift(engine);
          this.engines.push(engine);
          this.toastr.success('Engine created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  updateEngine(engine: Engine) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .put<{ engine: Engine }>(
        this.accountService.REST_API_SERVER_DELPHY + 'engine/',
        engine
      )
      .subscribe(responseData => {
        let itemIndex = this.engines.findIndex(item => item.id == this.selectedEngine.id);
        this.engines[itemIndex] = engine;
        this.selectedEngine = engine;
        this.selectedEngineEvent.next(engine);
        this.toastr.success('Engine  updated');
      },
        error => {
          this.toastr.error(error.message);
            console.log(error.message);
            // this.error.next(error.message);
        });

  }

  trashEngine() {
   
    this.http
      .delete(this.accountService.REST_API_SERVER_DELPHY 
        + 'engine?id=' + this.selectedEngine.id)
        .subscribe(responseData => {
          if (responseData != undefined) {
            const index = this.engines.findIndex(x => x.id === this.selectedEngine.id);
            this.engines.splice(index, 1);
            this.toastr.success('This engine has been deleted', 'Engine deleted');
            this.selectedEngine = undefined;
            this.selectedEngineEvent.next(undefined);
          } else {
            this.error.next(responseData["Result"].message);
          }
        },
          error => {
            this.error.next(error.message);
          });

  }

  public getEngines(): Observable<Engine[]> {
    return this.http.get<Engine[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'engine/?project_id=' + this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY)
    )
      .pipe(
      );
  }

  getEngine(id: number) {
    if (this.engines == undefined)
      return undefined;
    const el = this.engines.find(
      (r) => {
        return r.id == id;
      }
    );
    return el;
  }

  onCloseForm() {
    this.modalService.dismissAll();
  }
}
