import { Component, EventEmitter, OnDestroy, OnInit, Output, ɵisListLikeIterable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ProjectService } from '../project.service';
import { CookieService } from 'ngx-cookie-service';
import { ProjectDeleteComponent } from '../project-delete/project-delete.component';
import { ProjectEditComponent } from '../project-edit/project-edit.component';
import { AccountService } from 'src/app/account/account.service';
import { ProjectDELPHY } from '../../shared/projectDELPHY.model';
import { ProjectDELPHYService } from '../../project/project-delphy.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  subscription: Subscription;
  projects: ProjectDELPHY[];
  // incomingFilter: ProjectFilter = new ProjectFilter("incoming", "INCOMING", "NONE");

  @Output() projectWasSelected = new EventEmitter<ProjectDELPHY>();

  // filters: ProjectFilter;
  selectedProjectId: string;


  constructor(
    public projectService: ProjectService,
    public projectDELPHYService: ProjectDELPHYService,
    private router: Router,
    private modalService: NgbModal,
    public cookieService: CookieService,
    public accountService: AccountService
  ) {
    this.cookieService.set('typeMenu', 'project');
    
  }


  ngOnInit() {
    
    this.projectService.deleteFromCookie();
    this.projectService.selectedProject = undefined;
    this.projectService.switch = true;
    this.projectService.getProjects().subscribe(res => {
      
      this.projects = res;
      this.projectService.setProjects(this.projects);
      console.log(this.projects);
    });
    
  }

  ngAfterViewInit() {
    
   
  }


  openAdd() {
    this.projectService.selectedEditMode = false;
    const modalRef = this.modalService.open(ProjectEditComponent);
  }


  onEdit(project: ProjectDELPHY) {
    this.projectService.selectedEditMode = true;
    const modalRef = this.modalService.open(ProjectEditComponent);
    modalRef.componentInstance.project = project;
  }


  onCloneProject(project: ProjectDELPHY){
    this.projectService.selectedEditMode = true;
    // const modalRef = this.modalService.open(ProjectCloneComponent);
    // modalRef.componentInstance.project = project;
  }

  onProjectSelected(project: ProjectDELPHY) {

    this.projectService.selectedProjectEvent.next(project);
    this.projectService.selectedProject = project;
    this.cookieService.set(ProjectDELPHY.selectedProjectDELPHY, JSON.stringify(project));
  }

  onTrash(projectDelete: ProjectDELPHY) {
    const modaldelete = this.modalService.open(ProjectDeleteComponent);
    modaldelete.componentInstance.project = projectDelete;
  }

  onProject(project: ProjectDELPHY) {
    // routing to vehicles
    this.projectDELPHYService.onActivate('dashboard');
    // aggiungere al cookie il projectEl o un emmiter per i vehicles
    this.cookieService.set(ProjectDELPHY.selectedProjectDELPHY, JSON.stringify(project));
    this.cookieService.set(ProjectDELPHY.idProjectSelectedDELPHY, JSON.stringify(project.id));


    // aggiungere un evento per cambiare la sidebar
    this.cookieService.set('typeMenu', 'projectDELPHY');
    this.projectService.selectedProjectEvent.next(project);
    this.projectService.selectedProject = project;
    this.router.navigate(['../DELPHY/projectDELPHY']);
  }


  onSwitch() {
    this.projectService.selectedProject=undefined;
    this.projectService.switch = !this.projectService.switch;

  }




}
