<div class="row full-row">
    <div class="col-on-five">
        <div>
            <app-vehicle-list></app-vehicle-list>
        </div>
        <hr>
        <div>
            <app-vehicle-details></app-vehicle-details>
        </div>


    </div>
    <div class="col-two-on-five">

        <app-vehicle-daniel></app-vehicle-daniel>

    </div>
    <div class="col-two-on-five">

        <app-vehicle-plots></app-vehicle-plots>

    </div>
</div>