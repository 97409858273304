<div class="container-fluid">




    <div class="textUnderIcon">

        <div class="card overflow-hidden noScale" style="height: 280px;">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 class="card-variant-title">
                            Report – Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-6" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <div><i class="bx bx-wrench font-size-20"></i>
                                                <h6 class="list-name-help">Version list</h6>
                                                <h6 class="font-size-11">list of version names, it is possible to add items in the specific version by entering the specific list</h6>
                                            </div>
                                        </div>
                                        <div class="col-4" style="cursor: pointer">
                                            <i class="bx bx-atom font-size-20"></i>
                                            <h6 class="list-name-help">Propulsion lists</h6>
                                            <h6 class="font-size-11">list of propulsion names, it is possible to add items for the specific propulsion by entering the specific list</h6>
                                        </div>

                                        <div class="col-4" style="cursor: pointer">
                                            <i class="bx bx-brush font-size-20"></i>
                                            <h6 class="list-name-help">Optional Items</h6>
                                            <h6 class="font-size-11">list of options collection names, it is possible to add items in the specific options collection by entering the specific list</h6>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm 12">
                                            <h5>Summary</h5>
                                            <hr>
                                        </div>

                                    </div>
                                    <div class="row" style="text-align: left;">
                                        <div class="col-12">

                                            <h6>The Reports page includes, in the left-hand section, a summary of the lists used to combine the specific Variant as well as a summary of the options included in the selected Optional List.
                                            </h6>

                                            <h6>In the right-hand section of the page there is a bubble graph showing each item as a bubble: the colour is a function of the Job category, the diameter is a function of the assigned weight and the position is
                                                shown according to the reference axes.
                                            </h6>

                                            <ul>
                                                <li>
                                                    <h6>The load conditions are calculated according to the chosen reference standard.</h6>
                                                </li>
                                                <li>
                                                    <h6>You can export the report as a pdf by clicking on the appropriate button.</h6>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>









    <div class="row">
        <div class="col-xl-12 card">
            <h2 style="text-align: -webkit-center;">
                {{reportService.project?.projectName}}
            </h2>
            <h2 style="text-align: -webkit-center;">
                {{reportService.vehicle?.regNumberName}}
            </h2>
        </div>
    </div>



    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">

                    <div class="row">
                        <div class="col-6">
                            <h4 class="card-title mb-4">Project Details:</h4>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <button type="button" style="padding: 5px; font-size: 9px; box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.25);" class="btn btn-primary" (click)="reportService.onExportPdf()">Export Report PDF</button>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            Length:
                        </div>
                        <div class="col-sm-6">
                            {{reportService.project?.length | number : '1.1-2'}}m
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            Beam:
                        </div>
                        <div class="col-sm-6">
                            {{reportService.project?.beam | number : '1.1-2'}}m
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-sm-6">
                            Variant name:
                        </div>
                        <div class="col-sm-6" style="cursor: pointer;" (click)="onVariantSelectedReport(reportService.variant)">
                            {{reportService.variant?.variantName}}
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-6">
                            Version list:
                        </div>
                        <div class="col-sm-6" style="cursor: pointer;" (click)="onListSelectedReport('versionList',reportService.variant.idVersionList, reportService.variant.versionListName)">
                            {{reportService.variant?.versionListName}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            Propulsion list:
                        </div>
                        <div class="col-sm-6" style="cursor: pointer;" (click)="onListSelectedReport('propulsionList',reportService.variant.idPropulsionList, reportService.variant.propulsionListName)">
                            {{reportService.variant?.propulsionListName}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            Optional items list:
                        </div>
                        <div class="col-sm-6" style="cursor: pointer;" (click)="onListSelectedReport('optList',reportService.variant.idOptList, reportService.variant.optListName)">
                            {{reportService.variant?.optListName}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body ">
                    <h4 class="card-title mb-4">{{reportService.optNumber}} optional items in list: {{reportService.variant?.optListName}}</h4>
                    <div class="scrollListOpt">

                        <div class="row " *ngFor="let opt of reportService.optsNameReport;">
                            <div class="col-sm-12">
                                {{opt | shorten: 30}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card">
                <div id="bubbleChart" style="height: 500px; width: 98%; padding:7px"></div>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Conditions</h4>
                    <div class="table-responsive">
                        <table id="reportTable" class="table table-nowrap table-centered mb-0">
                            <tbody>
                                <th scope="col">Name</th>
                                <th scope="col">Weight [Kg]</th>
                                <th scope="col">Xg [m]</th>
                                <th scope="col">Yg [m]</th>
                                <th scope="col">Zg [m]</th>

                                <tr *ngFor="let condition of reportService.reports">

                                    <td style="min-width: 250px; font-weight: bold;" *ngIf="condition.conditionName.includes('Condition') 
                                                                        || condition.conditionName == 'Crew'
                                                                        || condition.conditionName == 'Liquids'
                                                                        || condition.conditionName == 'Optional'
                                                                        || condition.conditionName == 'Maximum Load (ISO)'">
                                        {{condition.conditionName}}
                                    </td>

                                    <td style="min-width: 150px; font-weight: bold;" *ngIf="condition.conditionName.includes('Condition') 
                                                                                            || condition.conditionName == 'Optional'
                                                                                            || condition.conditionName == 'Maximum Load (ISO)'">
                                        {{condition.weight | number : '1.0-0' | noComma}}
                                    </td>
                                    <td style="min-width: 50px; font-weight: bold;" *ngIf="condition.conditionName.includes('Condition')
                                    || condition.conditionName == 'Optional'
                                    || condition.conditionName == 'Maximum Load (ISO)'">
                                        {{condition.xg | number : '1.2-2'}}
                                    </td>
                                    <td style="min-width: 50px; font-weight: bold;" *ngIf="condition.conditionName.includes('Condition') 
                                    || condition.conditionName == 'Optional'
                                    || condition.conditionName == 'Maximum Load (ISO)'">
                                        {{condition.yg | number : '1.2-2'}}
                                    </td>
                                    <td style="min-width: 50px; font-weight: bold;" *ngIf="condition.conditionName.includes('Condition')
                                    || condition.conditionName == 'Optional'
                                    || condition.conditionName == 'Maximum Load (ISO)'">
                                        {{condition.zg | number : '1.2-2'}}
                                    </td>


                                    <td style="min-width: 250px; padding-left:3em" *ngIf="!(condition.conditionName.includes('Condition') 
                                    || condition.conditionName == 'Crew'
                                    || condition.conditionName == 'Liquids'
                                    || condition.conditionName == 'Optional'
                                    || condition.conditionName == 'Maximum Load (ISO)')">
                                        {{condition.conditionName}}
                                    </td>

                                    <td style="min-width: 150px;" *ngIf="!(condition.conditionName.includes('Condition') 
                                    || condition.conditionName == 'Crew'
                                    || condition.conditionName == 'Liquids'
                                    || condition.conditionName == 'Optional'
                                    || condition.conditionName == 'Maximum Load (ISO)')">
                                        {{condition.weight | number : '1.0-2' | noComma}}
                                    </td>

                                    <td style="min-width: 50px;" *ngIf="!(condition.conditionName.includes('Condition') 
                                    || condition.conditionName == 'Crew'
                                    || condition.conditionName == 'Liquids'
                                    || condition.conditionName == 'Optional'
                                    || condition.conditionName == 'Maximum Load (ISO)')">
                                        {{condition.xg | number : '1.2-3'}}
                                    </td>
                                    <td style="min-width: 50px;" *ngIf="!(condition.conditionName.includes('Condition') 
                                    || condition.conditionName == 'Crew'
                                    || condition.conditionName == 'Liquids'
                                    || condition.conditionName == 'Optional'
                                    || condition.conditionName == 'Maximum Load (ISO)')">
                                        {{condition.yg | number : '1.2-3'}}
                                    </td>
                                    <td style="min-width: 50px;" *ngIf="!(condition.conditionName.includes('Condition') 
                                    || condition.conditionName == 'Crew'
                                    || condition.conditionName == 'Liquids'
                                    || condition.conditionName == 'Optional'
                                    || condition.conditionName == 'Maximum Load (ISO)')">
                                        {{condition.zg | number : '1.2-3'}}
                                    </td>
                                </tr>



                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>