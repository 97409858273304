import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Propeller } from '../../../shared/propeller.model';
import { PropellerService } from '../propeller.service';

@Component({
  selector: 'app-propeller-delete',
  templateUrl: './propeller-delete.component.html',
  styleUrls: ['./propeller-delete.component.scss']
})
export class PropellerDeleteComponent implements OnInit {

  @Input() public propeller: Propeller;
  constructor(public propellerService: PropellerService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.propellerService.trashPropeller();
   
  }

}
