import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { Item } from 'src/app/CALC/shared/item.model';
import { List } from 'src/app/CALC/shared/list.model';
import { VehicleService } from 'src/app/DELPHY/pages/project/vehicles/vehicle.service';
import { VariantService } from '../../../variants/variant.service';
import { OptionalService } from '../optional.service';

@Component({
  selector: 'app-optional-delete',
  templateUrl: './optional-delete.component.html',
  styleUrls: ['./optional-delete.component.scss']
})
export class OptionalDeleteComponent implements OnInit {
  @Input() public item: Item;
  optItems: Item[];
  optLists:List[];
  optListsFound:List[] = [];

  error = new Subject<string>();

  constructor(public vehicleService: VehicleService,
    public optionalService: OptionalService,
    private modalService: NgbModal,
    public cookieService: CookieService) { }

  ngOnInit(): void {


    this.optionalService.getOptLists().subscribe(res => {
      if (res["Result"] == "OK") {
        this.optLists = res['Records'];
        this.optionalService.getAllOptionalItems("optItem").subscribe(res => {
          if (res["Result"] == "OK") {
            // filtrare qui per idItem  il loro 
            this.optItems = res['Records'];
            this.optItems = this.optItems.filter(opt => opt.idBLI == this.item.idItem)
    

            this.optItems.forEach(optItem => {
              console.log("this.optLists.find(list => list.id == optItem.idForeign)")
              console.log(this.optLists.find(list => list.id == optItem.idForeign))
              
              this.optListsFound.push(this.optLists.find(list => list.id == optItem.idForeign));
            });
    
            // devo capire in queli liste sta questo item
    
    
            
          } else {
            this.error.next(res["Result"].message);
          }
    
        });

      } else {
        this.error.next(res["Result"].message);
      }
    });


    


    this.optionalService.getAllOptionalItems("optItem").subscribe(res => {
      if (res["Result"] == "OK") {
        // filtrare qui per idItem  il loro 
        this.optItems = res['Records'];
        this.optItems = this.optItems.filter(opt => opt.idBLI == this.item.idItem)


        // devo capire in queli liste sta questo item


        
      } else {
        this.error.next(res["Result"].message);
      }

    });

  }

  onSubmit() {
    this.modalService.dismissAll();
    this.optionalService.trashOptional(this.item);

  }
}
