<div class="container-fluid">




    <div class="textUnderIcon">
        <div class="card overflow-hidden noScale">

            <div class="bg-soft-primary">
                <div class="row">
                    <div class="col-xl-12" style="text-align: center;">
                        <h5 style="color: white; padding-top: 25px; font-weight: bold; ">Item dashboard – Online Help
                        </h5>
                    </div>
                </div>
            </div>


            <div class="card-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="pt-6">
                            <div class="row numberReq">

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Features on sidebar</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Table view</h6>
                                                <h6 class="font-size-11">each row correspond to an item, by clicking on a row is possible to edit the detailed information.</h6>
                                            </div>
                                        </div>
                                        <div class="col-6" style="cursor: pointer">
                                            <i class="bx bx-line-chart font-size-20"></i>
                                            <h6 class="list-name-help">Cluster view</h6>
                                            <h6 class="font-size-11">by clicking on the blue arrow is possible to access into an options cluster.</h6>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12" style="cursor: pointer">
                                            <div><i class="bx bxs-data font-size-20"></i>
                                                <h6 class="list-name-help">Manage Cluster</h6>
                                                <h6 class="font-size-11"> this feature allows to create and customise a cluster of options. Only free options are allow to become a cluster and it is possible to simply <b>join</b> or mark as <b>Conflict cluster</b>.</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-3" style="border-right: 1px solid #dee2e6;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Buttons Features</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4" style="cursor: pointer">
                                            <button type="button" class="btn btn-primary btn-circle btn-xs font-size-5" style="border-radius: 20; padding: 0rem 0rem; width: 20px; height: 20px;">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                            <h6 class="font-size-13">Add a new object, for entire projects, lists or items</h6>
                                        </div>

                                        <div class="col-4">
                                            <i class="bx bxs-save font-size-20"></i>
                                            <h6 class="font-size-13">Save feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bxs-edit-alt font-size-20"></i>
                                            <h6 class="font-size-13">Edit information on specific card</h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <i class="bx bxs-copy font-size-20"></i>
                                            <h6 class="font-size-13">Clone feature, for entire projects, lists or items</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bx-select-multiple font-size-20"></i>
                                            <h6 class="font-size-13">Manage cluster</h6>
                                        </div>
                                        <div class="col-4">
                                            <i class="bx bx-undo font-size-20"></i>
                                            <h6 class="font-size-13">Delete the group by releasing all items.</h6>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6">
                                    <h5>Summary</h5>
                                    <hr>

                                    <div style="text-align: left;">
                                        <h6>In this section you can set the complete list of all options in the project as well as set the aggregation among different options.</h6>
                                        <h6>The inserted objects will be visible in the table and, by clicking on "<b>Manage Cluster</b>", it will be possible to aggregate the objects to form a cluster.</h6>

                                        <h6>To create a cluster you have to indicate a name and then choose whether to make a simple Join or mark the cluster as consisting of conflicting elements.</h6>

                                        <h6>Within the specific list of options, the insertion of elements marked as "<b>Join</b>" means that the inclusion of an object implies the inclusion of all those belonging to the same group. Inserting an item marked
                                            "
                                            <b>Conflict</b>" means that other objects belonging to the same group will be excluded from the list.</h6>
                                        <h6>Rows coloured red represent an element that does not yet have co-ordinates. </h6>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>













    <div class="row">

        <div [ngClass]="{'col-xl-9':  vehicleService.selectedEditMode != undefined,'col-xl-12':vehicleService.selectedEditMode == undefined}">

            <div class="card">
                <div class="card-body" style="padding: 0px;">

                    <p-table [value]="optionalService.itemsOptMatched" [columns]="_selectedColumns" [reorderableColumns]="true" sortMode="single" (onSort)="onSort()" dataKey="matchName" [expandedRowKeys]="optionalService.expandedGroups" [paginator]="true" [rows]="50" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single" [rowsPerPageOptions]="[10,25,50,100]" styleClass="p-datatable-gridlines">


                        <ng-template pTemplate="caption">
                            <div class="row" style=" margin-right: 0px; height: 35px;">
                                <div class="col-sm-4" style="text-align: left;">
                                    <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
                                </div>
                                <div class="col-sm-7">
                                </div>
                                <div class="col-sm-1" style="text-align: center;" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
                                    <div class="row" style="display: contents;"><i class="bx bx-select-multiple font-size-22" style="cursor: pointer; color: white;" (click)="onOpenMatchOptModal()" title="Manage Cluster"> </i></div>
                                    <div class="row" style="color: white; font-size: 10px; display:grid; cursor: pointer" (click)="onOpenMatchOptModal()" title="Manage Cluster">Manage Cluster</div>
                                </div>
                            </div>

                        </ng-template>

                        <ng-template pTemplate="header" let-columns>
                            <tr>

                                <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" pReorderableColumn>

                                    <div *ngIf="col.header=='Name'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Job'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="jobsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Topological'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="topologicalsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Weight'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Xg'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Yg'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Zg'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>



                                    <div *ngIf="col.header=='Product'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="productsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Design'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="designsFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Est.Weight'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>


                                    <div *ngIf="col.header=='Margin'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Units'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter field="{{col.field}}" matchMode="equals" display="menu">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-dropdown [ngModel]="value" [options]="typesFilter" (onChange)="filter($event.value)" placeholder="Any">
                                                    <ng-template let-option pTemplate="item">
                                                        <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                    </ng-template>
                                                </p-dropdown>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Quantity'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Conflict Code'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Inseparable Code'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Note'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>
                                    <div *ngIf="col.header=='Match'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='AssemblyHours'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                    <div *ngIf="col.header=='Price'" class="p-d-flex p-jc-between p-ai-center">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                        <p-columnFilter type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                                    </div>

                                </th>
                            </tr>
                        </ng-template>


                        <ng-template pTemplate="body" let-itemRow let-columns="columns" let-rowIndex="rowIndex" let-index="rowIndex" let-expanded="expanded">
                            <tr *ngIf="optionalService.rowGroupMetadata[itemRow.matchName].index === rowIndex" [pReorderableRow]="index">
                                <td colspan=colsSize>
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <button type="button" style="width: 2.357rem!important;" pButton pRipple [pRowToggler]="itemRow" class="p-button-text p-button-rounded p-button-plain " [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                        </div>




                                        <div class="col-sm-6" style="text-align: center; padding-top: 10px;">
                                            <span class="p-text-bold " *ngIf="itemRow.matchName!=optionalService.defaultMatchName" title="Show cluster optional items">{{itemRow.matchName}}</span>
                                        </div>

                                        <div class="col-sm-2" style="vertical-align: center; padding-top: 10px;" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
                                            <i *ngIf="itemRow.matchName!=optionalService.defaultMatchName" class="bx bxs-edit-alt font-size-22" style="cursor: pointer;" (click)="onOpenMatchOptModalEdit(itemRow.matchName)" title="Edit cluster components"></i>
                                            <div class="textUnderIcon">Edit</div>
                                        </div>

                                        <div class="col-sm-2" style="vertical-align: center; padding-top: 10px;" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
                                            <i *ngIf="itemRow.matchName!=optionalService.defaultMatchName" class="bx bx-undo font-size-22" style="cursor: pointer;" (click)="onFreeMatch(itemRow.matchName)" title="Unmark cluster"></i>
                                            <div class="textUnderIcon">Unmark</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>




                        <ng-template pTemplate="rowexpansion" let-rowIndex="rowIndex" let-itemRow let-columns="columns">
                            <tr>
                                <td style="cursor: pointer;" class="tableExpan" *ngFor="let col of columns" (click)="onSelectItem(itemRow)" title="{{itemRow.name}}, Rev: {{itemRow.revisionName}} ">
                                    {{itemRow[col.field]}}
                                </td>
                            </tr>
                        </ng-template>



                    </p-table>
                </div>
            </div>




        </div>

        <!-- details -->
        <div class="col-lg-3" *ngIf="vehicleService.selectedEditMode != undefined">
            <!-- class="col-xl-3"  -->
            <!-- <router-outlet></router-outlet>
            <ng-template #infoText>
                <p>Please select an item</p>
            </ng-template> -->
            <app-optional-edit></app-optional-edit>
        </div>
    </div>

    <!-- end row -->
</div>











<div class="floating-action-button" (click)="onNewItem()" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">
    <button type="button" class="btn btn-primary btn-circle btn-lg" id="addBtn">
        <i class="fas fa-plus" title="New"></i>
        <div class="textUnderIcon">New</div>
    </button>
</div>