<div class="content">
    <div class="row">
        <div class="col-sm-10">
            <h5>Are you sure to delete {{hull.name}}?</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="hullService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12">

            <form class="form" (ngSubmit)="onSubmit()">

                <div class="row">

                    <div class="col-lg-11">
                    </div>

                    <div class="col-lg-1" style="padding-left: 22px;">
                        <i class="bx bxs-trash-alt font-size-22" style="cursor: pointer;" (click)="onSubmit()"></i>
                        <div class="textUnderIcon">Delete</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>