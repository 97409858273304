import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { VehicleService } from 'src/app/CALC/pages/vehicles/vehicle.service';
import { User } from '../user.model';

import { ModuleLink } from './modulelink.model';

@Component({
  selector: 'app-dashboard-links',
  templateUrl: './dashboard-links.component.html',
  styleUrls: ['./dashboard-links.component.scss']
})
export class DashboardLinksComponent implements OnInit {

  currentUser: User;
  moduleLinks: ModuleLink[] = [
    {title:"CALC", subTitle: "Redirect to new CALC"},
    // {title:"CALC", subTitle: "Computer Aided Load Case"},
    {title:"EASYPRO", subTitle: "Project Managment Suite"},
    {title:"DELPHY", subTitle: "Performance Prediction Tool"},
    {title:"MICAPP", subTitle: "Documental Approval"},
  ];
  cols: number;
    constructor(
      public cookieService: CookieService,
      private vehicleService: VehicleService,
      
      ) {
        
       }
  
    ngOnInit(): void {
      
      this.vehicleService.deleteItemInfoListCookie();
      this.currentUser = JSON.parse(this.cookieService.get("loggedInUser"));
      this.cookieService.set("userRole",this.currentUser.roleName) ;
    if(this.currentUser.company.length == 1 && this.currentUser.company[0].name != "MICAD") {
      this.moduleLinks = [
        {title:"CALC", subTitle: "Computer Aided Load Case"},
        {title:" EASYPRO ", subTitle: "Project Managment Suite"},
        {title:" DELPHY ", subTitle: "Performance Prediction Tool"},
        {title:" MICAPP ", subTitle: "Documental Approval"},
      ];
    }
      this.cols = this.moduleLinks.length;
    }
  
  }
  