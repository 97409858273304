import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { AuthModule } from './auth/auth.module';
import { RemoveWhiteSpacePipe } from './removewhitespace.pipe';
import { ProfileComponent } from './users/profile/profile.component';

@NgModule({
  declarations: [
    RemoveWhiteSpacePipe,
    ProfileComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    AuthModule
  ]
})
export class AccountModule { }
