import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { VehicleDeleteComponent } from '../vehicle-delete/vehicle-delete.component';
import { VehicleEditComponent } from '../vehicle-edit/vehicle-edit.component';
import { Vehicle } from '../vehicle.model';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent implements OnInit {
  vehicle: Vehicle;
  idVehicle: string;

  constructor(public vehicleService: VehicleService,
    private modalService: NgbModal,
    public cookieService: CookieService,
    public accountService: AccountService,) { }

  ngOnInit(): void {
    this.vehicleService.selectedVehicleEvent.subscribe(
      (selectedVehicle: Vehicle) => {
        if(selectedVehicle != undefined)
          this.vehicle = selectedVehicle;
      }
    );
    if (this.vehicleService.selectedVehicle != undefined) {
      this.vehicle = this.vehicleService.selectedVehicle;
    }
  }

  onEdit(){
    this.vehicleService.selectedVehicle=undefined;
    this.vehicleService.selectedVehEditMode = true;
    const modal = this.modalService.open(VehicleEditComponent);
    modal.componentInstance.vehicle = this.vehicle;
  }

  
  onTrash(){
    this.vehicleService.selectedVehicle=undefined;
    const modaldelete = this.modalService.open(VehicleDeleteComponent);
    modaldelete.componentInstance.vehicle = this.vehicle;
  }
}