<div class="plot-card overflow-hidden">

    <div class="plot-header" title="Show projectEl.name">
        <h5 class="plot-title">Graph name</h5>
    </div>

    <div class="card-body pt-0">
        <canvas #lcsCanvas></canvas>
    </div>

</div>