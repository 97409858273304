import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/account/user.model';
import { ProjectCALC } from '../../shared/projectCALC.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-manage-projectuser',
  templateUrl: './manage-projectuser.component.html',
  styleUrls: ['./manage-projectuser.component.scss']
})
export class ManageProjectuserComponent implements OnInit {

  users: User[];
  usersFromProject: User[];
  selectedUsers: User[];
  userToAdd: User[];
  
  currentUser: User;
  @Input() public project: ProjectCALC;

  constructor(public userService: UserService,
    public cookieService: CookieService) { }

  ngOnInit(): void {

    this.userService.getUsers().subscribe(res => {

      this.users = res;


      // let cookieValue = this.cookieService.get('loggedInUser');
      // this.currentUser = JSON.parse(cookieValue);
      // var userAus = []
      // // cerco di capire se contiene micad per aggiungerli tutti
      // if(this.currentUser.company.find(comp => comp.name == "MICAD")){
      //   this.userService.setUsers(this.users);
      // } else {
      //   // In teoria non contiene MICAD
      //   this.currentUser.company.forEach(com => {
      //     this.users.forEach(user => {
      //      for (let i = 0; i < user.company.length; i++) {
      //        if(user.company[i].id ==com.id)
      //        userAus.push(user)
      //      }
      //     });
      //   });
       
      //   this.userService.setUsers(userAus.filter(this.onlyUnique));
      // }








      // this.userService.setUsers(this.users);
      console.log(this.users);

      this.usersFromProject = this.project.users;
      console.log("1this.usersFromProject");
      console.log(this.usersFromProject);


      this.selectedUsers = [];
      for (let index = 0; index < this.usersFromProject.length; index++) {
        this.selectedUsers.push(this.usersFromProject[index]);
        
      }
     




      // this.usersFromProject.forEach(userPro => {

      //   this.selectedUsers = [...this.selectedUsers, userPro];

      //   console.log("this.selectedUsers");
      // console.log(this.selectedUsers);
      // });

      this.usersFromProject.forEach((userPro) => {
        // cerco di trovare nella lista in visualizzazione itemsOpt quelli che hanno lo stesso conflict code e id diverso, vanno eliminati

        this.onSelectAlgorithm(userPro);
      });

    });

  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  onSelectAlgorithm(selUser: User) {
    var wasCheck = false;
    console.log(this.selectedUsers);
    console.log(selUser);
    console.log(wasCheck);
    this.selectedUsers.forEach(user => {
      if (user.email == selUser.email) {
        wasCheck = true;
      }
    });
    console.log(wasCheck);
    if (wasCheck) {
      var indexsArray = [];
      // qui cercare i suoi simili e toglierli dal array in visualizzazione per gestire il conflict
      // se ha un codice Conflict, togliere gli altri items con il codice dal arrayKCKC

      // if(selUser.conflictOptCode !=0){

      //   this.users = this.users.filter(optVis => optVis.idItem == selUser.idItem || optVis.conflictOptCode != selUser.conflictOptCode );

      // }

      // if(selUser.inseparableOptCode !=0){
      // // devo aggiungere gli inseparableitem al selectedUsers
      //   console.log("sto aggiungendo alla visualizzazione");
      //   this.optToAdd = this.users.filter(optVis => optVis.idItem != selUser.idItem && optVis.inseparableOptCode == selUser.inseparableOptCode );

      //   this.optToAdd.forEach(optItem => {
      //     // con il push() non aggiorna la vista del selection
      //     // quando fai il push non funziona il two way binding
      //     this.selectedUsers = [...this.selectedUsers, optItem];
      //   });

      // }


    } else {

      // if(selUser.conflictOptCode !=0){
      //   this.usersClone.forEach(optVis => {
      //     if(optVis.idItem != selUser.idItem && optVis.conflictOptCode == selUser.conflictOptCode && optVis.conflictOptCode != 0){
      //       this.users.push(optVis);
      //     }
      //     console.log("aggiungo dalla visualizzazione");
      //   });
      // }

      // if(selUser.inseparableOptCode !=0){
      //   // devo aggiungere gli inseparableitem al selectedUsers
      //     console.log("sto togliendo check alla visualizzazione");

      //       this.selectedUsers = this.selectedUsers.filter(optVis => optVis.inseparableOptCode != selUser.inseparableOptCode );
      // }

    }
    this.userService.sortByEnailUsers(this.users);
    console.log("this.selectedUsers");
    console.log(this.selectedUsers);

  }


  onSendSelectedUsers(){

    if((this.selectedUsers.length > 0 )){
      console.log("this.selectedOptItems");
    console.log(this.selectedUsers);
     
    this.userService.onCloseForm();
      this.userService.updateSelectedUsers(this.selectedUsers, this.project);

    }
    
  }
  // onTrashAllUsers(){

  //   if(this.users){
  //     console.log("this.users");
  //   console.log(this.users);
     
  //     this.userService.onCloseForm();
  //     this.userService.trashAllUsers(this.project);

  //   } else {
  //     this.toastr.error('No users remove');
  //   }
    
  // }
}
