<div style="height:33.34%; padding-bottom:20px">
    <div class="plot-card overflow-hidden">
        <canvas #RtCanvas></canvas>
    </div>
</div>
<div style="height:33.34%; padding-bottom:20px">
    <div class="plot-card overflow-hidden">
        <canvas #tauCanvas></canvas>
    </div>
</div>
<div style="height:33.34%">
    <div class="plot-card overflow-hidden">
        <canvas #RpmCanvas></canvas>
    </div>
</div>