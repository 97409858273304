import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Appendage } from '../../../shared/appendage.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { AppendageService } from '../appendage.service';

@Component({
  selector: 'app-appendage-list',
  templateUrl: './appendage-list.component.html',
  styleUrls: ['./appendage-list.component.scss']
})
export class AppendageListComponent implements OnInit {

  selectedAppendageId: number;
  constructor(
    public cookieService: CookieService,
    public appendageService: AppendageService,
    public modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
    this.appendageService.getAppendages().subscribe(res => {
      this.appendageService.appendages = res;
    });
  }

  openAdd() {
    this.appendageService.selectedEditMode = false;
    // const modalRef = this.modalService.open(AppendageEditComponent);
  }

  onAppendageSelected(appendage: Appendage) {
    
    this.appendageService.selectedAppendage = appendage;
    this.appendageService.selectedAppendageEvent.emit(appendage);
    if (appendage != undefined)
      this.selectedAppendageId = appendage.id;
    else
      this.selectedAppendageId = undefined;
  }
}
