import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Lc } from '../../shared/lc.model';
import { ProjectDELPHY } from '../../shared/projectDELPHY.model';


@Injectable({
  providedIn: 'root'
})
export class LcService {
  
  selectedEditMode: boolean;
  error = new Subject<string>();
  lcs: Lc[];
  lcsChanged = new Subject<Lc[]>();

  selectedLc: Lc;
  selectedLcEvent = new EventEmitter<Lc>();

  graph: any[] = [];

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  createLc(lc: Lc) {
    console.log(" dopo form lc");
    console.log(lc);
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ lc: Lc }>(
        this.accountService.REST_API_SERVER_DELPHY + 'lc/',
        lc
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          lc.id = responseData["id"];
          lc.date = responseData["date"];
          // this.lcs.unshift(lc);
          this.lcs.push(lc);
          this.lcsChanged.next(this.lcs);
          this.toastr.success('Lc created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  updateLc(lc: Lc) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .put<{ lc: Lc }>(
        this.accountService.REST_API_SERVER_DELPHY + 'lc/',
        lc
      )
      .subscribe(responseData => {
        let itemIndex = this.lcs.findIndex(item => item.id == this.selectedLc.id);
        lc.date = responseData["date"];
        this.lcs[itemIndex] = lc;
        this.selectedLc = lc;
        this.selectedLcEvent.next(lc);
        this.lcsChanged.next(this.lcs);
        this.toastr.success('Lc  updated');
      },
        error => {
          this.toastr.error(error.message);
            console.log(error.message);
            // this.error.next(error.message);
        });

  }

  trashLc() {
   
    this.http
      .delete(this.accountService.REST_API_SERVER_DELPHY 
        + 'lc?id=' + this.selectedLc.id)
        .subscribe(responseData => {
          if (responseData != undefined) {
            const index = this.lcs.findIndex(x => x.id === this.selectedLc.id);
            this.lcs.splice(index, 1);
            this.lcsChanged.next(this.lcs);
            this.toastr.success('This lc has been deleted', 'Lc deleted');
            this.selectedLcEvent.next(undefined);
            this.selectedLc = undefined;
          } else {
            this.error.next(responseData["Result"].message);
          }
        },
          error => {
            this.error.next(error.message);
          });

  }

  public getLcs(): Observable<Lc[]> {
    return this.http.get<Lc[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'lc/?project_id=' + this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY)
    )
      .pipe(
      );
  }

  
  getLc(id: number) {
    if (this.lcs == undefined)
      return undefined;
    const el = this.lcs.find(
      (r) => {
        return r.id == id;
      }
    );
    return el;
  }

  onCloseForm() {
    this.modalService.dismissAll();
  }










  onInitGraph() {

    this.setGraphJob();
    console.log("initi" );


  }

  // restituisce label e data
  setGraphJob() {
    let data = [], label = [];

    this.lcs.forEach(element => {
      var asus = {x:element.lcg.toFixed(3), y:element.vcg.toFixed(3), r:(element.displacement/1000).toFixed(3)};
      data.push(asus);
      label.push(element.name);
    });

    this.graph = [label, data];
  }




  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }











}