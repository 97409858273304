import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Engine } from '../../../shared/engine.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { EngineDeleteComponent } from '../engine-delete/engine-delete.component';
import { EngineEditComponent } from '../engine-edit/engine-edit.component';
import { EngineService } from '../engine.service';

@Component({
  selector: 'app-engine-details',
  templateUrl: './engine-details.component.html',
  styleUrls: ['./engine-details.component.scss']
})
export class EngineDetailsComponent implements OnInit {

  engine: Engine;
  project: ProjectDELPHY;
  
  constructor(
    public cookieService: CookieService,
    public engineService: EngineService,
    private modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

    ngOnInit(): void {
   
      if(this.engineService.selectedEngine == undefined){
        this.engineService.selectedEngineEvent.subscribe(
          (selectedEngine: Engine) => {
            this.engine = selectedEngine;
          }
        );
      }
    }

  onEdit() {
    this.engineService.selectedEditMode = true;
    const modalRef = this.modalService.open(EngineEditComponent);
    modalRef.componentInstance.engine = this.engineService.selectedEngine;
  }

  onTrash() {
    const modaldelete = this.modalService.open(EngineDeleteComponent);
    modaldelete.componentInstance.engine = this.engineService.selectedEngine;
  }

}
