import { Pipe, PipeTransform } from '@angular/core';
import { Appendage } from './DELPHY/pages/shared/appendage.model';

@Pipe({
  name: 'filterAppendagesPipe'
})
export class FilterAppendagesPipe implements PipeTransform {
  appendagesFiltered: Appendage[] = [];
  transform(appendages: Appendage[], type: number): Appendage[] {

    // console.log("appendages");
    // console.log(appendages);
    // console.log("type");
    // console.log(type);
    if (appendages == undefined){
      return null;
    } else {
      this.appendagesFiltered = appendages.filter(
        appendage =>
          appendage.category == type
      );
    }
    // console.log("appendagesFiltered");
    // console.log(this.appendagesFiltered);
    return this.appendagesFiltered;
  }

}
