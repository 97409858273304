import { Injectable, EventEmitter } from '@angular/core';
import { Approval } from './approval.model';


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ApprovalFilter } from './approvalFilter.model';
import { User } from 'src/app/account/user.model';
import { Project } from './project.model';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  approvals: Approval[];
  projectNone: Project = {
    replyByEmailEnabled: false,
    starred: false,
    showannouncement: false,
    harvesttimersenabled: false,
    subStatus: '',
    status: '',
    defaultPrivacy: '',
    createdon: '',
    filesAutoNewVersion: false,
    tags: [],
    overviewstartpage: '',
    logo: '',
    startDate: '',
    id: '',
    lastchangedon: '',
    portfolioBoards: [],
    type: '',
    endDate: '',
    tasksstartpage: '',
    name: 'NONE',
    privacyEnabled: false,
    description: '',
    announcement: '',
    directFileUploadsEnabled: false,
    startpage: '',
    skipWeekends: false,
    notifyeveryone: false,
    boardData: '',
    announcementHTML: ''
  }
  projectsTW: Project[];

  approvalsChanged = new Subject<Approval[]>();
  filtersChanged = new Subject<ApprovalFilter>();
  selectedApproval = new Subject<Approval>();
  error = new Subject<string>();
  // currentUser: string = 'c.ghervase@micad.it';

  noteSelected = new EventEmitter<string>();

  //[0] label,     [1] data
  graphProjYour: any[] = [];
  graphProjInc: any[] = [];
  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService) {

  }



  getYourApprovals(approvals: Approval[]): Approval[] {
    let yourApproval: Approval[] = [];

    if (approvals != undefined) {
      approvals.forEach(approval => {
        if (approval.emailOwner == this.accountService.currentUser.email) {
          yourApproval.push(approval);
        }
      });
    }
    return yourApproval;
  }
  getIncApprovals(approvals: Approval[]): Approval[] {
    let incApproval: Approval[] = [];
    if (approvals != undefined) {
      approvals.forEach(approval => {
        if (approval.emailFirst == this.accountService.currentUser.email || approval.emailSecond == this.accountService.currentUser.email) {
          incApproval.push(approval);
        }
      });
    }
    return incApproval;
  }

  getApprovalProjectsArray(approvals: Approval[]): string[] {

    let projectsArr: string[] = [];
    approvals.forEach(approval => {
      projectsArr.push(approval.projectTW);
    });
    return projectsArr;

  }

  // restituisce label e data
  setGraphProj(appProjArr: string[]) {
    let data = [], label = [], prev;

    appProjArr.sort();

    for (var i = 0; i < appProjArr.length; i++) {
      if (appProjArr[i] !== prev) {
        label.push(appProjArr[i]);
        data.push(1);
      } else {
        data[data.length - 1]++;
      }
      prev = appProjArr[i];
    }
    return [label, data]
  }



  // Get data from servlet and return the approvals array
  public getApprovals(): Observable<Approval[]> {
    return this.http.get<Approval[]>(this.accountService.REST_API_SERVER + 'approvalServlet?action=LISTANGULAR&currentEmail=' + this.accountService.currentUser.email)
      .pipe(map(result => result['Records'])
      );
  }

  setFilters(filter: ApprovalFilter) {
    this.filtersChanged.next(filter);
  }
  setApprovals(approvals: Approval[]) {
    this.approvals = approvals;
    this.approvalsChanged.next(this.approvals.slice());
  }

  getApproval(idApproval: string) {
    if (this.approvals != undefined) {
      const approval = this.approvals.find(
        (r) => {
          return r.idApproval == idApproval;
        }
      );
      return approval;
    }
    return undefined;
  }
  getProjectsFromApprovals(): string[] {
    return this.approvals.map(app => app.projectTW).filter(this.onlyUnique).sort();
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }


  createApproval(approval: Approval) {
    this.http
      .post<{ approval: Approval }>(
        this.accountService.REST_API_SERVER + 'approvalServlet?action=CREATE&currentEmail=' + this.accountService.currentUser.email,
        approval
      )
      .subscribe(responseData => {

        if(responseData['Result'] == 'OK'){
          let approvalEl = responseData['Record'];
          this.approvals.unshift(approvalEl);
          this.approvalsChanged.next(this.approvals.slice());
          this.toastr.success('Email sent to approvers', 'Approval created');
        }
        
      },
        error => {
          this.error.next(error.message);
        });

  }

  getProjectsTW() {

    this.http
      .get<{ projectsTW: Project[] }>(
        this.accountService.REST_API_SERVER + 'projectServletTW?action=LIST')
      .subscribe(responseData => {
        this.projectsTW = responseData['projects'];
      },
        error => {
          this.error.next(error.message);
        });

  }

  // viene usato per generare lo select con il project NONE
  getProjectsTWNone(): Subject<Project[]> {
    let result = new Subject<Project[]>();
    this.http
      // aggiungere <{è un optional raccomandato}>
      .get<{ projectsTW: Project[] }>(
        this.accountService.REST_API_SERVER + 'projectServletTW?action=LIST')
      .subscribe(responseData => {
        this.projectsTW = responseData['projects'];
        this.projectsTW.unshift(this.projectNone);
        result.next(this.projectsTW);
      },
        error => {
          result.next(null);
          this.error.next(error.message);
        });
    return result;
  }

  //action  = CHECKSECOND CHECKFIRST REFUSEFIRST  REFUSESECOND
  actionApproval(approval: Approval, note: string, action: string) {

    // qui devo passare le note da aggiungere
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ approval: Approval }>(
        this.accountService.REST_API_SERVER + 'approvalServlet?action=' + action,
        [approval, note],
      )
      .subscribe(responseData => {
        approval = responseData["approval"]["Record"];
        // qui va aggionato aggiungendo la nota e aggiornando l'approval
        let updateApproval = this.approvals.find(this.findIndexToUpdate, approval.idApproval);
        let index = this.approvals.indexOf(updateApproval);
        this.approvals[index] = approval;
        this.approvalsChanged.next(this.approvals.slice());
        if (action.indexOf("CHECK") >= 0) {
          this.toastr.success('Confirmation email sent to owner', 'Document has been approved');
        } else if (action.indexOf("REFUS") >= 0) {
          this.toastr.success('Confirmation email sent to owner', 'Document has been rejected');
        }

      },
        error => {
          this.error.next(error.message);
        });

  }

  // metodo ausiliario per aggioanre la lista dei approval
  findIndexToUpdate(newApproval) {
    return newApproval.idApproval === this;
  }

  updateApproval(newApproval: Approval) {
    const idApproval = newApproval.idApproval;
    this.getApproval(idApproval).description = newApproval.description;
    this.approvalsChanged.next(this.approvals.slice());
  }

  deleteApproval(idApproval: string) {
    const index = this.approvals.findIndex(x => x.idApproval === idApproval);
    this.approvals.splice(index, 1);
    this.approvalsChanged.next(this.approvals.slice());
    this.toastr.success('This approval has been deleted', 'Approval deleted');

  }

  trashApproval(approval: Approval) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ approval: Approval }>(
        this.accountService.REST_API_SERVER + 'approvalServlet?action=TRASH',
        approval
      )
      .subscribe(responseData => {
        if (responseData["Result"] == "OK") {
          const index = this.approvals.findIndex(x => x.idApproval === approval.idApproval);
          this.approvals.splice(index, 1);
          this.approvalsChanged.next(this.approvals.slice());
          this.toastr.success('This approval has been deleted', 'Approval deleted');
        } else {
          this.error.next(responseData["Result"].message);
        }
      },
        error => {
          this.error.next(error.message);
        });

  }




}
