import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Crew } from 'src/app/CALC/shared/crew.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Item } from 'src/app/CALC/shared/item.model';

@Injectable({
  providedIn: 'root'
})
export class CrewService {

  crews: Crew[];
  crewsChanged = new Subject<Crew[]>(); 

  selectedEditModeEvent = new EventEmitter<boolean>();
  selectedEditMode: boolean;

  selectedCrewEvent = new EventEmitter<Crew>();
  selectedCrew: Crew;

  editCrewReady: boolean = false;
  error = new Subject<string>();

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

    
    createCrew(crew: Crew) {

      this.http
        // aggiungere <{è un optional raccomandato}>
        .post<{ crew: Crew }>(
          this.accountService.REST_API_SERVER_CALC
          + this.cookieService.get(Item.servletName) + 'Servlet?action=CREATE&loggedInEmail=' + this.accountService.currentUser.email 
          + '&idForeign=' +this.cookieService.get(Item.tableNameIdList),
          crew
        )
        .subscribe(responseData => {
          if(responseData['Result'] == 'OK'){
            let crewRes = responseData['Record'];
            this.crews.push(crewRes);
            this.setCrews(this.crews);
            this.toastr.success('Crew  created');
          }
          
        },
          error => {
            this.toastr.error(error.message);
            console.log(error.message);
            this.error.next(error.message);
          });
  
    }
  
    updateCrew(crew: Crew) {
      this.http
        // aggiungere <{è un optional raccomandato}>
        .post<{ crew: Crew }>(
          this.accountService.REST_API_SERVER_CALC
          + this.cookieService.get(Item.servletName) +'Servlet?action=UPDATE&loggedInEmail=' + this.accountService.currentUser.email 
          + '&idForeign=' +this.cookieService.get(Item.tableNameIdList),
          crew
        )
        .subscribe(responseData => {
          // devo trovare l'crew negli crews e sostituirlo
          if(responseData['Result'] == 'OK'){
            let retrievedCrew = responseData['Record'];
            let crewIndex = this.crews.findIndex(crew => crew.id == retrievedCrew.id);
            this.crews[crewIndex] = retrievedCrew;
            // this.crews.unshift(crew);
            this.setCrews(this.crews);
            // this.crewsChanged.next(this.crews.slice());
            this.toastr.success('Crew  updated');
          }
        },
          error => {
            this.toastr.error(error.message);
            console.log(error.message);
            this.error.next(error.message);
          });
  
    }
  
    deleteCrew(crew: Crew) {
      this.http
        // aggiungere <{è un optional raccomandato}>
        .post<{ crew: Crew }>(
          this.accountService.REST_API_SERVER_CALC + this.cookieService.get(Item.servletName) + 'Servlet?action=DELETE&loggedInEmail='
          + this.accountService.currentUser.email + '&idForeign=' + crew.idForeign+ '&id=' + crew.id,
          crew
        )
        .subscribe(responseData => {
          if (responseData["Result"] == "OK") {
            console.log("Result");
            console.log(responseData["Result"]);
            this.onCloseForm()
            const index = this.crews.findIndex(x => x.id == crew.id);
            this.crews.splice(index, 1);
            this.setCrews(this.crews);
            // this.selectedEditModeEvent.next(undefined);
            // this.selectedEditMode = undefined;
            this.toastr.success('This crew has been deleted', 'Crew deleted');
          } else {
            this.error.next(responseData["Result"].message);
          }
        },
          error => {
            this.toastr.error(error.message);
            console.log(error.message);
            this.error.next(error.message);
          });
  
    }


  public getCrews(tableName: string, idList: number): Observable<Crew[]> {
    return this.http.get<Crew[]>(this.accountService.REST_API_SERVER_CALC
      + tableName + 'Servlet?action=LIST&loggedInEmail='
      + this.accountService.currentUser.email + '&idForeign='
      + idList 
    )
      .pipe(map(result => result['Records'])
      );
  }

  getCrew(id: number) {
    const Crew = this.crews.find(
      (r) => {
        return r.id== id;
      }
    );
    return Crew;
  }

  setCrews(crews: Crew[]) {
    this.crews = crews;
    this.crewsChanged.next(this.crews.slice());
  }

  
  notifyEditor() {
    console.log("nofityeditor start");

    this.selectedEditModeEvent.next(this.selectedEditMode);
    this.selectedCrewEvent.next(this.selectedCrew);
  }

  public selectCrewForEdit(crew: Crew) {
    this.selectedCrew = crew;
    if (this.editCrewReady) {
      this.notifyEditor();
    } else {
      this.selectedEditModeEvent.next(this.selectedEditMode);
    }
  }
  public editorReady() {
    this.editCrewReady = true;
    this.notifyEditor();
  }

  onCloseForm(){
    this.selectedCrew = undefined;
    this.selectedCrewEvent.next(undefined);

    this.selectedEditMode = undefined;
    this.selectedEditModeEvent.next(undefined);
  }

}
