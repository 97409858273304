import { Injectable, EventEmitter, ViewChild } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { Vehicle } from './vehicle.model';
import { Item } from '../../shared/item.model';
import { List } from '../../shared/list.model';
import { ProjectCALC } from '../../shared/projectCALC.model';
import { Crew } from '../../shared/crew.model';

import { Table } from 'primeng/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VariantService } from '../variants/variant.service';
import { formatDate } from '@angular/common';

declare function drawChart(bubbleItem: Item[]);

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Injectable({
  providedIn: 'root'
})
export class VehicleService {




  // items: Item[];
  itemsData: Item[];
  itemsLastRev: Item[];

  itemsRev: Item[];

  cols: any[];


  _selectedColumns: any[];
  scrollableCols: any[];
  titleList: string;
  //chiedere quali devono essere le colonne da export
  exportColumns: any[];
  exportColumnsNormal: any[];
  @ViewChild('dt') table: Table;

  jobsFilter: any[] = [];
  productsFilter: any[] = [];
  designsFilter: any[] = [];
  unitsOfMeasuresFilter: any[] = [];
  topologicalsFilter: any[] = [];

  revsFilter: any[] = [{ label: "APG", value: "APG" },
  { label: "001", value: "001" }];
  typesFilter: any[] = [{ label: "CAT", value: "CAT" },
  { label: "DWG", value: "DWG" }];

  requestType = "BaseLineItem";
  idList = 0;

  listCalculate: number[] = [0, 0, 0, 0, 0, 0, 0, 0];
  numberOfItem: number;

  project: ProjectCALC;
  vehicles: Vehicle[];
  vehiclesChanged = new Subject<Vehicle[]>();
  titleSelected: string;
  items: Item[];
  editItemReady: boolean = false;
  itemsEvent = new EventEmitter<Item[]>();
  itemsChanged = new Subject<Item[]>();

  selectedItemEvent = new EventEmitter<Item>();
  selectedItem: Item;

  selectedVehicleEvent = new EventEmitter<Vehicle>();
  selectedVehicle: Vehicle;

  selectedEditModeEvent = new EventEmitter<boolean>();
  selectedEditMode: boolean;
  selectedVehEditMode: boolean;

  error = new Subject<string>();


  // selectedTank: Tank;
  // tanks: Tank[];
  // tanksChanged = new Subject<Tank[]>();

  selectedCrew: Crew;
  crews: Crew[];
  crewsChanged = new Subject<Crew[]>();




  chartJob: any;
  chartTopo: any;
  //[0] label,     [1] data
  graphJob: any[] = [];
  graphTopo: any[] = [];


  myDate = new Date();

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private variantService: VariantService) {

  }

  onInitItems(): void {

    this.variantService.unselectItems();

    this.closeItemEditForm();
    this.titleList = this.cookieService.get(Item.tableTitleItem);
    var tableName = this.cookieService.get(Item.servletName);
    var idProjectSelected = this.cookieService.get("idProjectSelected");
    var idListItemsSelected = this.cookieService.get(Item.tableNameIdList);

    var idList;

    if (tableName == "BaseLineItem") {
      idList = idProjectSelected;
     
      
    } else {
      idList = idListItemsSelected;
    }
    if (tableName) {
      this.getItems(tableName, idList).subscribe(res => {

 if(tableName == "BaseLineItem"){
 

} else if(tableName == "versionItem"){
  this.cookieService.set("topbarTitle","Version item data input");

} else if(tableName == "propulsionItem"){
  this.cookieService.set("topbarTitle","Propulsion item data input");

} else if(tableName == "optItem"){
  this.cookieService.set("topbarTitle","Optional item data input");

} 
        
        this.numberOfItem = res['NumberOfItem'];
        this.setItems(res['Records']);

      });
    }


    this.scrollableCols = [

      { field: 'name', header: 'Name', width: "260px" },
      { field: 'jobName', header: 'Job', width: "120px" },
      { field: 'topologicalName', header: 'Topological', width: "150px" },
      { field: 'weight', header: 'Weight', width: "120px" },
      { field: 'xg', header: 'Xg', width: "90px" },
      { field: 'yg', header: 'Yg', width: "90px" },
      { field: 'zg', header: 'Zg', width: "90px" },
      { field: 'quantity', header: 'Quantity', width: "140px" },
      { field: 'revisionName', header: 'Rev', width: "95px" },
      { field: 'type', header: 'Type', width: "100px" },
      { field: 'productName', header: 'Product', width: "140px" },
      { field: 'designName', header: 'Design', width: "140px" },
      { field: 'estimateWeight', header: 'Est.Weight', width: "150px" },
      { field: 'margin', header: 'Margin', width: "140px" },
      { field: 'unitsOfMeasureName', header: 'Units', width: "140px" },
      { field: 'note', header: 'Note', width: "140px" },
      { field: 'lastUpdateDate', header: 'Last Date', width: "140px" },
      { field: 'assemblyHours', header: 'AssemblyHours', width: "150px" },
      { field: 'price', header: 'Price', width: "100px" }
    ];

    this.exportColumnsNormal = [

      { field: 'name', header: 'Name', width: "240px" },
      { field: 'topologicalName', header: 'Topological', width: "150px" },
      { field: 'estimateWeight', header: 'Est.Weight [kg]', width: "150px" },
      { field: 'unitsOfMeasureName', header: 'Units', width: "140px" },
      { field: 'quantity', header: 'Quantity', width: "100px" },

      { field: 'weight', header: 'Weight [kg]', width: "120px" },

      { field: 'xg', header: 'Xg [m]', width: "90px" },
      { field: 'yg', header: 'Yg [m]', width: "90px" },
      { field: 'zg', header: 'Zg [m]', width: "90px" }

      // { field: 'note', header: 'Note', width: "40px" }
    ];

    this.exportColumns = this.exportColumnsNormal.map(col => ({ title: col.header, dataKey: col.field }));


    this.cols = this.scrollableCols;
    this._selectedColumns = this.cols;

    // console.log(this._selectedColumns);
  }




  public selectItemForEdit(item: Item) {
    this.selectedItem = item;
    if (this.editItemReady) {
      this.notifyEditor();
    } else {
      this.selectedEditModeEvent.next(this.selectedEditMode);
    }
  }
  public editorReady() {
    this.editItemReady = true;
    this.notifyEditor();
  }

  notifyEditor() {
    console.log("nofityeditor start");

    this.selectedEditModeEvent.next(this.selectedEditMode);
    this.selectedItemEvent.next(this.selectedItem);
  }

  // aggiungere parametro queryString idProject da cookie
  // Get data from servlet and return the vehicles array
  public getVehicles(): Observable<Vehicle[]> {
    return this.getVehiclesForProject(this.cookieService.get(ProjectCALC.idProjectSelected))
  }

  public getVehiclesForProject(idProject: string): Observable<Vehicle[]> {

    return this.http.get<Vehicle[]>(this.accountService.REST_API_SERVER_CALC
      + 'vehicleServlet?action=LIST&loggedInEmail='
      + this.accountService.currentUser.email + '&idProject='
      + idProject

    )
      .pipe(map(result => result['Records'])
      );
  }

  public getAllVehicles(): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(this.accountService.REST_API_SERVER_CALC
      + 'vehicleServlet?action=ALLLIST&loggedInEmail='
      + this.accountService.currentUser.email
    )
      .pipe(map(result => result['Records'])
      );
  }

  sortByNameItemsArray(arrayItems: Item[]) {
    arrayItems.sort((a, b) => {
      return a.name > b.name
        ? 1
        : a.name < b.name
          ? -1
          : 0;
    });
  }
  exportPdf() {
    // this.project = JSON.parse(this.cookieService.get(ProjectCALC.selectedProject));

    if (this.project == undefined) {
      this.project = JSON.parse(localStorage.getItem(ProjectCALC.selectedProject));
    }


    this.sortByNameItemsArray(this.itemsLastRev);

    const doc = new jsPDF.default('landscape');
   

    doc.setFontSize(18);
    doc.text(15, 20, this.project.projectName + " - " + this.cookieService.get(Item.tableTitleItem));
    // doc.addImage('assets/images/LOGO MICAD.jpg', 'JPEG', 202, 10, 80, 16);
    doc.addImage('assets/images/logo-dark.png', 'PNG', 242, 12, 40, 13);

    console.log(this.itemsLastRev);
    console.log(this._selectedColumns);
    this.exportColumns = this._selectedColumns.map(col => ({ title: col.header, dataKey: col.field }));

    doc.autoTable({ startY: 32 });
    doc.autoTable(this.exportColumns, this.itemsLastRev);
    doc.save(this.project.projectName+ ' - ' + this.cookieService.get(Item.tableTitleItem) + '_'+ formatDate(new Date(), 'yyyy/MM/dd', 'en') +'.pdf');

  }
  public getItems(tableName: string, idList: number): Observable<Item[]> {

    let url = this.accountService.REST_API_SERVER_CALC
      + tableName + 'Servlet?action=LIST&loggedInEmail='
      + this.accountService.currentUser.email + '&idForeign='
      + idList;
    // console.log("Before get items with url:" + url); 
    return this.http.get<Item[]>(url)
      .pipe(map(result => result)
      );
  }

  getItem(id: number) {
    const Item = this.items.find(
      (r) => {
        return r.idItem == id;
      }
    );
    return Item;
  }

  getListHelper(listName: string, idJob: number): Observable<List[]> {

    // this.project = this.projectService.getProject(+this.cookieService.get(ProjectCALC.idProjectSelected));

    let id = undefined;

    // let selProject = this.cookieService.get(ProjectCALC.selectedProject);
    // if (selProject != undefined) {
    //   this.project = JSON.parse(selProject) as ProjectCALC;
    // }

    if (this.project == undefined) {
      this.project = JSON.parse(localStorage.getItem(ProjectCALC.selectedProject));
    }
    // console.log("this.project");
    // console.log(this.project);


    if (this.project == undefined) {
      return new Observable<List[]>();
    }
    if (listName == 'design') {
      id = this.project.idDesignList;
    } else if (listName == 'job') {
      id = this.project.idJobList;
    } else if (listName == 'topological') {
      id = this.project.idTopologicalList;
    } else if (listName == 'unitsOfMeasure') {
      id = this.project.idUnitsOfMeasureList;
    } else if (listName == 'product') {
      id = idJob;
    } else if (listName == 'revision') {
      id = this.cookieService.get(ProjectCALC.idProjectSelected);
    }
    let requestUrl = this.accountService.REST_API_SERVER_CALC + listName + 'Servlet?action=LIST&loggedInEmail=' +
      this.accountService.currentUser.email + '&idForeign=' + id;

    let pipedResults = this.http.get<List[]>(requestUrl).pipe(map(result => {
      // console.log(result);
      let records = result['Records'];
      // console.log(records);
      return records;
    }));

    if (pipedResults == undefined)
      console.error("Request to url" + requestUrl + " has failed!");
    return pipedResults;
  }

  createItem(item: Item, tableName: string) {

    let idList;

    if (tableName == 'BaseLineItem') {
      idList = this.cookieService.get(ProjectCALC.idProjectSelected);
    } else {
      idList = this.cookieService.get(Item.tableNameIdList)
    }


    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ item: Item }>(
        this.accountService.REST_API_SERVER_CALC
        + tableName + 'Servlet?action=CREATE&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' + idList,
        item
      )
      .subscribe(responseData => {

        item = responseData['item'];
        // console.log('item');
        // console.log(item);
        this.items.unshift(item);
        // this.itemsChanged.next(this.items.slice());
        this.setItems(this.items);
        this.toastr.success('Item  created');
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  updateItem(itemUpdated: Item, tableName: string) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ item: Item }>(
        this.accountService.REST_API_SERVER_CALC
        + tableName + 'Servlet?action=UPDATE&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' + itemUpdated.idForeign,
        itemUpdated
      )
      .subscribe(responseData => {
        // devo trovare l'item negli items e sostituirlo
        let retrievedItem = responseData['item'];
        let itemIndex = this.items.findIndex(item => item.idItem == retrievedItem.idItem);
        this.items[itemIndex] = retrievedItem;
        this.selectedEditMode = undefined;
        // this.items.unshift(item);
        this.setItems(this.items);
        // this.itemsChanged.next(this.items.slice());
        this.toastr.success('Item  updated');
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  cloneItem(item: Item, tableName: string) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ item: Item }>(
        this.accountService.REST_API_SERVER_CALC
        + tableName + 'Servlet?action=CLONE&loggedInEmail=' + this.accountService.currentUser.email
        + '&idForeign=' + item.idForeign + '&idItem=' + item.idItem + '&idProject=' + this.cookieService.get(ProjectCALC.idProjectSelected),
        item
      )
      .subscribe(responseData => {
        // gli items clonati devono essere inseriti nella lista
        responseData['Records'].forEach(t => {
          this.items = [...this.items, t];
        });
        // this.items.unshift(responseData['Records']);
        this.setItems(this.items);
        
        this.selectedEditMode = undefined;
        this.toastr.success('Item  cloned');
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  trashItem(item: Item) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ item: Item }>(
        this.accountService.REST_API_SERVER_CALC + this.cookieService.get(Item.servletName) + 'Servlet?action=TRASH&loggedInEmail='
        + this.accountService.currentUser.email + '&idForeign=' + item.idForeign + '&idItem=' + item.idItem + '&idProject=' + this.cookieService.get(ProjectCALC.idProjectSelected),
        item
      )
      .subscribe(responseData => {
        if (responseData["Result"] == "OK") {
          // console.log("Result");
          // console.log(responseData["Result"]);
          const index = this.items.findIndex(x => x.idItem == item.idItem);
          this.items.splice(index, 1);
          this.setItems(this.items);
          this.selectedEditModeEvent.next(undefined);
          this.selectedEditMode = undefined;
          this.toastr.success('This item has been deleted', 'Item deleted');
        } else {
          this.error.next(responseData["Result"].message);
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  setItems(items: Item[]) {
    this.items = items;

    let Wt = 0;
    let mom1 = 0;
    let mom2 = 0;
    let mom3 = 0;



    this.itemsLastRev = this.items.filter(t => t.lastRev === true);
    if(this.cookieService.get('servletName') != 'optItem')
    this.itemsLastRev = this.itemsLastRev.filter(t => t.isOptional === false);
    // console.log("this.itemsLastRev")
    // console.log(this.itemsLastRev)

    this.itemsChanged.next(this.items.slice());


    this.itemsLastRev.forEach(item => {

      Wt = Wt + item.weight;
      mom1 = mom1 + (item.weight * item.xg);
      mom2 = mom2 + (item.weight * item.yg);
      mom3 = mom3 + (item.weight * item.zg);

      this.jobsFilter.push({ label: item.jobName.replace(/\d+/g, "").replace("_", " "), value: item.jobName.replace(/\d+/g, "").replace("_", " ") });
      this.productsFilter.push({ label: item.productName.replace(/\d+/g, "").replace("_", " "), value: item.productName.replace(/\d+/g, "").replace("_", " ") });
      this.topologicalsFilter.push({ label: item.topologicalName.replace(/\d+/g, "").replace("_", " "), value: item.topologicalName.replace(/\d+/g, "").replace("_", " ") });
      this.unitsOfMeasuresFilter.push({ label: item.unitsOfMeasureName.replace(/\d+/g, "").replace("_", " "), value: item.unitsOfMeasureName.replace(/\d+/g, "").replace("_", " ") });
      this.designsFilter.push({ label: item.designName.replace(/\d+/g, "").replace("_", " "), value: item.designName.replace(/\d+/g, "").replace("_", " ") });
   
    });

    if (Wt != undefined && Wt != 0) {
      this.listCalculate = [Wt, mom1 / Wt, mom2 / Wt, mom3 / Wt, mom1, mom2, mom3, this.itemsLastRev.length]
    }

    this.jobsFilter = this.jobsFilter.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.label === test.label
      )
    ).sort();

    this.productsFilter = this.productsFilter.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.label === test.label
      )
    ).sort();

    this.topologicalsFilter = this.topologicalsFilter.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.label === test.label
      )
    ).sort();

    this.unitsOfMeasuresFilter = this.unitsOfMeasuresFilter.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.label === test.label
      )
    ).sort();

    this.designsFilter = this.designsFilter.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.label === test.label
      )
    ).sort();

  }



  setVehicles(vehicles: Vehicle[]) {
    this.vehicles = vehicles;
    this.vehiclesChanged.next(this.vehicles.slice());
  }


  getVehicle(id: number) {
    const vehicle = this.vehicles.find(
      (r) => {
        return r.idVehicle == id;
      }
    );
    return vehicle;
  }

  createVehicle(vehicle: Vehicle) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ vehicle: Vehicle }>(
        this.accountService.REST_API_SERVER_CALC
        + 'vehicleServlet?action=CREATE&loggedInEmail=' + this.accountService.currentUser.email + '&idProject=' + vehicle.idProject,
        vehicle
      )
      .subscribe(responseData => {
        if (responseData['Result'] == 'OK') {
          let vehicleEl = responseData['Record'];
          // console.log(vehicleEl);
          this.vehicles.push(vehicleEl);
          // this.vehicles.unshift(vehicle);
          this.vehiclesChanged.next(this.vehicles.slice());
          this.toastr.success('Vehicle  created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  updateVehicle(vehicle: Vehicle) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ vehicle: Vehicle }>(
        this.accountService.REST_API_SERVER_CALC
        + 'vehicleServlet?action=UPDATE&loggedInEmail=' + this.accountService.currentUser.email + '&idVehicle=' + vehicle.idVehicle,
        vehicle
      )
      .subscribe(responseData => {
        if (responseData['Result'] == 'OK') {
          let itemIndex = this.vehicles.findIndex(item => item.idVehicle == vehicle.idVehicle);
          this.vehicles[itemIndex] = vehicle;
          this.toastr.success('Vehicle updated');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  trashVehicle(vehicle: Vehicle) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ vehicle: Vehicle }>(
        this.accountService.REST_API_SERVER_CALC + 'vehicleServlet?action=TRASH&loggedInEmail=' + this.accountService.currentUser.email,
        vehicle
      )
      .subscribe(responseData => {
        if (responseData["Result"] == "OK") {
          const index = this.vehicles.findIndex(x => x.idVehicle === vehicle.idVehicle);
          this.vehicles.splice(index, 1);
          this.vehiclesChanged.next(this.vehicles.slice());
          this.selectedVehicle = undefined;
          this.toastr.success('This vehicle has been deleted', 'Vehicle deleted');
        } else {
          this.error.next(responseData["Result"].message);
        }
      },
        error => {
          this.error.next(error.message);
        });
  }

  closeItemEditForm() {
    this.selectedItem = undefined;
    this.selectedItemEvent.next(undefined);

    this.selectedEditMode = undefined;
    this.selectedEditModeEvent.next(undefined);
  }

  deleteItemInfoListCookie() {
    this.cookieService.delete(Item.servletName);
    this.cookieService.delete(Item.tableNameIdList);
    this.cookieService.delete(Item.tableTitleItem);
  }

  deleteFromCookie() {
    this.cookieService.delete(Vehicle.selectedVehicle);
    this.cookieService.delete(Vehicle.idVehicleSelected);

  }

  onCloseForm() {
    this.modalService.dismissAll();
  }

  onCloseDetails() {
    this.selectedVehicle = undefined;
    this.deleteFromCookie()
  }

  onInitGraph() {

    console.log('ngOnInit1123');
    // this.onInitItems();

    this.graphJob = this.setGraphJob(this.getItemsJobArray(this.itemsLastRev));
    this.graphTopo = this.setGraphTopo(this.getItemsTopoArray(this.itemsLastRev));
    console.log("initi" + this.graphJob);


  }

  getItemsJobArray(items: Item[]): string[] {

    let itemsArr: string[] = [];
    items.forEach(item => {
      if (item.jobName != "")
        itemsArr.push(item.jobName);
    });
    var unique = itemsArr.filter(this.onlyUnique);
    unique.sort();
    return unique;

  }

  getItemsTopoArray(items: Item[]): string[] {

    let itemsArr: string[] = [];
    items.forEach(item => {
      if (item.topologicalName != "")
        itemsArr.push(item.topologicalName);
    });

    var unique = itemsArr.filter(this.onlyUnique);
    unique.sort();
    return unique;

  }

  // restituisce label e data
  setGraphJob(appArray: string[]) {
    let data = [], label = [], prev;

    for (var i = 0; i < appArray.length; i++) {

      var weight = 0;
      this.itemsLastRev.forEach(item => {
        if (item.jobName == appArray[i]) {
          weight += item.weight;
        }
      });

      if (appArray[i] !== prev && weight != 0) {
        label.push(appArray[i]);
        data.push(weight.toFixed(2));
      } else {
        data[data.length - 1]++;
      }
      prev = appArray[i];
    }
    return [label, data]
  }

  // restituisce label e data
  setGraphTopo(appArray: string[]) {
    let data = [], label = [], prev;

    var weightTot = 0;
    this.itemsLastRev.forEach(item => {
      weightTot += item.weight;
    });

    var weightOther = 0;
    for (var i = 0; i < appArray.length; i++) {

      var weight = 0;
      this.itemsLastRev.forEach(item => {
        if (item.topologicalName == appArray[i]) {
          weight += item.weight;
        }
      });

      if (appArray[i] !== prev) {

        if (weight >= weightTot * 0.07 && weightTot != 0) {
          label.push(appArray[i]);
          data.push(weight.toFixed(2));
        } else {
          weightOther = + weight.toFixed(2);
        }


      } else {
        data[data.length - 1]++;
      }
      prev = appArray[i];
    }

    if (weightOther != 0) {
      label.push('other');
      data.push(weightOther);
    }


    return [label, data]
  }


  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  importJSON( json: string) {

    console.log(json);
    
    var tableName ='BaseLineItem';
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ item: Item }>(
        this.accountService.REST_API_SERVER_CALC
        + tableName + 'Servlet?action=IMPORTJSON&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' + this.cookieService.get(ProjectCALC.idProjectSelected),
        // '[{"MICAD_GH":1,"PRODUCT":"Sea_water","IS_OPT":"False","NAME":"Pipe","X":3201,"Y":-6280,"Z":0,"UNIT_WEIGHT":1.79,"QUANTITY":18.6,"ASSEMBLY_HOUR":null,"ASSEMBLY_NAME":"Pipe","TOPOLOGICAL":"Engine Room","SUPPL_CODE":"Bilge EASYFLEX/7-540/R","UNITS":"m","JOB":"System"},{"MICAD_GH":1,"PRODUCT":"Sea_water","IS_OPT":"False","NAME":"Hose Connector M","X":-1244,"Y":-10812,"Z":0,"UNIT_WEIGHT":195,"QUANTITY":1,"ASSEMBLY_HOUR":null,"ASSEMBLY_NAME":"Fitting","TOPOLOGICAL":"Engine Room","SUPPL_CODE":null,"UNITS":"n","JOB":"System"},{"MICAD_GH":1,"PRODUCT":"Sea_water","IS_OPT":"False","NAME":"Hose Connector M","X":10937,"Y":-4541,"Z":0,"UNIT_WEIGHT":250,"QUANTITY":1,"ASSEMBLY_HOUR":null,"ASSEMBLY_NAME":"Fitting","TOPOLOGICAL":"Engine Room","SUPPL_CODE":null,"UNITS":"n","JOB":"System"}]'
        json
        )
      .subscribe(responseData => {

        if(responseData["Result"] == "OK"){
          this.toastr.success('JSON added');
          this.onInitItems();

    this.itemsChanged.subscribe(() => {
      setTimeout(() => {
        // console.log(document.querySelector('#bubbleChart'));
        drawChart(this.itemsLastRev);
      }, 100);
    });
        }
       

      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        });

  }

 
}
