import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from 'src/app/account/account.service';
import { Item } from 'src/app/CALC/shared/item.model';
import { ProjectCALC } from '../../../shared/projectCALC.model';
import { VehicleService } from '../vehicle.service';

@Injectable({
  providedIn: 'root'
})
export class OptionalService {


  itemsEvent = new EventEmitter<Item[]>();
  itemsChanged = new Subject<Item[]>();
  items:Item[];


  itemsOpt: Item[];
  itemsOptMatched: Item[];

  rowGroupMetadata: any;
  expandedGroups: any;

  
  defaultMatchName = "Default";
  error = new Subject<string>();

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private vehicleService: VehicleService,
    private modalService: NgbModal,
    private toastr: ToastrService) { }


  
    public getAllOptionalItems(tableName: string): Observable<Item[]> {

      let url = this.accountService.REST_API_SERVER_CALC
        + tableName + 'Servlet?action=ALLLIST&loggedInEmail='
        + this.accountService.currentUser.email  
  
      return this.http.get<Item[]>(url)
        .pipe(map(result => result)
        );
  }
  
  
  public getOptLists(): Observable<Item[]> {

    let url = this.accountService.REST_API_SERVER_CALC
      + 'optListServlet?action=ALLLIST&loggedInEmail='
      + this.accountService.currentUser.email  

    return this.http.get<Item[]>(url)
      .pipe(map(result => result)
      );
}
    
  trashOptional(item: Item) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ item: Item }>(
        this.accountService.REST_API_SERVER_CALC + 'baseLineItemServlet?action=TRASHOPT&loggedInEmail='
        + this.accountService.currentUser.email + '&idItem=' + item.idItem,
        item
      )
      .subscribe(responseData => {
        if (responseData["Result"] == "OK") {
          console.log("dsfsfdsfds");
          const index = this.itemsOptMatched.findIndex(x => x.idItem == item.idItem);
          this.itemsOptMatched.splice(index, 1);
          this.vehicleService.selectedEditModeEvent.next(undefined);
          this.vehicleService.selectedEditMode = undefined;
          this.toastr.success('This item has been deleted', 'Optional items have been deleted');
        } else {
          this.error.next(responseData["Result"].message);
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }



 updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.itemsOptMatched) {
      this.itemsOptMatched.sort((a, b) => {
        if (a.matchName == this.defaultMatchName && b.matchName != this.defaultMatchName)
          return 1;
        if (b.matchName == this.defaultMatchName && a.matchName != this.defaultMatchName)
          return -1;
        if (b.matchName == this.defaultMatchName && a.matchName == this.defaultMatchName) {
          return a.name > b.name
            ? 1
            : a.name < b.name
              ? -1
              : 0;
        }

        return a.matchName > b.matchName
          ? 1
          : a.matchName < b.matchName
            ? -1
            : a.name > b.name
              ? 1
              : a.name < b.name
                ? -1
                : 0;;
      });
      for (let i = 0; i < this.itemsOptMatched.length; i++) {
        let rowData = this.itemsOptMatched[i];
        let matchName = rowData.matchName;
        // if (matchName != 'Default') {
        if (i == 0) {
          this.rowGroupMetadata[matchName] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.itemsOptMatched[i - 1];
          let previousRowGroup = previousRowData.matchName;
          if (matchName === previousRowGroup)
            this.rowGroupMetadata[matchName].size++;
          else
            this.rowGroupMetadata[matchName] = { index: i, size: 1 };
        }
        // }
      }

      this.expandedGroups = {};
      this.expandedGroups[this.defaultMatchName] = true;
     
    }

    console.log(this.rowGroupMetadata);
    console.log(this.expandedGroups);

  }

  matchConflictOpt(items: Item[]) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ items: Item }>(
        this.accountService.REST_API_SERVER_CALC
        +  'baseLineItemServlet?action=MATCHCONFLICTOPT&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' + items[0].idForeign,
        items
      )
      .subscribe(responseData => {
        // devo trovare l'item negli items e sostituirlo
        let retrievedItems = responseData.items;
        // let itemIndex = this.items.findIndex(item => item.idItem == retrievedItem.idItem);
        // this.items[itemIndex] = retrievedItems;
        // // this.items.unshift(item);
        // this.itemsChanged.next(this.items.slice());
        this.toastr.success('Match conflict added');
      },
        error => {
          this.error.next(error.message);
        });

  }


  
  matchInseparableOpt(items: Item[]) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ items: Item }>(
        this.accountService.REST_API_SERVER_CALC
        +  'baseLineItemServlet?action=MATCHINSEPARABLEOPT&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' + items[0].idForeign,
        items
      )
      .subscribe(responseData => {
        // devo trovare l'item negli items e sostituirlo
        let retrievedItems = responseData.items;
        this.toastr.success('Match inseparable added');
      },
        error => {
          this.error.next(error.message);
        });

  }



  

  matchConflictOptEdit(items: Item[], matchName: string) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ items: Item }>(
        this.accountService.REST_API_SERVER_CALC
        +  'baseLineItemServlet?action=MATCHCONFLICTOPTEDIT&loggedInEmail=' + this.accountService.currentUser.email 
        + '&idForeign=' + items[0].idForeign ,
        [items, matchName]
      )
      .subscribe(responseData => {
        // devo trovare l'item negli items e sostituirlo
        // let retrievedItems = responseData.items;
        this.vehicleService.getItems("BaseLineItem", +this.cookieService.get(ProjectCALC.idProjectSelected)).subscribe(
          res => {
            this.itemsChanged.next(res["Records"]);
            this.vehicleService.itemsChanged.next(res["Records"]);
            }
        );
        this.toastr.success('Match conflict added');
      },
        error => {
          this.error.next(error.message);
        });

  }


  
  matchInseparableOptEdit(items: Item[], matchName: string) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ items: Item }>(
        this.accountService.REST_API_SERVER_CALC
        +  'baseLineItemServlet?action=MATCHINSEPARABLEOPTEDIT&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' + items[0].idForeign,
        [items, matchName]
      )
      .subscribe(responseData => {
        // devo trovare l'item negli items e sostituirlo
        // let retrievedItems = responseData.items;
        this.vehicleService.getItems("BaseLineItem", +this.cookieService.get(ProjectCALC.idProjectSelected)).subscribe(
          res => {
            this.itemsChanged.next(res["Records"]);
            this.vehicleService.itemsChanged.next(res["Records"]);
            }
        );
        this.toastr.success('Match inseparable added');
      },
        error => {
          this.error.next(error.message);
        });

  }







  freeMatch(matchName: string){
    this.http
      .post<{ matchName: string }>(
        this.accountService.REST_API_SERVER_CALC + 'baseLineItemServlet?action=FREEMATCH&loggedInEmail=' + this.accountService.currentUser.email+ '&idForeign=' + this.cookieService.get(ProjectCALC.idProjectSelected),
        matchName
      )
      .subscribe(responseData => {

        // approval = responseData.approval;
        // this.approvals.unshift(approval);
        // this.approvalsChanged.next(this.approvals.slice());
        console.log(responseData);
        if(responseData)
        this.toastr.success('Match is now free');
      },
        error => {
          this.error.next(error.message);
        });
  }


  
  
  updateSelectedOpt(items: Item[]) {
    var itemSelected= items.filter(
      item =>
      item.name != ''
    );
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ items: Item }>(
        this.accountService.REST_API_SERVER_CALC
        +  'optItemServlet?action=UPDATESELECTEDOPT&loggedInEmail=' + this.accountService.currentUser.email 
        + '&idForeign=' + items[0].idForeign 
        + '&idProject=' + this.cookieService.get(ProjectCALC.idProjectSelected),
        itemSelected
      )
      .subscribe(responseData => {
        this.vehicleService.onInitItems();
        this.toastr.success('Optional list updated');
      },
        error => {
          this.error.next(error.message);
        });

  }  
  trashAllOptItems() {
    this.http
      .get(
        this.accountService.REST_API_SERVER_CALC
        +  'optItemServlet?action=TRASHALLOPT&loggedInEmail=' + this.accountService.currentUser.email + '&idForeign=' + this.vehicleService.items[0].idForeign
        
      )
      .subscribe(responseData => {
        this.vehicleService.onInitItems();
        this.toastr.success('Trash optional items successed');
      },
        error => {
          this.error.next(error.message);
        });

  }

  onCloseForm() {
    this.modalService.dismissAll();
  }

}
