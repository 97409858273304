import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ɵINTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { Item } from 'src/app/CALC/shared/item.model';
import { ProjectCALC } from '../../../../shared/projectCALC.model';
import { VehicleService } from '../../vehicle.service';
import { OptionalService } from '../optional.service';

@Component({
  selector: 'app-optional-select',
  templateUrl: './optional-select.component.html',
  styleUrls: ['./optional-select.component.scss']
})
export class OptionalSelectComponent implements OnInit {

  
  
  
  optionalItem: Item;
  itemRow: Item[];
  items: Item[];
  itemsData: Item[];
  itemsOpt: Item[];
  itemsOptClone: Item[];
  optToAdd: Item[];
  selectedOptItems: Item[];
  cols: any[];

  //chiedere quali devono essere le colonne da export
  @ViewChild('dt') table: Table;

  jobsFilter: any[] = [];
  productsFilter: any[] = [];
  designsFilter: any[] = [];
  unitsOfMeasuresFilter: any[] = [];
  topologicalsFilter: any[] = [];

  revsFilter: any[] = [{ label: "APG", value: "APG" },
  { label: "001", value: "001" }];
  typesFilter: any[] = [{ label: "CAT", value: "CAT" },
  { label: "DWG", value: "DWG" }];

  requestType = "BaseLineItem";
  idList = 0;

  constructor(public vehicleService: VehicleService,
    public cookieService: CookieService,
    private modalService: NgbModal,
    private optionalService:OptionalService,
    private toastr: ToastrService) {



  }

  ngOnInit(): void {


    this.selectedOptItems = [];
    // devo prendere gli item gia inseriti 
    // devo prendere gli item disponibili
    // fare l'incrocio dei dati e far vedere solo quelli non ancora inseriti 
// attenzione agli item che sono in contrasto 
// attenzione agli optional che devono andare per forza con altri optional
    this.vehicleService.closeItemEditForm();

    
    this.vehicleService.getItems("BaseLineItem", +this.cookieService.get(ProjectCALC.idProjectSelected)).subscribe(
      res => {
        this.items = res["Records"];
        this.itemsOpt = this.items.filter(t => t.isOptional === true && t.isTrash === false);

        this.vehicleService.sortByNameItemsArray(this.itemsOpt);

        this.itemsOptClone = this.itemsOpt.slice();
        // console.log("this.items");
        // console.log(this.items);
        

        this.vehicleService.items.forEach(optInList => {
          this.itemsOpt.filter((optSelToChId) => {
            if(optInList.name == optSelToChId.name){
              optSelToChId.idItem = optInList.idItem;
            }
            
          });

          
          this.selectedOptItems = [...this.selectedOptItems, optInList];
        });
        
       
        this.selectedOptItems.forEach((optSel) => {
        // cerco di trovare nella lista in visualizzazione itemsOpt quelli che hanno lo stesso conflict code e id diverso, vanno eliminati
        
        this.onSelectAlgorithm(optSel);
      });

      this.selectedOptItems = [...new Map(this.selectedOptItems.map(item =>
        [item["name"], item])).values()];

        this.itemsOpt.forEach(item => {
  
          this.jobsFilter.push({ label: item.jobName, value: item.jobName });
          this.productsFilter.push({ label: item.productName, value: item.productName });
          this.topologicalsFilter.push({ label: item.topologicalName, value: item.topologicalName });
          this.unitsOfMeasuresFilter.push({ label: item.unitsOfMeasureName, value: item.unitsOfMeasureName });
          this.designsFilter.push({ label: item.designName, value: item.designName });
  
        });
  
  
  
        this.jobsFilter = this.jobsFilter.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.label === test.label
          )
        ).sort();
  
        this.productsFilter = this.productsFilter.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.label === test.label
          )
        ).sort();
  
        this.topologicalsFilter = this.topologicalsFilter.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.label === test.label
          )
        ).sort();
  
        this.unitsOfMeasuresFilter = this.unitsOfMeasuresFilter.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.label === test.label
          )
        ).sort();
  
        this.designsFilter = this.designsFilter.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.label === test.label
          )
        ).sort();
      }
    );

    



    this.cols = [

      { field: 'name', header: 'Name', width: "260px" },
      { field: 'jobName', header: 'Job', width: "120px" },
      { field: 'topologicalName', header: 'Topological', width: "150px" },
      { field: 'weight', header: 'Weight', width: "120px" }
     
    ];

    

  }

  refreshDataForParams(params: Params) {
    if (params == undefined || !this.isFunction(params.get)) {
      this.idList = +this.cookieService.get(ProjectCALC.idProjectSelected);
      this.requestType = "BaseLineItem";
    } else {
      this.requestType = params.get("listType");
      this.idList = params.get("idList");
    }
  }



  onSelectAlgorithm(selOpt: Item){
    var wasCheck = false;
    this.selectedOptItems.forEach(opt => {
      if(opt.idItem == selOpt.idItem){
        wasCheck = true;
      }
    });

    if(wasCheck){
      var indexsArray = [];
      // qui cercare i suoi simili e toglierli dal array in visualizzazione per gestire il conflict
      // se ha un codice Conflict, togliere gli altri items con il codice dal arrayKCKC

      if(selOpt.conflictOptCode !=0){

        this.itemsOpt = this.itemsOpt.filter(optVis => optVis.idItem == selOpt.idItem || optVis.conflictOptCode != selOpt.conflictOptCode );
      
      }

      if(selOpt.inseparableOptCode !=0){
      // devo aggiungere gli inseparableitem al selectedOptItems
        this.optToAdd = this.itemsOpt.filter(optVis => optVis.idItem != selOpt.idItem && optVis.inseparableOptCode == selOpt.inseparableOptCode );
        
        this.optToAdd.forEach(optItem => {
          // con il push() non aggiorna la vista del selection
          // quando fai il push non funziona il two way binding
          this.selectedOptItems = [...this.selectedOptItems, optItem];
        });
        
      }

      
    } else {

      if(selOpt.conflictOptCode !=0){
        this.itemsOptClone.forEach(optVis => {
          if(optVis.idItem != selOpt.idItem && optVis.conflictOptCode == selOpt.conflictOptCode && optVis.conflictOptCode != 0){
            this.itemsOpt.push(optVis);
          }
        });
      }

      if(selOpt.inseparableOptCode !=0){
        // devo aggiungere gli inseparableitem al selectedOptItems
          
            this.selectedOptItems = this.selectedOptItems.filter(optVis => optVis.inseparableOptCode != selOpt.inseparableOptCode );
      }

    }

    this.vehicleService.sortByNameItemsArray(this.itemsOpt);
    
  }

  onSendSelectedOptItems(){

    if((this.selectedOptItems.length > 0 )){
     
    this.selectedOptItems.forEach(element => {
      // dove e l'informazione della lista
      element.idForeign = +this.cookieService.get(Item.tableNameIdList);
    });
      this.modalService.dismissAll();
      this.optionalService.updateSelectedOpt(this.selectedOptItems);

      // this.optItemsMatched.forEach(opt => {
      //   this.optionalService.itemsOptMatched.find(x => x.idItem === opt.idItem).matchName=this.matchName;
      // });
      // this.optionalService.updateRowGroupMetaData();
    }
    
  }
  onTrashAllOptItems(){

    if(this.vehicleService.items){
     
      this.modalService.dismissAll();
      this.optionalService.trashAllOptItems();

    } else {
      this.toastr.error('No optional items to trash');
    }
    
  }



  isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }
  // per filtri
  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
      const activity = parseInt(value);

      if (!isNaN(activity)) {
        this.table.filter(activity, 'activity', 'gte');
      }
    }
  }

  onDateSelect(value) {
    this.table.filter(this.formatDate(value), 'date', 'equals')
  }

  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  onRepresentativeChange(event) {
    this.table.filter(event.value, 'representative', 'in')
  }

  onRowSelect(event) {
    alert('info');
  }

  onRowUnselect(event) {
    alert('info');
  }



}
