<div class="content">


    <div class="row" style="font-weight: bold;">
        <div class="col-sm-10">
            <h5>Clone {{variant.variantName}}</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="variantService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>

    <div class="row">
        <div class="col-sm-1">
            <i class="bx bx-help-circle font-size-22" style="padding-top: 160%;"></i>
        </div>
        <div class="col-sm-11">
            <h4>The following objects associated with the variant will be cloned as we proceed:</h4>
            <ul style="text-align: left;">
                <li>
                    <h6>Tank list {{variant.tankListName}}</h6>
                </li>
                <li>
                    <h6>Crew list {{variant.crewListName}}</h6>
                </li>
                <li>
                    <h6>Pax list {{variant.paxListName}}</h6>
                </li>
            </ul>
            <h6>Version, Propulsion and Optional Item will not be cloned.</h6>
            <h6>The specific lists will be associated with the cloned variant. At any time it will be possible to clone the specific lists and associate them with the new variant.</h6>
        </div>
    </div>
    <hr>

    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="variantForm" (ngSubmit)="onSubmit()">

                <input type="hidden" id="id" formControlName="id" class="form-control">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" id="name" formControlName="name" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-11">
                    </div>
                    <div class="col-sm-1" style="padding-left: 15px;">
                        <button type="submit" class="btn btn-success" [disabled]="!variantForm.valid"><i class="bx bxs-copy font-size-50"></i></button>
                        <div class="textUnderIcon">Clone</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>