import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Tank } from 'src/app/CALC/shared/tank.model';
import { TankService } from '../tank.service';

@Component({
  selector: 'app-tank-delete',
  templateUrl: './tank-delete.component.html',
  styleUrls: ['./tank-delete.component.scss']
})
export class TankDeleteComponent implements OnInit {

  @Input() public tank: Tank;
  constructor(public tankService: TankService,
              private modalService: NgbModal,
              public cookieService: CookieService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.tankService.trashTank(this.tank);
    // this.vehicleService.selectedTank.next(undefined);
   
  }
  onCancel(){
    this.modalService.dismissAll();
    
  }

}
