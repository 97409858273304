<div class="content">
    <div class="row">
        <div class="col-sm-12">
            <form class="form" [formGroup]="appendageForm">
                <input type="hidden" id="id" formControlName="id" class="form-control">
                <input type="hidden" id="date" formControlName="date" class="form-control">
                <input type="hidden" id="category" formControlName="category" class="form-control">
                <input type="hidden" id="project" formControlName="project" class="form-control">

                <div class="row">
                    <div class="col-sm-10">
                        <h5>Clone {{appendage?.name}}</h5>
                    </div>
                    <div class="col-sm-2">
                        <button type="button" class="close" aria-label="Close" title="Close" (click)="appendageService.onCloseForm()">
                                 <span aria-hidden="true"> &times;</span>
                              </button>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" class="form-control" formControlName="name" #s_name (change)="nameCheckUnique()" />
                        </div>
                        <div *ngIf="appendageForm.controls['name'].invalid && (appendageForm.controls['name'].dirty || appendageForm.controls['name'].touched)" class="alert alert-danger">
                            <div *ngIf="appendageForm.controls['name'].errors.required">
                                Name is required.
                            </div>
                            <div *ngIf="appendageForm.controls['name'].errors.name">
                                Enter Valid Name
                            </div>
                        </div>
                        <div *ngIf="nameAlredyExist != ''" class="alert alert-danger">
                            {{nameAlredyExist}}
                        </div>
                    </div>
                </div>





                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>A*</label>
                            <input type="number" step=".01" min="0" id="A" formControlName="A" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="type == 4">
                            <label>D*</label>
                            <input type="number" id="D" step=".001" min="0" formControlName="D" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="type == 1 || type == 2 || type == 5">
                            <label>t*</label>
                            <input type="number" step=".001" min="0" id="t" formControlName="t" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="type == 1 || type == 2 || type == 5">
                            <label>c*</label>
                            <input type="number" step=".001" min="0" id="c" formControlName="c" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="type == 3 || type == 4">
                            <label>L*</label>
                            <input type="number" step=".001" min="0" id="L" formControlName="L" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group" *ngIf="type == 4">
                            <label>epsilon*</label>
                            <input type="number" id="epsilon" step=".1" min="0" formControlName="epsilon" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label>Px</label>
                            <input type="number" id="Px" step=".001" formControlName="Px" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label>Py</label>
                            <input type="number" id="Py" step=".001" formControlName="Py" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form form-group">
                            <label>Pz</label>
                            <input type="number" id="Pz" step=".001" formControlName="Pz" class="form-control">
                        </div>
                    </div>
                </div>



                <div class="row" style="padding-top: 10px;">
                    <div class="col-sm-11">
                    </div>

                    <div class="col-sm-1">
                        <button type="submit" class="btn btn-success" [disabled]="!appendageForm.valid || nameAlredyExist != ''"><i class="bx bxs-save font-size-50"(click)="onSubmit()" title="Save data"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>