import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleService } from '../../../vehicles/vehicle.service';
import { List } from '../../../../shared/list.model';
import { ListService } from '../list.service';

@Component({
  selector: 'app-list-delete',
  templateUrl: './list-delete.component.html',
  styleUrls: ['./list-delete.component.scss']
})
export class ListDeleteComponent implements OnInit {

  @Input() public list: List;
  constructor(public listService: ListService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.listService.deleteList(this.list)
  }
  onCancel(){
    this.modalService.dismissAll();
    
  }
}
