import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Hull } from '../../shared/hull.model';
import { ProjectDELPHY } from '../../shared/projectDELPHY.model';

@Injectable({
  providedIn: 'root'
})
export class HullService {

  selectedEditMode: boolean;
  error = new Subject<string>();
  hulls: Hull[];
  selectedHull: Hull;
  selectedHullEvent = new EventEmitter<Hull>();

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  createHull(hull: Hull) {
    console.log(" dopo form hull");
    console.log(hull);
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ hull: Hull }>(
        this.accountService.REST_API_SERVER_DELPHY + 'hull/',
        hull
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          hull.id = responseData["id"];
          hull.date = responseData["date"];
          // this.hulls.unshift(hull);
          this.hulls.push(hull);
          this.toastr.success('Hull created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  updateHull(hull: Hull) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .put<{ hull: Hull }>(
        this.accountService.REST_API_SERVER_DELPHY + 'hull/',
        hull
      )
      .subscribe(responseData => {
        let itemIndex = this.hulls.findIndex(item => item.id == this.selectedHull.id);
        hull.date = responseData["date"];
        this.hulls[itemIndex] = hull;
        this.selectedHull = hull;
        this.selectedHullEvent.next(hull);
        this.toastr.success('Hull  updated');
      },
        error => {
          this.toastr.error(error.message);
            console.log(error.message);
            // this.error.next(error.message);
        });

  }

  trashHull() {
   
    this.http
      .delete(this.accountService.REST_API_SERVER_DELPHY 
        + 'hull?id=' + this.selectedHull.id)
        .subscribe(responseData => {
          if (responseData != undefined) {
            const index = this.hulls.findIndex(x => x.id === this.selectedHull.id);
            this.hulls.splice(index, 1);
            this.toastr.success('This hull has been deleted', 'Hull deleted');
            this.selectedHullEvent.next(undefined);
            this.selectedHull = undefined;
          } else {
            this.error.next(responseData["Result"].message);
          }
        },
          error => {
            this.error.next(error.message);
          });

  }

  public getHulls(): Observable<Hull[]> {
    return this.http.get<Hull[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'hull/?project_id=' + this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY)
    )
      .pipe(
      );
  }

  getHull(id: number) {
    if (this.hulls == undefined)
      return undefined;
    const el = this.hulls.find(
      (r) => {
        return r.id == id;
      }
    );
    return el;
  }

  onCloseForm() {
    this.modalService.dismissAll();
  }
}
