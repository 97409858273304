<!-- <form [formGroup]="signupForm" (ngSubmit)="onSubmitRegister()">
    <h3 style="
  padding-bottom: 2%;
  font-family: Barlow;
  font-weight: bold;
  font-size: 2 rem;     
  ">
        Create Your Account</h3>
    <fieldset class="col s12">



        <div class="row">
            <div class="col-sm-6">
                <div class="form form-group">
                    <label>Name*</label>
                    <input type="text" id="name" formControlName="name" class="form-control">
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form form-group">
                    <label>Surname*</label>
                    <input type="text" id="surname" formControlName="surname" class="form-control">
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-6">
                <div class="form form-group">
                    <label>Company*</label>
                    <input type="text" id="company" formControlName="company" class="form-control">
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form form-group">
                    <label>Country*</label>
                    <input type="text" id="country" formControlName="country" class="form-control">
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-12">
                <div class="form form-group">
                    <label>Email*</label>
                    <input type="email" id="email" formControlName="email" class="form-control">
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-sm-6">
                <div class="form form-group">
                    <label>Password*</label>
                    <input type="password" id="txtNewPassword" onkeyup="passwordkeyup()" formControlName="password" class="form-control">
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form form-group">
                    <label>Confirm password*</label>
                    <input type="password" id="txtConfirmPassword" onkeyup="passwordkeyup()" formControlName="confirmPassword" class="form-control">
                </div>
            </div>
        </div>



        <div class="registrationFormAlert" id="divCheckPasswordMatch"></div>


    </fieldset>
    <button class="btn login" type="submit" style="color:#040E1D" value="Register" id="register" [disabled]="!signupForm.valid">Register</button>

</form> -->

<div class="row" style="text-align: center;">
    <div class="col-sm-12">
        <h4>For registration send an email to</h4>
        <h4>easypro@micad.it</h4>
    </div>
</div>