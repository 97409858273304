import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Hull } from '../../../shared/hull.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { HullService } from '../hull.service';

@Component({
  selector: 'app-hull-edit',
  templateUrl: './hull-edit.component.html',
  styleUrls: ['./hull-edit.component.scss']
})
export class HullEditComponent implements OnInit {

  hullForm: FormGroup;

  @Input() hull: Hull;

  // selectedJob: List;
  selectedEditModeSubscription: Subscription;
  selectedHullSubscription: Subscription;

  nameAlredyExist = "";

  constructor(public hullService: HullService,
    public accountService: AccountService,
    public cookieService: CookieService) {

  }

  ngOnInit(): void {
    this.initForm();
  }


  
  nameCheckUnique() {

    console.log("Dentro check");
    let flag = false;
    

    this.hullService.hulls.forEach(hull => {
      if(hull.name == this.hullForm.controls['name'].value)
          flag = true
    });

    if (flag) {
      this.nameAlredyExist = "Name Alredy Exist";
    }
    else{
      this.nameAlredyExist = "";
    }

  }
  onSubmit() {
    // this.dialogRef.close();
    if (this.hullForm.valid && this.nameAlredyExist == "") {

      if (this.hullService.selectedEditMode) {

        this.hullService.updateHull(this.hullForm.value);
      } else {
        this.hullService.createHull(this.hullForm.value)
      }
      this.hullService.onCloseForm()
    }

  }


  private initForm() {
    let id: number;
    let name: string;
    let date: string;
    let b: number;
    let boa: number;
    let loa: number;
    let beta: number;
    let epsilon: number;
    let Px: number;
    let Pz: number;
    let project = +this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY);
   
    console.log(" edit mode imit");
    console.log(this.hullService.selectedEditMode);
    
    if (this.hullService.selectedEditMode && this.hull != undefined) {
      id = this.hull.id;
      name = this.hull.name;
      date = this.hull.date;
      b = this.hull.b;
      boa = this.hull.boa;
      loa = this.hull.loa;
      beta = this.hull.beta;
      epsilon = this.hull.epsilon;
      Px = this.hull.Px;
      Pz = this.hull.Pz;

      

    }

    this.hullForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(name, Validators.required),
      'date': new FormControl(date),
      'b': new FormControl(b, Validators.required),
      'boa': new FormControl(boa, Validators.required),
      'loa': new FormControl(loa, Validators.required),

      'beta': new FormControl(beta, Validators.required),
      'epsilon': new FormControl(epsilon, Validators.required),
      'Px': new FormControl(Px, Validators.required),
      'Pz': new FormControl(Pz, Validators.required),
      'project': new FormControl(project)

    });
  }

}
