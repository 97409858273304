import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Variant } from 'src/app/CALC/shared/variant.model';
import { VariantService } from '../variant.service';

@Component({
  selector: 'app-variant-delete',
  templateUrl: './variant-delete.component.html',
  styleUrls: ['./variant-delete.component.scss']
})
export class VariantDeleteComponent implements OnInit {

  @Input() public variant: Variant;
  constructor(public variantService: VariantService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.variantService.trashVariant(this.variant);
   
  }
  

}
