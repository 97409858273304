<div class="content">
    <div class="row">
        <div class="col-sm-10" *ngIf="projectService.selectedEditMode == false">
            <h5>Add project form</h5>
        </div>
        <div class="col-sm-10" *ngIf="projectService.selectedEditMode == true">
            <h5>Edit {{project.projectName}}</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="projectService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="projectForm" (ngSubmit)="onSubmit()">

                <input type="hidden" id="idProject" formControlName="idProject" class="form-control">
                <input type="hidden" id="jobListName" formControlName="jobListName" class="form-control">
                <input type="hidden" id="topologicalListName" formControlName="topologicalListName" class="form-control">
                <input type="hidden" id="designListName" formControlName="designListName" class="form-control">
                <input type="hidden" id="unitsOfMeasureListName" formControlName="unitsOfMeasureListName" class="form-control">
                <input type="hidden" id="isTrash" formControlName="isTrash" value=false class="form-control">
                <input type="hidden" id="isDelete" formControlName="isDelete" value=false class="form-control">

                <input type="hidden" id="creationDate" formControlName="creationDate" class="form-control">
                <input type="hidden" id="projectOwner" formControlName="projectOwner" class="form-control">
                <input type="hidden" id="imgProjectOwner" formControlName="imgProjectOwner" class="form-control">


                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" id="projectName" formControlName="projectName" class="form-control" #name>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label>Company name*</label>
                            <select class="form-control" formControlName="company" id="company">

                                <option *ngFor="let company of projectService.companies" [ngValue]="company">
                                    {{company.name}}
                                </option>

                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-sm-3">
                        <div class="form form-group">
                            <label>Length*</label>
                            <input type="number" step=".01" id="length" formControlName="length" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form form-group">
                            <label>Beam*</label>
                            <input type="number" step=".01" id="beam" formControlName="beam" class="form-control">
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div class="form form-group">
                            <label>Start date*</label>
                            <input type="date" formControlName="startDate" id="startDate" class="form-control">
                        </div>

                    </div>
                    <div class="col-sm-3">

                        <div class="form form-group">
                            <label>End date*</label>
                            <input type="date" formControlName="endDate" id="endDate" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="row" *ngIf="projectService.selectedEditMode==false">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="idJobList">Select a Job List*</label>
                            <select class="form-control" formControlName="idJobList" id="idJobList">

                                <option *ngFor="let jobList of jobLists" [value]="jobList.id">
                                    {{jobList.name}}
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="idTopologicalList">Select a Topological List*</label>
                            <select class="form-control" formControlName="idTopologicalList" id="topologicalListName">

                                <option *ngFor="let topologicalList of topologicalLists" [value]="topologicalList.id">
                                    {{topologicalList.name}}
                                </option>

                            </select>
                        </div>
                    </div>
                </div>


                <div class="row" *ngIf="projectService.selectedEditMode==false">
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="idDesignList">Select a Design List*</label>
                            <select class="form-control" formControlName="idDesignList" id="idDesignList">

                                <option *ngFor="let designList of designLists" [value]="designList.id">
                                    {{designList.name}}
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form form-group">
                            <label for="idUnitsOfMeasureList">Select a Units Of Measure List*</label>
                            <select class="form-control" formControlName="idUnitsOfMeasureList" id="idUnitsOfMeasureList">

                                <option *ngFor="let unitsOfMeasureList of unitsOfMeasureLists" [value]="unitsOfMeasureList.id">
                                    {{unitsOfMeasureList.name}}
                                </option>

                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <!-- <input type="file" id="img" formControlName="img" (change)="handleFileInput($event.target.files)"> -->
                            <label>Project image URL</label>
                            <input type="text" id="img" formControlName="img" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Note</label>
                            <input type="text" id="note" formControlName="note" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-sm-11">
                    </div>

                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(projectService.selectedEditMode == false)">
                        <button type="submit" class="btn btn-success" [disabled]="!projectForm.valid"><i class="bx bxs-save font-size-50"></i></button>
                        <div class="textUnderIcon">Save</div>
                    </div>
                    <div class="col-sm-1" style="padding-left: 15px;" *ngIf="(projectService.selectedEditMode == true)">
                        <button type="submit" class="btn btn-success" [disabled]="!projectForm.valid"><i class="bx bxs-edit-alt font-size-50"></i></button>
                        <div class="textUnderIcon">Edit</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>