<div class="row full-row">
    <div class="col-on-five">
        <div>
            <app-propeller-list></app-propeller-list>
        </div>
        <hr>
        <div>
            <app-propeller-details></app-propeller-details>
        </div>
    </div>
    <div class="col-two-on-five">

        <app-propeller-ludwig></app-propeller-ludwig>

    </div>
    <div class="col-two-on-five">

        <app-propeller-plots></app-propeller-plots>

    </div>
</div>