import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { NgbNavModule, NgbDropdownModule, NgbModalModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { UIModule } from 'src/app/shared/ui/ui.module';
import { LoaderService } from 'src/app/core/services/loader.service';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CalcRoutingModule } from './calc-routing.module';
import { ProjectsRoutingModule } from './projects/projects-routing.module';
import { ProjectsComponent } from './projects/projects.component';
import { DashboardComponent } from './projects/dashboard/dashboard.component';
import { ListsComponent } from './projects/lists/lists.component';
import { ListEditComponent } from './projects/lists/list-edit/list-edit.component';;
import { TreegraphComponent } from './projects/dashboard/treegraph/treegraph.component';
import { ProjectEditComponent } from './projects/project-edit/project-edit.component';
import { ProjectDeleteComponent } from './projects/project-delete/project-delete.component';
import { ProjectDetailsComponent } from './projects/project-details/project-details.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { VehicleDetailsComponent } from './vehicles/vehicle-details/vehicle-details.component';
import { VehicleEditComponent } from './vehicles/vehicle-edit/vehicle-edit.component';
import { VehicleDeleteComponent } from './vehicles/vehicle-delete/vehicle-delete.component';
import { VariantsComponent } from './variants/variants.component';
import { VariantDetailsComponent } from './variants/variant-details/variant-details.component';
import { VariantDeleteComponent } from './variants/variant-delete/variant-delete.component';


import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { ReportsComponent } from './reports/reports.component';
import { ItemsComponent } from './vehicles/items/items.component';
import { CrewsComponent } from './vehicles/crews/crews.component';
import { TanksComponent } from './vehicles/tanks/tanks.component';
import { ItemEditComponent } from './vehicles/items/item-edit/item-edit.component';
import { ItemDeleteComponent } from './vehicles/items/item-delete/item-delete.component';
import { ItemCloneComponent } from './vehicles/items/item-clone/item-clone.component';
import { CrewEditComponent } from './vehicles/crews/crew-edit/crew-edit.component';
import { TankEditComponent } from './vehicles/tanks/tank-edit/tank-edit.component';
import {TabMenuModule} from 'primeng/tabmenu';
import { OptionalsComponent } from './vehicles/optionals/optionals.component';
import { OptionalEditComponent } from './vehicles/optionals/optional-edit/optional-edit.component';
import { OptionalDeleteComponent } from './vehicles/optionals/optional-delete/optional-delete.component';
import { OptionalSelectComponent } from './vehicles/optionals/optional-select/optional-select.component';
import {ToolbarModule} from 'primeng/toolbar';
import { OptionalMatchComponent } from './vehicles/optionals/optional-match/optional-match.component';
import {PickListModule} from 'primeng/picklist';
import {InputTextModule} from 'primeng/inputtext';
import { ListDeleteComponent } from './projects/lists/list-delete/list-delete.component';
import { VariantEditComponent } from './variants/variant-edit/variant-edit.component';
import { TankDeleteComponent } from './vehicles/tanks/tank-delete/tank-delete.component';
import { CrewDeleteComponent } from './vehicles/crews/crew-delete/crew-delete.component';
import { ListCloneComponent } from './projects/lists/list-clone/list-clone.component';
import { ItemPieGraphComponent } from './vehicles/items/item-pie-graph/item-pie-graph.component';
import { ProjectCloneComponent } from './projects/project-clone/project-clone.component';
import { OptionalMatchEditComponent } from './vehicles/optionals/optional-match-edit/optional-match-edit.component';
import { OptionalMatchFreeComponent } from './vehicles/optionals/optional-match-free/optional-match-free.component';
import { NoCommaPipe } from 'src/app/noComma.pipe';
import { ItemExportPDFComponent } from './vehicles/items/item-export-pdf/item-export-pdf.component';
import { VariantCloneComponent } from './variants/variant-clone/variant-clone.component';
import { FilterProjectsCALCPipe } from 'src/app/filter-projectCALC-company.pipe';
import { DibaComponent } from './dibas/diba/diba.component';
import { ImportgrasshopperComponent } from './vehicles/importgrasshopper/importgrasshopper.component';
import {NgxCSVtoJSONModule} from 'ngx-csvto-json';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};

@NgModule({
  declarations: [
    ProjectsComponent,
    DashboardComponent,
    ListsComponent,
    ListEditComponent,
    TreegraphComponent,
    ProjectEditComponent,
    ProjectDeleteComponent,
    ProjectDetailsComponent,
    VehiclesComponent,
    VehicleDetailsComponent,
    VehicleEditComponent,
    VehicleDeleteComponent,
    VariantsComponent,
    VariantDetailsComponent,
    VariantDeleteComponent,
    ReportsComponent,
    ItemsComponent,
    CrewsComponent,
    TanksComponent,
    ItemEditComponent,
    ItemDeleteComponent,
    ItemCloneComponent,
    CrewEditComponent,
    TankEditComponent,
    OptionalsComponent,
    OptionalEditComponent,
    OptionalDeleteComponent,
    OptionalSelectComponent,
    OptionalMatchComponent,
    ListDeleteComponent,
    VariantEditComponent,
    TankDeleteComponent,
    CrewDeleteComponent,
    ListCloneComponent,
    ItemPieGraphComponent,
    ProjectCloneComponent,
    OptionalMatchEditComponent,
    OptionalMatchFreeComponent,
    NoCommaPipe,
    ItemExportPDFComponent,
    VariantCloneComponent,
    FilterProjectsCALCPipe,
    DibaComponent,
    ImportgrasshopperComponent
  ],
  exports: [
    NoCommaPipe,
    FilterProjectsCALCPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    NgbModalModule,
    NgApexchartsModule,
    CalcRoutingModule,
    ProjectsRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    UIModule,
    WidgetModule,
    FullCalendarModule,
    NgbNavModule,
    NgbTooltipModule,
    PerfectScrollbarModule,
    SharedModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    ButtonModule,
    TabMenuModule,
    ToolbarModule,
    PickListModule,
    InputTextModule,
    NgbModule,
    NgxCSVtoJSONModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    LoaderService,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true }
  ]
})
export class CalcModule { }
