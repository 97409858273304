import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Crew } from 'src/app/CALC/shared/crew.model';
import { CrewService } from '../crew.service';

@Component({
  selector: 'app-crew-delete',
  templateUrl: './crew-delete.component.html',
  styleUrls: ['./crew-delete.component.scss']
})
export class CrewDeleteComponent implements OnInit {

  @Input() public crew: Crew;
  constructor(public crewService: CrewService,
    private modalService: NgbModal,
    public cookieService: CookieService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.crewService.deleteCrew(this.crew);
    // this.vehicleService.selectedTank.next(undefined);
   
  }
  
}
