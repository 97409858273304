import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from'@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { AuthHttpService } from 'src/app/core/services/authhttp.service';
import { AccountService } from '../../account.service';
@Component({
  selector: 'app-passwordchange',
  templateUrl: './passwordchange.component.html',
  styleUrls: ['./passwordchange.component.scss']
})
export class PasswordchangeComponent implements OnInit {
  error = '';
  changeForm: FormGroup;
  tokenPassReset: string;
  email: string;
 
  constructor(private formBuilder: FormBuilder, 
    private http: HttpClient,
    private route: ActivatedRoute, 
    private router: Router, 
    private authenticationService: AuthenticationService,
    private authHttpService: AuthHttpService,
    private toastr: ToastrService,
    private accountService: AccountService) { }

  ngOnInit(): void {
    this.tokenPassReset = this.route.snapshot.paramMap.get('tokenPassReset');
    this.http.post( this.accountService.REST_API_SERVER 
    + 'checkTokenAngular?tokenPassReset=' + this.tokenPassReset,
    this.tokenPassReset
    )
    .subscribe(responseData => {
      
      if(responseData['Result'] == 'OK'){
        this.buildForm();
        this.toastr.success('Token valid');
        this.email = responseData['Email']
      } else {
        this.toastr.error('Token expire');
        this.router.navigate(['/login']);
      }
    },
      error => {
        this.toastr.error(error.message);
        this.router.navigate(['/login']);
      console.log(error.message);
      });
    
    
  }


  buildForm(): void {
    this.changeForm = this.formBuilder.group({
      passwords: this.formBuilder.group({
        password: ['', [Validators.required]],
        confirmedPassword: ['', [Validators.required]],
      }, { validator: this.passwordConfirming }),

    });
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmedPassword').value) {
      return { invalid: true };
    }
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.changeForm.invalid) {
      return;
    } else {
      this.authHttpService.changePassword( this.changeForm.value.passwords.password, this.tokenPassReset)
        .pipe(first())
        .subscribe(
          data => {
            if (data['Result'] == 'OK') {
              this.toastr.success("Email with new access data sent to "+ data['Email']);
              // this.router.navigate(['/login']);
              console.log(data['Email'],this.changeForm.value.passwords.password);
              this.authHttpService.login(data['Email'],this.changeForm.value.passwords.password)
          .pipe(first())
          .subscribe(
            data => {
              if (data['Result'] == 'OK') {
                console.log(data['User']);
                this.accountService.setCurrentUser(data['User']);
                this.router.navigate(['/dashboardLinks']);

              } else if (data['Result'] == 'ERROR') {
                this.error = data['Message'];
                this.toastr.error(this.error);
              }

            },
            error => {
              console.log(error + " erroe")
              this.error = error ? error : '';
              this.router.navigate(['/login']);
            });

            } else if (data['Result'] == 'ERROR') {
              this.error = data['Message']
              this.toastr.error(this.error);
            }

          },
          error => {
            console.log(error + " erroe")
            this.error = error ? error : '';
            this.router.navigate(['/login']);
          });
    }
  }
}
