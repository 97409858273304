import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { OptionalService } from '../optional.service';

@Component({
  selector: 'app-optional-match-free',
  templateUrl: './optional-match-free.component.html',
  styleUrls: ['./optional-match-free.component.scss']
})
export class OptionalMatchFreeComponent implements OnInit {

  @Input() public matchName: string;
  constructor(public optionalService: OptionalService,
              private modalService: NgbModal,
              public cookieService: CookieService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    
    this.optionalService.freeMatch(this.matchName);

    this.optionalService.itemsOptMatched.forEach(opt => {
      if(opt.matchName == this.matchName){
        opt.matchName='Default'
        opt.conflictOptCode=0;
        opt.inseparableOptCode=0;
      }
        
    });
   

    this.optionalService.updateRowGroupMetaData();
   
  }

}
