import { Company } from "../CALC/shared/company.model";

export class User{
	
	static companyOwner: string = "Company Owner";
	static projectOwner: string = "Project Owner";
	static collaborator: string = "Collaborator";
	static viewer: string = "Viewer";

    public idUser: number;
	public name: string;
	public surname: string;
	public idParent: number;
	public profession: string;
	public department: string;
	public company: Company[];
	public phone: string;
	public email: string;
	public avatarImg: string;
	public city: string;
	public zipCode: number;
	public country: string;
	public tokenTW: string;
	public roleName: string;

	constructor($idUser: number, $name: string, $surname: string, $idParent: number, 
		$profession: string, $department: string, $company: Company[], $phone: string, 
		$email: string, $avatarImg: string, $city: string, $zipCode: number, 
		$country: string, $tokenTW: string, $roleName: string) {
		this.idUser = $idUser;
		this.name = $name;
		this.surname = $surname;
		this.idParent = $idParent;
		this.profession = $profession;
		this.department = $department;
		this.company = $company;
		this.phone = $phone;
		this.email = $email;
		this.avatarImg = $avatarImg;
		this.city = $city;
		this.zipCode = $zipCode;
		this.country = $country;
		this.tokenTW = $tokenTW;
		this.roleName = $roleName;
	}


}

	
	