<div id="layout-wrapper">

    <app-topbar-micapp (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
    </app-topbar-micapp>
  
    <app-sidebar-micapp [isCondensed]="isCondensed"></app-sidebar-micapp>
  
    <div class="main-content">
      <div class="page-content">
        <!-- content -->
        <router-outlet></router-outlet>
      </div>
  
      <!-- footer -->
    </div>
  </div>