import { Component, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { LcService } from '../../lcs/lc.service';

@Component({
  selector: 'app-dashboard-plots-weight',
  templateUrl: './dashboard-plots-weight.component.html',
  styleUrls: ['./dashboard-plots-weight.component.scss']
})
export class DashboardPlotsWeightComponent implements OnInit {

  @ViewChild('weightCanvas') weightCanvas;

  constructor(public lcService: LcService) { }

  elChart: any;

  ngOnInit(): void {

    this.lcService.lcsChanged.subscribe(() => {
      this.lcService.onInitGraph();
      this.chartMethod("Weight", this.weightCanvas, this.lcService.graph[0], this.lcService.graph[1]);
    });
  }

  chartMethod(title: string, elCanvas: any, labelsData: string[], dataData: number[]) {

    console.log('Dentro grafico pesi lc')
    let weightData = [];
    let maxWeight = 0;
    console.log("labelsData lc")
    console.log(labelsData)
    console.log("dataData lc")
    console.log(dataData)

    for (let i = 0; i < dataData.length; i++) {

      if (dataData[i]['r'] > maxWeight) {
        maxWeight = dataData[i]['r'];
      }
      weightData.push(
        dataData[i]['r']
      );
    }

    if (this.elChart != undefined) {

      this.elChart.data.labels.splice(0); // remove the label first
      // this.elChart.data.datasets.data.splice(0);

      this.elChart.data.labels = labelsData;
      this.elChart.data.datasets.data = weightData;
      this.elChart.update();

    } else {
      this.elChart = new Chart(elCanvas.nativeElement, {
        type: 'bar',
        options: {
          title: {
            display: false,
            text: title
          },
          legend: {
            position: 'top',
            labels: {
              fontSize: 14
            }

          },
          rotation: -0.1,
          scales: {

            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMin: 0,
                suggestedMax: maxWeight + 10,
              }
              ,
              scaleLabel: {
                display: true,
                labelString: 'Weight [t]'
              }
            }]
          }

        },
        data: {
          labels: labelsData,
          datasets: [{
            label: title,
            data: weightData,
            backgroundColor: 
            'rgba(0, 158, 227, 0.2)'
          ,
          borderColor: 
            'rgba(0, 158, 227, 0.5)'
          ,
          borderWidth: 1,
            fill: false
          }]
        }
      });
    }




  }





  getRandomColorHover() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',0.3)';
  }

}
