import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectDELPHY } from '../../shared/projectDELPHY.model';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-delete',
  templateUrl: './project-delete.component.html',
  styleUrls: ['./project-delete.component.scss']
})
export class ProjectDeleteComponent implements OnInit {

  @Input() public project: ProjectDELPHY;
  constructor(public projectService: ProjectService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.projectService.trashProject(this.project);
    this.projectService.selectedProjectEvent.next(undefined);
   
  }
  
}
