import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Lc } from '../../../shared/lc.model';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { LcService } from '../lc.service';

@Component({
  selector: 'app-lc-edit',
  templateUrl: './lc-edit.component.html',
  styleUrls: ['./lc-edit.component.scss']
})
export class LcEditComponent implements OnInit {

  lcForm: FormGroup;

  @Input() lc: Lc;


  nameAlredyExist = "";

  constructor(public lcService: LcService,
    public accountService: AccountService,
    public cookieService: CookieService) {

  }

  ngOnInit(): void {
    this.initForm();
  }


  
  nameCheckUnique() {

    console.log("Dentro check");
    let flag = false;
    

    this.lcService.lcs.forEach(lc => {
      if(lc.name == this.lcForm.controls['name'].value)
          flag = true
    });

    if (flag) {
      this.nameAlredyExist = "Name Alredy Exist";
    }
    else{
      this.nameAlredyExist = "";
    }

  }
  onSubmit() {
    // this.dialogRef.close();
    if (this.lcForm.valid && this.nameAlredyExist == "") {

      if (this.lcService.selectedEditMode) {

        this.lcService.updateLc(this.lcForm.value);
      } else {
        this.lcService.createLc(this.lcForm.value)
      }
      this.lcService.onCloseForm()
    }

  }


  private initForm() {
    let id: number;
    let name: string;
    let date: string;
    let displacement: number;
    let lcg: number;
    let vcg: number;
    let project = +this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY);
   
    console.log(" edit mode imit");
    console.log(this.lcService.selectedEditMode);
    
    if (this.lcService.selectedEditMode && this.lc != undefined) {
      id = this.lc.id;
      name = this.lc.name;
      date = this.lc.date;
      displacement = this.lc.displacement;
      lcg = this.lc.lcg;
      vcg = this.lc.vcg;

      

    }

    this.lcForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(name, Validators.required),
      'date': new FormControl(date),
      'displacement': new FormControl(displacement),
      'lcg': new FormControl(lcg, Validators.required),
      'vcg': new FormControl(vcg, Validators.required),
      'project': new FormControl(project)

    });
  }

}
