<div class="container-login">
    <div class="login-card container" id="container">
        <div class="form-container form-group sign-up-container">
            <div class="row" style="width: 100%;">
                <app-signup style="width:100%;"></app-signup>
            </div>
        </div>






        <div class="form-container form-group sign-in-container">
            <!-- <h4><font color="red">${message}</font></h4>    -->
            <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">
                <h3 style="
                  padding-bottom: 8%;
                font-family: Barlow;
                font-weight: bold;
                font-size: 2 rem;     
                  ">Sign In</h3>
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <label for="email">
          <span> Email*
            <span class="required">
            </span>
          </span>
          <input type="email" formControlName="email" class="form-control" id="loginemail" placeholder="Email"
            style="width: 100% !important;" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
        </label><br>

                <label for="field2">
          <span> Password*
            <span class="required">
            </span>
          </span>
          <input type="password" formControlName="password" class="form-control" id="loginpassword"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password">
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
        </label> <br>
                <button class="btn login" type="submit" style="color:#040E1D" id="login">Login</button>
                <!-- <button class="btn ghost"><input type="submit" value="Log in"> </button>	  -->
            </form>
            <div class="row">
                <button class="btn " style="color:#040E1D" id="forgetPassword" (click)="onForgetPassword()">Forgot password?</button>
            </div>
        </div>

        <div class="overlay">
            <div class="overlay-panel overlay-left">
                <div class="lower-panel-left">
                    <h2 style="padding-bottom: 20%">Login to dashboard</h2>
                    <button class="btn ghost" style="color:white" id="signIn" onclick='showSignin()'>Sign in</button>
                </div>
            </div>
            <div class="overlay-panel overlay-right">
                <div class="logo-panel">
                    <div class="boxes">
                        <img class="logo" src="assets/images/logoblack.png">
                    </div>
                    <div class="blue-box"></div>
                </div>
                <div class="lower-panel-right" style="right: 0">
                    <h2 style="padding-bottom: 20%">Join EasyPro</h2>
                    <button class="btn ghost" style="color:white" id="signUp" onclick='showSignup()'>Sign up</button>
                </div>
            </div>
        </div>
    </div>
</div>