import { Component, Input, OnInit } from '@angular/core';
import { ProjectService } from '../project.service';
import { ProjectCALC } from '../../../shared/projectCALC.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { ProjectCloneComponent } from '../project-clone/project-clone.component';
import { ProjectEditComponent } from '../project-edit/project-edit.component';
import { ProjectDeleteComponent } from '../project-delete/project-delete.component';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent {
  project: ProjectCALC;
  idProjectCALC: string;

  constructor(
    public projectService: ProjectService,
    private modalService: NgbModal,
    public cookieService: CookieService,
    public accountService: AccountService) {

    this.projectService.selectedProjectEvent.subscribe(
      (selectedProject: ProjectCALC) => {
        console.log(selectedProject);
        if (selectedProject != undefined)
          this.project = selectedProject;
      }
    );
    if (this.projectService.selectedProject != undefined) {
      this.project = this.projectService.selectedProject;
      console.log(this.project);
    }
  }

  

  onEdit() {
    this.projectService.selectedEditMode = true;
    const modalRef = this.modalService.open(ProjectEditComponent);
    modalRef.componentInstance.project = this.project;
  }


  onCloneProject(){
    this.projectService.selectedEditMode = true;
    const modalRef = this.modalService.open(ProjectCloneComponent);
    modalRef.componentInstance.project = this.project;
  }
  onTrash() {
    const modaldelete = this.modalService.open(ProjectDeleteComponent);
    modaldelete.componentInstance.project = this.project;
  }
}
