<div class="full-row" [ngStyle]="{'display': projectDELPHYservice.dashboardDis}">
    <div class="row first-row">

        <div class="col-on-five">
            <!-- <app-hull-list></app-hull-list> -->
            <div class="card card-mini text-white card-mini-has-bg">

                <div class="card-mini-img-overlay d-flex flex-column">

                    <div class="card-mini-body" title="Show Hulls" (click)="onShowDetails('Hulls')">
                        <h4 class="card-mini-title mt-0 ">Hulls</h4>
                    </div>

                    <div class="card-mini-footer">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <i class="bx bx-dots-horizontal-rounded font-size-22" (click)="projectDELPHYservice.onActivate('hull')" title="Details"></i>
                                <div class="textUnderIcon ">Details</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-on-five ">
            <!-- <app-lc-list></app-lc-list> -->
            <div class="card card-mini text-white card-mini-has-bg">
                <div class="card-mini-img-overlay d-flex flex-column">
                    <div class="card-mini-body" title="Show Lc" (click)="onShowDetails('Loads')">
                        <h4 class="card-mini-title mt-0 ">Loads</h4>
                    </div>
                    <div class="card-mini-footer">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <i class="bx bx-dots-horizontal-rounded font-size-22" (click)="projectDELPHYservice.onActivate('lc')" title="Details"></i>
                                <div class="textUnderIcon ">Details</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-on-five ">
            <!-- <app-engine-list></app-engine-list> -->
            <div class="card card-mini text-white card-mini-has-bg">
                <div class="card-mini-img-overlay d-flex flex-column">
                    <div class="card-mini-body" title="Show Engines" (click)="onShowDetails('Engines')">
                        <h4 class="card-mini-title mt-0 ">Engines</h4>
                    </div>
                    <div class="card-mini-footer">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <i class="bx bx-dots-horizontal-rounded font-size-22" (click)="projectDELPHYservice.onActivate('eng')" title="Details"></i>
                                <div class="textUnderIcon ">Details</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-on-five ">
            <!-- <app-propeller-list></app-propeller-list> -->
            <div class="card card-mini text-white card-mini-has-bg">
                <div class="card-mini-img-overlay d-flex flex-column">
                    <div class="card-mini-body" title="Show Propellers" (click)="onShowDetails('Propellers')">
                        <h4 class="card-mini-title mt-0 ">Propellers</h4>
                    </div>
                    <div class="card-mini-footer">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <i class="bx bx-dots-horizontal-rounded font-size-22" (click)="projectDELPHYservice.onActivate('prop')" title="Details"></i>
                                <div class="textUnderIcon ">Details</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-on-five ">
            <!-- <app-appendage-set-list></app-appendage-set-list> -->
            <div class="card card-mini text-white card-mini-has-bg">
                <div class="card-mini-img-overlay d-flex flex-column">
                    <div class="card-mini-body" title="Show Appendage Set" (click)="onShowDetails('Appendage')">
                        <h4 class="card-mini-title mt-0 ">Appendage Set</h4>
                    </div>
                    <div class="card-mini-footer">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <i class="bx bx-dots-horizontal-rounded font-size-22" (click)="projectDELPHYservice.onActivate('app')" title="Details"></i>
                                <div class="textUnderIcon ">Details</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row other-row ">
        <div class="col-four-on-five ">
            <div style="min-height:20%; padding-bottom:20px ">
                <app-vehicle-list></app-vehicle-list>
            </div>
            <div class="row">
                <div class="col-6">
                    <app-dashboard-plots-weight></app-dashboard-plots-weight>
                </div>
                <div class="col-6">
                    <app-dashboard-plots-Vmax></app-dashboard-plots-Vmax>
                </div>
            </div>


        </div>
        <div class="col-on-five ">
            <div [ngStyle]="{ 'display': noOne} ">
                Please select a card from the above
            </div>
            <div [ngStyle]="{ 'display': Hulls} ">
                <app-hull-list></app-hull-list>
            </div>
            <div [ngStyle]="{ 'display': Loads} ">
                <app-lc-list></app-lc-list>
            </div>
            <div [ngStyle]="{ 'display': Engines} ">
                <app-engine-list></app-engine-list>
            </div>
            <div [ngStyle]="{ 'display': Propellers} ">
                <app-propeller-list></app-propeller-list>
            </div>
            <div [ngStyle]="{ 'display': Appendages} ">
                <app-appendage-set-list></app-appendage-set-list>
            </div>
        </div>
    </div>
</div>






<div class="full-row " [ngStyle]="{ 'display': projectDELPHYservice.hullDis} ">
    <app-hull-dashboard></app-hull-dashboard>
</div>

<div class="full-row " [ngStyle]="{ 'display': projectDELPHYservice.lcDis} ">
    <app-lc-dashboard></app-lc-dashboard>
</div>

<div class="full-row " [ngStyle]="{ 'display': projectDELPHYservice.propDis} ">
    <app-propeller-dashboard></app-propeller-dashboard>
</div>

<div class="full-row " [ngStyle]="{ 'display': projectDELPHYservice.engDis} ">
    <app-engine-dashboard></app-engine-dashboard>
</div>

<div class="full-row " [ngStyle]="{ 'display': projectDELPHYservice.appDis} ">
    <app-appendage-dashboard></app-appendage-dashboard>
</div>

<div class="full-row " [ngStyle]="{ 'display': projectDELPHYservice.vehDis} ">
    <app-vehicle-dashboard></app-vehicle-dashboard>
</div>