import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/account/account.service';
import { List } from 'src/app/CALC/shared/list.model';
import { ListService } from '../list.service';

@Component({
  selector: 'app-list-clone',
  templateUrl: './list-clone.component.html',
  styleUrls: ['./list-clone.component.scss']
})
export class ListCloneComponent implements OnInit {

  @Input() public list: List;
  listForm: FormGroup;
  constructor(public listService: ListService,
    public cookieService: CookieService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.initForm();
    
  }

  ngOnChanges() {
    console.log(this.listForm);
  }
  onSubmit() {
    this.modalService.dismissAll();
    this.listService.cloneList(this.listForm.value);
  }

  onCancel() {
    this.modalService.dismissAll();
  }


  private initForm() {
    let id : number;
    let listName = 'Copy of '+this.list.name;
    let listNote = '';
    let listisTrash = false;
    let listisDelete = false;
    let company;
    if (this.list != undefined) {
      id = this.list.id;
      company = this.list.company;
    }
    this.listForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(listName, Validators.required),
      'note': new FormControl(listNote),
      'company': new FormControl(company),
      'isTrash': new FormControl(listisTrash),
      'isDelete': new FormControl(listisDelete)
    });
  }


}
