import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { NgbNavModule, NgbDropdownModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { UIModule } from 'src/app/shared/ui/ui.module';
import { LoaderService } from 'src/app/core/services/loader.service';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DelphyRoutingModule } from './delphy-routing.module';


import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import {TabMenuModule} from 'primeng/tabmenu';
import {ToolbarModule} from 'primeng/toolbar';
import {PickListModule} from 'primeng/picklist';
import {InputTextModule} from 'primeng/inputtext';
import { DashboardComponent } from './projects/dashboard/dashboard.component';
import { ProjectEditComponent } from './projects/project-edit/project-edit.component';
import { ProjectDeleteComponent } from './projects/project-delete/project-delete.component';
import { ProjectDetailsComponent } from './projects/project-details/project-details.component';

import { DashboardprojectComponent } from './project/dashboardproject/dashboardproject.component';

// Hulls Components
import { HullPlotsComponent } from './project/hulls/hull-plots/hull-plots.component';
import { HullListComponent } from './project/hulls/hull-list/hull-list.component';
import { HullDetailsComponent } from './project/hulls/hull-details/hull-details.component';
import { HullDashboardComponent } from './project/hulls/hull-dashboard/hull-dashboard.component';
import { HullEditComponent } from './project/hulls/hull-edit/hull-edit.component';
import { HullDeleteComponent } from './project/hulls/hull-delete/hull-delete.component';

// Propeller Components
import { PropellerDetailsComponent } from './project/propellers/propeller-details/propeller-details.component';
import { PropellerEditComponent } from './project/propellers/propeller-edit/propeller-edit.component';
import { PropellerLudwigComponent } from './project/propellers/propeller-ludwig/propeller-ludwig.component';
import { PropellerDashboardComponent } from './project/propellers/propeller-dashboard/propeller-dashboard.component';
import { PropellerListComponent } from './project/propellers/propeller-list/propeller-list.component';
import { PropellerPlotsComponent } from './project/propellers/propeller-plots/propeller-plots.component';
import { PropellerDeleteComponent } from './project/propellers/propeller-delete/propeller-delete.component';

// Engine Components
import { EngineDetailsComponent } from './project/engines/engine-details/engine-details.component';
import { EngineEditComponent } from './project/engines/engine-edit/engine-edit.component';
import { EngineListComponent } from './project/engines/engine-list/engine-list.component';
import { EngineResultComponent } from './project/engines/engine-result/engine-result.component';
import { EnginePlotsComponent } from './project/engines/engine-plots/engine-plots.component';
import { EngineDashboardComponent } from './project/engines/engine-dashboard/engine-dashboard.component';
import { EngineDeleteComponent } from './project/engines/engine-delete/engine-delete.component';

import { LcDetailsComponent } from './project/lcs/lc-details/lc-details.component';
import { LcListComponent } from './project/lcs/lc-list/lc-list.component';
import { LcDashboardComponent } from './project/lcs/lc-dashboard/lc-dashboard.component';
import { LcPlotsComponent } from './project/lcs/lc-plots/lc-plots.component';
import { LcEditComponent } from './project/lcs/lc-edit/lc-edit.component';
import { LcDeleteComponent } from './project/lcs/lc-delete/lc-delete.component';


import { VehicleListComponent } from './project/vehicles/vehicle-list/vehicle-list.component';
import { VehicleEditComponent } from './project/vehicles/vehicle-edit/vehicle-edit.component';
import { VehicleDeleteComponent } from './project/vehicles/vehicle-delete/vehicle-delete.component';
import { VehicleDetailsComponent } from './project/vehicles/vehicle-details/vehicle-details.component';
import { VehicleDashboardComponent } from './project/vehicles/vehicle-dashboard/vehicle-dashboard.component';
import { VehicleDanielComponent } from './project/vehicles/vehicle-daniel/vehicle-daniel.component';
import { VehiclePlotsComponent } from './project/vehicles/vehicle-plots/vehicle-plots.component';

import { CategoryDeleteComponent } from './project/appendages/category-delete/category-delete.component';
import { AppendageDashboardComponent } from './project/appendages/appendage-dashboard/appendage-dashboard.component';
import { CategoryEditComponent } from './project/appendages/category-edit/category-edit.component';
import { CategoryListComponent } from './project/appendages/category-list/category-list.component';
import { AppendageListComponent } from './project/appendages/appendage-list/appendage-list.component';
import { FilterAppendagesPipe } from 'src/app/filter-appendages.pipe';
import { CategoryDetailsComponent } from './project/appendages/category-details/category-details.component';
import { AppendagePlotsComponent } from './project/appendages/appendage-plots/appendage-plots.component';
import { AppendageOsborneComponent } from './project/appendages/appendage-osborne/appendage-osborne.component';


import { DashboardPlotsWeightComponent } from './project/dashboardproject/dashboard-plots-weight/dashboard-plots-weight.component';
import { FloatPipe } from 'src/app/floatPipe.pipe';
import { AppendageSetEditComponent } from './project/appendages/appendage-set-edit/appendage-set-edit.component';
import { AppendageSetListComponent } from './project/appendages/appendage-set-list/appendage-set-list.component';
import { AppendageSetDeleteComponent } from './project/appendages/appendage-set-delete/appendage-set-delete.component';
import { AppendageSetDetailsComponent } from './project/appendages/appendage-set-details/appendage-set-details.component';
import { CategoryCloneComponent } from './project/appendages/category-clone/category-clone.component';
import { CalcModule } from 'src/app/CALC/pages/calc.module';
import { DashboardPlotsVMaxComponent } from './project/dashboardproject/dashboard-plots-Vmax/dashboard-plots-Vmax.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};

@NgModule({
  declarations: [
  DashboardComponent,
  ProjectEditComponent,
  ProjectDeleteComponent,
  ProjectDetailsComponent,
  HullPlotsComponent,
  HullDetailsComponent,
  HullListComponent,
  HullDashboardComponent,
  DashboardprojectComponent,
  PropellerDetailsComponent,
  PropellerEditComponent,
  PropellerLudwigComponent,
  PropellerListComponent,
  PropellerDashboardComponent,
  PropellerPlotsComponent,
  EngineDashboardComponent,
  EngineDetailsComponent,
  EngineEditComponent,
  EngineListComponent,
  EngineResultComponent,
  EnginePlotsComponent,
  DashboardPlotsVMaxComponent,
  LcDetailsComponent,
  LcListComponent,
  LcDashboardComponent,
  LcPlotsComponent,
  HullEditComponent,
  HullDeleteComponent,
  LcEditComponent,
  LcDeleteComponent,
  EngineDeleteComponent,
  PropellerDeleteComponent,
  VehicleListComponent,
  VehicleEditComponent,
  VehicleDeleteComponent,
  VehicleDetailsComponent,
  VehicleDashboardComponent,
  VehicleDanielComponent,
  VehiclePlotsComponent,
  CategoryDeleteComponent,
  AppendageDashboardComponent,
  CategoryEditComponent,
  CategoryListComponent,
  AppendageListComponent,
  DashboardPlotsWeightComponent,
  FilterAppendagesPipe,
  CategoryDetailsComponent,
  AppendagePlotsComponent,
  AppendageOsborneComponent,
  FloatPipe,
  AppendageSetEditComponent,
  AppendageSetListComponent,
  AppendageSetDeleteComponent,
  AppendageSetDetailsComponent,
  CategoryCloneComponent
],
  imports: [
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    NgbModalModule,
    NgApexchartsModule,
    DelphyRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    UIModule,
    WidgetModule,
    FullCalendarModule,
    NgbNavModule,
    NgbTooltipModule,
    PerfectScrollbarModule,
    SharedModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    ButtonModule,
    TabMenuModule,
    ToolbarModule,
    PickListModule,
    InputTextModule,
    CalcModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    LoaderService,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true }
  ]
})
export class DelphyModule { }
