import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Daniel } from '../../shared/daniel.model';
import { ProjectDELPHY } from '../../shared/projectDELPHY.model';
import { Vehicle } from '../../shared/vehicle.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  selectedEditMode: boolean;
  error = new Subject<string>();

  vehicles: Vehicle[];
  vehiclesChanged = new Subject<Vehicle[]>();

  selectedVehicle: Vehicle;
  selectedVehicleEvent = new EventEmitter<Vehicle>();

  daniels: Daniel[];
  danielsChanged = new Subject<Daniel[]>();

  //[0] label,     [1] data
  graphRt: any[] = [];
  graphTau: any[] = [];
  graphRpm: any[] = [];

  graphVmax: any[] = [];

  constructor(private http: HttpClient,
    public cookieService: CookieService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  createVehicle(vehicle: Vehicle) {
    console.log(" dopo form vehicle");
    console.log(vehicle);
    this.http
      // aggiungere <{è un optional raccomandato}>
      .post<{ vehicle: Vehicle }>(
        this.accountService.REST_API_SERVER_DELPHY + 'vehicle/',
        vehicle
      )
      .subscribe(responseData => {
        if (responseData != undefined) {
          vehicle.id = responseData["id"];
          vehicle.date = responseData["date"];
          vehicle.revision = responseData["revision"];
          // this.vehicles.unshift(vehicle);
          this.vehicles.push(vehicle);
          this.vehiclesChanged.next(this.vehicles);
          this.toastr.success('Vehicle created');
        }
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          this.error.next(error.message);
        });

  }

  updateVehicle(vehicle: Vehicle) {
    this.http
      // aggiungere <{è un optional raccomandato}>
      .put<{ vehicle: Vehicle }>(
        this.accountService.REST_API_SERVER_DELPHY + 'vehicle/',
        vehicle
      )
      .subscribe(responseData => {
        let itemIndex = this.vehicles.findIndex(item => item.id == this.selectedVehicle.id);
        
        this.vehicles[itemIndex] = vehicle;
        this.selectedVehicle = vehicle;
        this.selectedVehicleEvent.next(vehicle);
        this.vehiclesChanged.next(this.vehicles);
        this.danielsChanged.next([]);
          this.daniels = [];
        this.toastr.success('Vehicle  updated');
      },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
          // this.error.next(error.message);
        });

  }

  trashVehicle() {

    this.http
      .delete(this.accountService.REST_API_SERVER_DELPHY
        + 'vehicle?id=' + this.selectedVehicle.id)
      .subscribe(responseData => {
        if (responseData != undefined) {
          const index = this.vehicles.findIndex(x => x.id === this.selectedVehicle.id);
          this.vehicles.splice(index, 1);
          this.toastr.success('This vehicle has been deleted', 'Project deleted');
          this.selectedVehicleEvent.next(undefined);
          this.selectedVehicle = undefined;
          this.danielsChanged.next(undefined);
          this.daniels = undefined;
        } else {
          this.error.next(responseData["Result"].message);
        }
      },
        error => {
          this.error.next(error.message);
        });

  }

  public getVehicles(): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'vehicle/?project_id=' + this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY)
    )
      .pipe(
      );
  }


  
  public getDaniels(vehicle: Vehicle): Observable<Daniel[]> {
    return this.http.get<Daniel[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'daniel/?id=' + vehicle.id
    )
      .pipe(
      );
  }
 
  public putDaniels(): Observable<Daniel[]> {
    return this.http.put<Daniel[]>(this.accountService.REST_API_SERVER_DELPHY
      + 'daniel/?id=' + this.selectedVehicle.id, this.selectedVehicle
    )
      .pipe(
      );
  }

  onCloseForm() {
    this.modalService.dismissAll();
  }


  onInitGraphDaniel() {
    console.log("Dentro onInitGraphDaniel") 
    this.setGraphDaniel();
    
  }

  // restituisce label e data
  setGraphDaniel() {
    let dataRt = [], dataTau = [], dataRpm = [], label = [];
    console.log("daniels") 
    console.log(this.daniels) 
    this.daniels?.forEach(element => {


      console.log("element") 
      console.log(element) 



      label.push(element.V.toFixed(0));
      dataRt.push(element.Rt.toFixed(0));
      dataTau.push(element.tau.toFixed(2));
      dataRpm.push(element.rpm.toFixed(0));
    });

    this.graphRt = [label, dataRt];

    this.graphTau = [label, dataTau];

    this.graphRpm = [label, dataRpm];
  }



  onInitGraphVmax() {
    console.log("Dentro onInitGraphVmax")
    this.setGraphJobVmax();
  }

  // restituisce label e data
  setGraphJobVmax() {
    console.log("Dentro setGraphJobVmax")
    let dataVmax = [], label = [];

    this.vehicles.forEach(element => {
      if(element.V_max){
        console.log(element.name)
        console.log(element.V_max.toFixed(1))
        label.push(element.name);
        dataVmax.push(element.V_max.toFixed(1));
      }
      
    });

    this.graphVmax = [label, dataVmax];
  }




  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }


  





}
