import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { Vehicle } from '../../../shared/vehicle.model';
import { AppendageService } from '../../appendages/appendage.service';
import { EngineService } from '../../engines/engine.service';
import { HullService } from '../../hulls/hull.service';
import { LcService } from '../../lcs/lc.service';
import { ProjectDELPHYService } from '../../project-delphy.service';
import { PropellerService } from '../../propellers/propeller.service';
import { VehicleDeleteComponent } from '../vehicle-delete/vehicle-delete.component';
import { VehicleEditComponent } from '../vehicle-edit/vehicle-edit.component';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent implements OnInit {

  vehicle: Vehicle;
  project: ProjectDELPHY;

  constructor(
    public hullService: HullService,
    public lcService: LcService,
    public engineService: EngineService,
    public propellerService: PropellerService,
    public appendageService: AppendageService,
    public cookieService: CookieService,
    public vehicleService: VehicleService,
    private modalService: NgbModal,
    public projectDELPHYservice: ProjectDELPHYService) { }

  ngOnInit(): void {
 
    if(this.vehicleService.selectedVehicle == undefined){
      this.vehicleService.selectedVehicleEvent.subscribe(
        (selectedVehicle: Vehicle) => {
          this.vehicle = selectedVehicle;
        }
      );
    }
  }

  onEdit() {
    this.vehicleService.selectedEditMode = true;
    const modalRef = this.modalService.open(VehicleEditComponent);
    modalRef.componentInstance.vehicle = this.vehicleService.selectedVehicle;
  }

  onTrash() {
    const modaldelete = this.modalService.open(VehicleDeleteComponent);
    modaldelete.componentInstance.vehicle = this.vehicleService.selectedVehicle;
  }


  

}
