<div class="container-fluid">

    <div class="row">
        <div class="col-sm-3" *ngFor="let projectEl of projects">

            <div class="card text-white card-has-bg click-col" style="background-image:url('http://files.solidworks.com/casestudies_eng/Greenline-65-Ocean-Class_banner.jpg')">
                <!-- <div class="card text-white card-has-bg click-col"> -->
                <img class="card-img d-none" src="https://source.unsplash.com/600x900/?tech,street" alt="Goverment Lorem Ipsum Sit Amet Consectetur dipisi?">
                <div class="card-img-overlay d-flex flex-column">
                    <div class="card-body" (click)="onProject(projectEl)" style="cursor: pointer;">
                        <!-- <small class="card-meta mb-2">{{projectEl?.projectName}}</small> -->
                        <h4 class="card-title mt-0 "><a class="text-white" herf="#">{{projectEl?.name}}</a></h4>
                        <small><i class="far fa-clock"></i> {{projectEl?.startDate}}</small>
                    </div>
                    <div class="card-footer">

                        <div class="row">
                            <!-- <div class="col-sm-3">
                                <img class="mr-3 rounded-circle" src={{img}} alt="Generic placeholder image" style="max-width:50px">

                            </div> -->

                            <div class="col-sm-6 action-icon">
                                <i class="bx bxs-edit-alt font-size-22" (click)="onEdit(projectEl)" title="Edit"></i>
                                <div class="textUnderIcon">Edit</div>
                            </div>
                            <!-- <div class="col-sm-4 action-icon">
                                <i class="bx bx-dots-horizontal-rounded font-size-22" (click)="onProject(projectEl)" title="Details"></i>
                                <div class="textUnderIcon">Details</div>
                            </div> -->
                            <!-- <div class="col-sm-4">
                                <i style="cursor: pointer;" class="bx bx-dots-horizontal-rounded action-icon" (click)="onProject(projectEl)" title="Details">
                                </i>
                                <div class="textUnderIcon">Details</div>
                            </div> -->

                            <div class="col-sm-6 action-icon">
                                <i class="bx bxs-trash-alt font-size-22" (click)="onTrash(projectEl)" title="Delete"></i>
                                <div class="textUnderIcon">Delete</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>











    <!-- 

    <div class="row">
        <div class="col-xl-4" *ngFor="let projectEl of projects">
            <div class="micard overflow-hidden">

                <div class="micard-header" title="Show {{projectEl.name}}">
                    <div class="row">
                        <div class="col-sm-3">
                            <img class="rounded-circle imgCardDetails img-thumbnail rounded-circle" src="assets/images/hull.svg">
                        </div>

                        <div class="col-sm-9">
                            <div>
                                <h5 class="micard-title">{{projectEl.name}}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="micard-body">
                    <div class="row" style="margin-top:5px">
                        </div> 
    <div class="col-sm-6 property-icon">
        <i class="bx bx-calendar font-size-22"></i>
    </div>
    <div class="col-sm-6 property-icon">
        <i class="bx bx-time-five font-size-22"></i>
    </div>
</div>
<div class="row" style="margin-top:5px">
    <div class="col-sm-6 property-name">
        Start
    </div>
    <div class="col-sm-6 property-name">
        Latest Update
    </div>
</div>
<div class="row">
    <div class="col-sm-6 property-value">
        2021-03-25
    </div>

    <div class="col-sm-6 property-value">
        2021-04-01
    </div>
</div>
</div>

<div class="border-top">
    <div class="row card-action">
        <div class="col-sm-4 card-button">
            <i class="bx bxs-edit-alt font-size-22 action-icon" (click)="onEdit(projectEl)" title="Edit"></i>
        </div>

        <div class="col-sm-4 card-button">
            <i class="bx bx-dots-horizontal-rounded action-icon" (click)="onProject(projectEl)" title="Details">
                            </i>
        </div>

        <div class="col-sm-4 card-button">
            <i class="bx bxs-trash-alt font-size-22 action-icon" (click)="onTrash(projectEl)" title="Delete"></i>
        </div>
    </div>
</div>

</div>

</div>
</div> -->





</div>


<div class="floating-action-button" (click)="openAdd()">
    <button type="button" class="btn btn-primary btn-circle btn-lg" id="addBtn">
        <i class="fas fa-plus" title="New"></i>
        <div class="textUnderIcon">New</div>
    </button>
</div>