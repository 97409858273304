import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { ProjectDELPHY } from '../../../shared/projectDELPHY.model';
import { Propeller } from '../../../shared/propeller.model';
import { PropellerService } from '../propeller.service';

@Component({
  selector: 'app-propeller-edit',
  templateUrl: './propeller-edit.component.html',
  styleUrls: ['./propeller-edit.component.scss']
})
export class PropellerEditComponent implements OnInit {

  propellerForm: FormGroup;

  @Input() propeller: Propeller;

  // selectedJob: List;
  selectedEditModeSubscription: Subscription;
  selectedPropellerSubscription: Subscription;

  nameAlredyExist = "";

  constructor(public propellerService: PropellerService,
    public accountService: AccountService,
    public cookieService: CookieService) {

  }

  ngOnInit(): void {
    this.initForm();
  }


  
  nameCheckUnique() {

    console.log("Dentro check");
    let flag = false;
    

    this.propellerService.propellers.forEach(propeller => {
      if(propeller.name == this.propellerForm.controls['name'].value)
          flag = true
    });

    if (flag) {
      this.nameAlredyExist = "Name Alredy Exist";
    }
    else{
      this.nameAlredyExist = "";
    }

  }
  onSubmit() {
    // this.dialogRef.close();
    if (this.propellerForm.valid && this.nameAlredyExist == "") {

      if (this.propellerService.selectedEditMode) {

        this.propellerService.updatePropeller(this.propellerForm.value);
      } else {
        this.propellerService.createPropeller(this.propellerForm.value)
      }
      this.propellerService.onCloseForm()
    }

  }


  private initForm() {
    let id: number;
    let name: string;
    let date: string;
    let D: number;
    let N: number;
    let EAR: number;
    let PD_ratio: number;
    let project = +this.cookieService.get(ProjectDELPHY.idProjectSelectedDELPHY);
   
    console.log(" edit mode imit");
    console.log(this.propellerService.selectedEditMode);
    
    if (this.propellerService.selectedEditMode && this.propeller != undefined) {
      id = this.propeller.id;
      name = this.propeller.name;
      date = this.propeller.date;
      D = this.propeller.D;
      N = this.propeller.N;
      EAR = this.propeller.EAR;
      PD_ratio = this.propeller.PD_ratio;

      

    }

    this.propellerForm = new FormGroup({
      'id': new FormControl(id),
      'name': new FormControl(name, Validators.required),
      'date': new FormControl(date),
      'D': new FormControl(D, Validators.required),
      'N': new FormControl(N, Validators.required),
      'EAR': new FormControl(EAR, Validators.required),
      'PD_ratio': new FormControl(PD_ratio, Validators.required),
      'project': new FormControl(project)

    });
  }

}
