<div class="content">
    <div class="card">
        <div class="row">
            <div class="col-sm-12">
                <form class="form" [formGroup]="itemForm">
                    <input type="hidden" id="idItem" formControlName="idItem" class="form-control">
                    <input type="hidden" id="idForeign" formControlName="idForeign" class="form-control">

                    <input type="hidden" id="itemCode" formControlName="itemCode" class="form-control">
                    <input type="hidden" id="itemStatus" formControlName="itemStatus" class="form-control">
                    <input type="hidden" id="jobName" formControlName="jobName" class="form-control">
                    <input type="hidden" id="productName" formControlName="productName" class="form-control">
                    <input type="hidden" id="designName" formControlName="designName" class="form-control">
                    <input type="hidden" id="topologicalName" formControlName="topologicalName" class="form-control">
                    <input type="hidden" id="revisionName" formControlName="revisionName" class="form-control">
                    <input type="hidden" id="firstRev" formControlName="firstRev" class="form-control">
                    <input type="hidden" id="lastRev" formControlName="lastRev" class="form-control">

                    <input type="hidden" id="enableForCALC" formControlName="enableForCALC" class="form-control">
                    <input type="hidden" id="unitsOfMeasureName" formControlName="unitsOfMeasureName" class="form-control">
                    <input type="hidden" id="tableName" formControlName="tableName" class="form-control">
                    <input type="hidden" id="hasOtherRevisions" formControlName="hasOtherRevisions" class="form-control">
                    <input type="hidden" id="lastUpdateDate" formControlName="lastUpdateDate" class="form-control">
                    <input type="hidden" id="idAssembly" formControlName="idAssembly" class="form-control">
                    <input type="hidden" id="isTrash" formControlName="isTrash" class="form-control">
                    <input type="hidden" id="isDelete" formControlName="isDelete" class="form-control">
                    <input type="hidden" id="conflictOptCode" formControlName="conflictOptCode" class="form-control">
                    <input type="hidden" id="inseparableOptCode" formControlName="inseparableOptCode" class="form-control">
                    <input type="hidden" id="hasOtherConflictOptCode" formControlName="hasOtherConflictOptCode" class="form-control">
                    <input type="hidden" id="hasOtherInseparableOptCode" formControlName="hasOtherInseparableOptCode" class="form-control">
                    <input type="hidden" id="isOptional" formControlName="isOptional" class="form-control">
                    <input type="hidden" id="assemblyHours" formControlName="assemblyHours" class="form-control">
                    <input type="hidden" id="price" formControlName="price" class="form-control">
                    <input type="hidden" id="checkValue" formControlName="checkValue" class="form-control">
                    <input type="hidden" id="matchName" formControlName="matchName" class="form-control">

                    <div class="row">
                        <div class="col-sm-10">
                            <h5 *ngIf="vehicleService.selectedEditMode == false">New Item</h5>
                            <h5 *ngIf="vehicleService.selectedEditMode == true">Edit {{item?.name}}</h5>
                        </div>
                        <div class="col-sm-2">
                            <button type="button" class="close" aria-label="Close" title="Close" (click)="onCloseForm()">
                                 <span aria-hidden="true"> &times;</span>
                              </button>
                        </div>
                    </div>
                    <hr>
                    <div class="row" *ngIf="!(cookieService.get('servletName') == 'optItem')">
                        <div class="col-sm-12">
                            <div class="form form-group">
                                <label>Name*</label>
                                <input type="text" id="name" formControlName="name" class="form-control" required #name>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="vehicleService.selectedEditMode==false && !(cookieService.get('servletName') == 'optItem')">
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label for="idRevision">Select a revision*</label>
                                <select class="form-control" formControlName="idRevision" id="idRevision" required>
                                    <option *ngFor="let revision of revisions" [value]="revision.id">
                                        {{revision.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label for="typeList">Select a Type*</label>
                                <select class="form-control" formControlName="type" id="type" required>
                                    <option>CAT</option>
                                    <option>DWG</option>
                                </select>
                            </div>
                        </div>
                    </div>



                    <div class="row" id="checkRow" *ngIf="vehicleService.selectedEditMode==false">
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label for="standardEquipments">Stand. Equip.</label>

                                <input type="checkbox" id="standardEquipments" formControlName="standardEquipments" class="form-control">

                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label for="nonEdibleStores">Non edible</label>
                                <div id="checkDiv">
                                    <input type="checkbox" id="nonEdibleStores" formControlName="nonEdibleStores" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row" *ngIf="!(cookieService.get('servletName') == 'optItem')">
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label for="idJob">Job*</label>
                                <select class="form-control" formControlName="idJob" id="idJob" (ngModelChange)="onChange($event)" required>
                                    <option  *ngFor="let job of jobs" [ngValue]="job.id">
                                        {{job.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label for="idProduct">Product*</label>
                                <select class="form-control" formControlName="idProduct" id="idProduct" required>
                                    <option *ngFor="let product of products" [ngValue]="product.id">
                                        {{product.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <div class="row" *ngIf="!(cookieService.get('servletName') == 'optItem')">
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label for="idDesign">Design*</label>
                                <select class="form-control" formControlName="idDesign" id="idDesign">
                                    <option *ngFor="let design of designs" [ngValue]="design.id">
                                        {{design.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label for="idTopological">Topological*</label>
                                <select class="form-control" formControlName="idTopological" id="idTopological" required>
                                    <option *ngFor="let topological of topologicals" [ngValue]="topological.id">
                                        {{topological.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form form-group" *ngIf="!(cookieService.get('servletName') == 'optItem')">
                                <label>Unit Weight*</label>
                                <input type="number" step=".01" id="estimateWeight" min="0" oninput="validity.valid||(value='');" formControlName="estimateWeight" class="form-control" required>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form form-group" *ngIf="!(cookieService.get('servletName') == 'optItem')">
                                <label>Margin*</label>
                                <input type="number" step="1" min="0" max="100" oninput="validity.valid||(value='');" id="margin" formControlName="margin" class="form-control" required>
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <div class="form form-group" *ngIf="!(cookieService.get('servletName') == 'optItem')">
                                <label for="idUnitsOfMeasure">Units*</label>
                                <select class="form-control" formControlName="idUnitsOfMeasure" id="idUnitsOfMeasure" required>
                                    <option *ngFor="let unitsOfMeasure of unitsOfMeasures" [ngValue]="unitsOfMeasure.id">
                                        {{unitsOfMeasure.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form form-group">
                                <label>Quantity*</label>
                                <input type="number" step=".01" id="quantity" min="0" oninput="validity.valid||(value='');" formControlName="quantity" class="form-control" required>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form form-group">
                                <label>Xg*</label>
                                <input type="number" id="xg" step=".001" formControlName="xg" class="form-control" required>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form form-group">
                                <label>Yg*</label>
                                <input type="number" id="yg" step=".001" formControlName="yg" class="form-control" required>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form form-group">
                                <label>Zg*</label>
                                <input type="number" step=".001" id="zg" formControlName="zg" class="form-control" required>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form form-group">
                                <label>Note</label>
                                <input type="text" id="note" formControlName="note" class="form-control">
                            </div>
                        </div>
                    </div>


                    <div class="row" *ngIf="!(cookieService.get('servletName') == 'optItem')">
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label>Assembly Hours</label>
                                <input type="number" id="assemblyHours" formControlName="assemblyHours" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form form-group">
                                <label>Price</label>
                                <input type="number" id="price" step=".01" formControlName="price" class="form-control">
                            </div>
                        </div>
                    </div>


                    <div class="row" style="padding-top: 10px;" *ngIf="!(cookieService.get('userRole') == accountService.viewer)">

                        <div class="col-sm-4" style="text-align: center;" *ngIf="(vehicleService.selectedEditMode == false)">
                            <button type="submit" class="btn btn-success" [disabled]="!itemForm.valid"><i class="bx bxs-save font-size-22"(click)="onSubmit()" title="Save data"></i></button>
                            <div class="textUnderIcon">Save</div>
                        </div>

                        <div class="col-sm-4" style="text-align: center;" *ngIf="(vehicleService.selectedEditMode == true)">
                            <button type="submit" class="btn btn-success" [disabled]="!itemForm.valid || !itemForm.dirty"><i class="bx bxs-edit-alt font-size-22"(click)="onSubmit()" title="Save edited data"></i></button>
                            <div class="textUnderIcon">Edit</div>
                        </div>

                        <div class="col-sm-4" style="padding-left: 11px; text-align: center;" *ngIf="vehicleService.selectedEditMode==true">
                            <div *ngIf="item.firstRev && !(cookieService.get('servletName') == 'optItem')">
                                <button type="clone" class="btn btn-primary" (click)="onClone()"><i class="bx bxs-copy font-size-22" title="Clone {{item.name}}"></i></button>
                                <div class="textUnderIcon">Clone</div>
                            </div>
                        </div>
                        <div class="col-sm-4" style="padding-left: 20px; text-align: center;" *ngIf="vehicleService.selectedEditMode==true">
                            <button type="clear" class="btn btn-danger" (click)="onCancel()"><i class="bx bxs-trash-alt font-size-22" title="Delete {{item.name}}"></i></button>
                            <div class="textUnderIcon">Delete</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>