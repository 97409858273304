export class Vehicle{
	static selectedVehicle: string = "selectedVehicle";
	static idVehicleSelected: string = "idVehicleSelected";

    public idVehicle: number;
	public idProject: number;
	public idRegNumber: number;
	public regNumberName: string;
	public vehicleStatus: string;
	public startDate: string;
	public endDate: string;
	public note: string;
	public isTrash: boolean;
	public isDelete: boolean;

	constructor($idVehicle: number, $idProject: number, $idRegNumber: number, $regNumberName: string, $vehicleStatus: string, $startDate: string, $endDate: string, $note: string, $isTrash: boolean, $isDelete: boolean) {
		this.idVehicle = $idVehicle;
		this.idProject = $idProject;
		this.idRegNumber = $idRegNumber;
		this.regNumberName = $regNumberName;
		this.vehicleStatus = $vehicleStatus;
		this.startDate = $startDate;
		this.endDate = $endDate;
		this.note = $note;
		this.isTrash = $isTrash;
		this.isDelete = $isDelete;
	}

   
}