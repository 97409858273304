<div class="result-card">

    <div class="micard-header" title="Show projectEl.name">
        <h5 class="result-title">OSBORNE RESULTS</h5>
    </div>

    <div class="card-body pt-0">

        <div class="table-responsive">
            <table class="table table-nowrap table-centered mb-0">
                <tbody>
                    <th>V</th>
                    <th>Rt</th>
                    <th>Cf</th>
                    <th>Re</th>
                    <tr *ngFor="let el of appendageService.osbornes">
                        <td>{{el?.V | number : '1.2-2'}}</td>
                        <td>{{el?.Rt | number : '1.3-3'}}</td>
                        <td>{{el?.Cf | number : '1.3-3'}}</td>
                        <td>{{el?.Re | float}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>