import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Variant } from 'src/app/CALC/shared/variant.model';
import { List } from '../../../shared/list.model';
import { VariantService } from '../variant.service';

@Component({
  selector: 'app-variant-edit',
  templateUrl: './variant-edit.component.html',
  styleUrls: ['./variant-edit.component.scss']
})
export class VariantEditComponent implements OnInit {

  variantForm: FormGroup;
  subscription: Subscription;
  
  versionLists: List[];
  propulsionLists: List[];
  optLists: List[];

  @Input() public variant: Variant;

  constructor(public variantService: VariantService,
    private modalService: NgbModal,) {

  }

  ngOnInit(): void {
    
    this.variantService.getVersionListsOptions().subscribe(res => {
      this.versionLists = res['Records'];
      if(this.variantService.selectedEditMode == false)
      this.variantForm.patchValue({  "idVersionList": this.versionLists[0].id }); 
      this.variantForm.patchValue({  "versionListName": this.versionLists[0].name });
    });

    this.variantService.getPropulsionListsOptions().subscribe(res => {
      this.propulsionLists = res['Records'];
      if(this.variantService.selectedEditMode == false)
      this.variantForm.patchValue({  "idPropulsionList": this.propulsionLists[0].id });
      this.variantForm.patchValue({  "propulsionListName": this.propulsionLists[0].name });
    });

    this.variantService.getOptListsOptions().subscribe(res => {
      this.optLists = res['Records'];
      if(this.variantService.selectedEditMode == false)
      this.variantForm.patchValue({  "idOptList": this.optLists[0].id });
      this.variantForm.patchValue({  "optListName": this.optLists[0].name });
    });
    
    this.initForm();
    
  }

  ngOnChanges() {
    console.log(this.variantForm);
  }
  onSubmit() {
    this.modalService.dismissAll();
    console.log(this.variantForm.value);
    if(this.variantService.selectedEditMode){
      this.variantService.updateVariant(this.variantForm.value);
    }else{
      this.variantService.createVariant(this.variantForm.value);
    }
    
  }

  onCancel() {
    this.modalService.dismissAll();

  }


  private initForm() {

    let idVariant: number;

    let variantName = '';
    let note = '';

    let idVersionList: number;
    let versionListName = '';

    let idPropulsionList: number;
    let propulsionListName = '';

    let idOptList: number;
    let optListName = '';

    let idTankList: number;
    let tankListName = '';

    let idCrewList: number;
    let crewListName = '';

    let idPaxList: number;
    let paxListName = '';

    let idDistributedList: number;
    let distributedListName = '';

    let isTrash = false;
    let isDelete = false;

    if(this.variantService.selectedEditMode && this.variant){
      idVariant = this.variant.idVariant;
  
      note = this.variant.note;
      variantName = this.variant.variantName;

      idVersionList = this.variant.idVersionList;
      versionListName = this.variant.versionListName;

      idPropulsionList = this.variant.idPropulsionList;
      propulsionListName = this.variant.propulsionListName;
      
      idOptList = this.variant.idOptList;
      optListName = this.variant.optListName;
     
      idTankList = this.variant.idTankList;
      tankListName = this.variant.tankListName;

      idCrewList = this.variant.idCrewList;
      crewListName = this.variant.crewListName;

      idPaxList = this.variant.idPaxList;
      paxListName = this.variant.paxListName;

      idDistributedList = this.variant.idDistributedList;
      distributedListName = this.variant.distributedListName;

      isTrash = this.variant.isTrash;
      isDelete = this.variant.isDelete;
    }

    this.variantForm = new FormGroup({
      'idVariant': new FormControl(idVariant),

      'variantName': new FormControl(variantName, Validators.required),
      
      'note': new FormControl(note),
      
      'idVersionList': new FormControl(idVersionList),
      'versionListName': new FormControl(versionListName, Validators.required),

      'idPropulsionList': new FormControl(idPropulsionList),
      'propulsionListName': new FormControl(propulsionListName, Validators.required),

      'idOptList': new FormControl(idOptList),
      'optListName': new FormControl(optListName, Validators.required),

      'idTankList': new FormControl(idTankList),
      'tankListName': new FormControl(tankListName),

      'idCrewList': new FormControl(idCrewList),
      'crewListName': new FormControl(crewListName),

      'idPaxList': new FormControl(idPaxList),
      'paxListName': new FormControl(paxListName),

      'idDistributedList': new FormControl(idDistributedList),
      'distributedListName': new FormControl(distributedListName),

      'isTrash': new FormControl(isTrash),
      'isDelete': new FormControl(isDelete)
    });
  }

}
