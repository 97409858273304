import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
import { Item } from 'src/app/CALC/shared/item.model';
import { List } from '../../../../shared/list.model';
import { VehicleService } from '../../vehicle.service';
import { ItemCloneComponent } from '../item-clone/item-clone.component';
import { ItemDeleteComponent } from '../item-delete/item-delete.component';


@Component({
  selector: 'app-item-edit',
  templateUrl: './item-edit.component.html',
  styleUrls: ['./item-edit.component.scss']
})
export class ItemEditComponent implements OnInit {


  idItem: number;
  editMode = true;
  itemForm: FormGroup;

  item: Item;
  revisions: List[];

  designs: List[];
  topologicals: List[];
  unitsOfMeasures: List[];
  jobs: List[];
  products: List[];

  // selectedJob: List;
  selectedEditModeSubscription: Subscription;
  selectedItemSubscription: Subscription;

  @ViewChild('name', { static: false }) inputEl: ElementRef;
  constructor(public vehicleService: VehicleService,
    public accountService: AccountService,
    private modalService: NgbModal,
    public cookieService: CookieService) {
    if (this.selectedEditModeSubscription == undefined)
      this.selectedEditModeSubscription = this.vehicleService.selectedEditModeEvent.subscribe(
        (selectedEditMode: boolean) => {
          this.editMode = selectedEditMode;

        }
      );

    if (this.selectedItemSubscription == undefined)
      this.selectedItemSubscription = this.vehicleService.selectedItemEvent.subscribe(
        (selectedItem: Item) => {
          this.item = selectedItem;
          // this.ngAfterViewInit();
          this.idItem = this.item.idItem;

          this.initForm();
          this.reloadLists();

        }
      );

    this.vehicleService.editorReady();
  }

  ngOnInit(): void {


  }

  ngOnDestroy() {
    this.selectedEditModeSubscription.unsubscribe();
    this.selectedItemSubscription.unsubscribe();
  }

  reloadLists() {
    this.vehicleService.getListHelper('revision', null).subscribe(res => {
      this.revisions = res;
    });
    this.vehicleService.getListHelper('job', null).subscribe(res => {
      if (res == undefined) {
        return;
      }
      this.jobs = res;
      let idJob;
      if (this.editMode == false) {
        idJob = this.jobs[0].id;
      } else if (this.item != undefined) {
        idJob = this.item.idJob;
      }

      this.vehicleService.getListHelper('product', idJob).subscribe(res => {
        this.products = res;

      });
    });

    // controllare fino a qui

    this.vehicleService.getListHelper('design', null).subscribe(res => {
      this.designs = res;
      this.designs.unshift(new List(0, "", 0, undefined, "", false, false));
    });

    this.vehicleService.getListHelper('topological', null).subscribe(res => {
      this.topologicals = res;
    });
    this.vehicleService.getListHelper('unitsOfMeasure', null).subscribe(res => {
      this.unitsOfMeasures = res;
    });

  }

  ngOnChanges() {
  }

 
  
  onSubmit() {
    // this.dialogRef.close();
    console.log(this.itemForm.valid)
    console.log(this.itemForm.dirty)
    if (this.itemForm.valid && this.itemForm.dirty) {

      let tableName = this.cookieService.get(Item.servletName);
      if (this.editMode) {

        this.vehicleService.updateItem(this.itemForm.value, tableName);
      } else {

        this.vehicleService.createItem(this.itemForm.value, tableName);

        this.itemForm.patchValue({ "name": "" });
      }

    }

  }

  onCancel() {
    const modaldelete = this.modalService.open(ItemDeleteComponent);
    modaldelete.componentInstance.item = this.item;
  }

  onClone() {

    const modalclone = this.modalService.open(ItemCloneComponent);
    modalclone.componentInstance.item = this.item;


  }
  onChange(selectedIdJob) {
    let idJob;
    if (this.jobs != undefined) {
      this.jobs.forEach(job => {
        if (job.id == selectedIdJob) {
          this.vehicleService.getListHelper('product', selectedIdJob).subscribe(res => {
            this.products = res;
            this.itemForm.patchValue({ 'idProduct': this.products[0].id });
          });
        }
      });
    }

  }

  onCloseForm() {
    this.vehicleService.closeItemEditForm();

  }

  private initForm() {
    let idItem: number;
    let idForeign: number;
    let itemCode: number;
    let itemStatus = '';
    let type = 'DWG';

    let standardEquipments = false;
    let nonEdibleStores = false;
    let idJob: number;
    let jobName = '';
    let idProduct: number;
    let productName = '';
    let idDesign: number;
    let designName = '';

    let idTopological: number;
    let topologicalName = '';
    let idRevision: number;
    let revisionName = '';
    let firstRev = true;
    let lastRev = true;
    let enableForCALC = true;
    let name = '';

    let estimateWeight: number;
    let margin: number;
    let verifiedWeight: number;
    let idUnitsOfMeasure: number;
    let unitsOfMeasureName = '';
    let quantity: number;

    let weight: number;
    let xg: number;
    let yg: number;
    let zg: number;
    let note = '';

    let hasOtherRevisions = false;
    let lastUpdateDate = '';
    let tableName = '';
    let idAssembly: number;
    let isTrash = false;
    let isDelete = false;

    let conflictOptCode: number;
    let inseparableOptCode: number;

    let hasOtherConflictOptCode = false;
    let hasOtherInseparableOptCode = false;
    let isOptional = false;
    let assemblyHours: number;
    let price: number;
    let checkValue = false;
    let matchName = "Default";
    // this.item = this.vehicleService.selectedItem;
    if (this.editMode && this.item != undefined) {
      idItem = this.item.idItem;
      idForeign = this.item.idForeign;
      itemCode = this.item.itemCode;
      itemStatus = this.item.itemStatus;
      type = this.item.type;

      standardEquipments = this.item.standardEquipments;
      nonEdibleStores = this.item.nonEdibleStores;
      idJob = this.item.idJob;
      jobName = this.item.jobName;
      idProduct = this.item.idProduct;
      productName = this.item.productName;



      if (this.item.idDesign != 0) {
        idDesign = this.item.idDesign;
        designName = this.item.designName;
      }
      idTopological = this.item.idTopological;
      topologicalName = this.item.topologicalName;
      idRevision = this.item.idRevision;
      revisionName = this.item.revisionName;
      firstRev = this.item.firstRev;
      lastRev = this.item.lastRev;
      enableForCALC = this.item.enableForCALC;
      name = this.item.name;

      estimateWeight = this.item.estimateWeight;
      margin = this.item.margin;
      verifiedWeight = this.item.verifiedWeight;
      idUnitsOfMeasure = this.item.idUnitsOfMeasure;
      unitsOfMeasureName = this.item.unitsOfMeasureName;
      quantity = this.item.quantity;

      weight = this.item.weight;
      xg = this.item.xg;
      yg = this.item.yg;
      zg = this.item.zg;
      note = this.item.note;

      hasOtherRevisions = this.item.hasOtherRevisions;
      lastUpdateDate = this.item.lastUpdateDate;
      tableName = this.item.tableName;
      idAssembly = this.item.idAssembly;
      isTrash = this.item.isTrash;
      isDelete = this.item.isDelete;

      conflictOptCode = this.item.conflictOptCode;
      inseparableOptCode = this.item.inseparableOptCode;
      isOptional = this.item.isOptional;
      hasOtherConflictOptCode = this.item.hasOtherConflictOptCode;
      hasOtherInseparableOptCode = this.item.hasOtherInseparableOptCode;
      assemblyHours = this.item.assemblyHours;
      price = this.item.price;
      checkValue = this.item.checkValue;
      matchName = this.item.matchName;

    }



    this.itemForm = new FormGroup({
      'idItem': new FormControl(idItem),
      'idForeign': new FormControl(idForeign),
      'itemCode': new FormControl(itemCode),
      'itemStatus': new FormControl(itemStatus),
      'type': new FormControl(type),

      'standardEquipments': new FormControl(standardEquipments),
      'nonEdibleStores': new FormControl(nonEdibleStores),
      'idJob': new FormControl(idJob, Validators.required),
      'jobName': new FormControl(jobName),
      'idProduct': new FormControl(idProduct, Validators.required),
      'productName': new FormControl(productName),
      'idDesign': new FormControl(idDesign),
      'designName': new FormControl(designName),

      'idTopological': new FormControl(idTopological, Validators.required),
      'topologicalName': new FormControl(topologicalName),
      'idRevision': new FormControl(idRevision),
      'revisionName': new FormControl(revisionName),
      'firstRev': new FormControl(firstRev),
      'lastRev': new FormControl(lastRev),
      'enableForCALC': new FormControl(enableForCALC),
      'name': new FormControl(name, Validators.required),

     'estimateWeight': new FormControl(estimateWeight, [Validators.required, Validators.min(0)]),
     'margin': new FormControl(margin, [Validators.required, Validators.min(0), Validators.max(100)]),
      'verifiedWeight': new FormControl(verifiedWeight),
      'idUnitsOfMeasure': new FormControl(idUnitsOfMeasure, Validators.required),
      'unitsOfMeasureName': new FormControl(unitsOfMeasureName),
     'quantity': new FormControl(quantity, [Validators.required, Validators.min(0)]),

      'weight': new FormControl(weight),
      'xg': new FormControl(xg, Validators.required),
      'yg': new FormControl(yg, Validators.required),
      'zg': new FormControl(zg, Validators.required),
      'note': new FormControl(note),
      'tableName': new FormControl(tableName),

      'hasOtherRevisions': new FormControl(hasOtherRevisions),
      'lastUpdateDate': new FormControl(lastUpdateDate),
      'idAssembly': new FormControl(idAssembly),
      'isTrash': new FormControl(isTrash),
      'isDelete': new FormControl(isDelete),

      'conflictOptCode': new FormControl(conflictOptCode),
      'inseparableOptCode': new FormControl(inseparableOptCode),
      'isOptional': new FormControl(isOptional),
      'hasOtherConflictOptCode': new FormControl(hasOtherConflictOptCode),
      'hasOtherInseparableOptCode': new FormControl(hasOtherInseparableOptCode),
      'assemblyHours': new FormControl(assemblyHours),
      'price': new FormControl(price),
      'checkValue': new FormControl(checkValue),
      'matchName': new FormControl(matchName),

    });
    setTimeout(() => {
      if (!this.editMode && this.item != undefined) {
        this.inputEl.nativeElement.focus();
        this.itemForm.patchValue({ "idRevision": this.revisions[0].id });
        this.itemForm.patchValue({ "idJob": this.jobs[0].id });
        this.itemForm.patchValue({ "idProduct": this.products[0].id });
        this.itemForm.patchValue({ "idDesign": this.designs[0].id });
        this.itemForm.patchValue({ "idTopological": this.topologicals[0].id });
        this.itemForm.patchValue({ "idUnitsOfMeasure": this.unitsOfMeasures[0].id });

      }
    }, 300);

  }
}
