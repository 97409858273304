import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Hull } from '../../../shared/hull.model';
import { HullService } from '../hull.service';

@Component({
  selector: 'app-hull-delete',
  templateUrl: './hull-delete.component.html',
  styleUrls: ['./hull-delete.component.scss']
})
export class HullDeleteComponent implements OnInit {

  @Input() public hull: Hull;
  constructor(public hullService: HullService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.hullService.trashHull();
   
  }

}
