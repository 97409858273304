<div id="layout-wrapper">

    <app-topbar-delphy (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
    </app-topbar-delphy>

    <app-sidebar-delphy [isCondensed]="isCondensed"></app-sidebar-delphy>

    <div class="main-content">
        <div class="page-content">
            <!-- content -->
            <router-outlet></router-outlet>
        </div>

        <!-- footer -->
    </div>
</div>