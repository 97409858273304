import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Engine } from '../../../shared/engine.model';
import { EngineService } from '../engine.service';

@Component({
  selector: 'app-engine-delete',
  templateUrl: './engine-delete.component.html',
  styleUrls: ['./engine-delete.component.scss']
})
export class EngineDeleteComponent implements OnInit {

  @Input() public engine: Engine;
  constructor(public engineService: EngineService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.engineService.trashEngine();
   
  }

}
