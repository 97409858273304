<div class="content">


    <div class="row" style="font-weight: bold;">
        <div class="col-sm-10">
            <h5>Clone {{project.projectName}}</h5>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" aria-label="Close" title="Close" (click)="projectService.onCloseForm()">
                 <span aria-hidden="true"> &times;</span>
              </button>
        </div>
    </div>
    <hr>

    <div class="row">
        <div class="col-sm-1">
            <i class="bx bx-help-circle font-size-22" style="padding-top: 420%;"></i>
        </div>
        <div class="col-sm-11">
            <h4>You will clone these project-associated things:</h4>
            <ul style="text-align: left;">
                <li>
                    <h6>Job list: "{{project.jobListName}}"</h6>
                </li>
                <li>
                    <h6>Tolopogical list: "{{project.topologicalListName}}"</h6>
                </li>
                <li>
                    <h6>Design list: "{{project.designListName}}"</h6>
                </li>
                <li>
                    <h6>Units of measure list: "{{project.unitsOfMeasureListName}}"</h6>
                </li>
                <li>
                    <h6>Baseline and Optional Catalog items, cluster including</h6>
                </li>
                <li>
                    <h6>Vehicles and Revisions lists </h6>
                </li>
                <li>
                    <h6>Version, Propulsion and Optional lists vehicles-associated with relative items</h6>
                </li>



            </ul>
            <h6 style="padding-left: 10px;">Variants, Crew, Pax and Tanks lists will be not be cloned.</h6>
        </div>
    </div>
    <hr>

    <div class="row">
        <div class="col-sm-12">

            <form class="form" [formGroup]="projectForm" (ngSubmit)="onSubmit()">

                <input type="hidden" id="id" formControlName="id" class="form-control">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form form-group">
                            <label>Name*</label>
                            <input type="text" id="name" formControlName="name" class="form-control">
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-11">
                    </div>
                    <div class="col-sm-1" style="padding-left: 15px;">
                        <button type="submit" class="btn btn-success" [disabled]="!projectForm.valid"><i class="bx bxs-copy font-size-50"></i></button>
                        <div class="textUnderIcon">Clone</div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>