import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Vehicle } from '../../../shared/vehicle.model';
import { VehicleService } from '../vehicle.service';

@Component({
  selector: 'app-vehicle-delete',
  templateUrl: './vehicle-delete.component.html',
  styleUrls: ['./vehicle-delete.component.scss']
})
export class VehicleDeleteComponent implements OnInit {

  @Input() public vehicle: Vehicle;
  constructor(public vehicleService: VehicleService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.modalService.dismissAll();
    this.vehicleService.trashVehicle();
   
  }

}
